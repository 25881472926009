import { getSearchQuery } from '../config'

const apiConfig = {
  getBuilderSiteUnitListVM: {
    method: "GET",
    url: "api/Project/GetBuilderSiteUnitListVM"
  },
  getBuilderTaskListVM: {
    method: "GET",
    url: "api/Scheduling/BuilderTaskListVM"
  },
  getBuilderTaskList: {
    method: 'GET',
    url: ({
      siteUnitId = 0,
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = '',
      builderTaskListPage = '',
      taskViewMode = ''
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("TaskName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("BuilderTaskListPage", builderTaskListPage),
        getSearchQuery("TaskViewMode", taskViewMode),
        getSearchQuery("SiteUnitId", siteUnitId)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          unitName = '',
          taskName = '',
          assignees = [],
          status = [],
          siteIds = [],
          states = [],
          cities = [],
          zipCodes = [],
          streetAddress = "",
          requestedStartDate,
          requestedEndDate,
          scheduledStartDate,
          scheduledEndDate,
          completedStartDate,
          completedEndDate
        } = advanceSearch

        const assigneesList = assignees.map((assignee) => getSearchQuery("AssigneeIds", assignee.value)).join("")
        const siteIdsList = siteIds.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const statusList = status.map((st) => getSearchQuery("TaskStatusGroupIds", st.value)).join("")
        const statesList = states.map((obj) => getSearchQuery("States", obj.value)).join("")
        const citiesList = cities.map((obj) => getSearchQuery("Cities", obj.city)).join("")
        const zipCodesList = zipCodes.map((obj) => getSearchQuery("ZipCodes", obj.zipCode)).join("")

        searchString = [
          searchString,
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("TaskName", taskName, false, true),
          getSearchQuery("TaskScheduledDateFrom", scheduledStartDate),
          getSearchQuery("TaskScheduledDateTo", scheduledEndDate),
          getSearchQuery("TaskCompletionDateFrom", completedStartDate),
          getSearchQuery("TaskCompletionDateTo", completedEndDate),
          getSearchQuery("TaskRequestedDateFrom", requestedStartDate),
          getSearchQuery("TaskRequestedDateTo", requestedEndDate),
          getSearchQuery("StreetAddress", streetAddress),
          statesList,
          citiesList,
          zipCodesList,
          assigneesList,
          siteIdsList,
          statusList
        ].join('')
      }
      return `api/Scheduling/GetBuilderTaskList${searchString}`
    }
  },
  getBuilderSiteUnitList: {
    method: "GET",
    url: ({
      siteId = 0,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("UnitName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          type = "",
          streetAddress = "",
          unit = "",
          states = [],
          cities = [],
          zipCodes = []
        } = advanceSearch

        const statesList = states.map((obj) => getSearchQuery("States", obj.value)).join("")
        const citiesList = cities.map((obj) => getSearchQuery("Cities", obj.city)).join("")
        const zipCodesList = zipCodes.map((obj) => getSearchQuery("ZipCodes", obj.zipCode)).join("")

        searchString = [
          searchString,
          statesList,
          citiesList,
          zipCodesList,
          getSearchQuery("StreetAddress", streetAddress),
          getSearchQuery("UnitName", unit, false, true),
          getSearchQuery("UnitTypeId", type, false, true)
        ].join('')
      }

      return `api/Project/site/${siteId}/GetBuilderSiteUnitList${searchString}`
    }
  },
  getRequestInspetionVM: {
    method: "GET",
    url: "api/Scheduling/Builder/RequestInspectionVM"
  },
  getRequestInspetionTasks: {
    method: 'GET',
    url: ({
      siteIds = [],
      observationTaskName = "",
      siteUnitIds = 0,
      pageSize = 0,
      pageNumber
    }) => {
      const sitesIdsList = siteIds.map((data) => data.value).join(",")
      const siteUnitIdsList = siteUnitIds.map((data) => data.value).join(",")

      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteIds", sitesIdsList),
        getSearchQuery("ObservationTaskName", observationTaskName, false, true),
        getSearchQuery("SiteUnitIds", siteUnitIdsList)
      ].join('')
      return `api/Scheduling/Builder/RequestInspectionTasks/DDL${searchString}`
    }
  },
  updateRequestInspetion: {
    method: 'PUT',
    url: "api/Scheduling/Builder/RequestInspection"
  },
  getBuilderReinspectionFee: {
    method: 'GET',
    url: ({
      siteUnitIds,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections = ''
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteUnitIds", siteUnitIds, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      return `api/Campaign/Builder/ReinspectionFee${searchString}`
    }
  },
  getTaskListWidget: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = '',
      builderTaskListPage = '',
      taskViewMode = ''
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("TaskName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("BuilderTaskListPage", builderTaskListPage),
        getSearchQuery("TaskViewMode", taskViewMode)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          taskName = '',
          assignees = [],
          status = [],
          siteIds = [],
          states = [],
          cities = [],
          zipCodes = [],
          streetAddress = "",
          scheduledStartDate,
          scheduledEndDate,
          completedStartDate,
          completedEndDate
        } = advanceSearch

        const assigneesList = assignees.map((assignee) => getSearchQuery("AssigneeIds", assignee.value)).join("")
        const siteIdsList = siteIds.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const statusList = status.map((st) => getSearchQuery("TaskStatusGroupIds", st.value)).join("")
        const statesList = states.map((obj) => getSearchQuery("States", obj.value)).join("")
        const citiesList = cities.map((obj) => getSearchQuery("Cities", obj.city)).join("")
        const zipCodesList = zipCodes.map((obj) => getSearchQuery("ZipCodes", obj.zipCode)).join("")

        searchString = [
          searchString,
          getSearchQuery("TaskName", taskName, false, true),
          getSearchQuery("TaskScheduledDateFrom", scheduledStartDate),
          getSearchQuery("TaskScheduledDateTo", scheduledEndDate),
          getSearchQuery("TaskCompletionDateFrom", completedStartDate),
          getSearchQuery("TaskCompletionDateTo", completedEndDate),
          getSearchQuery("StreetAddress", streetAddress),
          statesList,
          citiesList,
          zipCodesList,
          assigneesList,
          siteIdsList,
          statusList
        ].join('')
      }
      return `api/Scheduling/Builder/TaskListWidget${searchString}`
    }
  }
}

export default apiConfig
