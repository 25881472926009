import React, { useState, useCallback, useEffect, useMemo } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap"
import { useDispatch } from "react-redux"

import { TABLE_LIST_KEYS } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { asyncForEach, getLocaleMessage } from "@utils"
import {
  CustomUILoader,
  SideModalCloseButton
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { RESOURCE_CENTER_SOURCE_TYPES } from "@views/components/resource-center-uploader/config"
import { PLAN_FORM_SECTION_TYPES } from "./config"
import { getInitialState, getFormattedOrgPlanPayload, hasValidationErrorInPlanDetails, hasValidationErrorInBlueprintDetails, hasValidationErrorInEnergyModelDetails, hasValidationErrorInPlanNameDetails, validatePlanDetail, validateBlueprintDetail, validateEnergyModelDetail } from "./helpers"
import PlanDetailForm from "./PlanDetailForm"
const {
  ORG_PLANS
} = LANGUAGE_CONSTANTS

const INITIAL_PLAN_DETAIL = {
  organizationPlanId: "",
  planName: "",
  planNumber: "",
  elevation: "",
  swing: "",
  blueprintId: "",
  blueprintName: "",
  energyModelId: "",
  energyModelName: "",
  totalConditionSpace: "",
  firstFloorArea: "",
  secondFloorArea: "",
  thirdFloorArea: "",
  fourthFloorArea: "",
  volume: "",
  mfncArea: "",
  missingItems: "",
  missingItemsName: ""
}

const OrgPlanForm = ({
  intl,
  isOrgPlan,
  isModal,
  planId,
  organizationId,
  siteUnitId = '',
  siteUnitPlanId = "",
  siteUnitPlanDetail = {},
  onToggleForm = () => { },
  onRefreshUnitDetail = () => { }
}) => {
  const dispatch = useDispatch()

  const [formSectionList, setActiveSectionList] = useState([PLAN_FORM_SECTION_TYPES.PLAN, PLAN_FORM_SECTION_TYPES.BLUEPRINT, PLAN_FORM_SECTION_TYPES.ENERGY_MODEL])
  const [planDetail, setPlanDetail] = useState(getInitialState({ isOrgPlan, siteUnitPlanId, siteUnitPlanDetail, initialState: INITIAL_PLAN_DETAIL }))
  const [blueprintFiles, setBlueprintFiles] = useState([])
  const [energyModelFiles, setEnergyModelFiles] = useState([])
  const [isFetching, setFetching] = useState(false)
  const [errors, setErrors] = useState({})
  const [isEnableAddNewPlanMode, setEnableAddNewPlanMode] = useState(isOrgPlan || false)
  const [isEnableAddNewBlueprintMode, setEnableAddNewBlueprintMode] = useState((isOrgPlan || !!planId) || false)
  const [isEnableAddNewEnergyModelMode, setEnableAddNewEnergyModelMode] = useState((isOrgPlan || !!planId) || false)
  const [isEditMode] = useState(isOrgPlan ? !!planId : !!siteUnitPlanId)

  const handleFetchPlanDetails = useCallback(({ selectedPlanId, onSetDetail = () => { } }) => {
    setFetching(true)
    const queryPayload = {
      organizationPlanId: selectedPlanId,
      siteUnitPlanId: Number(selectedPlanId) === Number(siteUnitPlanDetail?.organizationPlanId) ? siteUnitPlanId : undefined
    }
    dispatch(actions.getOrganizationPlanRequest(queryPayload, (res) => {
      if (res) {
        onSetDetail(res)
      }
      setFetching(false)
    }))
  }, [siteUnitPlanDetail.organizationPlanId, siteUnitPlanId])

  const handleFetchFiles = useCallback(({ key, value, onSetDetail = () => { } }) => {
    setFetching(true)
    const queryPayload = {
      [key]: value,
      pageSize: 100,
      pageNumber: 1
    }
    dispatch(actions.getResourceListRequest(queryPayload, (res) => {
      if (res) {
        onSetDetail(res.items || [])
      }
      setFetching(false)
    }))
  }, [])

  const handleResetState = useCallback(() => {
    setPlanDetail(Object.assign({}, INITIAL_PLAN_DETAIL))
    setErrors({})
  }, [])

  const handleCloseForm = useCallback(() => {
    handleResetState()
    onToggleForm()

    if (isOrgPlan) {
      dispatch(
        actions.refreshTableList({
          listKey: TABLE_LIST_KEYS.ORG_PLAN_LIST_KEY,
          value: true
        })
      )
    }
  }, [isOrgPlan, handleResetState, onToggleForm])

  const handleUpdatePlanDetail = useCallback((planId, data, isEditUnit) => {
    const updatedDetails = {
      organizationPlanId: planId,
      planName: data.planName,
      planNumber: data.planNumber,
      elevation: data.elevation,
      swing: data.swing,
      totalConditionSpace: String(data.totalConditionSpace || ""),
      firstFloorArea: String(data.firstFloorArea || ""),
      secondFloorArea: String(data.secondFloorArea || ""),
      thirdFloorArea: String(data.thirdFloorArea || ""),
      fourthFloorArea: String(data.fourthFloorArea || ""),
      volume: String(data.volume || ""),
      mfncArea: String(data.mfncCompartmentalizationArea || ""),
      missingItems: String(data.isMissingItems || ""),
      missingItemsName: data.isMissingItems ? "Yes" : "No"
    }
    setPlanDetail((prevState) => ({
      ...(isEditUnit ? prevState : INITIAL_PLAN_DETAIL),
      ...updatedDetails
    }))
  }, [])

  useEffect(() => {
    if (planId) {
      setErrors({})
      setBlueprintFiles([])
      setEnergyModelFiles([])
      handleFetchPlanDetails({
        selectedPlanId: planId,
        onSetDetail: (data) => {
          handleUpdatePlanDetail(planId, data)
        }
      })
    }
  }, [planId])

  useEffect(() => {
    if (!isOrgPlan && Number(siteUnitPlanId)) {
      handleFetchPlanDetails({
        selectedPlanId: planDetail.organizationPlanId,
        onSetDetail: (data) => {
          handleUpdatePlanDetail(planDetail.organizationPlanId, data, true)
          if (Number(planDetail.blueprintId)) {
            handleFetchFiles({
              key: "organizationBluePrintId",
              value: planDetail.blueprintId,
              onSetDetail: (res) => {
                setBlueprintFiles(res)
                setTimeout(() => {
                  if (Number(planDetail.energyModelId)) {
                    handleFetchFiles({
                      key: "organizationEnergyModelId",
                      value: planDetail.energyModelId,
                      onSetDetail: (resp) => {
                        setEnergyModelFiles(resp)
                      }
                    })
                  }
                }, 100)
              }
            })
          }
        }
      })
    }
  }, [])

  const handleChangeDetail = useCallback(
    (key, value, label, callback = () => {}) => {

      if (!!Object.keys(errors).length) {
        setErrors({})
      }

      if (key === "blueprintFiles") {
        setBlueprintFiles(value)
        return
      }

      if (key === "energyModelFiles") {
        setEnergyModelFiles(value)
        return
      }

      let updatedDetails = Object.assign({}, planDetail)
      if (key === "missingItems") {
        updatedDetails.missingItemsName = label
      }

      if (key === "organizationPlanId") {
        if (!!value) {
          updatedDetails = {
            ...INITIAL_PLAN_DETAIL,
            organizationId: value
          }
          setBlueprintFiles([])
          setEnergyModelFiles([])
          handleFetchPlanDetails({
            selectedPlanId: value,
            onSetDetail: (data) => {
              handleUpdatePlanDetail(value, data)
            }
          })
        } else {
          //To reset last selected plan details
          updatedDetails = {
            ...INITIAL_PLAN_DETAIL
          }
          setBlueprintFiles([])
          setEnergyModelFiles([])
        }
      } else {

        if (key === "blueprintId") {
          //To reset energy model details, when blueprint id changes
          updatedDetails.energyModelId = ""
          updatedDetails.energyModelName = ""
          setEnergyModelFiles([])

          if (!!value) {
            updatedDetails.blueprintName = label
            setBlueprintFiles([])
            handleFetchFiles({
              key: "organizationBluePrintId",
              value,
              onSetDetail: (res) => {
                setBlueprintFiles(res)
                callback()
              }
            })
          } else {
            //To reset blueprint details, when blueprint id changes
            updatedDetails.blueprintName = ""
            setBlueprintFiles([])
            callback()
          }
        }

        if (key === "energyModelId") {
          if (!!value) {
            updatedDetails.energyModelName = label
            setEnergyModelFiles([])
            handleFetchFiles({
              key: "organizationEnergyModelId",
              value,
              onSetDetail: (res) => {
                setEnergyModelFiles(res)
                callback()
              }
            })
          } else {
            //To reset energyModel details, when energyModel id changes
            updatedDetails.energyModelName = ""
            setEnergyModelFiles([])
            callback()
          }
        }

        if (key === "blueprintName" && !!updatedDetails.blueprintId) {
          updatedDetails.blueprintId = ""
          setBlueprintFiles([])
          updatedDetails.energyModelId = ""
          updatedDetails.energyModelName = ""
          setEnergyModelFiles([])
        }

        if (key === "energyModelName" && !!updatedDetails.energyModelId) {
          updatedDetails.energyModelId = ""
          updatedDetails.energyModelName = ""
          setEnergyModelFiles([])
        }

        updatedDetails[key] = value
      }

      setPlanDetail(updatedDetails)
    }, [planDetail, errors, handleFetchFiles, handleFetchPlanDetails])

  const handleUploadFile = useCallback((payload, callback) => {
    dispatch(
      actions.uploadRCDocumentRequest(payload, (data) => {
        callback(!!data)
      })
    )
  }, [])

  const handleRefreshUnitDetail = useCallback(() => {
    if (siteUnitId) {
      onRefreshUnitDetail()
    }
  }, [onRefreshUnitDetail])

  const handleUploadFilesRequest = useCallback(async (fileList, config) => {
    try {
      const { isAddNewBlueprint, isAddNewEnergyModel, callback = () => {} } = config || {}
      const lastFileIndex = fileList.length - 1
      let isListRefreshRequired = false
      await asyncForEach(fileList, async (sFile, index) => {
        const payload = {
          ...sFile,
          isLoadingNotRequired: true
        }
        const isLastFile = index === lastFileIndex
        handleUploadFile(payload, (isSaved) => {
          if (isLastFile) {
            callback()
          }
          if (!isAddNewBlueprint && !isAddNewEnergyModel) {
            if (isSaved) {
              isListRefreshRequired = true
            }
            if (isLastFile && isListRefreshRequired) {
              callback(true)
              handleCloseForm()
            }
          }
        })
      })
    } catch (err) {
      setFetching(false)
     }

  }, [handleUploadFile, handleCloseForm])

  const handleSaveNewBlueprintEnergyModel = useCallback(() => {
    setFetching(true)
    const errors = validateEnergyModelDetail(planDetail, energyModelFiles)
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      setFetching(false)
      return
    }
    const payload = {
      organizationBluePrintId: planDetail.blueprintId,
      organizationPlanId: planDetail.organizationPlanId,
      energyModelName: planDetail.energyModelName
    }
    dispatch(actions.addOrganizationPlanBlueprintEnergyModelRequest(payload, (res) => {
      if (res) {
        const updatedEnergyModelFiles = energyModelFiles.map((emf) => {
          return {
            ...emf,
            fileLocationType: RESOURCE_CENTER_SOURCE_TYPES.ENERGY_MODEL,
            organizationPlanId: payload.organizationPlanId,
            fileSourceName: "organizationEnergyModelId",
            fileSourceId: res
          }
        })
        if (!!updatedEnergyModelFiles.length) {
          handleUploadFilesRequest(updatedEnergyModelFiles, {
            isAddNewEnergyModel: true, 
            callback: () => {
              if (isOrgPlan) {
                handleChangeDetail("energyModelId", "", "", () => {
                  setTimeout(() => {
                    setFetching(false)
                  }, 10)
                })
              } else {
                handleChangeDetail("energyModelId", res.toString(), payload.energyModelName, () => {
                  setTimeout(() => {
                    setEnableAddNewEnergyModelMode(false)
                    setFetching(false)
                  }, 50)
                })
              }
            } 
          })
        } else {
          setFetching(false)
        }
      } else {
        setFetching(false)
      }
    }))
  }, [isOrgPlan, planDetail, energyModelFiles, handleChangeDetail, handleUploadFilesRequest])

  const handleSaveNewBlueprint = useCallback(() => {
    setFetching(true)
    const errors = validateBlueprintDetail(planDetail, blueprintFiles)
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      setFetching(false)
      return
    }

    const payload = {
      organizationPlanId: planDetail.organizationPlanId,
      bluePrintName: planDetail.blueprintName
    }
    dispatch(actions.addOrganizationPlanBlueprintRequest(payload, (res) => {
      if (res) {
          const updatedBlueprintFiles = blueprintFiles.map((bf) => {
            return {
              ...bf,
              fileLocationType: RESOURCE_CENTER_SOURCE_TYPES.BLUEPRINT,
              organizationPlanId: payload.organizationPlanId,
              fileSourceName: "OrganizationBluePrintId",
              fileSourceId: res
            }
          })
          if (!!updatedBlueprintFiles.length) {
            handleUploadFilesRequest(updatedBlueprintFiles, {
              isAddNewBlueprint: true, 
              callback: () => {
                if (isOrgPlan) {
                  setFetching(true)
                  handleChangeDetail("blueprintId", "", "", () => {
                    setTimeout(() => {
                      setFetching(false)
                    }, 10)
                  })
                } else {
                  setFetching(true)
                  handleChangeDetail("blueprintId", res.toString(), payload.bluePrintName, () => {
                    setTimeout(() => {
                      setEnableAddNewBlueprintMode(false)
                      setFetching(false)
                    }, 50)
                  })
                }
              } 
            })
          } else {
            setFetching(false)
          }
      } else {
        setFetching(false)
      }
    }))
  }, [isOrgPlan, planDetail, blueprintFiles, handleChangeDetail, handleUploadFilesRequest])

  const handleSavePlan = useCallback(() => {
    const errors = validatePlanDetail({
      data: planDetail, 
      blueprintFiles, 
      energyModelFiles, 
      siteUnitId, 
      isEnableAddNewPlanMode
    })

    if (!!Object.keys(errors).length) {
      setErrors(errors)

      const updatedSectionList = [...formSectionList]

      if (hasValidationErrorInPlanDetails(errors)) {
        if (!updatedSectionList.includes(PLAN_FORM_SECTION_TYPES.PLAN_DETAILS)) {
          updatedSectionList.push(PLAN_FORM_SECTION_TYPES.PLAN_DETAILS)
        }
      }

      if (hasValidationErrorInPlanNameDetails(errors)) {
        if (!updatedSectionList.includes(PLAN_FORM_SECTION_TYPES.PLAN)) {
          updatedSectionList.push(PLAN_FORM_SECTION_TYPES.PLAN)
        }
      }

      if (hasValidationErrorInBlueprintDetails(errors)) {
        if (!updatedSectionList.includes(PLAN_FORM_SECTION_TYPES.BLUEPRINT)) {
          updatedSectionList.push(PLAN_FORM_SECTION_TYPES.BLUEPRINT)
        }
      }

      if (hasValidationErrorInEnergyModelDetails(errors)) {
        if (!updatedSectionList.includes(PLAN_FORM_SECTION_TYPES.ENERGY_MODEL)) {
          updatedSectionList.push(PLAN_FORM_SECTION_TYPES.ENERGY_MODEL)
        }
      }
      setActiveSectionList(updatedSectionList)

      return true
    }

    const payload = getFormattedOrgPlanPayload({
      planDetail,
      organizationId,
      siteUnitId,
      siteUnitPlanId
    })

    //POST api called for following - 
    //To add org plan, 
    //to add new plan to unit, 
    //to add existing org plan to unit when no plan attached yet
    let apiActionFn = actions.updateOrganizationPlanRequest

    if (siteUnitId && siteUnitPlanId && payload.organizationPlanId) {
      //To edit existing plan for unit plan only, as plan is already attached
      apiActionFn = actions.updateOrganizationUnitPlanRequest
    }

    dispatch(apiActionFn(payload, (res) => {
      if (res) {
        if (!!planId && isOrgPlan) { //To close edit org plan view
          handleCloseForm()
        } else {
        const updatedBlueprintFiles = payload.organizationBluePrintId ? [] : blueprintFiles.map((bf) => {
          return {
            ...bf,
            fileLocationType: RESOURCE_CENTER_SOURCE_TYPES.BLUEPRINT,
            organizationPlanId: res.organizationPlanId,
            fileSourceName: "OrganizationBluePrintId",
            fileSourceId: res.organizationBluePrintId
          }
        })
        const updatedEnergyModelFiles = payload.organizationEnergyModelId ? [] : energyModelFiles.map((emf) => {
          return {
            ...emf,
            fileLocationType: RESOURCE_CENTER_SOURCE_TYPES.ENERGY_MODEL,
            organizationPlanId: res.organizationPlanId,
            fileSourceName: "organizationEnergyModelId",
            fileSourceId: res.organizationEnergyModelId
          }
        })
        const files = [...updatedBlueprintFiles, ...updatedEnergyModelFiles].filter(x => !x.resourceCenterId)
        if (!!files.length) {
          handleUploadFilesRequest(files, {
            callback: (isRefresh) => {
              if (isRefresh) {
                handleRefreshUnitDetail()
              }
            } 
          })
        } else {
          handleRefreshUnitDetail()
          handleCloseForm()
        }
      }
      }
    }))
  }, [
      blueprintFiles, 
      energyModelFiles,
      errors, 
      formSectionList,
      isEnableAddNewPlanMode,
      organizationId,
      planId,
      planDetail, 
      siteUnitId, 
      siteUnitPlanId,
      handleCloseForm,
      handleRefreshUnitDetail
    ])

  if (isModal) {
    return (
      <Modal
        isOpen
        className={"sidebar-sm"}
        modalClassName={"modal-slide-in note-media-modal schedule-time-modal"}
        contentClassName="p-0"
      >
        <ModalHeader
          className="mb-2"
          toggle={handleCloseForm}
          close={<SideModalCloseButton onClose={handleCloseForm} />}
          tag="div"
        >
          <h5 className="modal-title">
            {getLocaleMessage(intl, ORG_PLANS.MANAGE_UNIT_PLAN)}
          </h5>
        </ModalHeader>
        <CustomUILoader isLoading={isFetching}>
          <ModalBody className="flex-grow-1">
            <PlanDetailForm
              isModal
              isOrgPlan={isOrgPlan}
              planDetail={planDetail}
              blueprintFiles={blueprintFiles}
              energyModelFiles={energyModelFiles}
              organizationId={organizationId}
              errors={errors}
              formSectionList={formSectionList}
              isEnableAddNewPlanMode={isEnableAddNewPlanMode}
              isEnableAddNewBlueprintMode={isEnableAddNewBlueprintMode}
              isEnableAddNewEnergyModelMode={isEnableAddNewEnergyModelMode}
              isEditMode={isEditMode}
              onClose={handleCloseForm}
              onChangeDetail={handleChangeDetail}
              onSavePlan={handleSavePlan}
              onSaveNewBlueprint={handleSaveNewBlueprint}
              onSaveNewBlueprintEnergyModel={handleSaveNewBlueprintEnergyModel}
              onSetActiveSectionList={setActiveSectionList}
              onSetEnableAddNewPlanMode={setEnableAddNewPlanMode}
              onSetEnableAddNewBlueprintMode={setEnableAddNewBlueprintMode}
              onSetEnableAddNewEnergyModelMode={setEnableAddNewEnergyModelMode}
            />
          </ModalBody>
        </CustomUILoader>
      </Modal>
    )
  }

  return (
    <Card>
      <CustomUILoader isLoading={isFetching}>
        <CardBody className="p-0">
          <CardHeader className="p-0 setup-header">
            <div className="setup-card-title">
              <CardTitle className={"setup-title mb-2"} tag="h4">
                {getLocaleMessage(intl, ORG_PLANS.ORGANIZATION_PLAN)}
              </CardTitle>
            </div>
          </CardHeader>
          <Form>
            <PlanDetailForm
              planDetail={planDetail}
              blueprintFiles={blueprintFiles}
              energyModelFiles={energyModelFiles}
              organizationId={organizationId}
              errors={errors}
              formSectionList={formSectionList}
              isEnableAddNewPlanMode={isEnableAddNewPlanMode}
              isEnableAddNewBlueprintMode={isEnableAddNewBlueprintMode}
              isEnableAddNewEnergyModelMode={isEnableAddNewEnergyModelMode}
              isOrgPlan={isOrgPlan}
              isEditMode={isEditMode}
              onClose={handleCloseForm}
              onChangeDetail={handleChangeDetail}
              onSavePlan={handleSavePlan}
              onSaveNewBlueprint={handleSaveNewBlueprint}
              onSaveNewBlueprintEnergyModel={handleSaveNewBlueprintEnergyModel}
              onSetActiveSectionList={setActiveSectionList}
              onSetEnableAddNewPlanMode={setEnableAddNewPlanMode}
              onSetEnableAddNewBlueprintMode={setEnableAddNewBlueprintMode}
              onSetEnableAddNewEnergyModelMode={setEnableAddNewEnergyModelMode}
            />
          </Form>
        </CardBody>
      </CustomUILoader>
    </Card>
  )
}

export default localeMessageWrapper(OrgPlanForm)
