import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method uploadRCDocument : To upload document to resource center
 * 
 * Payload contains following:-
 * ResourceCenterSourceId : Source page id
 * Title
 * FileDescription
 * [data.fileSourceName]
 * file
 * 
 * data contains following:-
 * - fileLocationType
 * - title
 * - fileDescription
 * - fileSourceName
 * - fileSourceId
 * - fileContent
 * 
 * data.fileSourceName can be following:-
 * - OrganizationId
 * - ProjectId
 * - SiteId
 * - SiteUnitId
 * - ProgramId
 * - CampaignId
 * - CampaignServiceId
 * - CheckListId
 * - ActivityId
 * - ActivityVersionId 
 * - ObservationTaskId 
 * 
 */

export const uploadRCDocument = (data) => {
  const { url } = config.uploadRCDocument

  const queryPayload = {
    resourceCenterSourceId: Number(data.fileLocationType),
    title: data.title,
    fileDescription: data.fileDescription,
    fileSourceName: data.fileSourceName,
    fileSourceId: !!data.isRequestInspectionModal ? data.fileSourceId : Number(data.fileSourceId),
    programRequirementId: Number(data.programRequirementId),
    programRequirementServiceId: Number(data.programRequirementServiceId),
    siteId: data.siteId,
    organizationId: data.organizationId,
    fileCategoryId: Number(data.fileCategoryId),
    organizationPlanId: Number(data.organizationPlanId),
    additionalPOId: Number(data.additionalPOId),
    observationTaskId: Number(data.observationTaskId)
  }

  if (!!data.isRequestInspectionModal) {
    queryPayload.isForUnitToo = true
    queryPayload.isRequestInspectionModal = data.isRequestInspectionModal
  }

  const payload = new FormData()
  payload.append("UploadDocument.file", data.fileContent, data.fileContent.name)

  return postDataApi(url(queryPayload), payload, {
    'Content-Type': 'multipart/form-data'
  })
}

export default uploadRCDocument
