import cx from "classnames"
import React, { useCallback, useRef, useEffect, useState } from "react"
import { FormGroup, Button } from "reactstrap"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { cross } from "@src/assets/images/new-theme"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, ResourceCenterUploader, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { ORG_PLANS } = LANGUAGE_CONSTANTS

const FileUploader = ({
  intl,
  id = "",
  label = "",
  errors = {},
  defaultFileCategoryLabel = "",
  isDisableFileCategory = false,
  isRequiresSolidButton = true,
  isRequired = false,
  canUploadFiles = true,
  canDeleteFiles = true,
  isUploadDisabled = false,
  value,
  onChangeDetail = () => { }
}) => {
  const dispatch = useDispatch()

  const hiddenFileInput = useRef(null)
  const [isShowUploader, setShowUploader] = useState(false)

  const handleChangeRef = useCallback(() => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = null
    }
  }, [])

  const handleToggleFileUploaderModal = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setShowUploader(!isShowUploader)
  }, [isShowUploader])

  useEffect(() => {
    return () => {
      handleChangeRef()
    }
  }, [])

  const handleChangeDetail = useCallback(
    (fileList) => {
      onChangeDetail(id, fileList)
    },
    [onChangeDetail]
  )

  const handleUploadFile = useCallback(({ fileList, customFileDetailList }) => {
    if (fileList.length) {
      const formattedFiles = !!value ? [...value] : []
      fileList.forEach((sFile, index) => {
        const fileDetail = customFileDetailList[index]
        formattedFiles.push({
          title: `${fileDetail.fileName}${fileDetail.extension}`,
          fileDescription: fileDetail.fileDescription,
          fileContent: sFile,
          fileCategoryId: fileDetail.fileCategoryId
        })
      })
      handleChangeDetail(formattedFiles)
    }
  },
    [value, handleChangeDetail]
  )

  const handleRemoveFile = useCallback(
    (index) => {
      const fileList = [...value]
      const selectedFile = fileList[index]

      if (selectedFile?.resourceCenterId) {
        dispatch(
          actions.deleteResourceCenterRequest(selectedFile.resourceCenterId, (res) => {
            if (res) {
              fileList.splice(index, 1)
              handleChangeDetail(fileList)
            }
          })
        )
      } else {
        fileList.splice(index, 1)
        handleChangeDetail(fileList)
      }
    },
    [value, handleChangeDetail]
  )

  const handleDownloadFile = useCallback(({ fileContent, fileURL, title }) => {
    const link = document.createElement("a")
    let url = fileURL
    if (!url) {
      if (!fileContent) {
        return
      }
      const blob = new Blob([fileContent])
      url = URL.createObjectURL(blob)
    }

    link.setAttribute("href", url)
    link.setAttribute("download", title)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const handleDownloadMultipleFiles = useCallback(() => {
    value.forEach((obj, index) => {
      setTimeout(() => {
        handleDownloadFile(obj)
      }, index * 1000) // A delay of 1 second between each download
    })
  }, [value, handleDownloadFile])

  return (
    <>
      {isShowUploader && (
        <ResourceCenterUploader
          isResourceUploader
          isAllFilesAllowed
          defaultFileCategoryLabel={defaultFileCategoryLabel}
          isInternalUploadRequired={false}
          isDisableFileCategory={isDisableFileCategory}
          onSetSelectedFiles={handleUploadFile}
          onClose={handleToggleFileUploaderModal}
        />
      )}
      <FormGroup>
        <div className="d-flex justify-content-between align-items-center">
          <CustomLabel title={label} required={isRequired} />
          {!!value.length && <Button
            className={"waves-effect primary-text-btn p-0"}
            color="link"
            onClick={handleDownloadMultipleFiles}
          >
            {getLocaleMessage(intl, ORG_PLANS.DOWNLOAD_ALL)}
          </Button>}
        </div>
        <div className="d-flex w-100">
          <div className={cx("upload-box task-data-upload w-100", { "plan-upload-section": isRequiresSolidButton })}>
            {canUploadFiles && <Button
              id={id}
              outline
              disabled={isUploadDisabled}
              className={cx("waves-effect grey-outlined mw-100 w-100", { disabled: isUploadDisabled })}
              onClick={handleToggleFileUploaderModal}
            >
              {getLocaleMessage(
                intl,
                ORG_PLANS.UPLOAD_FILES
              )}
            </Button>}
            {!!value.length && <label> {getLocaleMessage(intl, ORG_PLANS.ATTACHED_FILES, { count: value.length })}</label>}
            {!!value.length && (
              <div
                className={cx("accident-place-imgs")}
              >
                {value.map((file, index) => {
                  if (file.isDeleted) {
                    return null
                  }

                  return (
                    <div key={`file_response_${index}`}>
                      <span className="plan-details-attached-filename">
                        <span onClick={() => handleDownloadFile(file)}>
                          {file.title || file.fileName}
                        </span>
                        {canDeleteFiles && <span
                          className="cross"
                          onClick={() => handleRemoveFile(index)}
                        >
                          <img src={cross} alt="cross" />
                        </span>}
                      </span>

                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </FormGroup>
      {errors && (
        <ErrorMessage
          isShow={!!errors[id]}
          message={errors[id]}
        />
      )}
    </>
  )
}

export default localeMessageWrapper(FileUploader)
