import config from "./APIConfig"
import { getDataApi } from "../index"

export const getActionConfirmationVM = async () => {
  const { url } = config.getActionConfirmationVM
  return await getDataApi(url)
}

export const getActivityCollection = async (data) => {
  const { url } = config.getActivityCollection
  return await getDataApi(url(data))
}

export const getActivityCountStatus = async (data) => {
  const { url } = config.getActivityCountStatus
  return await getDataApi(url(data))
}

export const getActivityReviewVM = async () => {
  const { url } = config.getActivityReviewVM
  return await getDataApi(url())
}

export const getInProgressWidget = async (data) => {
  const { url } = config.getInProgressWidget
  return await getDataApi(url(data))
}

export const getActivityNoteAndMedia = async (data) => {
  const { url } = config.getActivityNoteAndMedia
  return await getDataApi(url(data))
}

export const getObservationTask = async (data) => {
  const { url } = config.getObservationTask
  return await getDataApi(url(data))
}

export const getObservationTaskListView = async (data) => {
  const { cancelToken = '', ...payload } = data
  const { url } = config.getObservationTaskListView

  if (cancelToken) { // If cancelToken is provided, use it to cancel the API request if necessary
    const headers = {
      accept: "text/plain"
    }
    // Send request with cancellation token
    return await getDataApi(url(payload), headers, { cancelToken })
  }

  return await getDataApi(url(payload))
}

export const getObservationTaskMyView = async (data) => {
  const { url } = config.getObservationTaskMyView
  return await getDataApi(url(data))
}

export const getObservationTaskViewVM = async () => {
  const { url } = config.getObservationTaskViewVM
  return await getDataApi(url())
}

export const getOverdueTaskWidget = async (data) => {
  const { url } = config.getOverdueTaskWidget
  return await getDataApi(url(data))
}

export const getPendingCompletionTaskWidget = async (data) => {
  const { url } = config.getPendingCompletionTaskWidget
  return await getDataApi(url(data))
}

export const getPreTaskCheckList = async (data) => {
  const { url } = config.getPreTaskCheckList
  return await getDataApi(url(data))
}

export const getSelfAssignTask = async (data) => {
  const { cancelToken = '', ...payload } = data
  const { url } = config.getSelfAssignTask

  if (cancelToken) { // If cancelToken is provided, use it to cancel the API request if necessary
    const headers = {
      accept: "text/plain"
    }
    // Send request with cancellation token
    return await getDataApi(url(payload), headers, { cancelToken })
  }

  return await getDataApi(url(payload))
}

export const getTaskCanSelfAssign = async (data) => {
  const { url } = config.getTaskCanSelfAssign
  return await getDataApi(url(data))
}

export const getTaskCount = async (data) => {
  const { url } = config.getTaskCount
  return await getDataApi(url(data))
}

export const getTaskHeaderDetail = async (data) => {
  const { url } = config.getTaskHeaderDetail
  return await getDataApi(url(data))
}

export const getTaskTargetTabDetail = async (data) => {
  const { url } = config.getTaskTargetTabDetail
  return await getDataApi(url(data))
}

export const getTaskLinkList = async (data) => {
  const { url } = config.getTaskLinkList
  return await getDataApi(url(data))
}

export const getTaskLinksCount = async (data) => {
  const { url } = config.getTaskLinksCount
  return await getDataApi(url(data))
}

export const getReassignmentContactList = async (data) => {
  const { url } = config.getReassignmentContactList
  return await getDataApi(url(data))
}
