export const GET_BUILDER_SITE_UNIT_LIST_VM = 'GET_BUILDER_SITE_UNIT_LIST_VM'
export const GET_BUILDER_TASK_LIST_VM = 'GET_BUILDER_TASK_LIST_VM'
export const GET_BUILDER_TASK_LIST = 'GET_BUILDER_TASK_LIST'
export const GET_REQUEST_INSPECTION_VM = 'GET_REQUEST_INSPECTION_VM'
export const GET_REQUEST_INSPECTION_TASKS = 'GET_REQUEST_INSPECTION_TASKS'
export const UPDATE_REQUEST_INSPECTION = 'UPDATE_REQUEST_INSPECTION'
export const GET_BUILDER_SITE_UNIT_LIST = 'GET_BUILDER_SITE_UNIT_LIST'
export const GET_BUILDER_RE_INSPECTION_FEE = 'GET_BUILDER_RE_INSPECTION_FEE'
export const GET_TASK_LIST_WIDGET = 'GET_TASK_LIST_WIDGET'


/****************************************************/
/** GET_BUILDER_SITE_UNIT_LIST_VM **/
/****************************************************/

/**
 * @method getBuilderSiteUnitListVM :  To get builder site unit list VM
 *
 */
export const getBuilderSiteUnitListVMRequest = callback => {
  return {
    type: GET_BUILDER_SITE_UNIT_LIST_VM,
    callback
  }
}

/****************************************************/
/** GET_BUILDER_TASK_LIST_VM **/
/****************************************************/

/**
 * @method getBuilderTaskListVM :  To get builder task list VM
 *
 */
export const getBuilderTaskListVMRequest = callback => {
  return {
    type: GET_BUILDER_TASK_LIST_VM,
    callback
  }
}

/****************************************************/
/** GET_BUILDER_TASK_LIST **/
/****************************************************/

/**
 * @method getBuilderTaskList :  To get builder task list
 *
 */
export const getBuilderTaskListRequest = (payload, callback) => {
  return {
    type: GET_BUILDER_TASK_LIST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REQUEST_INSPECTION_VM **/
/****************************************************/

/**
 * @method getBuilderTaskListVMRequest :  To get builder Request Inspection VM
 *
 */
export const getRequestInspetionVMRequest = (callback) => {
  return {
    type: GET_REQUEST_INSPECTION_VM,
    callback
  }
}
/****************************************************/
/** GET_REQUEST_INSPECTION_TASKS **/
/****************************************************/

/**
 * @method getRequestInspetionTasksRequest :  To get builder Request Inspection builder task
 *
 */
export const getRequestInspetionTasksRequest = (payload, callback) => {
  return {
    type: GET_REQUEST_INSPECTION_TASKS,
    payload,
    callback
  }
}
/****************************************************/
/** UPDATE_REQUEST_INSPECTION **/
/****************************************************/

/**
 * @method updateRequestInspetionRequest :  To update builder Request Inspection task
 *
 */
export const updateRequestInspetionRequest = (payload, callback) => {
  return {
    type: UPDATE_REQUEST_INSPECTION,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BUILDER_SITE_UNIT_LIST **/
/****************************************************/

/**
 * @method getBuilderSiteUnitList :  To get builder task list VM
 *
 */
export const getBuilderSiteUnitListRequest = (payload, callback) => {
  return {
    type: GET_BUILDER_SITE_UNIT_LIST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BUILDER_RE_INSPECTION_FEE **/
/****************************************************/

/**
 * @method getBuilderReinspectionFee :  To get builder reinspection fee list
 *
 */
export const getBuilderReinspectionFeeRequest = (payload, callback) => {
  return {
    type: GET_BUILDER_RE_INSPECTION_FEE,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_LIST_WIDGET **/
/****************************************************/

/**
 * @method getTaskListWidgetRequest :  To task list widgets
 *
 */
export const getTaskListWidgetRequest = (payload, callback) => {
  return {
    type: GET_TASK_LIST_WIDGET,
    payload,
    callback
  }
}