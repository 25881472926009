import { getSearchQuery } from '../config'

const apiConfig = {
  getActivityCollection: {
    method: 'GET',
    url: ({
      observationTaskId,
      activityStatusId,
      WFActionId,
      pageNumber,
      pageSize,
      langauge,
      isReviewActivity,
      isApproved,
      activityReviewTypeId,
      groupId,
      isIncludingActionInfo,
      assignmentActivityId
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GroupId", groupId),
        getSearchQuery("AssignmentActivityId", assignmentActivityId),
        langauge ? `&Langauge=${langauge}` : '',
        isApproved ? `&isApproved=${isApproved}` : '',
        isReviewActivity ? `&isReviewActivity=true` : '',
        activityReviewTypeId ? `&ActivityReviewTypeId=${activityReviewTypeId}` : '',
        getSearchQuery("IsIncludingActionInfo", isIncludingActionInfo)
      ].join('')

      if (WFActionId) {
        return `api/Checkpoint/InspectionDetail/${WFActionId}${searchString}`
      }

      return `api/Checkpoint/activitycollection/${observationTaskId}/${activityStatusId}${searchString}`
    }
  },
  getActivityCountStatus: {
    method: 'GET',
    url: (observationTaskId) => `api/Checkpoint/ActivityCountStatus/${observationTaskId}`
  },
  getObservationTaskListView: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      isMyTaskOnly = false,
      taskAssignedToId = "",
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("IsMyTaskOnly", isMyTaskOnly)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const { 
          timeInterval = "",
          checkListName = "",
          taskTypeIds = [],
          taskStatusIds = [],
          assigneeIds = [],
          organizationIds = [],
          siteIds = [],
          unitName = "",
          blockIds = [],
          campaignIds = [],
          campaignTypeIds = [],
          cityNames = [],
          stateCodes = [],
          regionIds = [],
          zipCodes = [],
          scheduleStartDate,
          scheduleEndDate,
          organization = [],
          sites = [],
          units = []
        } = advanceSearch

        const taskTypeIdSearch = taskTypeIds.map((val) => getSearchQuery("TaskTypeIds", val)).join("")
        const taskStatusIdSearch = taskStatusIds.map((val) => getSearchQuery("TaskStatusIds", val)).join("")
        const assigneeIdSearch = taskAssignedToId ? getSearchQuery("ContactIds", taskAssignedToId) : assigneeIds.map((val) => getSearchQuery("ContactIds", val)).join("")
        const organizationIdSearch = organizationIds.map((val) => getSearchQuery("OrganizationIds", val)).join("")
        const siteIdSearch = siteIds.map((val) => getSearchQuery("SiteIds", val)).join("")
        const blockIdSearch = blockIds.map((val) => getSearchQuery("Blocks", val)).join("")
        const campaignIdSearch = campaignIds.map((val) => getSearchQuery("CampaignIds", val)).join("")
        const campaignTypeIdSearch = campaignTypeIds.map((val) => getSearchQuery("CampaignTypeIds", val)).join("")
        const cityNameSearch = cityNames.map((val) => getSearchQuery("Cities", val, false, true)).join("")
        const stateCodeSearch = stateCodes.map((val) => getSearchQuery("States", val)).join("")
        const regionIdSearch = regionIds.map((val) => getSearchQuery("RegionIds", val)).join("")
        const zipCodeSearch = zipCodes.map((val) => getSearchQuery("ZipCodes", val)).join("")

        const organizationListId = organization.map((data) => getSearchQuery("OrganizationIds", data.organizationId)).join("")
        const siteSearch = sites.map((data) => getSearchQuery("SiteIds", data.value)).join("")
        const unitSearch = units.map((data) => getSearchQuery("SiteUnitIds", data.value)).join("")

        searchString = [
          searchString, 
          getSearchQuery("TimeInterval", timeInterval),
          getSearchQuery("CheckListName", checkListName, false, true),
          taskTypeIdSearch, 
          taskStatusIdSearch,
          assigneeIdSearch,
          organizationIdSearch,
          organizationListId,
          siteIdSearch,
          siteSearch,
          getSearchQuery("UnitName", unitName, false, true),
          unitSearch,
          blockIdSearch,
          campaignIdSearch,
          campaignTypeIdSearch,
          getSearchQuery("StartDate", scheduleStartDate),
          getSearchQuery("EndDate", scheduleEndDate),
          cityNameSearch,
          stateCodeSearch,
          regionIdSearch,
          zipCodeSearch
        ].join("")
      }

      return `api/Checkpoint/ObservationTaskListView${searchString}`
    }
  },
  getObservationTaskMyView: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      timeInterval,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("TimeInterval", timeInterval, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      if (!!Object.keys(advanceSearch).length) {
        const {
          checkListName,
          taskTypeId,
          taskStatusId = [],
          organization = [],
          assignee = [],
          projectId,
          programId,
          campaignId,
          workflowId,
          siteId,
          unitName,
          programName,
          programTypeId,
          campaignName,
          campaignTypeId,
          startDate,
          endDate,
          city,
          country,
          zipCode,
          state,
          block,
          type
        } = advanceSearch
        const assigneeListIds = assignee.map((data) => getSearchQuery("ContactIds", data.value)).join("")
        const taskStatusListId = taskStatusId.map((task) => getSearchQuery("TaskStatusIds", task)).join("")
        const organizationListIds = organization.map((data) => getSearchQuery("OrganizationIds", data.organizationId)).join("")
        
        searchString = [
          searchString,
          getSearchQuery("CheckListName", checkListName, false, true),
          getSearchQuery("TaskTypeId", taskTypeId),
          getSearchQuery("CampaignId", campaignId),
          getSearchQuery("WFCampaignSetupId", workflowId),
          getSearchQuery("ProgramId", programId),
          taskStatusListId,
          assigneeListIds,
          organizationListIds,
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("ProgramName", programName, false, true),
          getSearchQuery("ProgramTypeId", programTypeId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          getSearchQuery("City", city, false, true),
          getSearchQuery("State", state),
          getSearchQuery("CountryCode", country),
          getSearchQuery("ZipCode", zipCode, false, true),
          getSearchQuery("Block", block, false, true),
          getSearchQuery("UnitTypeId", type)
        ].join('')
      }

      return `api/Checkpoint/ObservationTaskMyView/${timeInterval}${searchString}`
    }
  },
  getTaskHeaderDetail: {
    method: 'GET',
    url: ({ observationTaskId }) => `api/Scheduling/TaskHeaderDetail/${observationTaskId}`
  },
  getTaskTargetTabDetail: {
    method: 'GET',
    url: ({ observationTaskId }) => `api/Scheduling/TargetTab/${observationTaskId}`
  },
  saveActivityCollection: {
    method: 'POST',
    url: ({
      assignmentId
    }) => {
      return `api/Checkpoint/TaskActivityCollection/${assignmentId}`
    }
  },
  updateCourseRegistration: {
    method: 'POST',
    url: 'api/Checkpoint/UpdateCourseRegistration'
  },
  getObservationTaskViewVM: {
    method: 'GET',
    url: () => `api/Checkpoint/ObservationTaskViewVM`
  },

  getSelfAssignTask: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      advanceSearch = {},
      checkListName,
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CheckListName", checkListName, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const { 
          timeInterval, 
          taskTypeIds = [],
          assigneeIds = [],
          organizationIds = [],
          siteIds = [],
          unitName,
          blockIds = [],
          campaignIds = [],
          campaignTypeIds = [],
          cityNames = [],
          stateCodes = [],
          regionIds = [],
          zipCodes = [],
          scheduleStartDate,
          scheduleEndDate,
          organization = [],
          sites = [],
          units = []
        } = advanceSearch
        const taskTypeIdSearch = taskTypeIds.map((val) => getSearchQuery("TaskTypeIds", val)).join("")
        const assigneeIdSearch = assigneeIds.map((val) => getSearchQuery("ContactIds", val)).join("")
        const organizationIdSearch = organizationIds.map((val) => getSearchQuery("OrganizationIds", val)).join("")
        const siteIdSearch = siteIds.map((val) => getSearchQuery("SiteIds", val)).join("")
        const blockIdSearch = blockIds.map((val) => getSearchQuery("Blocks", val)).join("")
        const campaignIdSearch = campaignIds.map((val) => getSearchQuery("CampaignIds", val)).join("")
        const campaignTypeIdSearch = campaignTypeIds.map((val) => getSearchQuery("CampaignTypeIds", val)).join("")
        const cityNameSearch = cityNames.map((val) => getSearchQuery("Cities", val, false, true)).join("")
        const stateCodeSearch = stateCodes.map((val) => getSearchQuery("States", val)).join("")
        const regionIdSearch = regionIds.map((val) => getSearchQuery("RegionIds", val)).join("")
        const zipCodeSearch = zipCodes.map((val) => getSearchQuery("ZipCodes", val)).join("")

        const organizationListId = organization.map((data) => getSearchQuery("OrganizationIds", data.organizationId)).join("")
        const siteSearch = sites.map((data) => getSearchQuery("SiteIds", data.value)).join("")
        const unitSearch = units.map((data) => getSearchQuery("SiteUnitIds", data.value)).join("")

        searchString = [
          searchString, 
          getSearchQuery("TimeInterval", timeInterval), 
          taskTypeIdSearch, 
          assigneeIdSearch,
          organizationIdSearch,
          organizationListId,
          siteIdSearch,
          siteSearch,
          getSearchQuery("UnitName", unitName, false, true),
          unitSearch,
          blockIdSearch,
          campaignIdSearch,
          campaignTypeIdSearch,
          getSearchQuery("StartDate", scheduleStartDate),
          getSearchQuery("EndDate", scheduleEndDate),
          cityNameSearch,
          stateCodeSearch,
          regionIdSearch,
          zipCodeSearch
        ].join("")
        if (!checkListName) {
          searchString = [
            searchString, 
            getSearchQuery("CheckListName", advanceSearch.checkListName, false, true)
          ].join("")
        }
      }

      return `api/Checkpoint/GetSelfScheduleList${searchString}`
    }
  },
  saveCompleteTask: {
    method: 'POST',
    url: ({ assignmentId }) => {
      return `api/Checkpoint/SubmitTask/${assignmentId}`
    }
  },
  startChecklistActivity: {
    method: 'POST',
    url: ({ observationTaskId, isFCInspectionReady }) => {
      return `api/Scheduling/confirmFCInspectionReady/${observationTaskId}?IsFCInspectionReady=${isFCInspectionReady}`
    }
  },
  getReassignmentContactList: {
    method: 'GET',
    url: ({ observationTaskId, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Scheduling/GetOrganizationContact/${observationTaskId}${searchString}`
    }
  },
  updateTaskReassignmentContact: {
    method: 'PUT',
    url: ({ observationTaskId, contactID }) => `api/Scheduling/Assignment/${observationTaskId}/${contactID}`
  },
  saveImagesAndNotes: {
    method: 'POST',
    url: ({ checkpointId, checkpointVersionId, assignmentId }) => {
      return `api/Checkpoint/activityAuditTrail/${checkpointId}/${checkpointVersionId}/${assignmentId}`
    }
  },
  addNoteAndMedia: {
    method: 'POST',
    url: ({ assignmentActivityId }) => {
      return `api/Checkpoint/ActivityNote/${assignmentActivityId}`
    }
  },
  getInProgressWidget: {
    method: 'GET',
    url: ({
      advanceSearch = {}
    }) => {
      let searchString
      if (!!Object.keys(advanceSearch).length) {
        const {
          checkListName,
          unitName,
          programName,
          campaignName,
          campaignTypeId,
          programTypeId,
          projectId,
          siteId,
          taskTypeId,
          startDate,
          endDate,
          organization
        } = advanceSearch
        const organizationIdList = organization.map((org) => getSearchQuery("OrganizationIds", org.organizationId)).join("")
        searchString = [
          getSearchQuery("CheckListName", checkListName, false, true),
          getSearchQuery("TaskTypeId", taskTypeId),
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("ProgramName", programName, false, true),
          getSearchQuery("ProgramTypeId", programTypeId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          organizationIdList
        ].join('')
      }
      searchString = searchString.replace('&', '?')
      return `api/Report/Widget/Tasks/InProgressTaskCount${searchString}`
    }
  },
  getOverdueTaskWidget: {
    method: 'GET',
    url: ({
      advanceSearch = {}
    }) => {
      let searchString
      if (!!Object.keys(advanceSearch).length) {
        const {
          checkListName,
          unitName,
          programName,
          campaignName,
          campaignTypeId,
          programTypeId,
          projectId,
          siteId,
          taskTypeId,
          startDate,
          endDate,
          organization
        } = advanceSearch
        const organizationIdList = organization.map((org) => getSearchQuery("OrganizationIds", org.organizationId)).join("")
        searchString = [
          getSearchQuery("CheckListName", checkListName, false, true),
          getSearchQuery("TaskTypeId", taskTypeId),
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("ProgramName", programName, false, true),
          getSearchQuery("ProgramTypeId", programTypeId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          organizationIdList
        ].join('')
      }
      searchString = searchString.replace('&', '?')
      return `api/Report/Widget/Tasks/OverdueTaskCount${searchString}`
    }
  },
  getPendingCompletionTaskWidget: {
    method: 'GET',
    url: ({
      advanceSearch = {}
    }) => {
      let searchString
      if (!!Object.keys(advanceSearch).length) {
        const {
          checkListName,
          unitName,
          programName,
          campaignName,
          campaignTypeId,
          programTypeId,
          projectId,
          siteId,
          taskTypeId,
          startDate,
          endDate,
          organization
        } = advanceSearch
        const organizationIdList = organization.map((org) => getSearchQuery("OrganizationIds", org.organizationId)).join("")
        searchString = [
          getSearchQuery("CheckListName", checkListName, false, true),
          getSearchQuery("TaskTypeId", taskTypeId),
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("ProgramName", programName, false, true),
          getSearchQuery("ProgramTypeId", programTypeId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          organizationIdList
        ].join('')
      }
      searchString = searchString.replace('&', '?')
      return `api/Report/Widget/Tasks/PendingCompletionTaskCount${searchString}`
    }
  },
  getActivityNoteAndMedia: {
    method: 'GET',
    url: ({
      assignmentActivityId
    }) => {
      return `api/Checkpoint/ActivityNote/${assignmentActivityId}`
    }
  },
  deleteActivityNoteAndMedia: {
    method: 'DELETE',
    url: ({
      assignmentActivityId,
      noteId
    }) => {
      return `api/Checkpoint/ActivityNote/${assignmentActivityId}/${noteId}`
    }
  },
  addTradeContact: {
    method: 'POST',
    url: 'api/Checkpoint/contact/siterole'
  },
  getActivityReviewVM: {
    method: 'GET',
    url: () => `api/Checkpoint/vm/ActivityReview`
  },
  updateTaskReviewAssignment: {
    method: 'POST',
    url: ({ assignmentId }) => {
      return `api/Scheduling/Assignment/TaskReview/${assignmentId}`
    }
  },
  assignSelfSchedule: {
    method: 'POST',
    url: "api/Scheduling/Assignment/SelfSchedule"
  },
  getTaskCanSelfAssign: {
    method: 'GET',
    url: (observationTaskId) => `api/Scheduling/Assignment/CanSelfAssign/${observationTaskId}`
  },
  getTaskCount: {
    method: 'GET',
    url: ({
      advanceSearch = {},
      genericSearch,
      taskAssignedToId = ''
    }) => {
      let searchString = getSearchQuery("CheckListName", genericSearch, false, true)

      if (!!Object.keys(advanceSearch).length) {
        const {
          timeInterval,
          checkListName, taskTypeId,
          taskStatusId, projectId, siteId,
          unitName, programName, organization = [],
          programTypeId, assignee = [], campaignName, campaignTypeId,
          startDate, endDate, city,
          country,
          zipCode,
          state,
          block,
          type
        } = advanceSearch
        const assigneeListIds = taskAssignedToId ? getSearchQuery("ContactIds", taskAssignedToId) : assignee.map((data) => getSearchQuery("ContactIds", data.value)).join("")
        const taskStatusListId = taskStatusId.map((task) => getSearchQuery("TaskStatusIds", task)).join("")
        const organizationListId = organization.map((data) => getSearchQuery("OrganizationIds", data.organizationId)).join("")
        
        searchString = [
          searchString,
          getSearchQuery("TimeInterval", timeInterval),
          getSearchQuery("TaskTypeId", taskTypeId),
          taskStatusListId,
          assigneeListIds,
          organizationListId,
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("ProgramName", programName, false, true),
          getSearchQuery("ProgramTypeId", programTypeId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          getSearchQuery("City", city, false, true),
          getSearchQuery("State", state),
          getSearchQuery("CountryCode", country),
          getSearchQuery("ZipCode", zipCode, false, true),
          getSearchQuery("Block", block, false, true),
          getSearchQuery("UnitTypeId", type)
        ].join('')

        if (!genericSearch) {
          searchString = [
            searchString,
            getSearchQuery("CheckListName", checkListName, false, true)
          ]
        }
      }

      if (!!searchString.length) {
        searchString = searchString.trim().replace('&', '?')
      }

      return `api/Checkpoint/Task/Count${searchString}`
    }
  },
  getObservationTask:{
    method: 'GET',
    url: ({ pageNumber, pageSize, genericSearch = "", campaignId, siteId, programId }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("ProgramId", programId)
      ].join('')
      return `api/Common/filter/ObservationTask${searchString}`
    }
  },
  getActionConfirmationVM: {
    method: 'GET',
    url: 'api/Checkpoint/ActionConfirmation/DDL'
  },
  uploadInspectionFile: {
    method: 'POST',
    url: ({
      assignmentId,
      assignmentActivityId,
      responseId,
      fileCategoryId
    }) => {
      const searchString = [
        getSearchQuery("UploadInspectionFile.AssignmentId", assignmentId, true),
        getSearchQuery("UploadInspectionFile.AssignmentActivityId", assignmentActivityId),
        getSearchQuery("UploadInspectionFile.ResponseId", responseId),
        getSearchQuery("UploadInspectionFile.FileCategoryId", fileCategoryId)
      ].join('')
      return `api/ResourceCenter/UploadInspectionFile${searchString}`
    }
  },
  updatePreTaskCheckList: {
    method: 'POST',
    url: ({
      observationTaskId
    }) => {
      return `api/Checkpoint/PreTaskCheckList/${observationTaskId}`
    }
  },
  getPreTaskCheckList: {
    url: ({
      observationTaskId
    }) => {
      return `api/Checkpoint/PreTaskCheckList/${observationTaskId}`
  }
}, 
  getTaskLinksCount: {
    method: 'GET',
    url: ({
      observationTaskId
    }) => {
      const searchString = getSearchQuery("FromObservationTaskId", observationTaskId, true)
      return `api/Checkpoint/TaskLinks/Count${searchString}`
    }
  },
  getTaskLinkList: {
    method: 'GET',
    url: ({
      observationTaskId,
      pageNumber,
      pageSize,
      genericSearch,
      taskLinkTypeId = null
    }) => {
      const searchString = [
        getSearchQuery("FromObservationTaskId", observationTaskId, true),
        getSearchQuery("TaskLinkTypeId", taskLinkTypeId),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true)
      ].join('')
      return `api/Checkpoint/TaskLinks${searchString}`
    }
  },
  uploadResponseImage: {
    method: 'POST',
    url: `api/Checkpoint/UploadResponseImageForm`
  }
}

export default apiConfig
