import classnames from "classnames"
import React, { useCallback, useMemo } from "react"
import { Button, CardTitle } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { stackListView, groupView } from "@src/assets/images/new-theme"
import { getLocaleMessage } from "@utils"
import { BulkActionsDropdown, ToggleButton } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { defaultProps } from "./config"
import ExportListOptions from "./ExportListOptions"
import PageSizeFilter from "./PageSizeFilter"
import SearchFilter from "./SearchFilter"
import SelectFilter from './select-filter'
import HeaderDropdown from "./HeaderDropdown"

const ListHeader = ({
  intl,
  genericSearch,
  genericSearchRequired,
  isSearchFilterRequired,
  pageSizeFilterRequired,
  pageSize,
  tableViewAdvanceSearchRequired = true,
  tableComponentProps: tableComponentPropsList = {},
  title,
  exportRef,
  onAddNew = () => { },
  onAddVersion = () => { },
  onClone = () => { },
  onChangeSearchFilter = () => { },
  onExportList = () => { },
  onKeyDown = () => { },
  onPerPageRecords = () => { },
  onToggleAdvanceSearch = () => { },
  onCsvExport = () => { },
  onSaveBulkList = () => { },
  onSelectFilter = () => { },
  onToggleViewMode = () => { }
}) => {
  const tableComponentProps = useMemo(() => {
    return {
      ...defaultProps.defaultPropsListHeaderProps,
      ...tableComponentPropsList
    }
  }, [tableComponentPropsList])

  const headerConfig = useMemo(
    () => tableComponentProps.headerConfig || {},
    [tableComponentProps.headerConfig]
  )

  const handleExportCSV = useCallback(() => {
    onCsvExport(exportRef)
  }, [onCsvExport])

  return (
    <div className="list-header">
      {headerConfig.type === "button" && (
        <Button
          className={
            headerConfig.isGradientBtn ? "gradient-solid" : "secondary-solid"
          }
          disabled={headerConfig.isDisabled}
          onClick={headerConfig.onClick}
        >
          {getLocaleMessage(intl, headerConfig.labelLocaleId)}
        </Button>
      )}
      {!!headerConfig.listFilterComponent && headerConfig.listFilterComponent()}
      {!!tableComponentProps.isListTitleRequired && <CardTitle className="mb-0" tag="h4">{title}</CardTitle>}
      <div className={classnames({
        "d-flex flex-wrap list-header-filters align-items-center": true,
        "saved-filters-dropdown": !tableComponentProps.isListTitleRequired
      })}>
        {!!tableComponentProps.isRequiresSelectFilterDropdown && (
          <SelectFilter
            agGridRef={exportRef}
            isRefreshSavedFilterOptions={tableComponentProps.isRefreshSavedFilterOptions}
            searchFilterKey={tableComponentProps.searchFilterKey}
            selectedFilter={tableComponentProps.selectedFilter}
            isInitialSelectOptionRequired={tableComponentProps.isInitialSelectOptionRequired}
            onSetRefreshSavedFilterOptions={tableComponentProps.onSetRefreshSavedFilterOptions}
            onSelectFilter={onSelectFilter}
          />)}
        {!!tableComponentProps.isShowDataToggleButton && (
          <div className="data-toggle active-toggle">
            <ToggleButton
              className="custom-control-secondary mb-1"
              descriptionText={tableComponentProps.dataToggleButtonLocaleId}
              value={tableComponentProps.dataToggleButtonValue}
              onChange={(value) => tableComponentProps.onChangeDataToggle(value)}
            />
          </div>
        )}
       {!!tableComponentProps.isListHeaderRequired && <div className="list-header-list" >
        {!!tableComponentProps.isPageSizeFilter && (
          <PageSizeFilter
            pageSizeFilterRequired={pageSizeFilterRequired}
            pageSize={pageSize}
            onPerPageRecords={onPerPageRecords}
          />
        )}
        {tableComponentProps.isShowVersionButton && (
          <button
            type="button"
            className={"btn secondary-text-btn"}
            onClick={onAddVersion}
          >
            {getLocaleMessage(
              intl,
              tableComponentProps.newVersionButtonLocaleId
            )}
          </button>
        )}

        {tableComponentProps.isShowCloneButton && (
          <button
            type="button"
            className={"btn secondary-text-btn"}
            onClick={onClone}
          >
            {getLocaleMessage(
              intl,
              tableComponentProps.cloneButtonLocaleId
            )}
          </button>
        )}

        {!!tableComponentProps.tableHeaderDropdownConfig && <HeaderDropdown config={tableComponentProps.tableHeaderDropdownConfig} />}
        {!!tableComponentProps.maxSelectedCount && (
          <p>{`${tableComponentProps.selectedRowCount} ${getLocaleMessage(
            intl,
            LANGUAGE_CONSTANTS.OF
          )} ${tableComponentProps.maxSelectedCount} ${getLocaleMessage(
            intl,
            LANGUAGE_CONSTANTS.SELECTED
          )}`}</p>
        )}
        {tableComponentProps.isExportRequired && (
          <ExportListOptions
            tableComponentProps={tableComponentProps}
            onExportList={onExportList}
          />
        )}
        {tableComponentProps.isShowAddButton && (
          <button
            type="button"
            className={"btn primary-text-btn list-add-new-btn"}
            disabled={tableComponentProps.isDisableAddButton}
            onClick={onAddNew}
          >
            {getLocaleMessage(
              intl,
              tableComponentProps.addButtonLocaleId || LANGUAGE_CONSTANTS.ADD
            )}
          </button>
        )}
        {
          tableComponentProps.isAgGridCsvExport && (
            <button
              type="button"
              className={"btn"}
              onClick={handleExportCSV}
            >
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.EXPORT)}
            </button>
          )
        }
        </div>}
      
        {!!isSearchFilterRequired && (
          <SearchFilter
            genericSearch={genericSearch}
            genericSearchRequired={genericSearchRequired}
            isNewListTheme
            pageSizeFilterRequired={pageSizeFilterRequired}
            tableViewAdvanceSearchRequired={tableViewAdvanceSearchRequired}
            onChangeSearchFilter={onChangeSearchFilter}
            onKeyDown={onKeyDown}
            onToggleAdvanceSearch={onToggleAdvanceSearch}
          />
        )}
        {
          tableComponentProps.isShowBulkSaveButton && (
            <button
              type="button"
              className="common-button primary-solid ml-2"
              onClick={onSaveBulkList}
            >
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.SAVE)}
            </button>
          )
        }
        {tableComponentProps.isShowViewModeButton && (
          <div className="stack-list-view-btn" onClick={onToggleViewMode}>
            <img src={tableComponentProps.isListView ? stackListView : groupView} className="stack-icon" />
          </div>
        )}
        {!!tableComponentProps.bulkActionConfig && <BulkActionsDropdown {...tableComponentProps.bulkActionConfig} isListHeader />}
      </div>
    </div>
  )
}

export default localeMessageWrapper(ListHeader)
