import { put, takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from "@store/actions"

const { PROJECT_SITE_ROLE_SAGA } = LANGUAGE_CONSTANTS

/** getProjectSiteRoleList API **/
function* getProjectSiteRoleListRequest(action) {
  try {
    const { isLoadingRequired = false, ...payload } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(services.getProjectSiteRoleList, payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback()
  }
}

/** getProjectSiteRolesVM API **/
function* getProjectSiteRolesVMRequest(action) {
  try {
    const response = yield call(services.getProjectSiteRolesVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProjectSiteRole API **/
function* getProjectSiteRoleRequest(action) {
  try {
    const response = yield call(services.getProjectSiteRole, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addProjectSiteRole API **/
function* addProjectSiteRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addProjectSiteRole, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_ROLE_SAGA.ADDED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_ROLE_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** updateProjectSiteRole API **/
function* updateProjectSiteRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProjectSiteRole, action.payload)
    if (response && response.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_ROLE_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_ROLE_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** deleteProjectSiteRoleApi API **/
function* deleteProjectSiteRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteProjectSiteRole, action.payload)
    if (response) {
      action.callback(response)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_ROLE_SAGA.DELETED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_ROLE_SAGA.ERROR_IN_DELETING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** updateProjectSiteRolePrimary **/

function* updateProjectSiteRolePrimaryRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProjectSiteRolePrimary, action.payload)
    if (response) {
      action.callback(response)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_ROLE_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_ROLE_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: PROJECT_SITE_ROLE_SAGA.ERROR_IN_UPDATING })
    action.callback()
  }
}

/** getSiteRolesVM API **/
function* getSiteRolesVMRequest(action) {
  try {
    const response = yield call(services.getSiteRolesVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* projectSiteRolesSagas() {
  yield takeLatest(actions.ADD_PROJECT_SITE_ROLE_REQUEST, addProjectSiteRoleRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_ROLE_REQUEST, getProjectSiteRoleRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_ROLE_LIST_REQUEST, getProjectSiteRoleListRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_ROLES_VM_REQUEST, getProjectSiteRolesVMRequest)
  yield takeLatest(actions.UPDATE_PROJECT_SITE_ROLE_REQUEST, updateProjectSiteRoleRequest)
  yield takeLatest(actions.DELETE_PROJECT_SITE_ROLE_REQUEST, deleteProjectSiteRoleRequest)
  yield takeLatest(actions.UPDATE_SITE_ROLE_PRIMARY_REQUEST, updateProjectSiteRolePrimaryRequest)
  yield takeLatest(actions.GET_SITE_ROLES_VM_REQUEST, getSiteRolesVMRequest)
}

export default projectSiteRolesSagas
