import { put, takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { BUILDER_HOME } = LANGUAGE_CONSTANTS

/** getBuilderSiteUnitListVM API **/
function* getBuilderSiteUnitListVMRequest(action) {
  try {
    const response = yield call(services.getBuilderSiteUnitListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBuilderTaskListVM API **/
function* getBuilderTaskListVMRequest(action) {
  try {
    const response = yield call(services.getBuilderTaskListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBuilderTaskList API **/
function* getBuilderTaskListRequest(action) {
  try {
    const response = yield call(services.getBuilderTaskList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getBuilderSiteUnitList API **/
function* getBuilderSiteUnitListRequest(action) {
  try {
    const response = yield call(services.getBuilderSiteUnitList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getRequestInspetionVM API **/
function* getRequestInspetionVMRequest(action) {
  try {
    const response = yield call(services.getRequestInspetionVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getRequestInspetionTasks API **/
function* getRequestInspetionTasksRequest(action) {
  try {
    const response = yield call(services.getRequestInspetionTasks, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateRequestInspetion API **/
function* updateRequestInspetionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateRequestInspetion, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: BUILDER_HOME.REQUEST_INSPECTION_UPDATE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: BUILDER_HOME.ERROR_IN_UPDATING_REQUEST_INSPECTION })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getBuilderReinspectionFee API **/
function* getBuilderReinspectionFeeRequest(action) {
  try {
    const response = yield call(services.getBuilderReinspectionFee, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskListWidget API **/
function* getTaskListWidgetRequest(action) {
  try {
    const response = yield call(services.getTaskListWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* builderHomeSagas() {
  yield takeLatest(actions.GET_BUILDER_SITE_UNIT_LIST_VM, getBuilderSiteUnitListVMRequest)
  yield takeLatest(actions.GET_BUILDER_TASK_LIST_VM, getBuilderTaskListVMRequest)
  yield takeLatest(actions.GET_BUILDER_TASK_LIST, getBuilderTaskListRequest)
  yield takeLatest(actions.GET_REQUEST_INSPECTION_VM, getRequestInspetionVMRequest)
  yield takeLatest(actions.GET_REQUEST_INSPECTION_TASKS, getRequestInspetionTasksRequest)
  yield takeLatest(actions.UPDATE_REQUEST_INSPECTION, updateRequestInspetionRequest)
  yield takeLatest(actions.GET_BUILDER_SITE_UNIT_LIST, getBuilderSiteUnitListRequest)
  yield takeLatest(actions.GET_BUILDER_RE_INSPECTION_FEE, getBuilderReinspectionFeeRequest)
  yield takeLatest(actions.GET_TASK_LIST_WIDGET, getTaskListWidgetRequest)
}

export default builderHomeSagas