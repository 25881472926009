import { ROUTES } from '@shared/constants'
import {
  GET_MENU_LIST,
  CLEAR_SELECTED_FILTER,
  REFRESH_FOR_SAVED_FILTER_LIST,
  SET_SAVED_FILTER_AS_SELECTED,
  SET_EDIT_CONTACT_ROLE_PERMISSIONS_MODE,
  SET_USER_PERMISSION_LIST,
  TOGGLE_USER_MANAGEMENT_ROLE_MODAL,
  RESET_MENU_LIST_REQUEST
} from "@store/actions"

const getMenus = (list, permissions) => {
  const menus = []
  if (list?.length) {
    for (const menu of list) {
      const { id, title, children, iconName, style, navLink } = menu
    // Updated navLink logic
    const updatedNavLink = navLink === 'dashboard' && permissions?.hasBuilderHomepagePermission
    ? 'builderHome'
    : navLink

      const parentRoute = navLink && ROUTES[navLink] ? ROUTES[updatedNavLink] : ''
      const childrenList = []
      if (children?.length) {
        for (const child of children) {
          const grandChildrenList = []
          const { children, iconName, style } = child
          let grandChildren = [...children]
          if (grandChildren?.length) {
            for (const grandChild of grandChildren) {
              const grandChildRoute = grandChild.navLink && ROUTES[grandChild.navLink] ? ROUTES[grandChild.navLink] : ''
              if (grandChildRoute) {
                grandChildrenList.push({ ...grandChild, navLink: grandChildRoute, icon: { iconName: grandChild.iconName, style: grandChild.style } })
              }
            }
          }
          grandChildren = [...grandChildrenList]
          const route = child.navLink && ROUTES[child.navLink] ? ROUTES[child.navLink] : ''
          child.children = grandChildren
          if (route || child?.children?.length) {
            childrenList.push({ ...child, navLink: route, icon: { iconName, style } })
          }
        }
      }
      menus.push({ id, title, navLink: parentRoute, children: childrenList.length ? childrenList : null, icon: { iconName, style } })
    }
  }

  return menus
}

// ** Initial State
const initialState = {
  menus: [],
  permissions: [],
  roleDetail: {
    modalMode: "",
    isOpenRoleModal: false,
    data: {}
  },
  error: "",
  isEditContactRolePermission: false,
  contactRolePermissionDetail: null,

  /**
   * refreshForSavedFilters : Used as dependency in useEffect dependency array to refetch saved filters list from API
   */
  refreshForSavedFilters: false,

  /**
  * newSavedFilterValue: Used to auto select filter value in Select filter dropdown on saving new filter
  */
  newSavedFilterValue: null,

  /**
   * clearSelectedFilterValue : Used as dependency in useEffect dependency array to clear selected filter from Select filter dropdown
   */
  clearSelectedFilterValue: false
}

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {

    /** GET_MENU_LIST **/
    case GET_MENU_LIST.REQUEST:
      return { ...state, error: "" }
    case GET_MENU_LIST.SUCCESS:
      const permissions = { hasBuilderHomepagePermission: state.permissions?.includes('BuilderHomepage_MySites') }
      return {
        ...state,
        menus: getMenus(action.data, permissions)
      }
    case GET_MENU_LIST.FAILURE:
      return { ...state, error: action.error }

    /** SET_USER_PERMISSION_LIST **/
    case SET_USER_PERMISSION_LIST:
      return { ...state, permissions: action.data }

    /** TOGGLE_USER_MANAGEMENT_ROLE_MODAL **/
    case TOGGLE_USER_MANAGEMENT_ROLE_MODAL:
      return {
        ...state,
        roleDetail: {
          modalMode: action.payload?.mode || '',
          isOpenRoleModal: !state.roleDetail.isOpenRoleModal,
          data: action.payload?.data || {}
        }
      }

    /** SET_EDIT_CONTACT_ROLE_PERMISSIONS_MODE **/

    case SET_EDIT_CONTACT_ROLE_PERMISSIONS_MODE:
      return {
        ...state,
        isEditContactRolePermission: action.payload.isEditMode,
        contactRolePermissionDetail: action.payload.contactRolePermissionDetail
      }

    case REFRESH_FOR_SAVED_FILTER_LIST:
      return {
        ...state,
        refreshForSavedFilters: !state.refreshForSavedFilters
      }

    case SET_SAVED_FILTER_AS_SELECTED:
      return {
        ...state,
        newSavedFilterValue: action.payload
      }

    case CLEAR_SELECTED_FILTER:
      return {
        ...state,
        clearSelectedFilterValue: !state.clearSelectedFilterValue
      }

    case RESET_MENU_LIST_REQUEST:
      return {
        ...state,
        menus: []
      }
    default:
      return state
  }
}

export default userManagementReducer
