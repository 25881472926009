import { PERMISSION_ROUTES, ROUTES } from '@shared/constants'

import { store } from '../store'

const isPublicRoute = (path) => {
    switch (path) {
        case ROUTES.loginPage:
        case ROUTES.profile:
        case ROUTES.search:

        /**Todo: We need permission format for reports routes in token */
        case ROUTES.reportsOverview:

        /**Todo: We need permission format for programs routes in token */
        case ROUTES.programsOverview:
        case ROUTES.programsDetails:
        case ROUTES.addNewProgram:
        case ROUTES.editProgram:

        case ROUTES.dashboard:

        case ROUTES.manageTask:
        case ROUTES.manageTaskById:

            return true
        default:
            return false
    }
}

export const isPermissionDenied = (path) => {
    let flag = false
    if (!isPublicRoute(path)) {
        const permissions = store.getState()?.userManagement?.permissions || []
        const isRoute = Object.keys(ROUTES).findIndex(route => ROUTES[route] === path) > -1
        if (isRoute) {
            const permissionKey = Object.keys(PERMISSION_ROUTES).find(route => route === path)
            flag = permissionKey && permissions.indexOf(PERMISSION_ROUTES[permissionKey]) === -1
        } else {
            flag = permissions.indexOf(path) === -1
        }
    }

    return flag
}
// ** Default Route
export const getDefaultRoute = ({hasBuilderHomepagePermission}) => (hasBuilderHomepagePermission ? ROUTES.builderHome : ROUTES.dashboard)
