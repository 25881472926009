import config from "./APIConfig"
import { getDataApi, putDataApi } from '../index'

export const getBuilderSiteUnitListVM = () => {
  const { url } = config.getBuilderSiteUnitListVM
  return getDataApi(url)
}

export const getBuilderTaskListVM = () => {
  const { url } = config.getBuilderTaskListVM
  return getDataApi(url)
}

export const getBuilderTaskList = async (data) => {
  const { url } = config.getBuilderTaskList
  return await getDataApi(url(data))
}

export const getRequestInspetionVM = () => {
  const { url } = config.getRequestInspetionVM
  return getDataApi(url)
}

export const getRequestInspetionTasks = async(data) => {
  const { url } = config.getRequestInspetionTasks
  return await getDataApi(url(data))
}

export const updateRequestInspetion = async (payload) => {
  const { url } = config.updateRequestInspetion
  return await putDataApi(url, payload)
}

export const getBuilderSiteUnitList = async (data) => {
  const { url } = config.getBuilderSiteUnitList
  return await getDataApi(url(data))
}

export const getBuilderReinspectionFee = async (data) => {
  const { url } = config.getBuilderReinspectionFee
  return await getDataApi(url(data))
}

export const getTaskListWidget = async (data) => {
  const { url } = config.getTaskListWidget
  return await getDataApi(url(data))
}