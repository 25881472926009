import React, { useCallback } from 'react'
import { Input } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage, checkIfValidCharForNumbericInput } from "@utils"
import { CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const NumericInput = ({
    intl,
    id,
    name,
    isRequired = false,
    labelLocaleId,
    placeholderLocaleId,
    isLabelRequired = true,
    isAllowNegativeNumber = false,
    value,
    onChange = () => { }
}) => {
    const handleChangeInput = useCallback((event) => {
        const value = event.target.value
        if (!value?.trim()?.length || (isAllowNegativeNumber && value === '-') || (value?.split(".").length - 1 === 1) ||  checkIfValidCharForNumbericInput(value, isAllowNegativeNumber)) {
          onChange(id, value)
        }
    }, [id, isAllowNegativeNumber, onChange])

    return (
        <>
            {isLabelRequired && <CustomLabel
                title={
                    labelLocaleId || LANGUAGE_CONSTANTS.ENTER_NUMBER
                }
                required={isRequired}
            />}
            <Input
                placeholder={getLocaleMessage(intl, placeholderLocaleId || LANGUAGE_CONSTANTS.ENTER_NUMBER)}
                name={name}
                value={value}
                onChange={handleChangeInput}
            />
        </>
    )
}

export default localeMessageWrapper(NumericInput)