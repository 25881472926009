import { takeLatest, takeEvery, put, call } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { Notification } from '@views/components'

const { TASK_DATA_COLLECTION_SAGA } = LANGUAGE_CONSTANTS

/** getActivityCollection API **/
function* getActivityCollectionRequest(action) {
  try {
    const response = yield call(services.getActivityCollection, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivityCountStatus API **/
function* getActivityCountStatusRequest(action) {
  try {
    const response = yield call(services.getActivityCountStatus, action.payload)
    action.callback(response.data)
  } catch (error) {
    action.callback()
  }
}

/** saveActivityCollection API **/
function* saveActivityCollectionRequest(action) {
  try {
    const { isApproved, isMoveToNeedReview, isAddedActivityCopy, isDeletedActivity, isUpdatedCopyLabel } = action.payload
    const response = yield call(services.saveActivityCollection, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: isMoveToNeedReview ? TASK_DATA_COLLECTION_SAGA.ACTIVITY_MOVED_TO_NEEDS_REVIEW_SUCCESS : isAddedActivityCopy ? TASK_DATA_COLLECTION_SAGA.COPIED_ACTIVITY_ADDED_SUCCESS : isDeletedActivity ? TASK_DATA_COLLECTION_SAGA.COPIED_ACTIVITY_DELETED_SUCCESS : isUpdatedCopyLabel ? TASK_DATA_COLLECTION_SAGA.COPIED_ACTIVITY_LABEL_UPDATED_SUCCESS : !isApproved ? TASK_DATA_COLLECTION_SAGA.SAVE_SUCCESS : TASK_DATA_COLLECTION_SAGA.ACTIVITY_APPROVAL_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: isMoveToNeedReview ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_MOVING_ACTIVITY_TO_NEEDS_REVIEW : isAddedActivityCopy ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_ADDING_COPIED_ACTIVITY : isDeletedActivity ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_DELETING_COPIED_ACTIVITY : isUpdatedCopyLabel ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_COPIED_ACTIVITY_LABEL : !isApproved ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING : TASK_DATA_COLLECTION_SAGA.ERROR_IN_APPROVING_ACTIVITY })
    }
  } catch (error) {
    action.callback()
  }
}

/** getTrainingLaunchLink API **/
function* getTrainingLaunchLinkRequest(action) {
  try {
    const response = yield call(services.getTrainingLaunchLink, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_FETCHING_LAUNCH_LINK })
    }
  } catch (error) {
    Notification({ status: 400, isLocalizedMessage: !(error.response?.statusText || error.message), message: error.response?.statusText || error.message || TASK_DATA_COLLECTION_SAGA.ERROR_IN_FETCHING_LAUNCH_LINK })
    action.callback()
  }
}

/** updateCourseRegistration API **/
function* updateCourseRegistrationRequest(action) {
  try {
    const response = yield call(services.updateCourseRegistration, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.SAVE_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING })
    }
  } catch (error) {
    Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING })
    action.callback()
  }
}

/** getObservationTaskListView API **/
function* getObservationTaskListViewRequest(action) {
  try {
    const response = yield call(services.getObservationTaskListView, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_FETCHING_TASK_LIST })
    }
  } catch (error) {
    Notification({ status: 400, isLocalizedMessage: !(error.response?.statusText || error.message), message: error.response?.statusText || error.message || TASK_DATA_COLLECTION_SAGA.ERROR_IN_FETCHING_TASK_LIST })
    action.callback()
  }
}

/** getObservationTaskMyView API **/
function* getObservationTaskMyViewRequest(action) {
  try {
    const response = yield call(services.getObservationTaskMyView, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_FETCHING_TASK_LIST })
    }
  } catch (error) {
    Notification({ status: 400, isLocalizedMessage: !(error.response?.statusText || error.message), message: error.response?.statusText || error.message || TASK_DATA_COLLECTION_SAGA.ERROR_IN_FETCHING_TASK_LIST })
    action.callback()
  }
}

/** getSelfAssignTask API **/
function* getSelfAssignTaskRequest(action) {
  try {
    const response = yield call(services.getSelfAssignTask, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getObservationTaskViewVM API **/
function* getObservationTaskViewVMRequest(action) {
  try {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(services.getObservationTaskViewVM)
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = false } = action.payload || {}
    Notification({ status: 400, isLocalizedMessage: false, message: error.response?.statusText || error.message })
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback(null)
  }
}

/** getTaskHeaderDetail API **/
function* getTaskHeaderDetailRequest(action) {
  try {
    const response = yield call(services.getTaskHeaderDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    action.callback()
  }
}

/** getTaskTargetTabDetail API **/
function* getTaskTargetTabDetailRequest(action) {
  try {
    const response = yield call(services.getTaskTargetTabDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** saveCompleteTask API **/
function* saveCompleteTaskRequest(action) {
  const { type, ...payload } = action.payload
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.saveCompleteTask, payload)
    if (response?.status === 200 && response.data) {
      action.callback(response.data)
      Notification({ status: 200, message: type === "Approve" ? TASK_DATA_COLLECTION_SAGA.APPROVE_TASKS_SUCCESS : type === "Reject" ? TASK_DATA_COLLECTION_SAGA.REJECT_TASKS_SUCCESS : type === "Submit and Review" ? TASK_DATA_COLLECTION_SAGA.SUBMIT_AND_REVIEW_TASKS_SUCCESS : TASK_DATA_COLLECTION_SAGA.COMPLETED_TASKS_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: type === "Approve" ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_APPROVE_TASKS : type === "Reject" ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_REJECT_TASKS : type === "Submit and Review" ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_SUBMITTING_TASK_FOR_REVIEW : TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_TASKS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: type === "Approve" ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_APPROVE_TASKS : type === "Reject" ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_REJECT_TASKS : type === "Submit and Review" ? TASK_DATA_COLLECTION_SAGA.ERROR_IN_SUBMITTING_TASK_FOR_REVIEW : TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_TASKS })
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** startChecklistActivity API  */
function* startChecklistActivityRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.startChecklistActivity, action.payload)
    if (response?.status === 204) {
      action.callback(true)
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_STARTING_ACTIVITY })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_STARTING_ACTIVITY })
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getReassignmentContactList API */
function* getReassignmentContactListRequest(action) {
  try {
    const response = yield call(services.getReassignmentContactList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    }
  } catch (error) {
    action.callback()
    Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_FETCHING_CONTACT_LIST })
  }
}

/** updateTaskReassignmentContact API */
function* updateTaskReassignmentContactRequest(action) {
  try {
    const response = yield call(services.updateTaskReassignmentContact, action.payload)
    if (response.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.REASSIGNMENT_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_TASK_CONTACT })
    }
  } catch (error) {
    Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_TASK_CONTACT })
    action.callback()
  }
}

/** saveImagesAndNotes API **/
function* saveImagesAndNotesRequest(action) {
  try {
    const response = yield call(services.saveImagesAndNotes, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.NOTES_AND_IMAGES_SAVE_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_NOTES_AND_IMAGES })
    }
  } catch (error) {
    action.callback()
  }
}

/** addNoteAndMedia API **/
function* addNoteAndMediaRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addNoteAndMedia, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.NOTES_AND_IMAGES_SAVE_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_SAVING_NOTES_AND_IMAGES })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getInProgressWidget API */
function* getInProgressWidgetRequest(action) {
  try {
    const response = yield call(services.getInProgressWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getOverdueTaskWidget API */
function* getOverdueTaskWidgetRequest(action) {
  try {
    const response = yield call(services.getOverdueTaskWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getPendingCompletionTaskWidget API */
function* getPendingCompletionTaskWidgetRequest(action) {
  try {
    const response = yield call(services.getPendingCompletionTaskWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivityNoteAndMedia API */
function* getActivityNoteAndMediaRequest(action) {
  try {
    const response = yield call(services.getActivityNoteAndMedia, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteActivityNoteAndMedia API */
function* deleteActivityNoteAndMediaRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteActivityNoteAndMedia, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.ADDITIONAL_NOTES_AND_IMAGES_DELETE_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_DELETING_ADDITIONAL_NOTE_AND_MEDIA })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_DELETING_ADDITIONAL_NOTE_AND_MEDIA })
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** addTradeContact API **/
function* addTradeContactRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addTradeContact, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.TRADE_CONTACT_ADD_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_ADDING_TRADE_CONTACT })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getActivityReviewVMRequest API **/
function* getActivityReviewVMRequest(action) {
  try {
    const response = yield call(services.getActivityReviewVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateTaskReviewAssignment API **/
function* updateTaskReviewAssignmentRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateTaskReviewAssignment, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.TASK_REVIEW_ASSIGNMENT_UPDATED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_TASK_REVIEW_ASSIGNMENT })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_TASK_REVIEW_ASSIGNMENT })
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** assignSelfSchedule API **/
function* assignSelfScheduleRequest(action) {
  try {
    const response = yield call(services.assignSelfSchedule, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.ASSIGN_SELF_SCHEDULE_UPDATED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_SELF_SCHEDULE_ASSIGNMENT })
    }
  } catch (error) {
    Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_SELF_SCHEDULE_ASSIGNMENT })
    action.callback()
  }
}

/** getTaskCanSelfAssign API **/
function* getTaskCanSelfAssignRequest(action) {
  try {
    const response = yield call(services.getTaskCanSelfAssign, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskCount API **/
function* getTaskCountRequest(action) {
  try {
    const response = yield call(services.getTaskCount, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getObservationTask API **/
function* getObservationTaskRequest(action) {
  try {
    const response = yield call(services.getObservationTask, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivityConfirmationVM API **/
function* getActivityConfirmationVMRequest(action) {
  try {
    const response = yield call(services.getActionConfirmationVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}
/** uploadInspectionFile API **/
function* uploadInspectionFileRequest(action) {
  try {
    const response = yield call(services.uploadInspectionFile, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getActionConfirmationVM API **/
function* getActionConfirmationVMRequest(action) {
  try {
    const response = yield call(services.getActionConfirmationVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskLinksCount API **/
function* getTaskLinksCountRequest(action) {
  try {
    const response = yield call(services.getTaskLinksCount, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updatePreTaskCheckListRequest API **/
function* updatePreTaskCheckListRequest(action) {
    try {
    const response = yield call(services.updatePreTaskCheckList, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TASK_DATA_COLLECTION_SAGA.UPDATE_PRE_TASK_CHECKLIST_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TASK_DATA_COLLECTION_SAGA.ERROR_IN_UPDATING_PRE_TASK_CHECKLIST })
    }
  } catch (error) {
    action.callback()
  }
}

/** getPreTaskCheckListRequest API **/
function* getPreTaskCheckListRequest(action) {
    try {
    const response = yield call(services.getPreTaskCheckList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskLinkList API **/
function* getTaskLinkListRequest(action) {
  try {
    const response = yield call(services.getTaskLinkList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** uploadResponseImageRequest API **/
function* uploadResponseImageRequest(action) {
  try {
  const response = yield call(services.uploadResponseImage, action.payload)
  if (response?.status === 200) {
    action.callback(response?.data)
  } else {
    action.callback()
  }
} catch (error) {
  action.callback()
}
}

export function* taskDataCollectionSagas() {
  yield takeEvery(actions.GET_ACTIVITY_COLLECTION_REQUEST, getActivityCollectionRequest)
  yield takeLatest(actions.GET_ACTIVITY_COUNT_STATUS_REQUEST, getActivityCountStatusRequest)
  yield takeLatest(actions.SAVE_ACTIVITY_COLLECTION_REQUEST, saveActivityCollectionRequest)
  yield takeLatest(actions.GET_TRAINING_LAUNCH_LINK_REQUEST, getTrainingLaunchLinkRequest)
  yield takeLatest(actions.UPDATE_COURSE_REGISTRATION_REQUEST, updateCourseRegistrationRequest)
  yield takeLatest(actions.GET_OBSERVATION_TASK_LIST_VIEW_REQUEST, getObservationTaskListViewRequest)
  yield takeEvery(actions.GET_OBSERVATION_TASK_MY_VIEW_REQUEST, getObservationTaskMyViewRequest)
  yield takeEvery(actions.GET_SELF_ASSIGN_TASK_REQUEST, getSelfAssignTaskRequest)
  yield takeLatest(actions.GET_OBSERVATION_TASK_VIEW_VM_REQUEST, getObservationTaskViewVMRequest)
  yield takeLatest(actions.GET_TASK_HEADER_DETAIL_REQUEST, getTaskHeaderDetailRequest)
  yield takeLatest(actions.GET_TASK_TARGET_TAB_DETAIL_REQUEST, getTaskTargetTabDetailRequest)
  yield takeLatest(actions.SAVE_COMPLETE_TASK_REQUEST, saveCompleteTaskRequest)
  yield takeLatest(actions.START_CHECKLIST_ACTIVITY_REQUEST, startChecklistActivityRequest)
  yield takeLatest(actions.GET_TASK_REASSIGNMENT_CONTACT_REQUEST, getReassignmentContactListRequest)
  yield takeLatest(actions.UPDATE_TASK_ASSIGNMENT_CONTACT_REQUEST, updateTaskReassignmentContactRequest)
  yield takeLatest(actions.SAVE_IMAGES_AND_NOTES_REQUEST, saveImagesAndNotesRequest)
  yield takeLatest(actions.ADD_NOTES_AND_MEDIA_REQUEST, addNoteAndMediaRequest)
  yield takeLatest(actions.GET_IN_PROGRESS_WIDGET_REQUEST, getInProgressWidgetRequest)
  yield takeLatest(actions.GET_OVERDUE_TASK_WIDGET_REQUEST, getOverdueTaskWidgetRequest)
  yield takeLatest(actions.GET_PENDING_COMPLETION_TASK_WIDGET_REQUEST, getPendingCompletionTaskWidgetRequest)
  yield takeLatest(actions.GET_ACTIVITY_NOTE_AND_MEDIA_REQUEST, getActivityNoteAndMediaRequest)
  yield takeLatest(actions.DELETE_NOTE_AND_MEDIA_REQUEST, deleteActivityNoteAndMediaRequest)
  yield takeLatest(actions.ADD_TRADE_CONTACT_REQUEST, addTradeContactRequest)
  yield takeLatest(actions.GET_ACTIVITY_REVIEW_VM_REQUEST, getActivityReviewVMRequest)
  yield takeLatest(actions.UPDATE_TASK_REVIEW_ASSIGNMENT_REQUEST, updateTaskReviewAssignmentRequest)
  yield takeLatest(actions.ASSIGN_SELF_SCHEDULE_REQUEST, assignSelfScheduleRequest)
  yield takeLatest(actions.GET_TASK_CAN_SELF_ASSIGN_REQUEST, getTaskCanSelfAssignRequest)
  yield takeLatest(actions.GET_TASK_COUNT_REQUEST, getTaskCountRequest)
  yield takeLatest(actions.GET_OBSERVATION_TASK_REQUEST, getObservationTaskRequest)
  yield takeLatest(actions.GET_ACTION_CONFIRMATION_VM_REQUEST, getActivityConfirmationVMRequest)
  yield takeLatest(actions.UPLOAD_INSPECTION_FILE_REQUEST, uploadInspectionFileRequest)
  yield takeLatest(actions.GET_ACTION_CONFIRMATION_VM_REQUEST, getActionConfirmationVMRequest)
  yield takeLatest(actions.UPDATE_PRE_TASK_CHECKLIST_REQUEST, updatePreTaskCheckListRequest)
  yield takeLatest(actions.GET_PRE_TASK_CHECKLIST_REQUEST, getPreTaskCheckListRequest)
  yield takeLatest(actions.GET_TASK_LINKS_COUNT_REQUEST, getTaskLinksCountRequest)
  yield takeLatest(actions.GET_TASK_LINK_LIST_REQUEST, getTaskLinkListRequest)
  yield takeLatest(actions.UPLOAD_RESPONSE_IMAGE_REQUEST, uploadResponseImageRequest)
}

export default taskDataCollectionSagas
