import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { ADDRESS: { CITY } } = LANGUAGE_CONSTANTS
const CitySearch = ({
  intl,
  id = "city",
  name = "",
  value = "",
  filterPage = "",
  initialLabel = "",
  customComponents = {},
  customSelectedLable = "",
  states = [],
  isMulti = false,
  isDisabled = false,
  placeholder = "",
  labelRequired = true,
  onSelect = () => { }
}) => {

  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
        setOptions([])
        if (!!initialLabel && !!value) {
          setSelectedOptionLabel(initialLabel)
        }
      }, [100])
    }
    fn()
  }, [initialLabel, states])

  const handleLoadMore = useCallback((search, page, prevOptions, others) => {
    return new Promise((resolve) => {
      if (others.isReset && !prevOptions.length && options.length) {
        resolve({
          optionList: options,
          hasMore: true
        })
        return
      }
      const params = {
        pageNumber: page,
        pageSize: DEFAULT_VM_PAGE_SIZE,
        city: search,
        filterPage,
        states
      }
      dispatch(
        actions.getCityByStatesListRequest(params, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            resolve({
              optionList: items,
              hasMore: paginationData.hasNextPage
            })
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...response.items] : response.items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        })
      )
    })
  }, [states, options, value, filterPage])

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && lastElement.city === '— Select —') {
          onSelect(id, [])
        } else {
          onSelect(id, selected)
        }
      } else {
        onSelect(id, selected?.city || "", (selected?.city && selected?.city) || "")
        setSelectedOptionLabel((selected?.city && selected?.city) || "")
      }
    },
    [onSelect, id]
  )

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  useEffect(() => {
    if (!selectedOptionLabel?.length && !!value && !isMulti) {
      const selectedOption = options.find((item) => item.city === value)
      setSelectedOptionLabel(selectedOption?.city || "")
    }
  }, [selectedOptionLabel, value, options])

  const handleRefreshOptions = useCallback(() => {
    setOptions([...value])   
    setResetCache(prevState => !prevState)
  }, [value])

  return (
    <>
      {labelRequired && <CustomLabel title={getLocaleMessage(intl, CITY)} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        isDisabled={isDisabled}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder || getLocaleMessage(intl, CITY)}
        options={options}
        lableType={'city'}
        valueType={'city'}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                city: selectedOptionLabel,
                city: value
              }
              : null
            : value
        }
        isMulti={isMulti}
        isShowCustomAction={false}
        hideSelectedOptions={false}
        customComponents={customComponents}
        customSelectedLable={customSelectedLable}
        getOptionLabel={(option) => option.city}
        getOptionValue={(option) => option.city}
        onLoadOptions={handleLoadMore}
        onRefreshOptions={handleRefreshOptions}
        onSelect={handleSelect}
      />
    </>
  )
}

export default localeMessageWrapper(CitySearch)