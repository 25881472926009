import { getSearchQuery } from "../config"

const apiConfig = {
  getResourceList: {
    method: "GET",
    url: ({
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections,
      isDocumentOnly,
      organizationBluePrintId,
      organizationEnergyModelId
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrganizationBluePrintId", organizationBluePrintId),
        getSearchQuery("OrganizationEnergyModelId", organizationEnergyModelId),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("IsDocumentOnly", isDocumentOnly)
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const { organizationId, projectId, siteId, unitId, programId, campaignId, observationTaskId, fileName, uploadedBy = [], type = [], uploadedDateFrom, uploadedDateTo, fileCategory = [] } = advanceSearch
        const fileTypeSearch = type.map((type) => getSearchQuery("FileType", type)).join("")
        const fileCategorySearch = fileCategory.map((fcat) => getSearchQuery("FileCategoryIds", fcat.value)).join("")
        const uploadedBySearch = uploadedBy.map((name) => getSearchQuery("UploadedBy", name)).join("")

        searchString = [
          searchString,
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("SiteUnitId", unitId),
          getSearchQuery("ProgramId", programId),
          getSearchQuery("CampaignId", campaignId),
          getSearchQuery("ObservationTaskId", observationTaskId),
          getSearchQuery("FileName", fileName, false, true),
          getSearchQuery("UploadedDateFrom", uploadedDateFrom),
          getSearchQuery("UploadedDateTo", uploadedDateTo),
          fileCategorySearch,
          fileTypeSearch,
          uploadedBySearch
        ].join('')
      }

      return `api/ResourceCenter/ResourceList${searchString}`
    }
  },
  getResourceListVM: {
    method: 'GET',
    url: "api/ResourceCenter/ResourceListVM"
  },
  deleteResourceCenter: {
    method: 'DELETE',
    url: (ResourceCenterId) => `api/ResourceCenter/${ResourceCenterId}`
  },
  downloadResourceDocument: {
    method: 'GET',
    url: ({ ActualFileName, FileURL }) => {
      const searchString = [
        getSearchQuery("FileURL", FileURL, true),
        getSearchQuery("ActualFileName", ActualFileName)
      ].join('')
      return `api/ResourceCenter/DownloadDocument${searchString}`
    }
  },
  uploadRCDocument: {
    method: 'POST',
    url: ({
      resourceCenterSourceId,
      title,
      fileDescription,
      fileSourceName,
      fileSourceId,
      programRequirementId,
      programRequirementServiceId,
      siteId,
      organizationId,
      fileCategoryId,
      organizationPlanId,
      additionalPOId,
      observationTaskId,
      isForUnitToo,
      isRequestInspectionModal
    }) => {
      let searchString = [
        `UploadDocument.ResourceCenterSourceId=${resourceCenterSourceId}`,
        `&UploadDocument.Title=${title}`,
        `&UploadDocument.FileDescription=${fileDescription}`,
        `&UploadDocument.Note=${fileDescription}`,
        isRequestInspectionModal ? getSearchQuery(`${fileSourceName}`, fileSourceId) : `&UploadDocument.${fileSourceName}=${fileSourceId}`,
        programRequirementId ? `&UploadDocument.ProgramRequirementId=${programRequirementId}` : '',
        programRequirementServiceId ? `&UploadDocument.ProgramRequirementServiceId=${programRequirementServiceId}` : '',
        siteId ? `&UploadDocument.SiteId=${siteId}` : '',
        organizationId ? `&UploadDocument.OrganizationId=${organizationId}` : '',
        fileCategoryId ? `&UploadDocument.FileCategoryId=${fileCategoryId}` : '',
        organizationPlanId ? `&UploadDocument.OrganizationPlanId=${organizationPlanId}` : '',
        additionalPOId ? `&UploadDocument.AdditionalPOId=${additionalPOId}` : '',
        observationTaskId ? `&UploadDocument.ObservationTaskId=${observationTaskId}` : ''
      ].join('')

      if (isRequestInspectionModal) {
        searchString = [
          searchString,
          isForUnitToo ? getSearchQuery("IsForUnitToo", isForUnitToo) : ''
        ].join('')

        return `api/ResourceCenter/UploadRequestedInspectionDocument?${searchString}`
      }

      return `api/ResourceCenter/UploadDocument?${searchString}`
    }
  },
  replaceRCDocument: {
    method: "PUT",
    url: ({
      resourceCenterId,
      resourceCenterSourceId,
      title,
      fileDescription,
      fileSourceName,
      fileSourceId,
      fileCategoryId
    }) => {
      const fileSourceNameQueryString = !!fileSourceName?.length
        ? getSearchQuery(fileSourceName, fileSourceId)
        : ""
      const searchString = [
        getSearchQuery("ResourceCenterId", resourceCenterId, true),
        getSearchQuery("ResourceCenterSourceId", resourceCenterSourceId),
        getSearchQuery("FileCategoryId", fileCategoryId),
        getSearchQuery("Title", title, false, true),
        getSearchQuery("FileDescription", fileDescription, false, true),
        fileSourceNameQueryString
      ].join('')
      return `api/ResourceCenter/${resourceCenterId}${searchString}`
    }
  },
  renameRCDocument: {
    method: "PUT",
    url: ({
      resourceCenterId,
      resourceCenterSourceId,
      title,
      fileSourceName,
      fileSourceId,
      fileCategoryId,
      fileDescription
    }) => {
      const fileSourceNameQueryString = !!fileSourceName?.length
        ? getSearchQuery(fileSourceName, fileSourceId)
        : ""

      const searchString = [
        getSearchQuery("ResourceCenterId", resourceCenterId, true),
        getSearchQuery("ResourceCenterSourceId", resourceCenterSourceId),
        getSearchQuery("FileCategoryId", fileCategoryId),
        getSearchQuery("Title", title, false, true),
        getSearchQuery("Note", fileDescription, false, true),
        fileSourceNameQueryString
      ].join('')
      return `api/ResourceCenter/${resourceCenterId}${searchString}`
    }
  },
  getFileTypeDDL: {
    method: 'GET',
    url: ({ pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/ResourceCenter/DDL/FileType${searchString}`
    }
  },
  getUploadedByDDL: {
    method: 'GET',
    url: ({ pageNumber, pageSize, contactName }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ContactName", contactName, false, true)
      ].join('')
      return `api/ResourceCenter/DDL/UploadedBy${searchString}`
    }
  },
  getResourceCenterFileCategoryDDL: {
    method: 'GET',
    url: "api/ResourceCenter/DDL/FileCategory"
  }
}

export default apiConfig
