export const LANGUAGE_CONSTANTS = {
  DASHBOARD: {
    HOME: "Home",
    HEADER_TITLE: "Dashboard",
    PLAN_REVIEW: "Plan Review",
    PLAN_REVIEW_LEAD: "Plan Review (Lead)",
    FILE_PROCESS: "File Process",
    FILE_PROCESS_LEAD: "File Process (Lead)",
    PROVIDERSHIP: "Providership",
    REPORT_PROCESSOR: "Report Processor",
    OPEN_ACTIONS_AGING: "Open Actions Aging",
    WORK_ORDER: "Work Order",
    PERFORMANCE: "Performance",
    FIELD_OPS: "Field Ops",
    BUILD_QUALITY: "Build Quality",
    NO_RECORDS_TO_DISPLAY: "No records to display.",
    RESOLVED: 'Resolved',
    EXCEPTION: 'Exceptions',
    TABLE_COLUMNS: {
      PRIORITY: "Priority",
      ACCOUNT: "Account",
      ACCOUNT_OFFICE: "Account Office",
      ASSIGNED_ON: "Assigned On",
      COMMENTS: "Comments",
      MODELING_SOFTWARE: "Modeling Software",
      OPTION: "Option",
      PLAN: "Plan",
      PURCHASE_ORDER: "Purchase Order",
      COMPLETED_ON: "Completed On",
      ELEVATION: "Elevation",
      FINAL_INSPECTION_DATE: "Final Inspection Date",
      FP_ASSIGNEE: "FP Assignee",
      FE_TASK_URL: "FP Task URL",
      MISSING_DATA: "Missing Data",
      MISSING_PLAN: "Missing Plan",
      PLAN_NAME: "Plan Name",
      PR_ASSIGNEE: "PR Assignee",
      PR_TASK_URL: "PR Task URL",
      SERVICE: "Service",
      ORGANISATION: "Organization",
      PARENT_ORGANIZATION: "Parent Organization",
      SITE: "Site",
      ASSIGNEE: "Assignee",
      FILE_PROCESS: "File Process",
      ASSIGNED_ON_START: "Assigned On (Start)",
      ASSIGNED_ON_END: "Assigned On (End)",
      FINAL_INSPECTION_ON_START: "Final Inspection On (Start)",
      FINAL_INSPECTION_ON_END: "Final Inspection On (End)",
      MISSING_DATE: "Missing Date",
      SITE_NAME: "Site Name",
      UNIT_NAME: "Unit Name",
      UNIT_URL: "Unit URL",
      VERSION: "Version",
      WORK_ORDER: "Work Order",
      WORK_ORDER_URL: "Work Order URL",
      PLAN_REVIEWER: "Plan Reviewer",
      PLAN_REVIEW: "Plan Review",
      PLAN_REVIEW_URL: "Plan Review URL",
      FILE_PROCESSOR: "File Processor",
      FILE_PROCESS_URL: "File Process URL",
      MISSING_CHECKLIST: "Missing Checklists",
      CHECKLIST_RECEIVED: "Checklists Received",
      ADDITIONAL_SERVICES_FAILING: "Additional Services Failing",
      TASK: "Task",
      RATER: "Rater",
      STATUS: "Status",
      STREET_ADDRESS: "Street Address",
      SUBMITTED_ON_START_DATE: "Submitted On Start Date",
      SUBMITTED_ON_END_DATE: "Submitted On End Date",
      REGISTERED_ON_START_DATE: "Registered On Start Date",
      REGISTERED_ON_END_DATE: "Registered On End Date",
      REGISTERED_ON: "Registered On",
      ADDRESS: "Address",
      THERMAL_INSPECTOR: "Inspector (Thermal Bypass)",
      THERMAL_INSPECTION_DATE: "Inspection Date (Thermal Bypass)",
      THERMAL_TASK_ID: "Thermal Bypass Task Id",
      FINAL_INSPECTOR: "Inspector (Final)",
      FINAL_INSPECTION_DATE: "Inspection Date (Final)",
      FINAL_TASK_ID: "Final Task Id",
      FILE_PROCESSOR_SUBMITTED_ON: "Submitted on (File Processor)",
      FILE_PROCESSOR_TASK_ID: "File Processor Task Id",
      PROVIDERSHIP_TASK_ID: "Providership Task Id",
      REPORT_PROCESSOR_TASK_ID: "Report Processor Task Id",
      RESNET_REGISTERATION_NO: "RESNET Registration Number",
      REBATE_COMPANY: "Rebate Company",
      SERS_ENB_FILE: "SERS .ENB File",
      DATE_SUBMITTED: "Date Submitted",
      DATE_REGISTERED: "Date Registered",
      TASK_ID: "Task Id",
      PERMIT_NUMBER: "Permit Number",
      BUILDER_TYPE: "Builder Type",
      PRODUCT_NUMBER: "Product Number",
      DIVISION: "Division",
      SQFT: "SQFT",
      SITE: "Site",
      RECEIVED: "Recieved",
      FORECAST: "Forecast",
      SCHEDULED_DATE: "Scheduled Date",
      DELIVERED: "Delivered",
      CLOSEOUT: "ClosedOut",
      ASSIGNEE: "Assignee",
      PO_NUMBER: "PO Number",
      AMOUNT: "Amount",
      TASK_STATUS: "Task Status",
      JOB_NUMBER: "Job Number",
      MISSING_NTBO_PACKET: "Missing NTBO Packet",
      MISSING_NOC: "Missing NOC",
      MISSING_STAMPED_PLANS: "Missing Stamped Plans",
      SUPERINTENDENT: "Superintendent",
      PROJECT_NUMBER: "Project Number",
      GP_CUSTOMER: "GP Customer",
      DUX_INSPECTION: "DUX Inspection",
      VIRTUAL_INSPECTION: "Virtual Inspection",
      MUNICIPALITY: "Municipality",
      JOB_SITE_TYPE: "Job Site Type",
      BUILDER_TYPE: "Builder Type",
      INSPECTOR: "Inspector",
      INSPECTION_TYPE: "Inspection Type",
      STATE_INSPECTION: "State (Inspection)",
      RECEIVED_START_DATE: "Received Start Date",
      RECEIVED_END_DATE: "Received End Date",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      PASS_FAIL: "Pass/Fail",
      PLANS: "Plans",
      RESULT: "Result",
      INSPECTOR_FEEDBACK: "InspectorFeedback",
      HERSSCORE: "HERSScore",
      ACH50: "ACH50",
      CFM25: "CFM25",
      CFM25OUTSIDE: "CFM25Outside",
      TOTAL_DUCTLEAKAGE_QN: "Total DuctLeakage QN",
      OUTSIDE_DUCTLEAKAGE_QN: "Outside DuctLeakage QN",
      RETURN_STATIC: "Return Static",
      SUPPLY_STATIC: "Supply Static",
      TOTAL_DUCTLEAKAGE_GRADE_DESIGNATION: "Total DuctLeakage Grade Designation",
      BLOWER_FAN_VOLUMETRIC_AIRFLOW_GRADE: "Blower Fan Volumetric Airflow Grade",
      BLOWER_FAN_WATT_DRAW_GRADE: "Blower fan watt draw grade",
      REFRIG_CHARGE_GRADE_FOR_WEIGH: "Refrig Charge Grade for Weigh",
      RADIANT_BARRIER: "Radiant Barrier",
      WALL_INSULATION_BASEMENT_RVALUE: "Wall Insulation Basement RValue",
      WALL_INSULATION_BASEMENT_GRADING: "Wall Insulation Basement Grading",
      WALL_INSULATION_1ST_FLOORS_RVALUE: "Wall Insulation 1stFloor RValue",
      WALL_INSULATION_1ST_FLOORS_GRADING: "Wall Insulation 1stFloor Grading",
      WALL_INSULATION_ADDITION_FLOORS_RVALUE: "Wall Insulation Addition Floors RValue",
      WALL_INSULATION_ADDITION_FLOORS_GRADING: "Wall Insulation Addition Floors Grading",
      WALL_INSULATION_GARAGE_RVALUE: "Wall Insulation Garage RValue",
      WALL_INSULATION_GARAGE_GRADING: "Wall Insulation Garage Grading",
      INSULATION_CEILING_RVALUE: "Insulation Ceiling RValue",
      INSULATION_CEILING_GRADING: "Insulation Ceiling Grading",
      WALL_INSULATION_KNEEWALL_RVALUE: "Wall Insulation KneeWall RValue",
      WALL_INSULATION_GRADING_KNEEWALL: "Wall Insulation Grading KneeWall",
      INSULATION_BASEMENT_CEILING_RVALUE: "Insulation Basement Ceiling RValue",
      INSULATION_BASEMENT_CEILING_GRADING: "Insulation Basement Ceiling Grading",
      FLOORS_ABOVE_GARAGE_RVALUE: "Floors Above Garage RValue",
      FLOORS_ABOVE_GARAGE_GRADING: "Floors Above Garage Grading",
      CANTILEVERED_RAISED_FLOOR_RVALUE: "Cantilevered Raised Floor RValue",
      CANTILEVERED_RAISED_FLOOR_GRADING: "Cantilevered Raised Floor Grading",
      BATHROOM_SHGC: "Bathroom SHGC",
      BATHROOM_UFACTOR: "Bathroom UFactor",
      BATHROOM_TALLY: "Bathroom Tally",
      FRONT_SHGC: "Front SHGC",
      FRONT_UFACTOR: "Front UFactor",
      FRONT_TALLY: "Front Tally",
      FRONT_DOOR_SHGC: "Front Door SHGC",
      FRONT_DOOR_UFACTOR: "Front Door UFactor",
      FRONT_DOOR_TALLY: "Front Door Tally",
      LEFT_SHGC: "Left SHGC",
      LEFT_UFACTOR: "Left UFactor",
      LEFT_TALLY: "Left Tally",
      REAR_SHGC: "Rear SHGC",
      REAR_UFACTOR: "Rear UFactor",
      REAR_TALLY: "Rear Tally",
      REAR_DOOR_SHGC: "Rear Door SHGC",
      REAR_DOOR_UFACTOR: "Rear Door UFactor",
      REAR_DOOR_TALLY: "Rear Door Tally",
      RIGHT_SHGC: "Right SHGC",
      RIGHT_UFACTOR: "Right UFactor",
      RIGHT_TALLY: "Right Tally",
      ENERGY_PROGRAM_SOURCE_TASK: "Energy Program Source Task",
      ENERGY_PROGRAM: "Energy Program",
      REASON_FOR_EXCEPTION: "Reason",
      REASON_DATE: "Reason Date",
      MARKET: "Market",
      REASON_START_DATE: "Reason Start Date",
      REASON_END_DATE: "Reason End Date",
      REASON: "Reason"
    },
    ROW_ACTIONS: {
      ASSIGN_PLAN_REVIEW: "Assign Plan Review",
      REASSIGN_PLAN_REVIEW: "Reassign Plan Review",
      OPEN_UNIT: "Open Unit",
      OPEN_PLAN_REVIEW: "Open Plan Review",
      OPEN_ROUGH: "Open Rough",
      OPEN_COMMENTS: "Open Comments",
      ASSIGN_FILE_PROCESS: "Assign File Process",
      REASSIGN_FILE_PROCESS: "Reassign File Process",
      OPEN_FILE_PROCESS: "Open File Process",
      OPEN_FINAL: "Open Final",
      TOGGLE_PRIORITY: "Toggle Priority",
      TOGGLE_PLAN_REQUESTED: "Toggle Plan Requested",
      REMOVE_AUDIT_RANDOM: "Remove Audit Random (10%)",
      REMOVE_AUDIT_WITH_CAUSE: "Remove Audit with Cause",
      AUDIT_RANDOM: "Audit Random (10%)",
      AUDIT_WITH_CAUSE: "Audit with Cause"
    },
    ERRORS: {
      FAILED_TO_RESOLVE_EXCEPTION: "Failed to resolve exception",
      EXCEPTION_RESOLVED_SUCCESS: "Exception resolved successfully"
    }
  },
  OF: 'of',
  SELECTED: "Selected",
  VIEW_AUDIT_TRAIL: "View Audit Trail",
  ADD_NOTES_MEDIA: "Add Notes/Media",
  ADD_NOTE: "Add Note",
  PINNED_NOTE: "Pinned Note",
  PINNED_NOTE_DESCRIPTION: "Add your important note here. This note will always display at the top of the list for quick reference.",
  ADD_NEW: "Add New",
  EDIT_WIZARD_HEADER_BREADCRUMB: "Edit - {name}",
  USER_MANAGEMENT: "User Management",
  MORE: "more",
  QUICK_SEARCH: "Quick search",
  RESPONSE: "Response",
  ORDER: "Order",
  ALL: "All",
  SHARED: "Shared",
  GROUP_ACTIVITES: {
    GROUP: "Group",
    GROUPS: "Groups",
    GROUP_NAME: "Group Name",
    ADD_GROUP: "Add Group",
    DELETE_GROUP: "Delete Group",
    SAVE_GROUP: "Save Group",
    ADD_TO_GROUP: "Add to Group",
    SELECT_GROUP: "Select Group",
    MANAGE_CHECKLIST_GROUP: "Manage Checklist Groups",
    MANAGE_TEMPLATE_GROUP: "Manage Template Groups",
    ADD_TEMPLATE_GROUP_TEXT: "Create groups and add activities to help organize your templates.",
    ADD_GROUP_TEXT: "Create groups and add activities to help organize your checklists.",
    GROUP_ACTIVITIES_COUNT_SINGULAR_TEXT: "View {count} Activity",
    GROUP_ACTIVITIES_COUNT_PLURAL_TEXT: "View {count} Activities",
    NO_ACTIVITIES: "No Activities",
    NO_ACTIVITIES_MESSAGE: "No Activities added to Group. Select from available Activities above.",
    AVAILABLE_ACTIVITIES_LIST_TITLE: "Add to Group (Select from Available Activities)",
    NO_GROUPS_MESSAGE: "Groups display here once created.",
    AVAILABLE_ACTIVITIES_LIST_NOTE: "Note: Only the selected activities will be saved to your Group.",
    GROUP_NAME_IS_REQUIRED: "Group name is required to save group details.",
    ACTIVITIES: "Activities",
    PLEASE_SELECT_GROUP: "Please select group to add activities.",
    PLEASE_SELECT_ACTIVITIES: "Please select activities from below list to add to selected group.",
    NO_AVAILABLE_ACTIVITIES: "No activities available to add to groups."
  },
  GROUP_CONTACTS: {
    LIST_TITLE: "All Contacts / Groups",
    GROUP: "Group",
    AVAILABLE_CONTACTS_LIST_TITLE: "Add to Group (Select from Contacts)",
    ADD_TO_GROUP: "Add to Group",
    SELECT_GROUP: "Select Group",
    NO_CONTACTS_MESSAGE: "No Contacts added to Group. Select from Contacts above.",
    GROUP_NAME_IS_REQUIRED: "Group name is required to save group details.",
    GROUP_CONTACTS_COUNT_SINGULAR_TEXT: "View {count} Contact",
    GROUP_CONTACTS_COUNT_PLURAL_TEXT: "View {count} Contacts",
    NO_CONTACTS: "No Contacts",
    AVAILABLE_CONTACTS_LIST_NOTE: "Note: Only the selected contacts will be saved to your Group.",
    NO_AVAILABLE_CONTACTS: "No contacts available to add to groups.",
    PLEASE_SELECT_GROUP: "Please select group to add contacts.",
    PLEASE_SELECT_CONTACTS: "Please select contacts from below list to add to selected group.",
    NO_GROUPS_FOR_FILTERS: "You have no contact groups matched with filters you applied."
  },
  API_ERRORS: {
    NO_ACCESS_TO_RESOURCE: "You have no access to the resource",
    NO_ACCESS_TO_UPDATE: "You have no access to update",
    NO_ACCESS_TO_CREATE: "You have no access to create",
    NO_ACCESS_TO_DELETE: "You have no access to delete",
    SERVICES_DOWN: "Services are down"
  },
  NO_AUDIT_TRAILS: "No Audit Trails",
  AUDIT_TRAIL_MODAL_SAGA: {
    DELETE_SUCCESS: "Audit trail item deleted successfully",
    DELETE_FAIL: "Error in deleting audit trail item",
    ERROR_IN_SAVING_AUDIT_TRIAL: "Error in saving audit trail.",
    AUDIT_TRIAL_SAVE_SUCCESS: "Audit trail saved successfully.",
    ERROR_IN_UPDATING_AUDIT_TRIAL: "Error in updating audit trail.",
    AUDIT_TRIAL_UPDATE_SUCCESS: "Audit trail updated successfully."
  },
  ORGANIZATIONS: {
    HEADER_TITLE: "Organizations",
    ADD_ORGANIZATION: "Add Organization",
    CONTACTS_TABLE_TITLE: "Organization Contacts",
    LOCATION: "Location",
    DIRECTORY: "Directory",
    REVIEW: "Review",
    REVIEW_CONTACT_LIST_TITLE: "{count} Organization Contacts",
    ORGANIZATION_LIST: {
      NO_DATA: "No organizations to display.",
      LIST_TITLE: "All Organizations",
      NO_RECORDS_TO_DISPLAY: "No organizations to display. Create your first organization to get overviews and reports.",
      LIST_DESCRIPTION: "Organizations are used to group your business, projects and teammates."
    },
    HIGHLIGHTS: {
      HEADER_TITLE: "Highlights",
      ACTIVE_PROJECT_SITES: "Active Project Sites",
      ACTIVE_PRGRAMS: "Active Programs",
      UPCOMING_TASKS: "Upcoming Observation Tasks",
      COMPLETED_UNITS: "Completed Units",
      WARRANTY_COST: "Warranty Costs Avoided via Inspection",
      WARRANTY_CLAIM: "Warranty Claims Avoided via Inspection",
      WARRANTY_COST_SAVINGS: "Warranty Cost Savings",
      CORRECTION_COSTS: "Correction Costs Saved",
      CYCLE_TIME_REDUCTION: "Saved Cycle Time (Days)",
      CONSTRUCTION_CALLBACKS: "Construction Call Backs",
      QUALITY_SCORE: "Quality Score",
      AVG_ACTIVITY_CYCLE_TIME: "Avg Activity Cycle Time",
      AVG_FAILED_ACTIVITIES: "Avg Failed Activities"
    },
    TABLE_COLUMNS: {
      ORGANIZATION: "Organization",
      PARENT: "Parent",
      CATEGORY: "Category",
      ORG_TYPE: "Org Type",
      LOCATION: "Location",
      NUMBER_OF_SITES: "Number of sites"
    },
    ORGANIZATION_DETAILS: "Organization Details",
    ORG_DETAILS: "Org Details",
    ORGANIZATION_NAME: "Organization Name",
    ORGANIZATION_ID: "Organization ID (Auto-Generated)",
    ORG_ID: "Organization ID",
    ORGANIZATION_TYPE: "Organization Type",
    PARENT_ORG: "Parent Organization",
    PARENT_ORGANIZATION: "Parent Organization (if applicable)",
    ORGANIZATION_CATEGORY: "Organization Category",
    EXTERNAL_ID: "External ID",
    ENTER_ID: "Enter ID",
    ORGANIZATION_TREE: "Organization Tree",
    CHECK_FOR_CORRECT_ORGANIZATION_RELATIONSHIP: "Check for correct organization relationship",
    CHILD_ORGANIZATIONS: "Child Organizations",
    NO_CHILD_ORGANIZATION: "No Child Organization to display",
    SELECT_ORGANIZATION: "Select Organization",
    ORG_PLAN: {
      PLAN: "Plan",
      ADD_PLAN: 'Add Plan',
      LIST_TITLE: "Organization Plans",
      TABLE_COLUMNS: {
        PLAN_NAME: "Plan Name",
        BLUEPRINT_FILES: "Blueprint Files",
        ENB_XML_FILES: "ENB/XML Files",
        TOTAL_CONDITION_SPACE: "Total Condition Space",
        VOLUME: "Volume",
        CREATED_DATE: "Created Date",
        UPDATED_DATE: "Updated Date",
        BLUEPRINT_NAME: "Blueprint Name",
        ENERGY_MODEL_NAME: "Energy Model Name",
        ENERGY_MODEL_FILES: "Energy Model Files",
        MISSING_ITEMS: "Missing Items",
        NUMBER_OF_BLUEPRINTS: "# of Blueprints",
        NUMBER_OF_ENERGY_MODELS: "# of Energy Models"
      }
    }
  },
  CONTACTS: {
    CONTACT: "Contact",
    CONTACTS: "Contact(s)",
    CONTACT_NAME: "Contact Name",
    HEADER_TITLE: "Contacts",
    ADD_CONTACT: "Add Contact",
    ORGANIZATION_ROLE: "Org Role",
    ADD_GROUP: "Add Group",
    EDIT_GROUP: "Edit Group",
    EDIT_CONTACT: "Edit Contact",
    DELETE_CONTACT: "Delete Contact",
    TRAINING_LOG: "Training Log",
    NO_TRAINING_LOG_FOUND: "No training log data found.",
    LIST_TITLE: "All Contacts",
    NO_RECORDS_TO_DISPLAY: "No Contacts to display.  Add your first contact today and begin your journey to more profitable, safe, and compliant projects.",
    REMOVE_CONTACT: "Remove Contact",
    MANAGE_PROFILE_IMAGE: "Manage Profile Image",
    UPDATE_EMAIL_ADDRESS: "Update Email Address",
    CONTACT_ROLES_AND_PERMISSION: "Contact Roles and Permission",
    ADD_NEW_ROLE: "Add New Role",
    ACCOUNT: "Account",
    USER_ACCOUNT: "User Account",
    USER_HAS_ACCOUNT: "User has Account",
    WELCOME_EMAIL_SENT: "Welcome Email Sent",
    LAST_LOGGED_IN: "Last Logged In",
    CREATE_USER_ACCOUNT: "Create User Account",
    SEND_PASSWORD_RESET: "Send Password Reset",
    MANUAL_PASSWORD_RESET: "Manual Password Reset",
    ACCOUNT_HISTORY: "Account History (Past 10 Days)",
    RESET_PASSWORD: "Reset Password",
    NEW_PASSWORD: "New Password",
    ENTER_NEW_PASSWORD: "Enter New Password",
    CONFIRM_PASSWORD: "Confirm Password",
    NO_ACCOUNT_HISTORY_FOUND: "There is no Account History for this user. Once account actions takes place they will be logged here.",
    TABLE_COLUMNS: {
      NAME: "NAME",
      CONTACT_NAME: "Contact Name",
      EMAIL: "EMAIL",
      JOB_TITLE: "JOB TITLE",
      PHONE: "PHONE",
      ACCESS: "ACCESS",
      TITLE: "title",
      COMPLETED_DATE: "completed date",
      RESULT: "result",
      FIRST_ACCESS_DATE: "first access date",
      LAST_ACCESS_DATE: "last access date",
      CAMPAIGN: "Campaign",
      PROJECTS: "Projects",
      ROLE: "Role",
      ORGANIZATION: "Organization",
      SITE: "Site",
      DESCRIPTION: "Description",
      INSERTED_BY: "Inserted By",
      DATE: "Date",
      TIME: "Time",
      SITES: "Sites",
      TAG: "Tag"
    },
    CONTACT_TYPE: {
      SITE_POINT_OF_CONTACT: "Site Point of Contact",
      UNIT_PRIMARY_CONTACT: "Unit Primary Contact",
      SITE_DIRECTORY_CONTACT: "Site Directory Contact"
    },
    NAME: "Name",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    TITLE: "Title",
    ORGANIZATION: "Organization",
    SITE: "Site",
    JOB_TITLE: "Job Title",
    EMAIL_ADDRESS: "Email Address",
    EMAIL: "Email",
    TEXT: "Text",
    SYSTEM_ALERT: "System Alert",
    ACCOUNT_ACCESS: "Account Access",
    SYSTEM_ACCESS: "System Access",
    SELECT_SYSTEM_ACCESS_TYPE: "Select System Access Type",
    SELECT_ACCOUNT_ACCESS_TYPE: "Select Account Access Type",
    NOTIFICATION: "Notification",
    ALL: "All",
    SYSTEM: "System",
    SMS: "SMS",
    ROLE: "Role",
    FREQUENCY: "frequency",
    TYPE: "Type",
    ORG_ROLE: "Org role",
    SELECT_CONTACTS: "Select contact(s)",
    CURRENT_EMAIL: "Current Email",
    NEW_EMAIL: "New Email",
    CONFIRM_NEW_EMAIL: "Confirm New Email",
    ERRORS: {
      NAME: "First name and last name are required",
      FIRST_NAME: "Firstname is required",
      LAST_NAME: "Lastname is required",
      EMAIL: "Email is required",
      INVALID_EMAIL: "Invalid email address",
      ORGANIZATION: "Organization is required",
      INVALID_ORGANIZATION: "Invalid Organization , should be numeric value",
      PHONE: "Phone number is required",
      INVALID_PHONE: "Please enter valid phone number",
      ACCOUNT_ACCESS: "Account access is required",
      INVALID_ACCOUNT_ACCESS: "Invalid Account access , should be numeric value",
      JOB_TITLE: "Job title is required",
      PASSWORDS_DID_NOT_MATCH: "Passwords did not match",
      PASSWORD_REQUIRED: "Password is required",
      EMAILS_DONT_MATCH: "Emails do not match"
    },
    MANAGE_NOTIFICATIONS: "Manage Notifications",
    MANAGE_NOTIFICATIONS_DESC: "Select which contacts will be copied on notification emails to the organization",
    SELECT_NOTIFICATION_CONTACTS: "Select Contacts"
  },
  PROJECTS: {
    PROJECT_SITES: "Project Sites",
    HEADER_TITLE: "Projects",
    ADD_TO_PROJECT: "Add to Project",
    ADVANCED: {
      PROJECT_NAME: "Project Name",
      SITE_NAME: "Site Name",
      ROLE_NAME: "Role Name",
      ORGANIZATION_NAME: "Organization Name",
      ROLE: "Role"
    },
    TABLE_COLUMNS: {
      PROJECT: "PROJECT",
      ORGANIZATION: "ORGANIZATION",
      PROJECT_TYPE: "PROJECT TYPE",
      LOCATION: "Location",
      START_DATE: "START DATE",
      STATUS: "STATUS",
      SERVICE: "service",
      ASSIGNED_TO: "assigned to",
      PROJECTS: "Projects",
      SITE: "Site",
      SITE_ROLE: "Site Role",
      WORKFLOW: "Workflow",
      SITES: "Sites",
      CONTACTS_SITES: "Contact's Sites"
    },
    ADD_PROJECT: "Add Project",
    ADD_NEW_PROJECT: "Add New Project",
    EDIT_PROJECT: "Edit Project",
    VIEW_BY_ORGANIZATION: "View by Organization",
    PROJECT_NAME: "Project Name",
    PROJECT_DETAILS: "Project Details",
    ORGANIZATION: "Organization",
    PROJECT_TYPE: "Project Type",
    CONSTRUCTION_START_DATE: "Construction Start Date",
    SELECT_CONSTRUCTION_START_DATE: "Select Construction Start Date",
    PROJECT_STATUS: "Project Status",
    SELECT_ORGANIZATION: "Select Organization",
    SELECT_PROJECT_TYPE: "Select Project Type",
    SELECT_PROJECT: "Select Project",
    SELECT_PROJECT_STATUS: "Select Project Status",
    LAST_28_DAYS: "Last 28 Days",
    LAST_MONTH: "Last Month",
    LAST_YEAR: "Last Year",
    ERRORS: {
      NAME: "Please enter project name",
      ORGANIZATION: "Select project organization",
      TYPE: "Select project type",
      CONSTRUCTION_START_DATE: "Select project Construction Start Date",
      STATUS: "Select project status",
      PHONE: "Please enter valid phone number",
      STREET_ADDRESS: "Please enter street address",
      COUNTRY: "Please select country",
      CITY: "Please enter city name",
      STATE: "Please select state",
      ZIP_CODE: "Please enter zipcode",
      PLAN_NAME: "Please enter plan name",
      VERSION: "Please enter version",
      SITE_NAME: "Please enter site name",
      BUILDING_TYPE: "Please select building type",
      POINT_OF_CONTACT: "Please select point of contact",
      SITE_STATUS: "Please enter site status",
      UNIT_NAME: "Please enter unit name",
      UNIT_TYPE: "Please select unit type",
      BUILDING_NAME: "Please enter building name",
      FLOOR_NAME: "Please enter floor name",
      SITE_ROLE: "Please select site role",
      SELECT_ORGANIZATION: "Please select organization",
      SELECT_SITES: "Please select site",
      CONTACTS: "Please select contacts",
      INVALID_NO_OF_STORIES: "Invalid number of stories",
      INVALID_NO_OF_BEDROOMS: "Invalid number of bedrooms",
      INVALID_NO_OF_BATHROOMS: "Invalid number of bathrooms",
      INVALID_PHONE: "Please enter valid phone number",
      INVALID_HVAC_NUMBER: "Invalid # of HVAC system, it must be 1 or more",
      INVALID_ENERGY_MODELING_DATA: "Invalid energy modeling JSON data format"
    },
    USE_PROJECT_ADDRESS: "Use Project Address",
    EXPORT_ALL: "Do you want to export all",
    YES: 'Yes',
    NO: 'No'
  },
  TEMPLATES: {
    ADD_NEW: "Add New",
    HEADER_TITLE: "Templates",
    SETUP: {
      OVERVIEW: "Overview",
      ADD_ACTIVITIES: "Add Activities",
      REVIEW: "Review"
    },
    ADD_TEMPLATE: "Add Template",
    TEMPLATE_DETAILS: "Template Details",
    TEMPLATE_NAME: "Template Name",
    TEMPLATE_CATEGORY: "Template Category",
    BUILDING_TYPE: "Building Type",
    SCOPE_OF_WORK: "Scope of Work",
    TEMPLATE_STATUS: "Template Status",
    TEMPLATE_DESCRIPTION: "Template Description",
    SELECT_TEMPLATE: "Select Template",
    CLONE_TEMPLATE: "Clone Template",
    CLONE_MODAL_TEXT: "Select a template to copy it’s details to start your new template.",
    TEMPLATE: "template",
    CAMELCASE_TEMPLATE: "Template",
    TEMPLATE_OVERVIEW: "Template Overview",
    TEMPLATE_VERSION: "Template Version",
    TEMPLATE_DESCRIPTION_LIST: "Build templates to create a list of activities. Use templates in campaigns and programs to assign and send your template into the field.",
    TABLE_COLUMNS: {
      TEMPLATE: "TEMPLATE",
      BUILDING_TYPE: "Building Type",
      SCOPE_OF_WORK: "Scope of Work",
      TYPE: "Type",
      CATEGORY: "Category",
      STATUS: "Status",
      VERSION: "Version"
    },
    EDIT_ACTIVITY_TRAINING: 'Edit Activity Training',
    ADD_TRAINING: 'Add Training',
    SELECT_TRAINING: 'Select Training',
    SELECT_TRAINING_CONTENT: 'Select Training Content',
    PRIMARY: "Primary",
    LESSON_NAME: "Lesson Name",
    REQUIRED_LESSON: "Required Lesson",
    REQUIRED: "Required",
    NO_TRAINING_CONTENT_FOUND: "No Training Content Found",
    NO_RESPONSES_FOUND: "No responses found",
    TRAINING: "Training",
    VALIDATIONS: {
      TEMPLATE_NAME: "Please enter template name",
      TEMPLATE_LIMIT: "Template name should not be exceeded by 150",
      CATEGORY: "Select template category",
      BUILDING_TYPE: "Select building type",
      SCOPE_OF_WORK: "Select scope of work",
      STATUS: "Select template status",
      STAGE_OF_CONSTRUCTION: "Select stage of construction",
      RESPONSIBLE_SITE_ROLE: "Select responsible site role",
      RISK_LEVEL: "Select risk level",
      GOVERNANCE_REGULATION: "Please enter governance regulation",
      ASSEMBLY: "Select assembly",
      COMPONENT: "Select component",
      SUB_COMPONENT: "Select sub-component",
      MANUFACTURER: "Select manufacturer",
      MANUFACTURER: "Select hold production",
      ASSEMBLY_TYPE: "Select Assembly Type",
      MAIN_COMPONENT: "Select Main Component"
    },
    SEARCH_TEMPLATES: "Search Templates",
    TYPE: "Type",
    CATEGORY: "Category",
    STATUS: "Status",
    TEMPLATES_REVIEW_DESCRIPTION: "Add your template details, then click “Next”. To use an existing template “Select From Existing”.",
    TEMPLATES_ACTIVITY_DESCRIPTION: "Select or add new activities your want to save to your template. Then click “Next” to review your template.",
    ADDED_ACTIVITIES: "Added Activities ({count})",
    NAME: "Name",
    NO_ACTIVITY_TEMPLATES: "Activity not linked with any templates."
  },
  ACTIVITIES: {
    NO_ACTIVITY_DETAIL_MSG: "Your activity details will appear here as you add them.",
    ADD_ACTIVITY: "Add Activity",
    TABLE_COLUMNS: {
      TYPE: 'Type',
      ACTIVITY_NAME: "Activities",
      ACTIVITY_TYPE: "Activity Type",
      ORIGINATED_FROM: "ORIGINATED FROM",
      STANDARD: "STANDARD",
      ORDER: "Order",
      RISK_LEVEL: "Risk Level"
    },
    YES: "Yes",
    NO: "No",
    SEARCH_COURSE: "Search Course",
    SEARCH_BY_COURSE: "Search by Course",
    ACTIVITIES: "Activities",
    ADVANCE_USER: "Advance User",
    TEMPLATE_ACTIVITIES: "Template Activities",
    NEW_ACTIVITY: "New Activity",
    ACTIVITY_DETAILS: "Activity Details",
    ADD_ACTIVITIES_TO: 'Add activities to {activity_name}',
    ADD_ACTIVITIES_TITLE: "Create your template by selecting and adding activities.",
    SELECT_ACTIVITIES: "Select Activities",
    ADD_OBSERVATION_ACTIVITY: "Add Observation Activity",
    ADD_TRAINING_ACTIVITY: "Add Training Activity",
    ADD_NEW_ACTIVITY: "Add New Activity",
    ADD_NEW_ACTIVITY_TITLE: "Define what you activity you want, and how you'd like it pushed out.",
    ACTIVITY_TYPE: "Activity Type",
    SELECT_TYPE: "Select Type",
    OBSERVATION_ACTIVITY: "Observation Activity",
    EDIT_OBSERVATION_ACTIVITY: "Edit Observation Activity",
    OBSERVATION_ACTIVITY_TITLE: "Define what you want observed, and how the observation should be captured.",
    SELECT_VERSION: "Select Version",
    ACTIVITY: "Activity",
    ACTIVITY_MODAL: "activity",
    DEFINE_ACTIVITY: "Define Activity",
    ADD_RESPONSE: "Add Response",
    RESPONSE_TYPE: "Response Type",
    PRIMARY_RESPONSE: "Primary Response",
    SHOW_RESPONSE_IF: "Show Response If...",
    ALL_RESPONSES: "All Responses",
    SELECT_CONDITION: "Select Condition",
    ALL_RESPONSES_SELECTED: "All Responses Selected",
    SELECT_RESPONSE_TYPE_TO_CREATE_YOUR_PRIMARY_RESPONSE: "Select response type to create your primary response.",
    SHOW_IF_RESPONSE_IS: "Show if Response is",
    SELECT_RESPONSE: "Select Response",
    ADD_ACTIVITY: "Add Activity",
    UPDATE_ACTIVITY: "Update Activity",
    ADD_OBSERVATION_DETAILS: "Add Observation Details",
    EDIT_OBSERVATION_DETAILS: "Edit Observation Details",
    OBSERVATION_DETAILS_TITLE: "Specify how the new activity fits into your projects.",
    SYSTEM_DEFINITION: "System Definition",
    ACTIVITY_NAME: "Activity Name",
    ACTIVITY_TYPE: "Activity Type",
    STAGE_OF_CONSTRUCTION: "Stage of Construction",
    SCOPE_OF_WORK: "Scope of Work",
    SYSTEM_CATEGORY: "System Category",
    ASSIGNED_ROLE: "Assigned Role",
    RESPONSIBLE_SITE_ROLE: "Responsible Site Role",
    REQUIRE_PHOTOS: "Require Photos",
    SELECT_SITE_ROLE: "Select Site Role",
    RISK_LEVEL: "Risk Level",
    ALLOW_COPY: "Allow Copy",
    HOLD_PRODUCTION: "Hold Production?",
    SYSTEM_CATEGORY_NAICS: "System Category (NAICS)",
    SELECT_SYSTEM_CATEGORY: "Select System Category",
    BRAND: "Brand",
    SELECT_BRAND: "Select Brand",
    ASSEMBLY: "Assembly",
    SELECT_ASSEMBLY: "Select Assembly",
    COMPONENT: "Component",
    SELECT_COMPONENT: "Select Component",
    SUB_COMPONENT: "Sub-Component",
    SELECT_SUB_COMPONENT: "Select Sub-Component",
    MANUFACTURER: "Manufacturer",
    SELECT_MANUFACTURER: "Select Manufacturer",
    EDIT_ACTIVITY_TRAINING: "Edit Activity Training",
    ADD_TRAINING: "Add Training",
    SELECT_TRAINING_CONTENT: "Select Training Content",
    SELECT_TRAINING: "Select Training",
    REQUIRED: "Required",
    OPTIONAL_NOT_REQUIRED: "Optional (Not Required)",
    REQUIRED_LESSON: "Required Lesson",
    LESSON_NAME: "Lesson Name",
    PRIMARY: "Primary",
    DELETE: "Delete",
    TRAINING_ACTIVITY: "Training Activity",
    EDIT_TRAINING_ACTIVITY: "Edit Training Activity",
    TRAINING_ACTIVITY_TITLE: "Specify how the new activity fits into your projects.",
    SELECT_VERSION: "Select Version",
    LMS_ID: "LMS ID",
    TRAINING_CONTENT_URL: "Training Content (URL)",
    SUBJECT: "Subject",
    COURSE: "Course",
    CHAPTER: "Chapter",
    LESSON: "Lesson",
    CONTENT_DETAILS: "Content Details",
    ACTIVITY_STATUS: "Activity Status",
    SOURCE: "Source",
    VERSION: "Version #",
    VERSION_DATE: "Version Date",
    AUTHOR: "Author",
    STATUS: "Status",
    TRAINING_DESCRIPTION: "Training Description",
    IS_ALREADY_ADDED: "This activity is already added",
    ASSEMBLY_TYPE: "Assembly Type",
    MAIN_COMPONENT: "Main Component",
    PHOTO_REQUIRED: "Photo Required",
    DEFAULT_VALUE: "Default Value",
    RESULT_VALUE: "Result Update",
    ALWAYS_REINSPECT: "Always Reinspect",
    VALIDATIONS: {
      ACTIVITY_NAME: "Please define activity name",
      TRAINING_CONTENT: "Please enter training content",
      LMS_ID: "Please enter lms id",
      TRAINING_VERSION_ID: "Please enter training version id",
      SUBJECT: "Please select subject",
      COURSE: "Please select course",
      SOURCE: "Please select sourse",
      CHAPTER: "Please select chapter",
      LESSON: "Please select lesson",
      STATUS: "Please select status",
      STAGE_OF_CONSTRUCTION: "Please select stage of construction",
      TRAINING_VERSION_DATE: "Please select training version date",
      AUTHOR: "Please select author",
      TRAINING_DESCRIPTION: "Training description should not be more than 500 characters"
    },
    ADD_OBSERVATION_ACTIVITY: 'Add Observation Activity',
    ADD_TRAINING_ACTIVITY: 'Add Training Activity',
    SELECT_TYPE_TO_ADD_ACTIVITY: 'Select Type To Add Activity',
    VIEW_RESPONSES: "View Responses",
    VIEW_LESSONS: "View Lessons",
    SEARCH_ACTIVITIES: "Search Activities",
    CLONE_ACTIVITY: "Clone Activity",
    SELECT_AN_ACTIVITY_TO_COPY_IT_S_DETAILS_TO_YOUR_NEW_ACTIVITY: "Select an activity to copy it's details to your new activity.",
    SELECT_ACTIVITY: "Select Activity",
    RESPONSE_DELETION_CAUTION: 'Caution: If you press yes then all secondary responses will be deleted automatically.',
    APPLICABLE_REGUALTION: {
      NAME: "Regulation Name",
      REALTED_SECTION_NAME: "Related Section Name",
      DESCRIPTION: "Description for Related Section",
      NAME_PLACEHOLDER: "Regulation Name",
      SECTION_PLACEHOLDER: "Related Section Name",
      DESCRIPTION_PLACEHOLDER: "Write Description...",
      TITLE: "Applicable Regulation",
      LIST_TITLE: "Added Regulations",
      ERRORS: {
        ENTER_ALL_FIELDS: "Please enter all fields"
      },
      TABLE_COLUMNS: {
        REGULATION: "Regulation",
        RELATED_SECTION: "Related Section",
        RELATED_SECTION_DESCRIPTION: "Related Section Description"
      }
    },
    REFRENCE_STANDARDS: {
      TITLE: "Reference Standards",
      LIST_TITLE: "Added Standards",
      STANDARD: "Standard",
      STANDARD_PLACEHOLDER: "Standard Name",
      DESCRIPTION: "Standard description",
      DESCRIPTION_PLACEHOLDER: "Write Description...",
      ERRORS: {
        ENTER_ALL_FIELDS: "Please enter all fields"
      },
      TABLE_COLUMNS: {
        STANDARD: "Standard",
        STANDARD_DESCRIPTION: "Standard Description"
      }
    },
    INDUSTRY_INTEPRETATIONS: {
      TITLE: "Industry Interpretations",
      LIST_TITLE: "Added Interpretations",
      INTERPRETATIONS: "Interpretations",
      INTERPRETATIONS_PlACEHOLDER: "Interpretations",
      DESCRIPTION: "Interpretations description",
      DESCRIPTION_PLACEHOLDER: "Write Description...",
      ERRORS: {
        ENTER_ALL_FIELDS: "Please enter all fields"
      },
      TABLE_COLUMNS: {
        INTERPRETATIONS: "Interpretations",
        INTERPRETATIONS_DESCRIPTION: "Interpretations Description"
      }
    },
    TRAINING: {
      TITLE: "Training Content"
    },
    REPORT_TAG: "Report Tag",
    ADD_REPORT_TAG: "Add Report Tag"
  },
  OBSERVATION_ACTIVITY_RESPONSE_QUESTION: {
    VALIDATIONS: {
      RESPONSE_CONDITION: "Please select response condition",
      NUMERIC_RESPONSE_CONDITION: "Please enter number",
      ACTIVITY: "Please define activity",
      VERSION_ID: "Version Id is required to update activity. Please select version id.",
      ACTIVITY_RESPONSE: "Please add atleast one response for activity",
      CHOICE: "Please enter choice",
      DUPLICATE_OTHER_OPTION: "You can't enter duplicate other option",
      NUMERIC_TYPE: "Please select numeric type",
      NUMERIC_MIN: "Please enter minimum range value",
      VALIDATE_MIN: "Minimum value should not be negative",
      VALIDATE_NUMERIC: "Minimum value should be less the Maximum Value",
      NUMERIC_MAX: "Please enter maximum range value",
      VALIDATE_MAX: "Maximum value should not be negative",
      UNIT_TYPE: "Please select unit type",
      ACCEPTABLE: "Acceptable",
      UNACCEPTABLE: "Unacceptable",
      CONCEALED: "Concealed",
      N_A: "N/A",
      PRIMARY_RESPONSE: "Please choose a primary response",
      REQUIRED_RESPONSE_QUESTION: "please add a response question",
      RANGE_RESPONSE_MESSAGE: "Please enter value between {numericMin} and {numericMax}",
      NUMERIC_MIN_DECIMAL: "Minimum range should not be decimal",
      NUMERIC_MAX_DECIMAL: "Maximum range should not be decimal"
    }
  },
  TABLE: {
    COLUMN_ACTIONS: "Actions",
    SHOW: "Show",
    ADVANCED: "Advanced",
    VIEW: "View",
    EDIT: "Edit",
    DELETE: "Delete",
    DELETION_CONFIRMED: "Deleting...",
    SEARCH: "Search",
    SHOWING: "Showing",
    ENTRIES: "Entries",
    UNASSIGN: "Unassign",
    ORDER: "Order",
    CLONE: "Clone",
    NEW_VERSION: "New Version",
    EXPORT_DOCX: "Export to word",
    EXPORT_PDF: "Export to pdf",
    ROWS: "Rows",
    UNSAVED_EDITS: "Unsaved Edits",
    SAVED: "Saved",
    UNABLE_TO_SAVE_TRY_AGAIN: "Unable to Save, Try Again",
    CANCEL: "Cancel",
    REMOVE: "Remove",
    REASSIGN: "Re-assign",
    DOWNLOAD: "Download",
    TASK_RESULT_EXPORT: "Task Result Export",
    FAILED_INSPECTION_EXPORT: "Failed Inspection Export",
    EDIT_ROLE: "Edit Role",
    EDIT_CONTACT: "Edit Contact",
    VIEW_FILES: "View Files",
    ADD_MODEL: "Add Model"
  },
  NO_DATA_FOUND: "No data found for this record.",
  CLOSE: "Close",
  CLONE: "Clone",
  ENTER_TEXT: "Enter Text",
  ENTER_NUMBER: "Enter Number",
  NO_OF_SELECTED_FILTERS: "+{count} Filters",
  CLONE_TITLE: "Are you sure you want to add a clone of",
  INACTIVE_TITLE: "Are you sure you want to inactivate",
  ONARCHIEVE_TITLE: "Are you sure you want to archive",
  CLONE_ACTIVITIES: "Clone Activities",
  REMOVE_ACTIVITY: "Remove Activity",
  NEW_VERSION: "New Version",
  NEW_VERSION_TITLE: "Are you sure to add new version of",
  ADVANCED_SEARCH: "Advanced Search",
  ADDRESS: {
    PRIMARY_ADDRESS: "Primary Address",
    ADDITIONAL_ADDRESS: "Additional Address",
    ADD_ADDITIONAL_ADDRESS: "Add Additional Address",
    LOCATION_NAME: "Location Name",
    LOCATION_NAME_PLACEHOLDER: "e.g., Sales Office, Construction Office, etc.",
    ADDRESS_NAME_PLACEHOLDER: "Enter Name",
    PRIMARY_PHONE: "Primary Phone",
    PHONE_NUMBER: "Phone Number",
    COUNTRY: "Country",
    COUNTRY_PLACEHOLDER: "Choose Country",
    STREET_ADDRESS: "Street Address",
    LATITUDE: "Latitude",
    LONGITUDE: "Longitude",
    UNIT: "Unit",
    CITY: "City",
    STATE: "State",
    STATE_PLACEHOLDER: "Choose State",
    ZIP_CODE: "Zip Code",
    COUNTY: "County",
    UNIT_PLACEHOLDER: "Unit/Apt.",
    SELECT: "Select",
    ADDRESS: "Address",
    APT_UNIT: "Apt / Unit",
    UNIT_ADDRESS: "Unit Address",
    ADD_ADDRESS: "Add Address",
    MARKET: "Market",
    SUB_MARKET: "Sub Market",
    PRIMARY_MARKET: "Primary Market",
    SECONDARY_MARKET: "Secondary Market",
    SELECT_PRIMARY_MARKET: "Select Primary Market",
    SELECT_SECONDARY_MARKET: "Select Secondary Market",
    VALIDATIONS: {
      STREET_ADDRESS: 'Please enter street address',
      COUNTRY: 'Please select country',
      CITY: 'Please enter city name',
      STATE: 'Please select state',
      ZIPCODE: 'Please enter zipcode'
    }
  },
  SHOW_LESS: "Show Less",
  VERSION: "Version",
  AUTHORITY_HAVING_JURISDICTION: "Authority Having Jurisdiction",
  LOCATION_DETAILS: "Location Details",
  LOCATION: "Location",
  CLEAR: "Clear",
  SEARCH: "Search",
  SELECT: "— Select —",
  YES: "Yes",
  NO: "No",
  ADD: "Add",
  EDIT: "Edit",
  ADD_AND_CLOSE: "Add & Close",
  ASSIGN: "Assign",
  AUTHORITY_TYPE: "Authority Type",
  SELECT_AUTHORITY_TYPE: "Select Authority Type",
  AUTHORITY_NAME: "Authority Name",
  SELECT_AUTHORITY_NAME: "Select Authority Name",
  PROJECT_UNITS_VALIDATIONS: {
    UNIT_NAME_IS_REQUIRED: "Unit name is required",
    STREET_ADDRESS_IS_REQUIRED: "Street address is required",
    CITY_IS_REQUIRED: "City is required",
    ZIP_CODE_IS_REQUIRED: "Zip code is required",
    COUNTRY_IS_REQUIRED: "Country is required",
    UNIT_TYPE_IS_REQUIRED: "Unit Type is required",
    UNIT_SITE_IS_REQUIRED: "Unit Site is required",
    STATE_IS_REQUIRED: "State is required",
    BUILDING_IS_REQUIRED: "Building is required",
    FLOOR_IS_REQUIRED: "Floor is required"
  },
  PROJECT_UNITS: {
    GENERAL: "General",
    DELETE_UNIT: "Delete Unit",
    UNIT_NAME_LABEL: "Unit Name / Lot #",
    UNIT_NAME: "Unit Name",
    UNIT_TYPE: "Unit Type",
    UNIT_SITE: "Unit Site",
    STREET_ADDRESS: "Street Address",
    UNIT: "Unit",
    UNIT_PLACEHOLDER: "Unit / Apt.",
    CITY: "City",
    STATE: "State",
    ADDITIONAL_LOCATION_DETAILS: "Additional Location Details",
    HVAC_GRADING_ACCA_310: "HVAC Grading (ACCA 310)",
    HVAC_GRADING_SYSTEM_DESCRIPTION: "Create as many systems as needed for this plan by adding details below.",
    REFRIGERANT_CHARGE_GRADE: "Refrigerant Charge Grade",
    WEIGH_IN_METHOD: "Weigh In Method",
    NON_INVASIVE_METHOD: "Non-Invasive Method",
    UNIT_CUSTOM: "Unit Custom",
    UNIT_CUSTOM_DATA: "Unit Custom Data",
    COPY_AND_PASTE_JSON_HERE: "Copy and paste json here",
    TEXT: "Text",
    VIEW_JSON_OBJECT: "View JSON Object",
    INVALID_JSON_DATA: "Invalid JSON Data. Please update JSON Data.",
    JSON_DATA_VIEW_PLACEHOLDER: "No JSON Object to be shown here.",
    INVALID_UNIT_CUSTOM_DATA: "Invalid unit custom JSON data format",
    REFRIGERANT_CHANGE_GRADE_FOR_WEIGH_IN_METHOD_DESCRIPTION: "Does the location of the geotagged photo provided in Section 8.5.2.1.2 match the location of the Forced-Air HVAC System under test?",
    MECHANICAL_VENTILATION_AIRFLOW: "Mechanical Ventilation Airflow",
    DESIGN_AIRFLOW_RATE: "Design airflow rate",
    DESIGN_RUN_TIME: "Design run time",
    CREATE_SYSTEM: "Create System",
    SYSTEM_LABEL: "System {count}",
    DELETE_SYSTEM: "Delete System",
    NO_HVAC_GRADING_ACCA310_SYSTEM: "No HVAC Grading (ACCA 310) Sytsem details to show",
    CONTRACTOR_PROVIDED_COMMISSIONING_VALUES: "Contractor-Provided Commissioning Values",
    TOTAL_WEIGHT_OF_REFRIGERANT_ADDED_TO_OR_REMOVED_FROM_THE_SYSTEM: "The total weight of refrigerant added to or removed from the system",
    REFRIGERANT_ADDED_OR_REMOVED: "Refrigerant added or removed?",
    WAS_FACTORY_CHANGE_REMOVED_FIRST: "Was factory charge removed first?",
    GEO_TAG_TIMESTAMPED: "Was time-stamped geotagged photo(s) collected, showing scale with amount of refrigerant added or removed?",
    TOTAL_LENGTH_OF_LIQUID_LINE: "The total length of the liquid line",
    OUTSIDE_DIAMETER_OF_LIQUID: "The outside diameter of the liquid line",
    LENGTH_OF_LIQUID_ACCOUNTED_FOR_FACTORY: "The length of liquid line accounted for in the factory-supplied",
    FACTORY_SUPPLIED_REFRIGERANT: "The weight of the factory-supplied refrigerant",
    WEIGHT_OF_REFRIGERANT_ADDED_FOR_COMPONENTS: "The weight of refrigerant added for specific components (other than line length)",
    RATER_PROVIDED_TOTAL_LENGTH: "Rater provided total length of the liquid line",
    RATER_PROVIDED_DIAMETER_OF_LIQUID_LINE: "Rater provided diameter of the liquid line",
    EQUIPEMENT_RATED_SEER: "Equipment Rated SEER",
    BLOCK: "Block",
    BUILDING: "Building",
    FLOOR: "Floor",
    UNIT_PLAN: "Unit Plan",
    JOB_NUMBER: "Job Number",
    ENTER_NUMBER: "Enter Number",
    ENTER_JOB_NUMBER: "Enter Job Number",
    PLAN_DETAILS: "Plan Details",
    PERMIT: "Permit #",
    PLAN: "Plan #",
    SWING: "Swing",
    PERMIT_NUMBER: "Permit Number",
    PERMIT_DATE: "Permit Date",
    NO_OF_STORIES: "Number of Stories",
    NO_OF_BEDROOMS: "Number of Bedrooms",
    NO_OF_BATHROOMS: "Number of Bathrooms",
    TOTAL_CONDITION_SPACE: "Total Condition Space (Sq Feet)",
    ENTER_SQ_FOOTAGE: "Enter Sq Footage",
    FIRST_FLOOR_AREA: "First Floor Area (Sq Feet)",
    SECOND_FLOOR_AREA: "Second Floor Area (Sq Feet)",
    THIRD_FLOOR_AREA: "Third Floor Area (Sq Feet)",
    FOURTH_FLOOR_AREA: "Fourth Floor Area (Sq Feet)",
    MFNC_COMPARTMENT_AREA: "MFNC Compartmentalization Surface Area",
    CONDITIONED_BASEMENT: "Conditioned Basement Area (Sq Feet)",
    UNCONDITIONED_BASEMENT: "Unconditioned Basement Area (Sq Feet)",
    ELEVATION: "Elevation Above Sea Level (feet)",
    PLAN_ELEVATION: "Plan Elevation",
    WALL_HEIGHT: "Wall Height (feet)",
    VOLUME: "Volume",
    SHELL_AREA: "Shell Area",
    SYSTEM_TESTING_EQUIPMENT: "System Testing Equipment",
    SELECT: "Select",
    DUCT_LEAKAGE: "Duct Leakage",
    DUCT_LEAKAGE_LABEL: "All required design documentation collected, reviewed, and verified to be in accordance with Section 4.3, or an IVR obtained.",
    DWELLING_AND_FORCED: "Dwelling and Forced-Air HVAC System Set Up",
    DWELLING_AND_FORCED_LABEL: "Enter mode that forced-air HVAC system was tested in",
    HVAC_DESIGN_REPORT: "Information from HVAC Design Report",
    QDESIGN_HEATING: "QDesign Heating (CFM)",
    QDESIGN_COOLING: "QDesign Cooling (CFM)",
    FAN_VOLUMETRIC_AIRFLOW: "Enter design cooling blower fan volumetric airflow",
    MAXIMUM_HEAT_GAIN: "Enter maximum total heat gain",
    ENTER_METERING_DEVICE: "Enter metering device",
    TARGET_SUBCOOLING: "Target Subcooling",
    PHASE: "Phase",
    PHASE_START_DATE: "Phase Start Date",
    READY_DATE: "Ready Date",
    ADD_UNIT: 'Add Unit',
    EDIT_UNIT: "Edit Unit",
    UPDATE_UNIT: "Update Unit",
    AUTO_GENERATE: "Auto-Generate",
    AUTO_GENERATE_UNITS: "Auto-Generate Units",
    AUTO_GENERATE_TEXT: "Instantly create a number of unit determined by you.",
    NO_OF_UNITS: "Number of Units",
    UNIT_PREFIX_HEADING: "Unit Prefix (ex. Apt. / Unit)",
    UNIT_PREFIX: "Unit Prefix",
    UNIT_RANGE: "Unit Range",
    USE_ADDRESS_TEXT: "Use same Address for all units",
    USE_PLAN_DETAILS_TEXT: 'Use Plan Details for all units',
    USE_UNIT_PLAN_TEXT: 'Use Unit Plan for all units',
    USE_ADD_TO_WORKFLOWS_TEXT: 'Use same workflows for all units',
    UNITS_PREVIEW: "Units Preview: {unitPrefix} {fromValue} to {unitPrefix} {toValue}",
    GENERATE_UNITS: "Generate Units",
    BUILDING_TYPE: "Building Type",
    BUILDING_ID: "Building ID",
    LOT_UNIT: "Lot/Unit #",
    CHANGE_PLANS_WARNING: "Changing site plan for this unit will remove all updates made to the unit's schedule line items, will remove the existing conditions for tasks to be marked as ready and add schedule line items from the new site plan.",
    WISH_TO_CONTINUE: "Do you wish to continue",
    ADD_TO_WORKFLOWS: "Add to Workflows",
    ADD_TO_WORKFLOWS_LIST_TITLE: "Add to Workflow (Selected: {selectedCount} of {totalCount})",
    EXTERNAL_ID: "External ID",
    SUB_UNIT: "Sub Unit Qty",
    ADDITIONAL_UNIT_DETAILS: "Additional Unit Details",
    EXTERNAL_PLAN_NAME: "External Plan Name",
    PLAN_INFO: "Plan Info",
    VALIDATIONS: {
      NO_OF_UNITS: 'Please enter no of units',
      UNIT_PREFIX: 'Please enter unit prefix',
      UNIT_MIN_RANGE: 'Please enter unit min range',
      UNIT_MAX_RANGE: 'Please enter unit max range',
      BUILDING_TYPE: 'Please select building type'
    },
    UNIT_DETAIL_EDIT_ERROR: "Unable to get unit detail for update try again later.",
    SITE_UNITS_NO_DATA_MSG: "Your sites units will appear here as you add them.",
    EDIT_CONSTRUCTION_SITE: "Edit Construction Site",
    ADD_CONSTRUCTION_SITE: "Add Construction Site",
    UNABLE_TO_GET_SITE_DETAIL_FOR_UPDATE_TRY_AGAIN_LATER: "Unable to get site detail for update try again later.",
    USE_SITE_ADDRESS_FOR_ALL: "Use Site Address for all",
    USE_SITE_ADDRESS: "Use Site Address",
    SITE_UNITS: "Site Units",
    TABLE_COLUMNS: {
      FLOOR: "FLOOR",
      BUILDING: "BUILDING",
      BLOCK: "BLOCK",
      ADDRESS: "ADDRESS",
      UNIT: "UNIT",
      UNIT_TYPE: "UNIT TYPE"
    },
    UNITS: "Units",
    ADD_UNITS_DESCRIPTION: "Add your units to your site. Auto-generate or manually add as many units as you'd like.",
    EDIT_UNITS_DESCRIPTION: "Edit your units to your site",
    CHECKLIST_RECEIVED_DATE: "Checklist Received Date",
    MULTI_FAMILY: {
      TITLE: "Multi Family",
      MULTI_FAMILY_DWELLING_UNIT_VENTILATION: "Multifamily Dwelling Unit Ventilation",
      DESIGN_AIRFLOW_RATE: "Design Airflow Rate",
      DESIGN_RUN_TIME: "Design Run Time",
      MULTI_FAMILY_COMMON_SPACE_VENTILATION: "Multifamily Common space Ventilation",
      NO_MULTI_FAMILY_COMMON_SPACE_VENTILATION_SYSTEM: "No Multifamily Common space Ventilation Sytsem details to show",
      ASHRAE_62_1_MINIMUM_EXHAUST_RATE: "ASHRAE 62.1 Minimum Exhaust Rate",
      ADD_SYSTEM: "Add System",
      MULTI_FAMILY_COMMON_SPACE_MECHANICAL_EXHAUST: "Multifamily Common Space Mechanical Exhaust",
      NO_MULTI_FAMILY_COMMON_SPACE_MECHANICAL_EXHAUST_ROOM: "No Multifamily Common Space Mechanical Exhaust Room details to show",
      ADD_ROOMS: "Add Rooms",
      ROOM_LABEL: "Room {count}",
      DELETE_ROOM: "Delete Room",
      ROOM_TYPE: "Room Type",
      DESIGN_EXHAUST_RATE: "Design Exhaust Rate"
    }

  },
  ORG_PLANS: {
    ORGANIZATION_PLAN: "Organization Plan",
    ADD_PLAN: "Add Plan",
    ADD_ATTACH_PLAN: "Add/Attach Plan",
    MANAGE_UNIT_PLAN: "Manage Unit Plan",
    EDIT_PLAN: "Edit Plan",
    PLAN_NAME: "Plan Name",
    SELECT_PLAN_FIRST: "Select Plan First",
    PLAN_DETAILS: "Plan Details",
    PLAN_NUMBER: "Plan #",
    TOTAL_CONDITION_SPACE: "Total Condition Space (Sq Feet)",
    FIRST_FLOOR_AREA: "First Floor Area (Sq Feet)",
    SECOND_FLOOR_AREA: "Second Floor Area (Sq Feet)",
    THIRD_FLOOR_AREA: "Third Floor Area (Sq Feet)",
    FOURTH_FLOOR_AREA: "Fourth Floor Area (Sq Ft)",
    VOLUME: "Volume",
    MFNC: "MFNC Compartmentalization Area",
    PLAN_ELEVATION: "Plan Elevation",
    SWING: "Swing",
    MISSING_ITEMS: "Missing Items",
    BLUEPRINT: "Blueprint",
    BLUEPRINT_NAME: "Blueprint Name",
    DOWNLOAD_ALL: "Download All",
    SELECT: "Select",
    ENTER_NUMBER: "Enter Number",
    ENTER_TEXT: "Enter Text",
    UPLOAD_FILES: "Upload Files",
    ERRORS: {
      SELECT_PLAN_NAME: "Please select plan name",
      ENTER_BLUEPRINT_NAME: "Please enter blueprint name",
      SELECT_BLUEPRINT: "Please select blueprint",
      ENTER_TOTAL_CONDITION_SPACE: "Please enter total condition space (Sq Ft)",
      ENTER_FIRST_FLOOR_AREA: "Enter first floor area (Sq Ft)",
      ENTER_SECOND_FLOOR_AREA: "Enter second floor area (Sq Ft)",
      ENTER_THIRD_FLOOR_AREA: "Enter third floor area (Sq Ft)",
      ENTER_FOURTH_FLOOR_AREA: "Enter fourth floor area (Sq Ft)",
      ENTER_VOLUME: "Enter Volume",
      UPLOAD_BLUEPRINT_FILES: "Please upload blueprint files",
      UPLOAD_ENERGY_MODEL_FILES: "Please upload energy model files",
      ENTER_ENERGY_MODEL_NAME: "Please enter energy model name"
    },
    PLAN: "Plan",
    ADD_NEW_PLAN: "Add New Plan",
    SELECT_PLAN: "Select Plan",
    SELECT_BLUEPRINT: "Select Blueprint",
    ADD_NEW_BLUEPRINT: "Add New Blueprint",
    SELECT_BLUEPRINT_FIRST: "Select Blueprint First",
    BLUEPRINT_FILES: "Blueprint Files",
    SAVE_BLUEPRINT: "Save Blueprint",
    ATTACHED_FILES: "Attached Files ({count})",
    ADD_NEW_MODEL: "Add New Model",
    SELECT_ENERGY_MODEL: "Select Energy Model",
    ENERGY_MODEL_NAME: "Energy Model Name",
    SELECT_ENERGY_MODEL_FIRST: "Select Energy Model First",
    ENERGY_MODEL: "Energy Model",
    ENERGY_MODEL_FILES: "Energy Model Files",
    SAVE_ENERGY_MODEL: "Save Energy Model",
    VIEW_BLUEPRINT: "View Blueprint",
    ADD_BLUEPRINT: "Add Blueprint",
    VIEW_ENERGY_MODEL: "View Energy Model",
    ADD_ENERGY_MODEL: "Add Energy Model"
  },
  SITE_PROJECT_PLANS: {
    PLAN: "Plan",
    ADD_PLAN: 'Add Plan',
    UPDATE_PLAN: 'Update Plan',
    UPDATE_SITE: 'Update Site',
    PLAN_NAME: 'Plan Name',
    PLAN: 'Plan #',
    VERSION: 'Version',
    STORIES: '# of Stories',
    BEDROOMS: '# of Bedrooms',
    BATHROOMS: '# of Bathrooms',
    ELEVATION: 'Elevation',
    SQUARE_FOOTAGE: 'Square Footage',
    EKOTROPE: "Ekotrope",
    SQ_FOOTAGE: 'SQ FOOTAGE',
    SITE_PLAN: 'Site Plan',
    PLAN_DESCRIPTION: 'Plan description',
    ENERGY_MODELING: "Energy Modeling",
    ENERGY_MODELING_DATA: "Energy Modeling Data",
    COPY_AND_PASTE_JSON_HERE: "Copy and paste json here",
    TEXT: "Text",
    VIEW_JSON_OBJECT: "View JSON Object",
    INVALID_JSON_DATA: "Invalid JSON Data. Please update JSON Data.",
    JSON_DATA_VIEW_PLACEHOLDER: "No JSON Object to be shown here.",
    PLAN_DETAILS: 'Plan Details',
    BUILDING_PLANS_TEXT: "Create building plans to be used throughout your site.",
    SITE_PLAN_NO_DATA_MSG: "Site Plan details will appear here as you add them.",
    SITE_LOCATION: "Site location",
    UNABLE_TO_GET_PLAN_DETAIL_FOR_UPDATE_TRY_AGAIN_LATER: "Unable to get plan detail for update try again later.",
    PLAN_DESCRIPTION_TEXT: "Add your site's plans below. You may add as many plans as you'd like.",
    EDIT_PLAN_DESCRIPTION_TEXT: "Edit your site's plans below.",
    NUMBER_OF_HVAC_SYSTEMS: "# of HVAC Systems"
  },
  SITE_PROJECT_TABLE_CONFIG: {
    PLAN: 'PLAN',
    PLAN_NUMBER: 'PLAN #',
    ELEVATION: 'ELEVATION',
    SQ_FOOTAGE: 'SQ_FOOTAGE',
    VERSION: 'VERSION',
    ACTIONS: 'ACTIONS'
  },
  SITE_PROJECT_ROLES: {
    ADD_ROLE: "Add Role",
    SITE_ROLE: "SITE ROLE",
    ADD_NEW_SITE_ROLE_DESC: "Add Site Roles to the contact. Roles could be added to multiple sites at once.",
    UPDATE_SITE_ROLE_DESC: "Edit and assign site role. Roles could be added to multiple sites at once.",
    EDIT_SITE_ROLE: "Edit Site Role",
    NO_RECORDS_TO_DISPLAY: "No site role to display.",
    LOWERCASE_SITE_ROLE: "Site Role",
    SITE: "Site(s) (Select all that apply)",
    SELECT_SITES: "Select sites",
    ORGANIZATION: "Organization",
    CONTACT: "Contact",
    UPDATE_ROLE: "Update Role",
    ADD_SITE: "Add Site",
    CONSTRUCTION_SITE_TEXT: "Construction sites are a location on which multiple units can be built.",
    ADD_SITE_ROLES: "Add Site Roles",
    ADD_SITE_ROLE: "Add Site Role",
    SITE_NAME: "Site Name",
    SITE_STATUS: "Site Status",
    SITE_DESCRIPTION: "Site Description",
    ADD_SITE_ROLES: "Add Site Roles",
    EDIT_SITE_ROLES: "Edit Site Roles",
    PRIMARY: "Primary",
    SITE_MANAGER: "Site Manager",
    EMAIL: "Email",
    PHONE: "Phone",
    ROLE: "Role"
  },
  USER_PROFILE: {
    USER: "User",
    PROFILE: "Profile",
    SETTINGS: "Settings",
    LOGOUT: "Logout",
    GENERAL: "General",
    UPLOAD: "Upload",
    RESET: "Reset",
    NOTIFICATION: "Notification",
    NOTIFICATION_SETTINGS: {
      CONTACT_NOTIFICATIONS_SAVE_SUCCESS: "Contact notification saved successfully",
      ERROR_IN_CONTACT_NOTIFICATIONS_SAVE: "Error in saving contact notification"
    },
    ERRORS: {
      FIRSTNAME: "Firstname is required",
      INVALID_FIRSTNAME: "First name is not valid",
      LASTNAME: "Lastname is required",
      INVALID_LASTNAME: "Last name is not valid",
      EMAIL: "Email is required",
      INVALID_EMAIL: "Invalid email address",
      PHONE_NUMBER: "Phone number is required",
      INVALID_PHONE: "Please enter valid phone number"
    }

  },
  USER_MANAGEMENT_PERMISSION: {
    TITLE: "User Role Permission Management",
    ROLE: "Role",
    ENTITY: "Entity",
    DEFAULT: "Default",
    YES: "Yes",
    NO: "No",
    ACTIVE: "Active",
    PERMISSION: "Permission",
    MENU: "Menu",
    FORESIGHT_WEB_APP: "Foresight Web App",
    DESCRIPTION: "Description",
    ROLE_TYPE: "role type",
    ACCOUNT_ROLE: "account role",
    VENDOR_ROLE: "vendor role"
  },
  USER_MANAGEMENT_ROLE: {
    ROLES: "Roles",
    NO_DATA_MESSAGE: "Roles will appear here as you add them.",
    ROLE: "ROLE",
    ACTIVE: "ACTIVE",
    DESCRIPTION: "DESCRIPTION",
    TYPE: 'type',
    ROLE_TYPE: 'role type',
    ERRORS: {
      ROLE: "Please enter role",
      ACTIVE: "Select active",
      DESCRIPTION: "Please enter description"
    }
  },
  USER_MANAGEMENT_CONTACTS: {
    ERRORS: {
      ROLE: "Select user role",
      ORGANIZATION: "Select organization",
      ENTITY: "Select entity"
    },
    ENTITY: "Entity",
    ENTITIES: "Entities",
    NO_DATA_MESSAGE: "Entities will appear here as you add them.",
    USER_ROLE: "User Role",
    SEARCH_ORGANIZATION: "Search Organization",
    ORGANIZATION: "Organization",
    TABLE_COLUMNS: {
      ENTITY: "Entity",
      ORGANIZATION: "Organization",
      PROFILE_TYPE: "User Type",
      USER_ROLE: "User Role"
    },
    UPDATE_ROLE: "Update Role"
  },
  DELETE_CONFIRMATION_MODAL: {
    UPPER_TEXT: "Are you sure?",
    LOWER_TEXT: "You won't be able to revert this!"
  },
  CAMPAIGN_SEARCH: {
    CAMPAIGN_NAME: "Campaign Name",
    ADD_CAMPAIGN: "Add Campaign",
    CAMPAIGN_TAG: "Campaign Tag",
    CAMPAIGN_SCOPE: "Campaign Scope"
  },
  CLONE_MODAL: {
    ACTIVITY_OPTION_HEADER: "Activity Details",
    TEMPLATE_OPTION_HEADER: "Template Details",
    NO_STAGE_OF_CONSTRUCTION: "No Stage Of Construction",
    NO_SCOPE_OF_WORK: "No Scope Of Work",
    NO_RESPONSIBLE_SITE_ROLE: "No Responsible Site Role",
    NO_RISK_LEVEL: "No Risk Level",
    NO_GOVERNANCE_REGULATION: "No Governance Regulation"
  },
  LOADER: {
    LOADING: "Loading..."
  },
  ORGANIZATION_SEARCH: {
    PLUS_ADD_ORGANIZATION: "+ Add Organization",
    SEARCH_ORGANIZATIONS: "Search Organizations",
    EDIT_ORGANIZATION: "Edit Organization",
    UPDATE_ORG: "Update Org",
    ADD_ORG: "Add Org",
    PARENT_ORGANIZATION: "Parent Organization (if applicable)",
    ERRORS: {
      ORGANIZATION_NAME: "Please enter organization name",
      ORGANIZATION_TYPE: "Select organization type",
      ORGANIZATION_CATEGORY: "Select organization category"
    }
  },
  PLANS_SEARCH: {
    SEARCH_PLANS: "Search Plans",
    PLUS_ADD_PLAN: "+ Add Plan"
  },
  POINT_OF_CONTACTS_SELECT: {
    ADD_CONTACT: '+ Add Contact',
    LABEL: 'Contact(s) (Select all that apply)',
    PLACEHOLDER: "Search Contacts"
  },
  PROJECT_SEARCH: {
    LABEL: "Assign to Project",
    PLUS_ADD_PROJECT: "+ Add Project"
  },
  PROJECT_SITE_SEARCH: {
    LABEL: "Assigned Site(s)",
    PLACEHOLDER: "All Sites",
    ADD_SITE: "Add Site"
  },
  SITE_ROLE_SEARCH: {
    LABEL: "Site Role(s)",
    PLACEHOLDER: "Select a Role",
    ADD_ROLE: "Add New Site Role"
  },
  SITE_CONTACT_SEARCH: {
    ADD_CONTACT: "Add New Contact"
  },
  PROGRAM_SEARCH: {
    LABEL: "Program"
  },
  TABLE_ROW_CELL_MODAL: {
    TEMPLATE_DESCRIPTION: "Template Description",
    NO_DESCRIPTION: 'No Description'
  },
  CAMPAIGNS: {
    ADDRESS: "Address",
    HEADER_TITLE: "Campaigns",
    CAMPAIGN_DESCRIPTION_TEXT: "Campaigns give you the power to drive focused goals on-site, off-site, or a mix of both.",
    ADD_NEW: "Add New",
    EDIT_CAMPAIGN_HEADER: "Edit - {campaignName}",
    OVERVIEW: "overview",
    CUSTOMIZE_WORKFLOW: "customize workflow",
    ADD_CAMPAIGN_FOR: "Add Campaign For",
    SELECT_TYPE: "Select Type",
    REVIEW: "Review",
    SELECT_SITE: "Select Site",
    PROGRAM_SPECIFIC: "Program Specific",
    ADD_CAMPAIGN: "Add Campaign",
    CAMPAIGN_DETAILS: "Campaign Details",
    CAMPAIGN_STATUS: "Campaign Status",
    CAMPAIGN_TYPE: "Campaign Type",
    CAMPAIGN_OBJECTIVE: 'Campaign Objective',
    CAMPAIGN_DESCRIPTION: "Campaign Description",
    SITE: "Site",
    CORRECTION_VALIDATION: "Correction Validation",
    RISK_LEVELS_TEXT: "Determine the risk levels that require validation after failed observation.",
    LEVEL_1: "Level 1",
    SELECT_VALIDATING_ROLE: 'Select Validating Role',
    SELECT_ROLE: "Select Role",
    LEVEL_2: "Level 2",
    LEVEL_3: "Level 3",
    LEVEL_4: "Level 4",
    LEVEL_5: "Level 5",
    CAMPAIGNS: "Campaigns",
    PROGRAM_NAME: "Program Name",
    ALL_CAMPAIGNS: "All Campaigns",
    SHARED_CAMPAIGN: "Shared Campaign",
    YES_SHARED_CAMAPIGN_DESC: "Yes, this is Shared Campaign",
    NO_SHARED_CAMAPIGN_DESC: "No, this is not a Shared Campaign",
    SHARED_CAMAPIGN_DESC: "Shared Campaigns can be used on multiple sites.",
    CAMPAIGN: "Campaign",
    ADDITIONAL_FEES: "Additional fees",
    OSHA_300: "Osha 300",
    PROJECT: "Project",
    STATUS: "Status",
    ADD_CHECKLIST: "Add Checklist",
    NO_DATA_MESSAGE_ACTIVITIES: "Activities will appear here as you add them.",
    SCHEDULING_DRIVER: "Scheduling Driver",
    ADD_SERVICE: "Add Service",
    ADD_COURSE: "Add Course",
    SITE_NAME: "Site",
    UNIT_NAME: "Unit",
    SERVICE_NAME: "Service",
    CHECKLIST_NAME: "Checklist",
    TASK_TYPE: "Task Type",
    TASK_STATUS: "Status",
    ASSIGNED_TO: "Assigned To",
    ADD_TO_CAMPAIGN: "Add To Campaign",
    YES: 'Yes',
    NO: 'No',
    EXPORT_ALL: 'Do you want to export all',
    ACTIVE_CAMPAIGNS: 'Active Campaigns',
    TASKS: {
      HEADER_TITLE: "Tasks"
    },
    TABLE_COLUMNS: {
      CAMPAIGN: "CAMPAIGN",
      PROJECTS: "PROJECT",
      TYPE: "TYPE",
      OBJECTIVE: "OBJECTIVE",
      PROGRAM: "Program",
      SITE: "Site",
      UNITS: "Units",
      NO_ASSOCIATED_PROGRAM: "No Associated Program",
      ACTIVE_TASKS: "Active tasks"
    },
    ERRORS: {
      CAMPAIGN_NAME: 'Please enter campaign name',
      CAMPAIGN_NAME_LENGTH: 'Campaign name should not be more than 150 characters',
      CAMPAIGN_TYPE: 'Select campaign type',
      PROJECT: 'Select project',
      SITE: 'Select site',
      CAMPAIGN_STATUS: 'Select campaign status',
      CAMPAIGN_DESCRIPTION_LENGTH: 'Description should not be more than 500 characters',
      ORGANIZATION: 'Select organization',
      ADD_CAMPAIGN_FOR: 'Select add campaign for'
    },
    CAMPAIGN_OBSERVATION_COLUMNS: {
      TASK_TYPE: "TASK TYPE"
    },
    ADVANCED: {
      CAMPAIGN_NAME: 'Campaign Name',
      PROJECT_NAME: 'Project Name',
      ROLE_NAME: 'Role Name',
      TITLE: 'Title'
    },
    CAMPAIGN_LIST: {
      LIST_TITLE: "All Campaign",
      NO_RECORDS_TO_DISPLAY: "No Campaigns to display. Create your first Campaign today and begin your journey to more profitable, safe, and compliant projects.",
      LIST_DESCRIPTION: "Inspections, training, or a mix of both focused around a particular objective."
    },
    CAMPAIGN_REPORT_LIST: {
      LIST_TITLE: "Campaign Reports",
      TABLE_COLUMNS: {
        REPORT_NAME: "Report Name",
        TYPE: "Type",
        SENT_TO: "Sent To",
        DATE_AND_TIME: "Date & Time"
      }
    },
    CAMPAIGN_ADDITIONAL_FEES: {
      ADDITIONAL_FEES: "Additional fees",
      ADD_ADDITIONAL_FEES: "Add Additional Fees",
      NO_DATA_MESSAGE: "No Data Found",
      NO_DATA_FOR_FILTERS_MESSAGE: "No Data Found for selected filter",
      TABLE_COLUMNS: {
        ADDITIONAL_FEES: "Additional fees",
        PRODUCT_ID: "Product ID",
        PRODUCT_NAME: "Product Name",
        UNIT_AMOUNT: "Unit Amount"
      }
    }
  },
  WORKFLOW: {
    QUALITY_MANAGEMENT_GOAL: "Goal - Focused on ensuring project quality and timely inspection.",
    TRAINING_GOAL: "Goal - Helping your team effectively complete desired trainings.",
    ADHOC_FINDING_GOAL: "Goal - Focused on ensuring project quality through correction of ad-hoc findings",
    ADHOC_RECORDABLES_GOAL: "Goal - Focused on ensuring project quality by logging recordables",
    SCHEDULE_RECORDABLE_GOAL: "Goal - Focused on keeping project on track by logging details of any past due line items.",
    REPORT_GOAL: "Goal - Focused on ensuring project quality through reports",
    OSHA300_GOAL: "Goal - Focused on ensuring project safety by creating an OSHA 300 log",
    ADD_WORKFLOWS: "Add Workflow(s)",
    EDIT_WORKFLOWS: "Edit Workflow(s)",
    ADD_WORKFLOW_DESCRIPTION_TEXT: "Workflows link steps together to achieve your campaign goals. Add at least one to continue.",
    WORKFLOW_TEMPLATE: "Workflow Template",
    SELECT_TEMPLATE: "Select Template",
    WORKFLOW_NAME: "Workflow Name",
    WORKFLOW_TYPE: "Workflow Type",
    CREATE_TASKS_BY: "Create Tasks By",
    SELECT_TYPE: "Select Type",
    WORKFLOW: "Workflow",
    CHECKLIST: "Checklist",
    TEMPLATE: "Template",
    ACTIONS: "Actions",
    ADD_WORKFLOW: "add workflow",
    BUILD_WORKFLOW: "Build Workflow",
    REOCCUR_EVERY: "Reoccur Every",
    WORKFLOW_ITEM: "workflow item",
    SITE: "Site",
    SELECT_SITE: "Select Site",
    SELECT_TIME_UNIT: "Select Time Unit",
    SET_CORRECTION_BY_RISK_LEVELS: "Set Correction by Risk Levels",
    SAMPLING_RATE: "Sampling Rate (%)",
    SET_CORRECTION_RISK_LEVELS: "Set Correction Risk Levels",
    SET_CORRECTION_RISK_LEVELS_TEXT: "Determine how you'd liked failed activities corrected by risk level.",
    SELECT_OPTION: "Select Option",
    REOCCURRING_WORKFLOW: "Reoccurring Workflow",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    SELECT_DATE: "Select Date",
    LEVEL: "Level",
    SELECT_REOCCURRING_WORKFLOW: "Select Reoccurring Workflow",
    SELECT_TIME_INCREMENT: "Select Time Increment",
    SELECT_TIME_UNIT: "Select Time Unit",
    SELECT_START_DATE: "Select Start Date",
    SELECT_END_DATE: "Start End Date",
    SELECT_ASSIGNED_ROLE: "Select Assigned Role",
    SELECT_ASSIGNED_CONTACT: "Select Assigned Contact",
    SELECT_TASK_TYPE: "Select Task Type",
    SELECT_SAMPLING_RATE: "Select Sampling Rate",
    TASKS: "Tasks",
    SELECT_SITE: "Select Site",
    DELETE: "Delete",
    DELETE_CONFIRMATION_MODAL_TITLE: "Are you sure to remove",
    RESPONSIBLE_TRADE: "Primary Responsible Site Role",
    SELECT_TRADE: "Select Trade",
    UPDATED_DATE: "Updated Date",
    TAG: "Tag",
    SCOPE: "Scope",
    SKILL: "Skill",
    SELECT_SCOPE: "Select Scope",
    SELECT_SKILL: "Select Skill",
    APPLY_TO_ALL_ASSEMBLIES: "Apply to All Assemblies",
    TASK_TYPE: "Task Type",
    ADD_UNITS_TO_WORKFLOW: "Add Units to Workflow",
    ADD_UNITS_TO_WORKFLOW_LIST_TITLE: "Add Units to Workflow (Selected: {selectedCount} of {totalCount})",
    SORT_ORDER: "Sort Order",
    MAKE_FINDINGS_AVAILABLE: "Make Findings Available on",
    ERRORS: {
      SATURATION_RATE: "Please enter correct 'Saturation Rate (%)'",
      SELECT_WORKFLOW_TEMPLATE: "Select Workflow Template",
      ENTER_WORKFLOW_NAME: "Enter Workflow Name",
      SELECT_LOCATION: "Select Location",
      SELECT_TASK_TYPE: "Select Task Type",
      SELECT_FINDING_AVAILABLE_ON: "Select Finding Available On",
      SELECT_SOURCE: "Please select source",
      SELECT_ASSIGNED_MANUAL_EVENT: "Please select assigned manual event",
      SELECT_SCHEDULER: "Please select scheduler",
      SELECT_SELF_ASSIGN: "Please select self assign",
      SELECT_AUTO_ASSIGN: "Please select auto assign",
      SELECT_ASSIGNED_CONTACT: "Please select assigned contact",
      SELECT_ASSIGNED_ROLES: "Please select assigned roles",
      SELECT_ROLES: "Please select roles",
      SELECT_CONTACT_GROUP: "Please select contact group",
      SELECT_ELIGIBLE_REASSIGNMENT: "Please select eligible group or role for reassignment",
      SELECT_CONFIRM_SITE_VISIT: "Please select confirm site visit",
      SELECT_CONFIRMATION_METHOD: "Please select confirmation method",
      SELECT_NOTIFICATION_METHOD: "Please select notification method",
      SELECT_CORRECT_ITEMS_BY_RISK_LEVELS: "Please select correct items by risk levels",
      SELECT_TRAINING_FAIL_ACTIVITIES: "Please select assign training for fail activities",
      SELECT_REQUIRE_TRAINING_COMPLETION: "Please select require training completion",
      SELECT_REQUIRE_TRADE_CORRECTION: "Please select require trade correction",
      SELECT_REQUIRE_SUPERINDENDENT_VERIFICATION: "Please select require superindendent verification",
      SELECT_REQUIRE_REINSPECTION: "Please select require re-inspection",
      SELECT_ESCALATION_TYPE: "Please select escalation type",
      SELECT_ESCALATION_CRITERIA: "Please select escalation criteria",
      SELECT_POINT_OF_CONTACT: "Please select point of contact",
      SELECT_RISK_LEVEL: "Please select risk Level",
      SELECT_CONTACT_METHOD: "Please select contact method",
      SELECT_AFTER_ESCALATION: "Please select after escalation",
      SELECT_CRITERIA_TYPE: "Please select criteria type",
      CHECKLIST_REQUIRED: "Checklist is required to proceed",
      ADD_TRAINING_REQUIRED: "Training is required to proceed",
      SELECT_CONTACTS_TO_NOTIFY: "Please select contacts to notify for hold production",
      SELECT_AFTER_FAILED_TRAININGS: "Please select after failed trainings",
      SELECT_VALUE_FOR_MANDATORY_PLAN_TRIGGER_COLUMNS: "Please select mandatory fields of plan triggers list.",
      PLEASE_SELECT_REQUIRED_DATA: "To save workflow customization, please enter details of all required field.",
      SELECT_CRITERIA: "Select criteria",
      SELECT_RESPONSIBLE_ROLE: "Select responsible role",
      SELECT_RESPONSIBLE_SITE_ROLE: "Select responsible site role",
      SELECT_RESPONSIBLE_CONTACT: "Select responsible contact",
      SELECT_RECORDABLE_MANAGEMENT: "Select Recordable Management",
      SELECT_CONTACT_GROUP: "Please select contact group",
      SELECT_REQUIRE_CORRECTION: "Please select require correction",
      SELECT_REQUIRE_VERIFICATION: "Please select require verification",
      SELECT_RESPONSIBLE_PARTY_FOR_CORRECTION: "Select Responsible Party for Correction",
      SELECT_RESPONSIBLE_PARTY_FOR_VERIFICATION: "Select Responsible Party for Verification",
      SELECT_SIGN_OFF_REQUIRED: "Please select sign off required",
      SELECT_RESPONSIBLE_PARTY: "Please select responsible party for sign off",
      PLEASE_SELECT_CONTACT: "Please select contact",
      PLEASE_SELECT_ROLE: "Please select role",
      PLEASE_SELECT_WORKFLOW: "Please select workflow",
      PLEASE_SELECT_TRIGGER_WHEN_WORKFLOW: "Please select trigger when workflow",
      PLEASE_SELECT_LAG_TIME: "Please select lag time",
      PLEASE_SELECT_PRECEDING_WORKFLOW: "Please select if preceding workflow is cancelled",
      PLEASE_SELECT_STATUS: "Please select status"
    },
    BLOCK3: {
      SCHEDULE_TASK_TEXT: "Choose how you want each task scheduled",
      SATURATION_OBSERVE_TEXT: "Saturation (% of units to observe)",
      SELECT_SCHEDULER: "Select Scheduler",
      CHOOSE_SCHEDULER: "Choose Scheduler",
      SELECT_SITE_ROLE: "Select Site Role",
      SELECT_ORG_ROLE: "Select Organization Role",
      ORGANIZATION_ROLE: "Organization Role",
      SELECT_CONTACT: "Select Contact",
      LIMIT_SCHEDULING_GROUPS_LABEL: "Limit Scheduling by Group",
      LIMIT_SCHEDULING_GROUPS_PLACEHOLDER: "Select a Group",
      SELF_ASSIGN_BY: "Self Assign By",
      AUTO_ASSIGN_BY: "Auto Assign By",
      SELECT_GROUPS: "Select Groups",
      ELIGIBLE_GROUP_ROLE: "Eligible group or role for reassignment"
    },
    BLOCK1: {
      CREATE_TRIGGER: "create trigger",
      SOURCE: "Source",
      CONNECT_ACCOUNT: "Connect Account",
      SELECT_EVENT: "Select Event",
      TRIGGER_EVENT: "Trigger Event",
      ASSIGNED_MANUAL_EVENT: "Assigned Manual Event",
      SELECT_SITE_ROLE: "Select Site Role",
      SELECT_SITE_CONTACT: "Select Site Contact",
      ACCOUNT_CONNECTED: "Account Connected",
      PLAN_NAME: "Plan Name",
      SCHEDULE_LINE_ITEM_USOW: "Schedule Line Item/USOW",
      TRIGGER_ON: "Trigger On",
      COMPLETE_SCHEDULE_LINE_ITEM: "Complete Schedule Line Item",
      NO_EDIT_PLAN_TRIGGERS_DATA: "No plan triggers available",
      EDIT_PLAN_TRIGGERS: "Edit Plan Triggers",
      MILESTONE: "Milestone",
      ENTER_MILESTONE: "Enter milestone",
      OFFSET: "Offset",
      ENTER_OFFSET: "Enter numeric value for offset",
      SELECT_WORKFLOW: "Select Workflow",
      TRIGGER_WHEN_WORKFLOW: "Trigger When workflow",
      LAG_TIME: "Lag Time (Days)",
      PRECEDING_WORKFLOW: "If Preceding workflow is canceled",
      STATUS: "Status (Greater than or Equal to...)",
      PRECONDITION: "Precondition",
      NOT_HAVE_PRECONDITION: "Doesn't have precondition",
      HAS_PRECONDITION: "Has pre-condition",
      ADD_ANOTHER_PRE_CONDITION: "Add another pre-condition",
      PRECONDITION_TITLE: "Pre-Condition"
    },
    BLOCK2: {
      ASSIGN_CHECKLIST: "Assign Checklist(s)",
      BLOCK2_DESCRIPTION: "Add checklists to observe in this workflow.",
      ADD_CHECKLIST: "Create Custom Checklist",
      ADD_TEMPLATE_CHECKLIST: "Use Shared Template",
      OR: "Or",
      TEMPLATE: "Template",
      ESTIMATED_TIME: "Estimated Time (minutes)",
      TABLE_COLUMNS: {
        STAGE: "STAGE",
        RELEVANT_TRADE: "relevant trade",
        PRIMARY_ROLE: "primary role",
        TEMPLATE: 'template',
        PHOTO_REQUIRED: "Photo Required"
      },
      CHECKLIST_ACTIVITY_COUNT_VALUE: "{activityCount} Activities Included",
      CHECKLIST_SETTINGS: "Checklist Settings",
      PHOTO_UPLOAD: "Mobile App Photo Upload Settings",
      SELECT_TYPE: "Select Type"
    },
    WORKFLOWS: "workflows",
    STATUS: "status",
    REVIEW_PAGE: {
      REVIEW_WORKFLOW: "review workflow",
      SITE: "site",
      BLOCKS: {
        CREATE_TRIGGER: "create trigger",
        CREATE_TRIGGER_DESCRIPTION: "The source below will trigger this workflow.",
        ASSIGN_CHECKLIST: "assign checklist",
        ASSIGN_CHECKLIST_DESCRIPTION: "Each time the trigger is activated this checklist witll be assigned.",
        SCHEDULE_TASK: "schedule task",
        SCHEDULE_TASK_DESCRIPTION: "The following contacts will schedule visit for inspection.",
        CONFIRM_SITE_VISIT: "confirm site visit",
        CONFIRM_SITE_VISIT_DESCRIPTION: "The following contact will confirm site is ready for scheduled visit.",
        FAILED_INSPECTION_TRAINING: "failed inspection training",
        FAILED_INSPECTION_TRAINING_DESCRIPTION: "How you want to train contacts on failed items from inspection.",
        FAILED_INSPECTION_CORRECTION: "failed inspection correction",
        FAILED_INSPECTION_CORRECTION_DESCRIPTION: "How you want to correct the failed items identified.",
        TASK_CLOSE_OUT: "task close out",
        TASK_CLOSE_OUT_DESCRIPTION: "When failed items that haven't been corrected will auto-close.",
        REQUIRE_TRADE_CORRECTION: "Require Trade Correction",
        REQUIRE_VERIFICATION: "Require Verification"
      }
    },
    SOURCE: "Source",
    EVENT: "event",
    ASSIGNMENT: "Assignment",
    CONTACT: "Contact",
    ROLE: "Role",
    SITE_ROLE: "Site Role",
    ORGANIZATION_ROLE: "Organization Role",
    CENTRALIZED_SCHEDULER: "Centralized scheduler",
    AUTOMATED: "Automated",
    NAME_OF_THE_SITE_ROLE: "{WFRolePersonAssignmentName}",
    REQUIRE_TRAINING_COMPLETION: "Require Training Completion",
    CORRECT_ITEMS_BY_RISK_LEVEL: "Correct Items by Risk Level",
    CLOSE_INCOMPLETE_TRAINING_AFTER: "Close Incomplete Training after",
    CLOSE_INCOMPLETE_TRADE_CORRECTION_AFTER: "Close Incomplete Trade Correction after",
    CLOSE_INCOMPLETE_SUPERINTENDENT_VERIFICATION_AFTER: "Close Incomplete Superintendent Verification after",
    VIEW_ALL_ESCALATION_CRITERIA: "View all escalation criteria",
    NO_ESCALATION_DETAILS_TO_REVIEW: "You have no escalation details to review",
    CHECKLIST: "Checklist",
    SCHEDULER: "scheduler",
    CONFIRM_VISIT: "confirm visit",
    RESPONSIBLE_PARTY: "responsible party",
    ASSIGN_TRAINING: "assign training",
    REQUIRED: "Required",
    OPTIONAL_NOT_REQUIRED: "Optional (Not Required)",
    TRADE_CORRECTION: "trade correction",
    SUPERINTENDENT_VERIFICATION: "superindendent verification",
    REQUIRE_RE_INSPECTION: "Require Re-Inspection",
    YES: "Yes",
    NO: "No",
    CLOSE_OPEN_ITEMS_AFTER: "close open items after",
    CLOSEOUT_DAYS_COUNT: "{numberOfCloseoutDays} Days",
    ENTER_WORKFLOW: "Workflow",
    SELECT_STATUS: "Status",
    SELECT_WORKFLOW_TEMPLATE: "Template",
    SELECT_SITE: "Site",
    BLOCK4: {
      CONFIRM_SITE_VISIT_HEADING: "Confirm Site Visit",
      CONFIRM_SITE_VISIT: 'Confirm Site Visit?',
      BLOCK4_DESCRIPTION: "Select who should confirm site is ready for scheduled visit.",
      CONFIRMATION_METHOD: "Confirmation Method",
      SELECT_ROLE: "Select Role",
      SELECT_SITE_ROLE: "Select Site Role",
      SELECT_CONTACT: "Select Contact",
      NOTIFICATION_METHOD: "Notification Method",
      SELECT_METHOD: "Select Method"
    },
    BLOCK6: {
      FAILED_INSPECTION_TRAINING: "failed inspection training",
      ASSIGN_TRAINING: "Assign Training to relevant trades for field tasks.",
      ASSIGN_TRAINING_FAIL: "Assign Training for fail Activities",
      REQUIRE_TRAINING_COMPLETION: "Require Training Completion",
      SELECT_METHOD: "Select Method"
    },
    STEP_TITLE: "Step {number} - {stepName}",
    BLOCK9: {
      SUB_TITLE: 'How do you want to correct items which fail inspection?',
      REQUIRE_CORRECTION: "Require Correction?",
      REQUIRE_VERIFICATION: "Require Verification?",
      RE_INSPECTION: "Require Re-Inspection",
      SCHEDULE_RE_INSPECTION: "Schedule Re-Inspection",
      CORRECT_ITEMS: "Correct items by Risk Level?",
      SELECT_OPTION: "Select Option",
      HOLD_PRODUCTION: "Hold Production on open items?",
      CONTACTS_TO_NOTIFY: "Contacts to notify for hold production",
      CORRECTION_SETTINGS: "Correction Settings",
      VERIFICATION_SETTINGS: "Verification Settings",
      RESPONSIBLE_PARTY_FOR_CORRECTION: "Responsible Party for Correction",
      RESPONSIBLE_PARTY_FOR_VERIFICATION: "Responsible Party for Verification",
      RESPONSIBLE_SITE_ROLE: "Responsible Site Role",
      RESPONSIBLE_CONTACT: "Responsible Contacts",
      RESPONSIBLE_PARTY_FOR_CORRECTION_CONTACT: "Responsible Party for Correction Contact",
      RESPONSIBLE_PARTY_FOR_CORRECTION_SITE_PARTY_ROLE: "Responsible Party for Correction Site Party Role",
      RESPONSIBLE_PARTY_FOR_VERIFICATION_CONTACT: "Responsible Party for Verification Contact",
      RESPONSIBLE_PARTY_FOR_VERIFICATION_SITE_PARTY_ROLE: "Responsible Party for Verification Site Party Role",
      FINDING_SETTINGS: "Finding Settings",
      APPLICABLE_FINDING_WORKFLOWS: "Applicable Finding Workflows"
    },
    COMPLETE: 'complete',
    BLOCK10: {
      TASK_CLOSE_OUT: "Workflow Escalation",
      BLOCK10_DESCRIPTION: "Determine escalation for items not corrected or complete.",
      ESCALATION_CRITERIA: "Escalation Criteria",
      POINT_OF_CONTACT: "Point of contact",
      SELECT_CRITERIA: "Select criteria",
      SELECT_CONTACT: "Select contact",
      CRITERIA_TYPE: "Criteria Type",
      ESCALATION_TYPE: "Escalation Type",
      SELECT_ESCALATION_TYPE: "Select escation type",
      RISK_LEVEL: "Risk Level",
      SELECT_RISK_LEVEL: "Select risk",
      CONTACT_METHOD: "Contact Method",
      SELECT_CONTACT_METHOD: "Select contact method",
      NEW_ESCALATION: "New Escalation",
      ESCALATION: "Escalation",
      ESCALATE_PERCENTAGE_UNAPPROVED_UNITS: "Escalate after (%) of Unapproved Units",
      ESCALATE_UNAPPROVED_ACTIVITIES: "Escalate after # of Unapproved Activities",
      ESCALATE_AFTER_DAYS: "Escalate after # of Days",
      ESCALATE_NUMBER_UNAPPROVED_UNITS: "Escalate after # of Unapproved Units",
      SELECT_ESCAlATE: "Select escalation",
      ESCALATE_FAILED_TRAININGS: "Escalate after # of Failed Trainings."
    },
    BLOCK11: {
      TASK_CLOSE_OUT: "Task Close Out",
      BLOCK11_DESCRIPTION: "Determine how items after correction should be closed. ",
      CLOSE_UNAPPROVED_ITEMS_IN_WORKFLOW_AFTER__DAYS_: "Close Unapproved Items in Workflow After (Days)",
      CLOSE_INCOMPLETE_TRAINING_AFTER__DAYS_: "Close Incomplete Training After",
      CLOSE_INCOMPLETE_TRADE_CORRECTION_AFTER__DAYS_: "Close Incomplete Trade Correction After:",
      CLOSE_INCOMPLETE_SUPERINTENDENT_VERIFICATION_AFTER__DAYS_: "Close Incomplete Superintendent Verification After:",
      SELECT_UNIT: "Select Unit "
    },
    BLOCK_SIGN_OFF: {
      DESCRIPTION: "Set up a sign off action for the task.",
      SIGN_OFF_REQUIRED: "Sign Off Required",
      SIGN_OFF_RESPONSIBLE_PARTY: "Responsible party for Sign Off",
      SELECT_CONTACT: "Select Contact",
      SELECT: "Select",
      ERRORS: {
        PLEASE_SELECT_SIGN_OFF_REQUIRED: "Please select sign off required"
      },
      CONTACT: "Contact",
      ROLE: "Role"
    },
    FINISH_PAGE: {
      TITLE: "Review Campaign",
      CAMPAIGN_WORKFLOW_COUNT_VALUE: "{workflowCount} Workflows",
      TEMPLATE_TYPE: "template type"
    },
    STEPS: {
      BLOCKS_SAVE_SUCCESS: "Blocks configuration saved successfully.",
      ERROR_SAVING_BLOCKS: "Error saving blocks configuration. Try again.",
      BLOCKS_UPDATE_SUCCESS: "Blocks configuration updated successfully.",
      ERROR_UPDATING_BLOCKS: "Error updating blocks configuration. Try again."
    },
    BLOCK29: {
      REQUIRE_TRADE_CORRECTION: "Require Trade Correction?",
      REQUIRE_VERIFICATION: "Require Verification?"
    },
    BLOCK33: {
      RESPONSIBLE_ROLE: "Responsible Role",
      CRITERIA: "Criteria",
      SELECT_CRITERIA: "Select Criteria",
      BLOCK_DESCRIPTION: "Adjust when and who should manage recordables",
      RECORDABLE_MANAGEMENT: "Recordable Management",
      SELECT_METHOD: "Select Method",
      RESPONSIBLE_CONTACT: "Responsible Contact",
      SELECT_CONTACT: "Select Contact(s)",
      SELECT_ROLE: "Select Role(s)"
    },
    BLOCK34: {
      REPORT_NAME: "Report Name",
      FREQUENCY: "Frequency",
      VIEW_ALL_CONTACT_ROLE: "View All Contacts/Roles",
      SEND_REPORT_TO: "Send Report To",
      CONTACTS_ROLE: "Contacts/Role",
      REPORT: "Report",
      TRIGGER_EVENT: "Trigger Event",
      REPORT_AUTOMATION: "Report Automations",
      ADD_NEW_REPORT: "Add New Report",
      FREQUENCY_CYCLE: "Frequency Cycle",
      REPORTING_FREQUENCY: "Reporting Frequency",
      DAY_OF_MONTH: "Day of Month",
      DAY_OF_WEEK: "Day of Week",
      TIME_OF_DAY: "Time of Day",
      INCLUDE_FILE_AS: "Include Files as",
      ERRORS: {
        PLEASE_SELECT_REPORT: "Please select report",
        PLEASE_SELECT_TRIGGER_EVENT: "Please select trigger event",
        PLEASE_SELECT_SEND_REPORT_TO: "Please select sendReportTo",
        PLEASE_SELECT_CONTACTS: "Please select contacts",
        PLEASE_SELECT_ROLE: "Please select role",
        PLEASE_SELECT_ORGANIZATION_ROLE: "Please select organization role",
        PLEASE_SELECT_GROUPS: "Please select groups",
        PLEASE_SELECT_FREQUENCY: "Please select frequency",
        PLEASE_SELECT_DAY_OF_MONTH: "Please select day of month",
        PLEASE_SELECT_DAY_OF_WEEK: "Please select day of week",
        PLEASE_SELECT_TIME_OF_DAY: "Please select time of day",
        PLEASE_SELECT_SEND_TYPE: "Please select send type"
      },
      NO_REPORTS_TO_REVIEW: "You have no automated reports to review.",
      SEND_TYPE: "Send Type"
    },
    BLOCK35: {
      TASK_REVIEW_TEXT: "Specify how you want your task reviewed after it's inital inspection.",
      TASK_REVIEW_REQUIRED: "Task Review Required",
      REVIEW_TRIGGER: "Review Trigger",
      ASSIGNMENT_TYPE: "Assignment Type",
      SELECT_CONTACT: "Select Contact",
      SELECT_ROLE: "Select Role",
      SELECT_SITE_ROLE: "Select Site Role",
      SELECT_GROUP: "Select Group",
      ERRORS: {
        SELECT_TASK_REVIEW_REQUIRED: "Please select if task review is required or not",
        SELECT_REVIEW_TRIGGER_REQUIRED: "Please select trigger event for task review",
        ASSIGNEMNT_TYPE_REQUIRED: "Please select assignment type",
        PLEASE_SELECT_CONTACTS: "Please select contacts",
        PLEASE_SELECT_ROLE: "Please select role",
        PLEASE_SELECT_GROUPS: "Please select groups"
      },
      OPTIONAL_ACTIVITY_VIEW_TITLE: "(Optional) Add Activities to Complete During Task Review",
      OPTIONAL_ACTIVITY_VIEW_DESCRIPTION: "Review, add, remove, and create groups for your checklist."
    },
    NO_DETAILS_TO_REVIEW: "No details available to review. You need to complete workflow customization.",
    ALL_WORKFLOWS: "All Workflows",
    INACTIVATE: "Inactivate",
    ACTIVATE: "Activate",
    ACTIVATE_MODAL_TITLE: "Are you sure you want to activate this workflow?",
    PUT_ON_ARCHIVE: "Put On Archive",
    TRAINING_TEMPLATE: {
      BLOCK_ADD_TRAINING: {
        ADD_TRAINING_DESCRIPTION: "Add a training checklist by selecting an exsiting template, individual activities, or creating your own.",
        TRAINING_NAME: "Training Name",
        RESPONSIBLE_ROLE_PRIMARY: "Responsible Role (Primary)",
        ERRORS: {
          SELECT_TEMPLATE: "Select Template",
          SELECT_RESPONSIBLE_ROLE_PRIMARY: "Select Responsible Role (Primary)"
        },
        ADD_TRAINING: "Add Training",
        UPDATE_TRAINING: "Update Training",
        ADD_NEW_TRAINING: "Add New Training",
        EDIT_TRAINING: "Edit Training",
        BUILD_TRAINING_TEXT: "Create a custom training or build from a template."
      },
      BLOCK_DUE_DATE: {
        DUE_DATE_REQUIRED: "Due Date Required",
        DUE_DATE: "Due Date",
        ERRORS: {
          DUE_DATE_REQUIRED: "Please select due date required",
          DUE_DATE: "Please select due date"
        }
      },
      BLOCK_FAILED_TRAINING: {
        FAILED_TRAINING_DESCRIPTION: "Select any contacts you'd like to notify of failed training.",
        REDO_TRAINING: "Redo Training?",
        NOTIFY_CONTACTS: "Notify Contacts?",
        SELECT_CONTACT: "Select Contact(s)",
        SELECT_CONTACT_METHOD: "Select contact method",
        ERRORS: {
          REDO_TRAINING_REQUIRED: "Please select redo training",
          NOTIFY_CONTACTS_REQUIRED: "Please select notify contacts",
          SELECT_CONTACT_REQUIRED: "Please select contact",
          SELECT_CONTACT_METHOD_REQUIRED: "Please select contact method"
        }
      }
    },
    ACTIVITY_SELECTION_ACTION: {
      SELECT_TEMPLATE: "Select Template",
      SELECT_BY_ACTIVITY: "Select by Activity",
      ADD_NEW_ACTIVITY: "Add New Activity",
      SELECT_TEMPLATE_DESCRIPTION: "Note: You must select a template to continue to the next step.",
      SELECT_BY_ACTIVITY_DESCRIPTION: "Note: Select all the activities you'd like to add to you checklist. Then click next to review your checklist.",
      SAVE_CHECKLIST_DESCRIPTION_1: "Note: Only the selected activities will be saved to your checklist.",
      SAVE_CHECKLIST_DESCRIPTION_2: "Review your checklist. Note: Only the selected activities will be saved to your checklist.",
      SAVE_CHECKLIST: "Save Checklist"
    },
    BLOCK37: {
      PARTIAL_SUBMISSION_TEXT: "Determine whether it's possible to submit the task partially before completing the entire checklist.",
      ALLOW_PARTIAL_SUBMIT: "Allow Partial Submit?"
    }
  },
  SERVICES: {
    SERVICE: "SERVICE",
    DRIVER: "DRIVER",
    INTERVAL: "INTERVAL",
    CHECKLISTS: "CHECKLISTS",
    SERVICE_CAMELCASE: "Service",
    COURSE_CAMELCASE: "Course",
    TIME_INCREMENT: "Time Increment",
    SELECT_TYPE: "Select Type",
    START_INTERVAL_ON: "Start Interval On",
    SCHEDULER_DRIVER: "Scheduler Driver",
    SELECT_DATE: "Select Date",
    SATURATION_RATE: "Saturation Rate (%)",
    CAMPAIGN_OBSERVATIONS_SERVICE_TEXT: "Group your campaign’s observations by service.",
    SERVICE_NAME: "Service Name",
    SERVICE_TYPE: "Service Type",
    COURSE_NAME: "Course Name",
    COURSE_TYPE: "Course Type",
    CREATE_TASKS_BY: "Create Tasks by",
    SELECT_TASK: "Select Task",
    COMPLETION_AFTER_READY: "Completion After Ready",
    TIMEFRAME_TO_COMPLETE: "Timeframe to Complete",
    AFTER_MARKED_READY: "After marked ready",
    SELECT_NO_OF_DAYS: "Select # of Days",
    ALLOW_CONTACTS_TO_SELF_SCHEDULE: "Allow Contacts to Self-Schedule",
    ALLOW_SELF_SCHEDULING: "Allow Self-Scheduling",
    EVENT_TYPE: "Event Type",
    WORKFLOW: "Workflow",
    ERRORS: {
      SATURATION_RATE: "Please enter correct 'Saturation Rate (%)'"
    }
  },
  CHECKLISTS: {
    ADD_FROM_TEMPLATE: "Add From Template",
    SELECT_ACTIVITY: "Select Activity(s)",
    CREATE_ACTIVITY: "Create Activity",
    UPDATE_ACTIVITY: "Update Activity",
    CHECKLIST: "Checklist",
    EDIT_CHECKLIST: "Edit Checklist",
    ADD_NEW_CHECKLIST: "Add New Checklist",
    BUILD_CHECKLIST_TEXT: "Create a custom checklist or build from a template.",
    UPDATE_CHECKLISTS: "Update Checklists",
    UPDATE_CHECKLIST: "Update Checklist",
    ADD_CHECKLISTS: "Add Checklists",
    CHECKLIST_NAME: "Checklist Name",
    STAGE_OF_CONSTRUCTION: "Stage Of Construction",
    ASSIGNED_BY: 'Assigned By',
    SELECT_ASSIGN_TYPE: 'Select Assign Type',
    ASSIGNED_ROLE: 'Assigned Role(s)',
    ASSIGNED_CONTACT: 'Assigned Contact(s)',
    NEW_CHECKLIST_CREATED: 'New Checklist Created',
    USING_ANY_OF_THE_FOLLOWING_METHODS: 'using any of the following methods',
    NOT_NOW: 'Not Now',
    NO_DATA_MESSAGE: "You have no activities to select from template.",
    DELETE_WARNING: "If you do not choose a template to save, the newly added checklist will also be deleted",
    CHECKLIST_CLOSE: "Checklist & Close",
    ERRORS: {
      CHECKLIST_NAME: "Please enter checklist name",
      STAGE_OF_CONSTRUCTION: "Please select stage of construction",
      ASSIGNMENT_TYPE: "Please select assignment type",
      ASSIGNMENT_ROLE: "Please select assigned value",
      PRIMARY_ROLE_ASSIGNMENT: "Please select primary site role."
    },
    TABLE_COLUMNS: {
      CHECKLIST: "CHECKLIST",
      TRAINING: "Training",
      STAGE: "STAGE"
    }
  },
  BUILDER_HOME: {
    HOME: "Home",
    DESC: "Run programs to quickly create campaigns across your site for your choosen solution.",
    MY_SITES: "My Sites",
    MY_INSPECTIONS: "My Inspections",
    MY_ACTIONS: "My Actions",
    MY_REQUESTS: "My Requests",
    MY_REPORTS: "My Reports",
    MY_BUILDER_PORTAL: "My Builder Portal",
    REINSPECTION_FEES: "Reinspection Fees",
    TABS: {
      TABLE_COLUMNS: {
        SITE: "Site",
        LOCATION: "Location",
        NO_OF_UNITS: "Num. of Units",
        STATUS: "Status",
        TASK: "Task",
        UNIT: "Unit",
        ADDRESS: "Address",
        PERMIT_NUMBER: "Permit #",
        PENDING: "Pending",
        ASSIGNEE: "Assignee",
        REQUESTED: "Requested",
        SCHEDULED: "Scheduled",
        COMPLETED: "Completed",
        PASSED: "Passed",
        FAILED: "Failed",
        CANCELLED: "Cancelled",
        ACTIONS: "Actions",
        VIEW: "View",
        VIEW_UNIT: "View Unit",
        N_A: "N/A",
        CAMPAIGN: "Campaign",
        FEE_NAME: "Fee Name",
        REINSPECTION_FEES: "Reinspection Fees"
      },
      MY_SITES: {
        ALL_ORGANIZATIONS: "All Organizations",
        ALL_STATES: "All States",
        ALL_CITIES: "All Cities",
        ORGANIZATION_NAME: "Organization Name",
        ORGANIZATION: "Organization",
        SITE_NAME: "Site Name",
        UNIT_NAME: "Unit Name",
        TYPE: "Type",
        STATUS: "Status",
        ADDRESS: "Address",
        POINT_OF_CONTACT: "Point of Contact",
        TOTAL_NO_OF_UNITS: "Total: {count} units",
        BUILDING_JURISDICTION: "Building Jurisdiction (AHJ)",
        PERMIT_NUMBER: "Permit #"
      },
      MY_REQUESTS: {
        RECENT: "Recent",
        TODAY: "Today",
        FUTURE: "Future",
        REPORTS: "Reports",
        FEES: "Fees",
        REQUEST_INSPECTION: "Request Inspection",
        ALL_STATUSES: "All Statuses",
        ALL_ASSIGNEES: "All Assignees",
        ALL_SITES: "All Sites",
        REQUESTED_DATE: "Requested Date",
        SCHEDULED_DATE: "Scheduled Date",
        REQUEST: "Request",
        TASK: "Task",
        SELECT_TASK: "Select Task(s)",
        SITE: "Site",
        UNIT: "Unit",
        SELECT_SITE: "Select Site(s)",
        SELECT_UNIT: "Select unit or address....",
        REQUESTED_SCHEDULED_DATE: "Requested Schedule Date",
        SELECT_DATE: "Select Date",
        ATTACH_FILES: "Attach Files",
        UPLOAD_FILES: "Upload Files",
        CONCRETE_POUR: "Concrete Pour",
        TIME_OF_POUR: "Time of Pour",
        SELECT_TIME: "Select Time",
        COMPACT_TESTING: "Compaction Testing",
        SELECT_MINE: "Select Mine",
        SELECT: "Select",
        CANCEL_REQUEST: "Cancel Request",
        UPLOAD: "Upload",
        UNIT_NAME: "Unit Name",
        TASK_NAME: "Task Name",
        STATUS: "Status",
        ASSIGNEE: "Assignee",
        START_DATE: "Start Date",
        END_DATE: "End Date",
        COMPLETED_START_DATE: "Completed Start Date",
        COMPLETED_END_DATE: "Completed End Date",
        REQUESTED_START_DATE: "Requested Start Date",
        REQUESTED_END_DATE: "Requested End Date",
        SCHEDULED_START_DATE: "Scheduled Start Date",
        SCHEDULED_END_DATE: "Scheduled End Date",
        LOCATION: "Location:",
        COPIED: "Copied!",
        COPY: "Copy",
        PHONE: "Phone:",
        EMAIL: "Email:",
        VIEW: "View"
      },
      MY_INSPECTIONS: {
        COMPLETED_DATE: "Completed Date",
        CANCEL_REQUEST: "Cancel Request",
        UPLOAD: "Upload",
        REQUEST: "Request",
        VIEW_REPORT: "View Report"
      },
      WIDGETS: {
        PENDING: "Pending",
        REQUESTED: "Requested",
        SCHEDULED: "Scheduled",
        PASSED: "Passed",
        FAILED: "Failed",
        CANCELED: "Canceled"
      }
    },
    START_DATE: "Start Date:",
    END_DATE: "End Date:",
    NOT_SELECTED: "Not selected",
    YEAR: "Year",
    MONTH: "Month",
    NO_DATA_MESSAGE: "No Data Found",
    NO_DATA_FOR_FILTERS_MESSAGE: "No Data Found for selected filter",
    REQUEST_INSPECTION_UPDATE_SUCCESS: "Request inspection updated successfully.",
    ERROR_IN_UPDATING_REQUEST_INSPECTION: "Error in updating request inspection."
  },
  PAGES: {
    WELCOME_TO_FORESITE: 'Welcome to Foresite',
    PLEASE_SELECT_YOUR_PROFILE_TYPE: 'Please select your profile type',
    USER_PROFILE_TYPE: 'User Profile Type',
    PLEASE_SELECT_YOUR_ENTITY: 'Please select your Account',
    ENTITY_TYPE: 'Account Type',
    PLEASE_SELECT_YOUR_ORGANIZATION: 'Please select your Organization',
    SELECT_ALL: 'Select All',
    APPLY_TO_ALL: 'Apply To All',
    NO_PROFILE_TYPES_FOR_SELECTED_ACCOUNT: 'No profile types for selected account',
    NO_ORGANIZATIONS_MATCHED_WITH_SEARCH: 'No organizations matched with search',
    NO_ORGANIZATIONS_FOR_SELECTED_TYPES: 'No organizations for selected types',
    SELECT_PROFILE_TYPE_AND_ENTITY_TYPE_TO_GET_ORGANIZATIONS: 'Select Profile type and Entity type to get Organizations.',
    CONTINUE: 'Continue',
    FORGOT_PASSWORD: 'Forgot Password?',
    ENTER_YOUR_EMAIL_AND_WE_WILL_SEND_YOU_INSTRUCTIONS: "Enter your email and we'll send you instructions",
    TO_RESET_YOUR_PASSWORD: 'to reset your password.',
    SEND_RESET_LINK: 'Send reset link',
    BACK_TO_LOGIN: 'Back to login',
    LOGIN: "Login",
    PLEASE_SIGN_IN_OR_CREATE_A_NEW_ACCOUNT: 'Please sign-in or create a new account!',
    JOHN_EXAMPLE_COM: 'john@example.com',
    PASSWORD: 'Password',
    NEW_ON_OUR_PLATFORM: 'New on our platform',
    CREATE_AN_ACCOUNT: 'Create an account',
    OR: 'or',
    I_AGREE_TO: 'I agree to',
    PRIVACY_POLICY_TERMS: 'privacy policy & terms',
    JOHN: 'john',
    DOE: 'doe',
    SIGN_UP: 'Sign Up',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
    SIGN_IN_INSTEAD: 'Sign in instead',
    RESET_PASSWORD: 'Reset Password?',
    YOUR_NEW_PASSWORD_MUST_BE_DIFFERENT: 'Your new password must be different',
    FROM_PREVIOUSLY_USED_PASSWORDS: 'from previously used passwords',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    SET_NEW_PASSWORD: 'Set New Password',
    CONTINUE_WITH_GOOGLE: 'Continue with Google',
    CONTINUE_WITH_LINKEDIN: 'Continue with LinkedIn',
    CONTINUE_WITH_MICROSOFT: 'Continue with Microsoft',
    PAGE_NOT_FOUND: 'Page Not Found',
    OOPS: 'Oops!',
    THE_REQUESTED_URL_WAS_NOT_FOUND_ON_THIS_SERVER: 'The requested URL was not found on this server.',
    BACK_TO_HOME: 'Back to home',
    YOU_ARE_NOT_AUTHORIZED: 'You are not authorized!',
    PLEASE_LOGIN_TO_ACCESS_FORESITE: 'Please login to access Foresite!',
    YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: "You don't have permission to access this page"
  },
  ORGANIZATION_SAGA: {
    ADDED_SUCCESS: 'Organization details added successfully.',
    ERROR_IN_ADDING: 'Error in adding organization details.',
    UPDATED_SUCCESS: 'Organization details updated successfully.',
    ERROR_IN_UPDATING: 'Error in updating organization details.',
    DELETED_SUCCESS: 'Organization deleted successfully.',
    ERROR_IN_DELETING: 'Error in deleting organization',
    ALREADY_IN_USE: 'Organization name is already in use',
    ERROR_IN_FETCHING_LIST: 'Error in fetching organization list.',
    ADDRESS_UPDATE_SUCCESS: 'Organization address updated successfully',
    ADDRESS_UPDATE_ERROR: 'Error in updating organization address',
    PLAN_ADDED_SUCCESS: 'Organization plan added successfully.',
    ERROR_IN_ADDING_PLAN: 'Error in adding organization plan.',
    PLAN_UPDATED_SUCCESS: 'Organization plan updated successfully.',
    ERROR_IN_UPDATING_PLAN: 'Error in updating organization plan.',
    UNIT_PLAN_ADDED_SUCCESS: 'Unit plan added successfully.',
    ERROR_IN_ADDING_UNIT_PLAN: 'Error in adding unit plan.',
    UNIT_PLAN_UPDATED_SUCCESS: 'Unit plan updated successfully.',
    ERROR_IN_UPDATING_UNIT_PLAN: 'Error in updating unit plan.',
    PLAN_BLUEPRINT_ADDED_SUCCESS: 'Plan blueprint added successfully.',
    ERROR_IN_ADDING_PLAN_BLUEPRINT: 'Error in adding plan blueprint.',
    PLAN_BLUEPRINT_ENERGY_MODEL_ADDED_SUCCESS: "Blueprint's energy model added successfully.",
    ERROR_IN_ADDING_PLAN_BLUEPRINT_ENERGY_MODEL: "Error in adding blueprint's energy model."
  },
  ADDRESS_SAGA: {
    ADDED_SUCCESS: "Organization's primary address details added successfully.",
    ERROR_IN_ADDING: 'Error in adding primary address details',
    UPDATED_SUCCESS: 'Address updated successfully.',
    ERROR_IN_UPDATING: 'Error in updating primary address details',
    DELETED_SUCCESS: 'Address deleted successfully.',
    ERROR_IN_DELETING: 'Error in deleting address'
  },
  AUTH_SAGA: {
    SIGNUP_SUCCESS: 'Signup successfully',
    FAILED_TO_SIGNUP: 'Failed to Signup',
    FAILED_TO_LOGIN: 'Failed to Login',
    WE_VE_JUST_SENT_YOU_AN_EMAIL_TO_RESET_YOUR_PASSWORD: "We've just sent you an email to reset your password.",
    FAILED_TO_CHANGE_PASSWORD: 'Failed to change password',
    PROFILE_UPDATED_SUCCESS: "Profile updated successfully",
    UNABLE_TO_UPDATE_USER_DATA: "Unable to update user data"
  },
  CAMPAIGN_SERVICE_SAGA: {
    UPDATED_SUCCESS: 'Campaign service updated successfully',
    ERROR_IN_UPDATING: 'Error in updating campaign service'
  },
  CAMPAIGN_SAGA: {
    ADDED_SUCCESS: "Campaign detail added successfully.",
    ERROR_IN_ADDING: "Error in adding campaign details",
    WRONG_ID: "Wrong Campaign ID",
    UPDATED_SUCCESS: "Campaign detail updated successfully",
    ERROR_IN_UPDATING: "Error in updating campaign detail",
    DELETED_SUCCESS: "Campaign  deleted successfully.",
    ERROR_IN_FETCHING_LIST: 'Error in fetching campaign list.',
    ERROR_IN_DELETING: "Error in deleting campaign",
    CLONE_SUCCESS: "Campaign clone created successfully",
    ERROR_IN_CLONING: "Error cloning a campaign",
    ACTIVITY_REQUIRED: "Activity marked as required successfully",
    ACTIVITY_NOT_REQUIRED: "Activity marked as not required successfully",
    ERROR_IN_UPDATING_REQUIRED_ACTIVITY: "Error in updating required activity",
    ACTIVITY_COPY_ALLOWED: "Activity copy allowed successfully",
    ERROR_IN_COPY_ALLOWED_ACTIVITY: "Error in enabling allow activity copy feature",
    SITES_ADDED_SUCCESS: "Sites added to shared Campaign successfully",
    ERROR_IN_ADDING_SITES: "Error in adding sites to shared Campaign",
    DELETED_SHARED_CAMPAIGN_SITE_SUCCESS: "Shared campaign site deleted successfully.",
    ERROR_IN_DELETING_SHARED_CAMPAIGN_SITE: "Error in deleting shared campaign site."
  },
  CAMPAIGN_CHECKLIST_SAGA: {
    ADDED_SUCCESS: "Checklist added successfully.",
    ERROR_IN_ADDING: "Error in adding checklist",
    UPDATED_SUCCESS: "Checklist details updated successfully",
    ERROR_IN_UPDATING: "Error in updating checklist details",
    ERROR_IN_FETCHING_CHECKLIST_DETAIL: 'Error in fetching checklist details.',
    DELETED_SUCCESS: "Checklist deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting checklist"
  },
  WORKFLOW_FINISH_SETUP_SAGA: {
    UPDATED_SUCCESS: "Workflow task completed successfully",
    ERROR_IN_UPDATING: "Error in completing workflow task",
    CLONE_SUCCESS: "Workflow clone created successfully",
    ERROR_IN_CLONING: "Error cloning a workflow"
  },
  SERVICE_SAGA: {
    ADDED_SUCCESS: "Campaign service detail added successfully",
    ERROR_IN_ADDING: "Error in adding campaign service detail",
    DELETED_SUCCESS: "Campaign service deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting campaign service",
    ERROR_IN_FETCHING_LIST: 'Error in fetching campaign service list.'
  },
  ACTIVITY_SAGA: {
    ADDED_SUCCESS: "Activity added successfully.",
    ERROR_IN_ADDING: "Error in adding activity",
    ERROR_IN_FETCHING_QUESTIONS: "Error in fetching activity response questions",
    NEW_VERSION_ADDED_SUCCESS: "Activity's new version added successfully",
    QUESTIONS_UPDATED_SUCCESS: "Observation Activity response questions updated successfully.",
    QUESTIONS_ADDED_SUCCESS: "Observation Activity response questions added successfully.",
    ERROR_ADDING_NEW_VERSION: "Error adding new version activity",
    ERROR_IN_UPDATING_QUESTIONS: "Error in updating activity response questions",
    ERROR_IN_ADDING_QUESTIONS: "Error in adding activity response questions",
    DELETED_SUCCESS: 'Activity deleted successfully.',
    ERROR_IN_DELETING: 'Error in deleting activity',
    ACTIVITY_DELETE_SUCCESS: 'Activity removed from template successfully',
    ERROR_DELETING_ACTIVITY: 'Failed to remove activity from template'
  },
  CONTACT_GROUP_SAGA: {
    ADDED_SUCCESS: "Contact group created successfully.",
    ERROR_IN_ADDING: "Error in creating contact group.",
    ADDED_CONTACT_TO_CONTACT_GROUP_SUCCESS: "Contacts added to groups successfully.",
    ERROR_IN_ADDING_CONTACT_TO_CONTACT_GROUP: "Error in adding contacts to contact groups.",
    UPDATED_SUCCESS: "Contact group updated successfully.",
    ERROR_IN_UPDATING: "Error in updating contact group.",
    DELETED_SUCCESS: "Contact group deleted successfully.",
    ERROR_IN_DELETING: 'Error in deleting contact group.',
    DELETE_CONTACTS_BY_CONTACT_GROUP_SUCCESS: "Contact removed from group successfully.",
    ERROR_IN_DELETING_CONTACTS_BY_CONTACT_GROUP: "Error in removing contact from group.",
    UPDATED_ORDER_INDEX_SUCCESS: "Contact groups reordered successfully.",
    ERROR_IN_UPDATING_ORDER_INDEX: "Error in reordering contact groups."
  },
  CONTACT_SAGA: {
    ADDED_SUCCESS: "Organization contact added successfully.",
    ERROR_IN_ADDING: "Error in adding organization contact.",
    IMAGE_UPLOADED_SUCCESS: "Image uploaded successfully.",
    ERROR_IN_UPLOADING_IMAGE: "Error in uploading image.",
    IMAGE_RESET_SUCCESS: "Image reset successfully.",
    ERROR_IN_RESETTING_IMAGE: "Error in resetting image.",
    DELETED_SUCCESS: "Organization contact deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting organization contact",
    UPDATED_SUCCESS: "Organization contact updated successfully",
    ERROR_IN_UPDATING: "Error in updating organization contact",
    ADDED_ORG_ROLE_CONTACT_SUCCESS: "Organization role contact added successfully.",
    ERROR_IN_ADDING_ORG_ROLE_CONTACT: "Error in adding organization role contact.",
    UPDATED_ORG_ROLE_CONTACT_SUCCESS: "Organization role contact updated successfully",
    ERROR_IN_UPDATING_ORG_ROLE_CONTACT: "Error in updating organization role contact",
    DELETED_ORG_ROLE_CONTACT_SUCCESS: "Organization role contact deleted successfully.",
    ERROR_IN_DELETING_ORG_ROLE_CONTACT: "Error in deleting organization role contact",
    PASSWORD_RESET_SUCCESS: "Password Reset Sent- An email has been successfully sent to the contact's email",
    PASSWORD_RESET_ERROR: "Failed to send password reset email",
    MANUAL_PASSWORD_RESET_SUCCESS: "Password Reset success",
    MANUAL_PASSWORD_RESET_ERROR: "Failed to Reset Password",
    USER_ACCOUNT_CREATE_SUCCESS: "User Account Created Successfully",
    USER_ACCOUNT_CREATE_ERROR: "Failed to create user account",
    EMAIL_UPDATE_SUCCESS: "Email address updated successfully",
    EMAIL_UPDATE_ERROR: "Failed to update email address"
  },
  PROJECT_SITE_SAGA: {
    ADDED_SUCCESS: "Site details added successfully.",
    ERROR_IN_ADDING: "Error in adding site details",
    UPDATED_SUCCESS: "Site Details updated successfully",
    ERROR_IN_UPDATING: "Error in updating site details",
    UNABLE_TO_GET_LIST_OF_CONTACTS_FOR_SELECTED_ORGANIZATION: "Unable to get list of contacts for selected organization",
    DELETED_SUCCESS: "Site deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting site.",
    LOCATION_ADD_SUCCESS: "Location Details added successfully.",
    ERROR_IN_ADDING_LOCATION: "Error in adding location details.",
    LOCATION_UPDATE_SUCCESS: "Location Details updated successfully.",
    ERROR_IN_UPDATING_LOCATION: "Error in updating location details."
  },
  PROJECT_PLAN_SAGA: {
    ADDED_SUCCESS: "Site plan details added successfully.",
    ERROR_IN_ADDING: "Error in adding site plan details",
    UPDATED_SUCCESS: "Site Plan updated successfully",
    ERROR_IN_UPDATING: "Error in updating site plan details",
    DELETED_SUCCESS: "Site Plan deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting site plan.",
    SCHEDULE_DELETED_SUCCESS: "Site plan schedule deleted successfully.",
    ERROR_IN_DELETING_SCHEDULE: "Error in deleting site plan schedule.",
    SCHEDULE_ADDED_SUCCESS: "Site plan schedule line item added successfully.",
    ERROR_IN_ADDING_SCHEDULE: "Error in adding site plan schedule line item.",
    ROWS_REORDERED_SUCCESS: "Site plan details reordered successfully.",
    ERROR_IN_REORDERING_ROWS: "Error in reordering site plan schedule line item."
  },
  PROJECT_SITE_ROLE_SAGA: {
    ADDED_SUCCESS: "Site role details added successfully.",
    ERROR_IN_ADDING: "Error in adding site role details",
    UPDATED_SUCCESS: "Site Role updated successfully",
    ERROR_IN_UPDATING: "Error in updating site role details",
    DELETED_SUCCESS: "Site Role deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting site role."
  },
  PROJECT_SITE_UNIT_SAGA: {
    ADDED_SUCCESS: "Site unit details added successfully.",
    ERROR_IN_ADDING: "Error in adding site unit details",
    UPDATED_SUCCESS: "Site Unit updated successfully",
    ERROR_IN_UPDATING: "Error in updating site unit details",
    ADDED_MULTIPLE_SUCCESS: "Site units added successfully.",
    ERROR_IN_ADDING_MULTIPLE: "Error in adding site units",
    DELETED_SUCCESS: "Site Unit deleted successfully.",
    ALREADY_IN_USE: "Unit names already exists."
  },
  PROJECT_SAGA: {
    ADDED_SUCCESS: "Project's details added successfully.",
    ERROR_IN_ADDING: "Error in adding project details",
    UPDATED_SUCCESS: "Project details updated successfully",
    ERROR_IN_UPDATING: "Error in updating project details",
    ADDRESS_ADDED_SUCCESS: "Project's primary address details added successfully.",
    ALREADY_IN_USE: "Project name is already in use",
    ERROR_IN_DELETING: "Error in deleting project",
    DELETED_SUCCESS: "Project deleted successfully.",
    ADDRESS_DELETED_SUCCESS: "Additional address deleted successfully",
    ERROR_IN_DELETING_ADDRESS: "Error in deleting additional address of project",
    ERROR_IN_FETCHING_LIST: 'Error in fetching project list.',
    HAVE_BEEN_DELETED: "Project have been deleted.",
    DUPLICATE_USER_ACCESSES: "The user role has already been added for the same organization and user type.",
    ADDRESS_UPDATE_SUCCESS: "Project address updated successfully",
    ADDRESS_UPDATE_ERROR: "Error in updating project address",
    QA_TAG_ADDED_SUCCESS: "QA tag added successfully",
    ERROR_IN_ADDING_QA_TAG: "Error in adding qa tag",
    QA_TAG_DELETED_SUCCESS: "QA tag deleted successfully",
    ERROR_IN_ADDING_QA_TAG: "Error in deleting qa tag",
    ERROR_IN_FETCHING_CONTACT_SITE_ROLE_LIST: 'Error in fetching contact site role list.',
    SITE_ROLES_ADDED_SUCCESS: "Site roles added successfully",
    ERROR_IN_ADDING_SITE_ROLES: "Error in adding site roles",
    SITE_ROLE_DELETED_SUCCESS: "Site roles deleted successfully",
    ERROR_IN_DELETING_SITE_ROLES: "Error in deleting site roles",
    SHARED_CAMPAIGNS_ADDED_SUCCESS: "Shared campaigns added to site successfully",
    ERROR_IN_ADDING_SHARED_CAMPAIGNS: "Error in adding shared campaigns to site"
  },
  SCHEDULING_SAGA: {
    TASK_UNASSIGED_SUCCESS: "Task unassiged successfully.",
    ERROR_IN_UNASSIGNING_TASK: "Error in unassigning task",
    TASK_STATUS_UPDATED_SUCCESS: "Task status updated successfully.",
    ERROR_IN_UPDATING_TASK_STATUS: "Error in updating task status",
    TASK_SCHEDULED_SUCCESS: "Task scheduled successfully.",
    ERROR_IN_SCHEDULING_TASK: "Error in scheduling task",
    TASK_REVIEWER_REASSIGN_SUCCESS: "Task rewiewer reassigned successfully.",
    ERROR_IN_REASSIGNING_TASK_REVIEWER: "Error in reassigning task reviewer.",
    ASSIGNMENT_ACTIVITIES_UPDATE_SUCCESS: 'Task updated successfully.',
    ASSIGNMENT_ACTIVITIES_UPDATE_ERROR: 'Error in updating task',
    TASK_ASSIGNMENT_DOWNLOAD_ERROR: 'Error in downloading task assignment',
    ERROR_IN_ADDING_WORK_ORDER: 'Error in adding work order details.',
    WORK_ORDER_SUCCESS: 'Work order details added successfully.',
    WORK_ORDER_UPDATED_SUCCESS: 'Work order details updated successfully.',
    ERROR_IN_UPDATING_WORK_ORDER: 'Error in updating work order details.',
    SCHEDULE_PENDING_ASSIGNMENT_SUCCESS: 'Schedule date and time added successfully.',
    SCHEDULE_PENDING_ASSIGNMENT_UPDATE_SUCCESS: 'Schedule date and time update successfully.',
    ERROR_IN_ADDING_SCHEDULE_PENDING_ASSIGNMENT: 'Error in adding Schedule date and time.',
    ERROR_IN_UPDATING_SCHEDULE_PENDING_ASSIGNMENT: 'Error in updating Schedule date and time.',
    ADDITIONAL_PURCHASE_ORDER_SUCCESS: 'Additional purchase order details added successfully.',
    ERROR_IN_ADDING_ADDITIONAL_PURCHASE_ORDER: 'Error in adding additional purchase order details.',
    ADDITIONAL_PURCHASE_ORDER_UPDATED_SUCCESS: 'Additional purchase order details updated successfully.',
    ERROR_IN_UPDATING_ADDITIONAL_PURCHASE_ORDER: 'Error in updating additional purchase order details.',
    ADDITIONAL_PURCHASE_ORDER_DELETED_SUCCESS: 'Additional purchase order details deleted successfully.',
    ERROR_IN_DELETING_ADDITIONAL_PURCHASE_ORDER: 'Error in deleting additional purchase order details.',
    PRIMARY_PURCHASE_ORDER_UPDATED_SUCCESS: 'Primary purchase order details updated successfully.',
    ERROR_IN_UPDATING_PRIMARY_PURCHASE_ORDER: 'Error in updating primary purchase order details.',
    REMOVE_ASSIGNMENT_SUCCESS: "Assignee removed successfully.",
    ERROR_IN_REMOVEING_ASSIGNMENT: "Error in removing assignee.",
    REMOVE_SCHEDULE_DATE_TIME_UPDATED_SUCCESS: "Schedule date and time removed successfully",
    ERROR_IN_REMOVING_SCHEDULE_DATE_TIME: "Error in removing scheduled date and time",
    TASK_STATUS_UPDATED_SUCCESS: "Task Status updated successfully.",
    ERROR_IN_UPDATING_TASK_STATUS: "Error in updating task status.",
    TASK_REOPEN_SUCCESS: "Task Reopened successfully",
    ERROR_IN_REOPENING_TASK: "Failed to reopen task",
    TASK_CLOSE_SUCCESS: "Task closed successfully.",
    ERROR_IN_CLOSING_TASK: "Error in closing task."
  },
  SERVICE_CHECKLIST_SAGA: {
    ADDED_SUCCESS: "Service Checklist added successfully.",
    ERROR_IN_ADDING: "Error in adding service checklist",
    INVALID_ID: "Invalid Campaign Service Checklist Id",
    DELETED_SUCCESS: "Campaign service checklist deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting campaign service checklist",
    UPDATED_SUCCESS: "Service checklist details updated successfully",
    ERROR_IN_UPDATING: "Error in updating service checklist details",
    ACTIVITIES_ADDED_SUCCESS: "Activities added to Checklist successfully.",
    ERROR_IN_ADDING_ACTIVITIES: "Error in adding activities to checklist",
    ERROR_IN_FETCHING_LIST: 'Error in fetching service checklist.'
  },
  CHECKLIST_ACTIVITIES_SAGA: {
    UPDATED_SUCCESS: "Checklist activities updated successfully.",
    ERROR_IN_UPDATING: "Error in updating checklist activites"
  },
  TEMPLATE_SAGA: {
    ERROR_IN_FETCHING_TEMPLATE_DETAIL: "Error in fetching template details",
    NO_CONTENT_FOR_SELECTED_TEMPLATE: "No data found for template",
    ADDED_SUCCESS: "Template details added successfully.",
    ERROR_IN_ADDING: "Error in adding template details.",
    UPDATED_SUCCESS: "Template details updated successfully.",
    ERROR_IN_UPDATING: "Error in updating template details.",
    DELETED_SUCCESS: "Template deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting template",
    NEW_VERSION_SUCCESS: "Template's new version added successfully",
    ERROR_ADDING_NEW_VERSION: "Error adding new version template",
    CLONE_SUCCESS: "Clone of Template added successfully",
    ERROR_IN_CLONING: "Error cloning a template",
    ERROR_IN_FETCHING_TEMPLATE_VERSION_DDL: "Error in fetching version list of template",
    ERROR_IN_FETCHING_LIST: 'Error in fetching template list.'
  },
  TEMPLATE_ACTIVITIES_GROUP_SAGA: {
    REMOVED_SUCCESS: 'Template activities group removed successfully.',
    ERROR_IN_REMOVING: 'Error in removing template activities group.',
    ACTIVITY_REMOVED_SUCCESS: "Activity removed from template activities group successfully.",
    ERROR_IN_REMOVING_ACTIVITY: "Error in removing activity from template activities group.",
    ADDING_GROUP_SUCCESS: "Template activities group added successfully.",
    ERROR_IN_ADDING_GROUP: "Error in adding template activities group.",
    UPDATE_ACTIVITY_GROUP_SUCCESS: "Template activities group updated successfully.",
    ERROR_IN_UPDATING_GROUP: "Error in updating template activities group.",
    ACTIVITY_GROUP_REORDER_SUCCESS: "Template activities group reordered successfully.",
    ERROR_IN_REORDERING_ACTIVITY_GROUP: "Error in reordering template activities group.",
    GROUP_ACTIVITIES_REORDER_SUCCESS: "Group activities reordered successfully.",
    ERROR_IN_REORDERING_GROUP_ACTIVITIES: "Error in reordering group activities.",
    ADDING_ACTIVITIES_TO_GROUP_SUCCESS: "Template activities added to group successfully.",
    ERROR_IN_ADDING_ACTIVITIES_TO_GROUP: "Error in adding template activities to group."
  },
  TEMPLATE_ACTIVITY_SAGA: {
    ACTIVITY_DETAILS_UPDATED_SUCCESS: "Activity details updated successfully",
    ERROR_IN_UPDATING_ACTIVITY_DETAILS: "Error in updating activity details",
    CLONE_OF_ACTIVITY_ADDED_SUCCESS: "Clone of Activity added successfully",
    ERROR_CLONING_A_ACTIVITY: "Error cloning a activity",
    APPLICABLE_REGULATION: {
      APPLICABLE_REGULATION_ADDED_SUCCESS: "Applicable regulation added successfully",
      APPLICABLE_REGULATION_UPDATED_SUCCESS: "Applicable regulation updated successfully",
      APPLICABLE_REGULATION_DELETED_SUCCESS: "Applicable regulation deleted successfully"
    },
    REFERENCE_STANDARD: {
      REFERENCE_STANDARD_ADDED_SUCCESS: "reference standard added successfully",
      REFERENCE_STANDARD_UPDATED_SUCCESS: "reference standard updated successfully",
      REFERENCE_STANDARD_DELETED_SUCCESS: "reference standard deleted successfully"
    },
    INDUSTRY_INTERPERTATIONS: {
      INDUSTRY_INTERPERTATIONS_ADDED_SUCCESS: "Industry interpertations added successfully",
      INDUSTRY_INTERPERTATIONS_UPDATED_SUCCESS: "Industry interpertations updated successfully",
      INDUSTRY_INTERPERTATIONS_DELETED_SUCCESS: "Industry interpertations deleted successfully"
    },
    UPDATED_SUCCESS: {
      ACTIVITY_REQUIRED: "Activity marked as required successfully",
      ACTIVITY_NOT_REQUIRED: "Activity marked as not required successfully"
    },
    ERROR_IN_UPDAING: "Error in updating required activity",
    ACTIVITY_COPY_ALLOWED: "Activity copy allowed successfully",
    ERROR_IN_COPY_ALLOWED_ACTIVITY: "Error in enabling allow activity copy feature"
  },
  TRAINING_SAGA: {
    ADDED_SUCCESS: "Training added successfully",
    ERROR_IN_ADDING: "Error in adding training",
    DELETED_SUCCESS: "Training deleted successfully",
    ERROR_IN_DELETING: "Error in deleting training",
    UPDATED_SUCCESS: "Activity Training updated successfully",
    ERROR_IN_UPDAING: "Error in updaing activity training"
  },
  TEMPLATE_TRAINING_ACTIVITY_SAGA: {
    UNABLE_TO_LOAD_OPTIONS: "Unable to load training activity options.",
    ADDED_SUCCESS: "Training Activity added successfully.",
    ERROR_IN_ADDING: "Error in adding training activity.",
    UPDATED_SUCCESS: "Training activity updated successfully.",
    ERROR_IN_UPDATING: "Error in updating training activity."
  },
  USER_MANAGEMENT_SAGA: {
    UNABLE_TO_GET_PROFILE_TYPES: "Unable to get profile types",
    UNABLE_TO_GET_ENTITY_DDL_LIST: "Unable to get entity DDL list",
    UNABLE_TO_GET_ORGANIZATION_LIST: "Unable to get organization list",
    NEW_FILTER_SAVED_SUCCESS: "New filter saved successfully.",
    FILTER_UPDATED_SUCCESS: "Filter updated successfully.",
    VIEW_DELETED_SUCCESS: "View deleted successfully.",
    FILTER_DELETED_SUCCESS: "Filter deleted successfully.",
    ERROR_IN_DELETING_FILTER: "Error in deleting filter.",
    ERROR_IN_SAVING_NEW_FILTER: "Error in saving new filter.",
    ERROR_IN_UPDATING_FILTER: "Error in updating filter."
  },
  USER_ACCESS_SAGA: {
    ADDED_SUCCESS: "User access added successfully",
    ERROR_IN_ADDING: "Error in adding user access",
    DELETED_SUCCESS: "User access deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting user access",
    UPDATE_SUCCESS: "User access updated successfully",
    ERROR_IN_UPDATING: "Error in updating user access"
  },
  USER_PERMISSIONS_SAGA: {
    UPDATED_SUCCESS: "User Permissions Updated Successfully",
    UPDATION_FAILED: "User Permissions updation failed"
  },
  USER_ROLE_SAGA: {
    ADDED_SUCCESS: "User management role added successfully",
    ERROR_IN_ADDING: "Error in adding user management role",
    UPDATED_SUCCESS: "Foresite role updated successfully.",
    ERROR_IN_UPDATING: "Error in updating foresite role",
    DELETED_SUCCESS: "Foresite role deleted successfully.",
    ERROR_IN_DELETING: "User does not have access to this action based on current role level",
    ERROR_IN_FETCHING_ROLE_LIST: "Error in fetching roles list"
  },
  TRAINING_CAMPAIGN_SAGA: {
    ADDING_SUCCESS: 'Program training campaign services added successfully.',
    UPDATE_SUCCESS: 'Program training campaign services updated successfully.',
    ERROR_IN_ADDING: 'Error in adding program training campaign services.',
    ERROR_IN_UPDATING_TRAINING_CAMPAIGN: 'Error in updating training campaign.'
  },
  VIEW_ALL_NOTIFICATIONS: "View all notifications",
  ADD_TO_LIST: "Add To List",
  SAVE_CHANGES: "Save Changes",
  SAVE_RETURN: "Save & Return",
  SAVE_CLOSE: "Save & Close",
  CANCEL: "Cancel",
  N_A: "N/A",
  SUBMIT: 'submit',
  IMPORT: "Import",
  IMPORTING: "Importing",
  EXPORT_TO_CSV: "Export to CSV",
  PREFERRED_LANGUAGE: "Preferred Language",
  TIME_ZONE: "Time Zone",
  RESET: "Reset",
  RESET_MESSAGE: "Resetting would remove any custom settings in this block and update to the latest settings from the master shared campaign. Would you like to proceed?",
  UPLOAD: "Upload",
  BACK: "Back",
  GENERAL: "General",
  ROLE_PERMISSION: "Role Permission",
  LOCATION_OF_PROJECT_TEXT: 'Location of project headquaters, construction sites are added under your project.',
  SETUP: 'Setup',
  PREVIOUS: 'Previous',
  GLOBAL_SEARCH_PLACEHOLDER: 'Search',
  SELECT_FILTER: "Select Filter",
  NEXT: "Next",
  SAVE_AND_NEXT: "Save & Next",
  SAVE_AND_FINISH: "Save & Finish",
  SAVE_AND_ADD_NEW_LIST: "Save & Add new list",
  FINISH: "Finish",
  REVIEW_AND_FINISH: "Review and Finish",
  SAVE_AS_DRAFT: "Save As Draft",
  SAVE_AND_REVIEW: "Save and Review",
  SAVE: "Save",
  SAVE_EDITS: "Save Edits",
  REMOVE: "Remove",
  TO: "to",
  UPDATE: "Update",
  PLANS: "Plans",
  SITE_PLANS: "Site plans",
  ALL_PLANS: "All Plans",
  LIST: "List",
  SINGLE: "Single",
  SITE: "Site",
  SITES: "Sites",
  POINT_OF_CONTACT: "Point of Contact",
  TRACT: "Tract",
  NO_OPTIONS: "No options",
  COLLECTION_DETAILS: "Collection Details",
  TRAINING_CONTENT: "Training Content",
  DELETE_ACTIVITY: "Delete Activity",
  ADD_CLONE: "Add Clone",
  SKIP: "Skip",
  SPANISH_VERSION: "Spanish Version",
  TYPE: "Type",
  NAME: "Name",
  TRACKING_NO: "Tracking #",
  SEE_DETAILS: "See Details",
  HIDE_DETAILS: "Hide Details",
  TAKE_PHOTO: "Take Photo",
  UNIT: 'Unit',
  TEMPLATE_LIST: 'Template List',
  SCHEDULING: {
    BLOCK: 'Block',
    SCHEDULE: "SCHEDULE",
    SCHEDULING: "Scheduling",
    PLAN_SQ_FOOTAGE: "Plan Sq. Footage",
    BLOCK: "Block",
    PLAN_LINKED_TO_UNIT: "Plan linked to unit",
    SITE_PLAN: 'Site Plan',
    ASSIGN_TASK: "Assign Task",
    REASSIGN_TASK: "reassign task",
    TASK_DETAILS: "Task Details",
    ASSIGNED_TASK: "Assigned Task",
    ASSIGNED_CONTACT: "Assigned Contact",
    SELECT_ASSIGNEE: "Select Assignee",
    SEARCH_CONTACTS: "Search Contacts",
    SELECT: "Select",
    SELECTED: "Selected",
    NAME: "Name",
    ASSIGN: "Assign",
    NO_CONTACT_MSG: "No Contact Found",
    MANAGE_ASSIGNMENT: "Manage Assignment",
    SCHEDULED_DATE: "Scheduled Date",
    START_TIME: "Start Time",
    ESTIMATED_TIME_TO_COMPLETE: "Estimated Time to Complete (Optional)",
    SUBMIT_AND_ASSIGN: "Submit & Assign",
    SCHEDULED_TO_BE_SCHEDULED: "SCHEDULED/ TO BE SCHEDULED",
    TASK_WORKFLOW_REQUIREMENTS: "Task/Workflow Requirements:",
    REQUIRED_SKILLS: "Required Skills: ",
    REQUIRED_MARKET: "Required Market: ",
    SATURATION: "SATURATION",
    SITE: "Site",
    PROJECT: "PROJECT",
    UNIT: "Unit",
    UNIT_COST: "Unit Cost",
    UNIT_NAME: "Unit Name",
    TASK_ID: "Task Id",
    SERVICE: "SERVICE",
    STATUS: "Status",
    ASSIGNEE: "ASSIGNEE",
    DUE_DATE: "Due Date",
    SATURATION: "SATURATION",
    LAST_ACTIVITY: "LAST ACTIVITY",
    YOU_HAVE_NO_TASKS_TO_SELECT: "You have no tasks to select.",
    TASK_HISTORY: "Task History",
    PENDING_UNITS: "Pending Units",
    SATURATION_RATE: "Saturation Rate",
    SATURATION: "Saturation",
    CURRENT_SATURATION_RATE: "Current Saturation Rate",
    NO_CONTACTS_FILTER_MSG: "You have no contacts matched with filters you applied.",
    NO_CONTACTS_MSG: "You have no contacts for selected site.",
    SEARCH_ABOVE_TO_GENERATE_OPTIONS: "Search above to generate options.",
    SCHEDULE_TASKS_TO_CONTACT_TITLE: "Review and Schedule",
    CONTACT_SCHEDULE: "Contact Schedule",
    START_TIME: "Start Time",
    TASK_WILL_APPEAR: "Tasks will appear here as you add them.",
    TASK_INCLUDED_TEXT: "Tasks included in this event.",
    AVAILABILITY: "Availability",
    CAMPAIGN_TYPE: "Campaign Type",
    CAMPAIGN_DETAILS: "Campaign Details",
    TASK_STATUS: "Task Status",
    PLEASE_SELECT_START_TIME: "Please select start time",
    PLEASE_SELECT_ESTIMATED_TIME: "Please select estimated time",
    PLEASE_SELECT_CONTACT: "Please select contact",
    PLEASE_SELECT_DATE: "Please select date",
    PLEASE_SELECT_SAMPLE_DATE: "Please select sample date",
    CAMPAIGN_SERVICE: "Campaign Service",
    TASK: "Task",
    UNASSIGN_TASK: "Unassign Task",
    SET_TO_READY: "Set to Ready",
    SAMPLE: "Sample",
    SITE_NAME: "Site Name",
    BUILDING_TYPE: "Building Type",
    POINT_OF_CONTACT: "Point of Contact",
    ASSIGNEE: "Assignee",
    SITE_STATUS: "Site Status",
    CAMPAIGN_STATUS: "Campaign Status",
    PROJECT_TYPE: "Project Type",
    PROJECT_STATUS: "Project Status",
    ROLE: "Role",
    ASSIGNEE_DETAILS: "Assignee Details",
    DATA_RANGE_STARTDATE: "Scheduled Date - Start",
    DATA_RANGE_FINISHDATE: "Scheduled Date - End",
    SELECT_RANGE_START: "Select Range Start",
    SELECT_RANGE_FINISH: "Select Range Finish",
    TASKS_ASSIGN_ALERT: "You cannot assign task at this time, try again later.",
    CONTAIN_MULTIPLE_WORKFLOWS: "You cannot sample tasks of multiple workflows",
    CONTAIN_ALREADY_ASSIGNED_TASK: "You cannot assign already assigned tasks. Please remove them from list to continue.",
    MULTIPLE_TASK_STATUS: "You cannot assign to multiple task status",
    INVALID_SAMPLE_SELECTION: "Selected status cannot be marked as sample",
    INVALID_READY_STATUS: "Selected status cannot be marked as ready",
    INVALID_UNASSIGN_TASK: "Selected task cannot be un-assigned",
    WORKFLOW_NAME: "Workflow Name",
    NOT_READY: "Not Ready",
    CANCELED: "Canceled",
    CANCEL_TASK: "Cancel Task",
    SET_TO_NOT_READY: "Set To Not Ready",
    UNASSIGN: "Unassign",
    READY: "Ready",
    CLICK_TO_ASSIGN: "Click to assign",
    UNAVAILABLE: "Unavailable",
    SELECTED: "Selected",
    CAMPAIGN: "Campaign",
    SAMPLE_RATE: "Sample Rate",
    TASK_REINSPECTION: "Task Reinspection",
    DUE_DATE_FILTER: "Due Date",
    TO: "To",
    FROM: "From",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    DUE_DATE_FROM: "Due Date From",
    DUE_DATE_TO: "Due Date To",
    WORKFLOW: "Workflow",
    TARGET_SAMPLE_RATE: "Target Sample Rate",
    CURRENT_SAMPLE_RATE: "Current Sample Rate",
    HEADER_TITLE: "Scheduling",
    HEADER_DESCRIPTION: "Select, assign, and schedule available tasks.",
    HEADER_DESCRIPTION_ASSIGN_TASKS: "View scheduled tasks, and filter by organization, site role and contact.",
    ASSIGN_TASK_HEADER_TITLE: "Assign Task(s)",
    COMPLETED_DATE: "completed date",
    SCHEDULED_DATE: "Scheduled Date",
    SCHEDULED_FROM: "Scheduled From",
    SCHEDULED_TO: "Scheduled To",
    READY_AND_SCHEDULE: "Ready And Schedule",
    SAMPLE_GROUP: "Sample Group",
    SAMPLE_MANUALLY: "Sample Manually",
    TAG: "Tag",
    REOPEN_TASK_WARNING_MESSAGE: "Reverting the task will not cancel any Open Actions or pending Reinspection Tasks, and will create duplicates when resubmitted. Once the task is resubmitted, you will need to manually clean up any extra actions or tasks outstanding!",
    SAMPLED_DATE: "Sampled Date",
    COMPLETED_DATE: "Completed Date",
    SELECT_TASK: {
      DETAIL_TITLE: "Select Tasks",
      DETAIL_DESCRIPTION: "Select from available tasks. Then click, “assign” to  schedule them."
    },
    ASSIGN_TASK_DETAIL: {
      DETAIL_TITLE: "Assign and Schedule",
      DETAIL_DESCRIPTION: "The tasks you selected are shown in their relevant workflows. Manage saturation per workflow by unit selecting the units you'd like to inspect or samplle. Click “Assign”, to schedule any tasks marked for inspection.",
      SELECTED_TASK_LIST_HEADER: "Selected Tasks - {count}",
      ORDER_DETAILS_LIST_HEADER: "Order Details",
      MISSING_ITEMS: "{count} Missing Items"
    },
    ADDRESS: "Address",
    CALENDAR: "Calendar",
    HEADER_CALENDAR_DESCRIPTION: "View scheduled tasks, and filter by site, unit, and contact.",
    FILTER_CALENDAR: "Filter Calendar",
    FILTER_BY_ORGANIZATION: "Filter by Organization ({count})",
    FILTER_BY_SITE: "Filter by Site ({count})",
    FILTER_BY_SITE_ROLE: "Filter by SiteRole ({count})",
    FILTER_BY_CONTACT: "Select Contacts (up to 5)",
    SEARCH_SITE: "Search Site",
    SEARCH_ROLE: "Search Role",
    SEARCH_CONTACT: "Search Contact",
    SEARCH_REGION: "Search Region",
    FILTER_BY_REGION: "Select Region",
    BUILDER: "Builder",
    BLOCK: "Block",
    ZIP_CODE: "Zip Code",
    CAMPAIGN_TYPE: "Campaign Type",
    CONSTRUCTION_MANAGER_NAME: "Construction Manager Name",
    CONSTRUCTION_MANAGER_CONTACT_PHONE: "Construction Manager Contact Phone",
    SET_SCHEDULE_DATE_TIME: "Set Schedule Date/Time",
    EDIT_SCHEDULE_DATE_TIME: "Edit Schedule Date/Time",
    DATE: "Date",
    START_TIME: "Start Time",
    NOTE_OPTIONAL: "Note (Optional)",
    NOTE_OPTIONAL_PLACEHOLDER: "Your response",
    ADD_WORK_ORDER_DETAILS: "Add Work Order Details",
    PRIMARY_WORK_ORDER: "Primary Work Order",
    PRIMARY_WORK_ORDER_PLACEHOLDER: "Enter Work Order #",
    EXTRA_WORK_ORDER: "Extra Work Order",
    EXTRA_WORK_ORDER_PLACEHOLDER: "Enter Work Order #",
    TASK_NAME: "Task Name",
    PLEASE_SELECT_PRIMARY_WORK_ORDER: "Please Select Primary Work Order",
    SECONDARY_WORK_ORDER: "Secondary Work Order",
    EDIT_WORK_ORDER_DETAILS: "Edit Work Order Details",
    PRODUCT_ID: "Product ID",
    AMOUNT: "Amount",
    PURCHASE_ORDER: "Purchase Order",
    TOTAL_AMOUNT: "Total Amount",
    WORK_ORDER: "Work Order",
    ORDER_WILL_APPEAR: "Order details will appear here as you add them.",
    SELECT_ACTION: "Select Action",
    REASSIGN: "Re-assign",
    REMOVE_HOLD: "Remove Hold",
    COST_CATEGORY: "Cost Category",
    BILLING_TYPE: "Billing Type",
    ADDITIONAL_PURCHASE_ORDER: "Additional Purchase Order",
    EPO: "EPO",
    ENTER_EPO: "Enter EPO",
    PRIMARY_PO: "Primary PO:",
    COMPLETE: "Complete",
    SKIP_AND_REMOVE_HOLD: "Skip (Not Billable) & Remove Hold",
    SUBMIT_AND_REMOVE_HOLD: "Submit & Remove Hold",
    ADD_ADDITIONAL_PURCHASE_ORDER: "Add Additional Purchase Order",
    ADD_ADDITIONAL_BILLING: "Add Additional Billing",
    ALL_ADDITIONAL_BILLINGS: "All Additional Billings",
    ADDITIONAL_PO: "Additional PO",
    PRIMARY_PURCHASE_ORDER: "Primary Purchase Order",
    MANAGE_ADDITIONAL_PURCHASE_ORDERS: "Manage Additional Purchase Orders",
    EDIT_PURCHASE_ORDER: "Edit Purchase Order",
    ADD_PURCHASE_ORDER: "Add Purchase Order",
    NO_DATA_MSG: "You have no Additional PO's for this task",
    PRIMARY_BILLING_SECTION: "Primary Billing Section",
    PRIMARY_PURCHASE_ORDER_FILES: "Primary Purchase Order Files",
    ADDITIONAL_PURCHASE_ORDER_FILES: "Additional Purchase Order Files",
    MISSING_ID: "Missing ID",
    CLICK_TO_ADD: "Click to Add",
    ADD_PRODUCT_ID_FIRST: "Add Product ID First",
    NON_BILLABLE: "Non Billable",
    ADDITIONAL_BILLING_SECTION: "Additional Billing Section",
    PURCHASE_ORDER_ERROR: {
      PLEASE_SELECT_ADDITIONAL_PO: "Please Select Additional Purchase Order",
      PLEASE_SELECT_COST_CATEGORY: "Please Select Cost Category",
      PLEASE_SELECT_BILLING_TYPE: "Please Select Billing Type"
    },
    HOLD_REASON: "Hold Reason",
    HOLD_REASON_DESCRIPTION: "One or more preconditions not met",
    SELECTED_TASK: "Selected Task",
    PRECEDING_TASK: "Preceding Task",
    CANCEL_NOT_READY: "Cancel (Not Ready)",
    RECHECK: "Recheck",
    OVERRIDE_AND_MARK_AS_READY: "Override & Mark as Ready",
    CURRENT_STATUS: "Current Status",
    STATUS_DATE: "Status Date",
    NO_COMMON_OPTIONS: "Selected tasks have no actions. Please select tasks with similar task status",
    NO_OPTIONS: "Selected task has no actions to be performed",
    SELECT_TASK_TO_PERFORM_ACTION: "Select task from list to perform some action.",
    NO_ACTION_FOR_SELECTED_TASK: "No actions for selected task.",
    SAMPLE_DATE: "Sample Date",
    STREET_ADDRESS: "Street Address",
    CITY: "City",
    STATE: "State",
    OVERVIEW_TABS: {
      ASSIGNMENT_TITLE: "Select Scheduling Details",
      CONTACT_DETAILS_TITLE: 'View Related Contacts',
      TASKS_ATTACHED: "Tasks Attached",
      NOTES_TITLE: "Notes",
      PURCHASE_ORDERS_LIST_HEADER: "Purchase Orders / Additional Fees",
      ADD_NOTE: "Add Note",
      WRITE_NOTE_HERE: "Write your note here....",
      CONTACT_DETAILS: {
        SELECT_TASK: 'Select Task',
        SELECT_UNIT: 'Select Unit',
        PLEASE_SELECT_TASKS: "Select task to view its contact details"
      },
      CREATE_NEW_SCHEDULED_EVENT: "Create New Scheduled Event",
      UPDATE_SCHEDULE_DATE_TIME: "Update schedule date & time",
      NO_TASK_ASSIGNMENT_TEXT: "There are no tasks eligible for assignment. Tasks are either already in progress or completed. Please review the status under tasks tab.",
      REMOVE_SCHEDULE_DATE: "Remove Schedule Date",
      REMOVE_ASSIGNEE: "Remove Assignee"
    },
    BULK_SAMPLED_TASK_UPDATE_CONFIRMATION_TITLE: "Selected tasks contains sampled tasks as well. So do you want to update sampled tasks?",
    SAMPLED_TASK_UPDATE_CONFIRMATION_TITLE: "Are you sure to update sampled task?",
    REQUIRED_SKILLS: "Required Skills:",
    MARKET: "Market:",
    ATTENTION: "Attention:",
    ATTENTION_NOTE: "Tasks within this Assignment have different required skills. The list above represents ALL skills required to be assigned this group of tasks.",
    SKILLS_CERTS: "Skills and Certs:",
    SKILLS: "Skills",
    SCOPE: "Scope",
    NONE: "None",
    PRIMARY_MARKET: "Primary Market",
    SECONDARY_MARKET: "Secondary Market",
    NOT_IN_MARKET: "Not in Market"
  },
  GLOBAL_SEARCH: {
    UNITS: "Units",
    SERVICES: "Services",
    CHECKLISTS: "Checklists",
    SITE_ROLES: "Site Roles",
    TASKS: "Tasks",
    DIRECTORY: "Directory",
    FILES: "Files",
    DATA_ACCESS: "Data Access",
    STATUS: "Status"
  },
  NO_DATA_MESSAGES: {
    ORGANIZATIONS: {
      NO_DATA: `Your organizations will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no organizations matched with filters you applied.`
    },
    CONTACTS: {
      NO_DATA: `Your contacts will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no contacts matched with filters you applied.`
    },
    PROJECTS: {
      NO_DATA: `Your projects will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no projects matched with filters you applied.`
    },
    SITES: {
      NO_DATA: `Your sites will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no sites matched with filters you applied.`
    },
    SITE_PLANS: {
      NO_DATA: `Your site plans will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no site plans matched with filters you applied.`
    },
    SITE_ROLES: {
      NO_DATA: `Your site roles will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no site roles matched with filters you applied.`
    },
    SITE_UNITS: {
      NO_DATA: `Your site units will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no site units matched with filters you applied.`
    },
    TEMPLATES: {
      NO_DATA: `Your templates will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no templates matched with filters you applied.`
    },
    ACTIVITIES: {
      NO_DATA: `Your activities will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no activities matched with filters you applied.`
    },
    CAMPAIGNS: {
      NO_DATA: `Your campaigns will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no campaigns matched with filters you applied.`
    },
    CAMPAIGN_SERVICES: {
      NO_DATA: `Your campaign services will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no campaign services matched with filters you applied.`
    },
    CAMPAIGN_SERVICE_CHECKLISTS: {
      NO_DATA: `Your campaign service checklists will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no campaign service checklists matched with filters you applied.`
    },
    SCHEDULING_SITE_TASKS: {
      NO_DATA: `Your scheduling site tasks will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no scheduling site tasks matched with filters you applied.`
    },
    PROGRAMS: {
      NO_DATA: `Your programs will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no programs matched with filters you applied.`
    },
    ASSEMBLY: {
      NO_DATA: `Your assembly will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no assembly matched with filters you applied.`
    },
    RESOURCES: {
      NO_DATA: `Your resources will appear here as you add them.`,
      NO_DATA_FOR_FILTERS: `You have no resources matched with filters you applied.`
    },
    CAMPAIGN_OBSERVATION_TASKS: {
      NO_DATA: "Your campaign observation tasks will appear here as you add them.",
      NO_DATA_FOR_FILTERS: "You have no campaign observation tasks matched with filters you applied."
    },
    MANAGE_TASKS: {
      NO_DATA: "No data found for this id"
    },
    TASK_DATA_COLLECTION: {
      NO_DATA: "You have no tasks to collect data.",
      NO_DATA_FOR_FILTERS: "You have no tasks matched with filters you applied.",
      NO_SCHEDULED_TASKS: "No scheduled tasks."
    },
    RELATED_TASK_DATA_COLLECTION: {
      NO_DATA: "No related tasks found.",
      NO_DATA_FOR_FILTERS: "You have no related tasks matched with filters you applied."
    },
    TASK_ACTIONS: {
      NO_DATA: "You have no task/activity actions.",
      NO_DATA_FOR_FILTERS: "You have no task/activity actions matched with filters you applied."
    },
    SITE_INSPECTION_REPORT: {
      NO_DATA: "Your Inspection report will appear as you add them.",
      NO_DATA_FOR_FILTERS: "You have no inspections reports matched with filters you applied."
    },
    PROGRAM_REPORT_LIST: {
      NO_DATA: "Report list will appear here as you add them.",
      NO_DATA_FOR_FILTERS: "You have no activity reports matched with filters you applied."
    },
    OVERVIEW_TASKS_SECTION: {
      NO_DATA: 'No tasks to display.',
      NO_DATA_FOR_FILTERS: 'You have no tasks matched with filters you applied.'
    },
    OVERVIEW_UNITS_SECTION: {
      NO_DATA: 'No units to display.',
      NO_DATA_FOR_FILTERS: 'You have no units matched with filters you applied.'
    },
    OVERVIEW_WORKFLOW_SECTION: {
      NO_DATA: 'No workflows to display.',
      NO_DATA_FOR_FILTERS: 'You have no workflows matched with filters you applied.'
    },
    OVERVIEW_DIRECTORY_SECTION: {
      NO_DATA: 'No directories to display.',
      NO_DATA_FOR_FILTERS: 'You have no directories matched with filters you applied.'
    },
    DATA_ACCESS: {
      NO_DATA: `You have no reports to view.`,
      NO_DATA_FOR_FILTERS: `You have no reports matched with filters you applied.`
    },
    SITES_SECTION: {
      NO_DATA: 'No sites to display.',
      NO_DATA_FOR_FILTERS: 'You have no sites matched with filters you applied.'
    },
    CAMPAIGN_SECTION: {
      NO_DATA: 'No campaigns to display.',
      NO_DATA_FOR_FILTERS: 'You have no campaigns matched with filters you applied.'
    },
    SATURATION_SUMMARY: {
      NO_DATA: 'No sampling summary to display',
      NO_DATA_FOR_FILTERS: 'You have no sampling summary matched with the filters applied.'
    },
    SATURATION_DETAILS: {
      NO_DATA: 'No sampling details to display',
      NO_DATA_FOR_FILTERS: 'You have no sampling details matched with the filters applied.'
    },
    PROGRAM_BUILDER_CHECKLIST: {
      NO_DATA: 'No checklist items to display'
    },
    SAMPLE_GROUP_LIST: {
      NO_DATA: "Add Groups to start sampling or inspection on units.",
      NO_DATA_FOR_FILTERS: "You have no sample groups matched with the filters applied."
    },
    SAMPLE_ALL_UNITS: {
      NO_DATA: "No workflow units to display",
      NO_DATA_FOR_FILTERS: "You have no workflow units matched with the filters applied."
    },
    SITE_UNITS: {
      NO_DATA: "No site unit schedules to display",
      NO_DATA_FOR_FILTERS: "You have no site unit schedules matched with the filters applied."
    },
    ACTIVITY_TEMPLATES: {
      NO_DATA: "Activity not linked with any templates."
    },
    CAMPAIGN_REPORT: {
      NO_DATA: "No campaign reports to display",
      NO_DATA_FOR_FILTERS: 'You have no campaign reports matched with filters you applied.'
    },
    COMMUNICATION_LOG: {
      NO_DATA: "No communication log are found",
      NO_DATA_FOR_FILTERS: 'You have no communication log matched with filters you applied.'
    },
    CHILD_ORGANIZATIONS: {
      NO_DATA: "No Child Organization to display",
      NO_DATA_FOR_FILTERS: `You have no organizations matched with filters you applied.`
    },
    OVERVIEW_PLAN_SECTION: {
      NO_DATA: "No plans to display.",
      NO_DATA_FOR_FILTERS: "You have no plans matched with filters you applied.",
      NO_BLUEPRINT_DATA: "No blueprints to display."
    }
  },
  PROGRAMS: {
    HEADER_TITLE: "Programs",
    DETAIL_TITLE: "Add Program Details",
    DETAIL_DESCRIPTION: "Name and select the type of program you want to create. Then choose where you want to run this program",
    ADD_PROGRAM: 'Add Program',
    PROGRAM_DETAILS: "Program Details",
    PROGRAM_NAME: "Program Name",
    PROGRAM_TYPE: "Program Type",
    PROGRAM_STATUS: "Program Status",
    SITE_NAME: "Site Name",
    SITE_ADDRESS: "Site Address",
    ORGANIZATION: "Organization",
    SELECT_LOCATION: "Select Location",
    ASSIGN_SUPERVISORS: "Assign Supervisors",
    SAFETY_MANAGER: "Safety Manager",
    SUPERINTENDENT: "Superintendent",
    PROJECT: "Project",
    SITE: "Site",
    SITES: "Sites",
    SELECT_SITE: "Select Site",
    SELECT_SAFETY_MANAGER: "Select Safety Manager",
    SELECT_SUPERINTENDENT: "Select Superintendent",
    PROGRAM: "Program",
    TABLE_COLUMNS: {
      PROGRAM: "program",
      TYPE: "type",
      PROJECT: "project",
      SITES: "sites",
      STATUS: "status",
      LOCATION: "Location",
      CREATED_ON: "Created on"
    },
    ERRORS: {
      PROGRAM_NAME: "Please enter program name",
      PROGRAM_STATUS: "Please select program status",
      PROGRAM_TYPE: "Please select program type",
      PROJECT: "Please select project",
      ORGANIZATION: "Please select organization",
      SITE: "Please select site",
      OSHA_SAFETY_REQUIREMENT: "Please select requirements",
      TRAINING_START_DATE: "Please select start date",
      SITE_ROLE: "Please select role",
      TRAINING_AFTER_READY: "Please enter no. of days",
      DOCUMENTATION_CAMPOAIGN: "Please select osha documentation campaigns",
      EMOJI_ERROR: "Please remove emoji"
    },
    ENERGY_STAR: {
      SELECT_SITES_AND_ROLES: {
        HEADER_TITLE: "Select Roles",
        HEADER_DESCRIPTION: "Select and begin building a solution for your project.",
        ROLES_DESCRIPTION: "The following roles are missing from the site. To continue with creation of Energy Star Program assign contacts to following site roles.",
        NO_MISSING_ROLES: "You have no missing roles."
      },
      SELECT_ITEMS: "Select upto {items} items",
      TABLE_COLUMNS: {
        CHECKLIST_ITEMS: "Checklist Items",
        INSPECTION_TYPE: "Inspection Type"
      },
      SUMMARY: {
        HEADER_TITLE: "Program Summary",
        PARTNERSHIPS_DESC: "Partnership Status Verified"
      },
      SUCCESS: {
        HEADER_TITLE: 'Congratulations! Your ENERGY STAR Program has been created successfully!',
        HEADER_DESCRIPTION: 'Your site is one step closer to become more energy efficent and more profitable. View your program to see your ENERY STAR solution overview.'
      }
    },
    PROGRAM_NAME: 'Program Name',
    PROGRAM_TYPE: 'Program Type',
    PROGRAM_STATUS: 'Program Status',
    OSHA_OBSERVATION_CAMPAIGN: "OSHA - Observation Campaign",
    OSHA_TRAINING_CAMPAIGN: "OSHA - Training Campaign",
    OSHA_REVIEW_TEXT: "Review and modify the associated services for your program's observation campaign.",
    OSHA_REVIEW_TRAINING__TEXT: "Review and modify the associated lessons for this program's training campaign.",
    SELECT_START_DATE: "Select Start Date",
    COMPLETION_AFTER_READY: "Completion After Ready",
    NUMBER_OF_DAYS: "Number of Days",
    OSHA_CONSTRUCTION_NOTE: "Note: Construction is estimated to start on",
    ASSIGN_TO_SITE_ROLES: "Assign to Site Roles",
    SELECT_ROLES: "Select Roles",
    SELECT_ROLE: "Select Role",
    OSHA_DOCUMENTATION_CAPAIGN: "OSHA - Documentation Campaign",
    OSHA_DOCUMENTATION_CAPAIGN_DESCRIPTION: "A OSHA 300 Log has been created with all your select tasks, as well as a Disciplinary Log.",
    OSHA_DOCUMENTATION_CAPAIGN_NOTE: "Note: These files will be automatically saved in your Foresite Resource Center.",
    SELECT_FREQUENCY: "Select Frequency",
    PROGRAM_SPECIFIC: {
      DETAIL_TITLE: "OSHA - Written Programs",
      DETAIL_DESCRIPTION: "Below are your generated written programs, download these to keep for your records.",
      DETAIL_NOTE: "Note: These files will be automatically saved in your Foresite Document Library",
      TABLE_COLUMNS: {
        SAFETY_REQUIREMENT: "Safety Requirement",
        FILE_TYPE: "File Type",
        UPLOAD_FILE: "Uploaded File",
        UPLOAD_DATE: "Upload Date"
      },
      USE_TEMPLATE: "Use Template",
      UPLOAD: 'Upload',
      DOWNLOAD: 'Download',
      WRITTEN_DOCUMENT_LIST_TITLE: "Showing {count} Written Programs",
      OTHER_DOCUMENT_LIST_TITLE: "Showing {count} Other Required Documents",
      NO_OSHA_DOCUMENTS: 'No osha documents found'
    },
    OSHA_SAFETY_REQUIREMENT: {
      DETAIL_TITLE: 'OSHA - Safety Requirements',
      DETAIL_DESCRIPTION: 'Select all the requirements which apply to your program',
      TABLE_COLUMNS: {
        REQUIREMENTS: 'requirements',
        ACTIVE: 'active',
        INACTIVE: 'inactive'
      },
      NO_OSHA_REQUIREMENTS_FOUND: "No osha safety requirements found."
    },
    TRAINING_CAMPAIGN: {
      TABLE_COLUMNS: {
        COURSE: "COURSE",
        LESSONS: "LESSONS",
        ACTIVE: 'active',
        INACTIVE: 'inactive'
      },
      NO_TRAINING_LIST_FOUND: "No training campaign services list found."
    },
    QUALITY_CONTROL_PROGRAM: {
      CORRECTION: "Correction",
      INSPECTION: "Inspection",
      CLOSE_OUT: "Close Out",
      FINISH: "Finish",
      SELECT_OBSERVATION: {
        ASSEMBLY: "Assembly",
        ASSEMBLY_TYPE: "Assembly Type",
        DETAIL_TITLE: 'Select Observation',
        DETAIL_DESCRIPTION: "Choose the assemblies you'd like observed at each stage of construction",
        STAGE_OF_CONSTRUCTION: "Stage of Construction",
        SELECT_ASSEMBLIES: "Select Assemblies",
        RESPONSIBLE_TRADE_ROLE: "Responsible Trade Role",
        ENTER_RATE: "Enter Rate %",
        NO_TASKS_DATA: "No observations to select",
        OBSERVATION_TEMPLATE: "Observation Template",
        PHOTO_REQUIRED: "Photo Required",
        MAIN_COMPONENT: "Main Component"
      },
      SATURATION_RATE_AND_INSPECTION: {
        DETAIL_TITLE: "Sampling Rate and Inspection",
        DETAIL_DESCRIPTION: "Select what % of your site should be inspected for each assembly. Then define the responsible parties for that inspection.",
        STAGE: "Stage",
        NO_TASKS_DATA: "No activities to review",
        SATURATION_RATE: "Saturation Rate (%)",
        ASSEMBLY: "Assembly",
        ASSEMBLY_TYPE: "Assembly Type",
        INSPECTION_ORG_TYPE: "Inspection Org Type",
        ORGANIZATION: "Organization",
        CONTACTS_TO_NOTIFY: "Contacts to Notify",
        SAMPLE_RATE: "Sample Rate (%)"
      },
      REINSPECTION: {
        DETAIL_TITLE: "Reinspection",
        DETAIL_DESCRIPTION: "Choose if you'd like reinspection for failed items for each assembly's risk levels.",
        ASSEMBLY: "Assembly",
        RISK_LEVEL: "Risk Level",
        REQUIRES_REINSPECTION: "Requires Reinspection",
        HOLD_PRODUCTION: "Hold Production on Open Items?",
        CONTACTS_TO_NOTIFY: "Contacts to Notify",
        NO_ASSEMBLY_DATA: "No assemblies available for re-inspection",
        ASSEMBLY_TYPE: "Assembly Type"
      },
      ESCALATION: {
        DETAIL_TITLE: "Escalation",
        DETAIL_DESCRIPTION: "Create escalation criteria and a contact method that suits your needs.",
        DETAIL_DESCRIPTION: "Create escalation criteria and a contact method that suits your needs.",
        ESCALATION_LIST: {
          TITLE: "Add New Escalation"
        }
      },
      TASK_CLOSE_OUT: {
        DETAIL_TITLE: "Task Close Out",
        DETAIL_DESCRIPTION: "Select how you'd like each type of tasks close out."
      },
      ERRORS: {
        ESCALATIONS_REQUIRED: "Please add atleast one escalation.",
        REINSPECTION_LIST_REQUIRED_CONTACT_TO_NOTIFY: "Contact to notify selection is required, if you mark hold production on open items as Yes.",
        SELECT_OBSERVATION_REQUIRED_PROGRAM_ASSEMBLY: "Please select atleast one program assembly",
        SELECT_OBSERVATION_TEMPLATE: "Please select observation template",
        ASSEMBLY_INSPECTION_REQUIRED_ORG_TYPE: "Please select inspection org type.",
        ASSEMBLY_INSPECTION_REQUIRED_ORG: "Please select organization.",
        ASSEMBLY_INSPECTION_REQUIRED_CONTACTS: "Please select contacts to notify.",
        ASSEMBLY_INSPECTION_REQUIRED_ORG_AND_ORG_TYPE: "Please select inspection organization type and organization.",
        ASSEMBLY_INSPECTION_REQUIRED_SATURATION_RATE: "Please select saturation rate.",
        ASSEMBLY_INSPECTION_REQUIRED_ORG_TYPE_AND_ROLE: "Please select inspection organization type and Responsible Role.",
        TASK_CLOSE_OUT_REQUIRED_DETAILS: "Please Select all task close out details",
        CONFIRM_SITE_VISIT_DETAILS_REQUIRED: "Please add confirm site visit details for all assemblies.",
        SATURATION_RATE: "Please enter correct 'Saturation Rate (%)'",
        SELECT_OBSERVATION_RESPONSIBLE_TRADE_ROLE: "Please select responsible trade role",
        SELECT_OBSERVATION_ASSEMBLY_MAIN_COMPONENT: "Please select main component"
      },
      CONFIRM_SITE_VISIT: {
        DETAIL_TITLE: "Confirm Site Visit",
        DETAIL_DESCRIPTION: "Determine who should confirm when your site is ready for inspection, for each assembly.",
        ASSEMBLY: "Assembly",
        ASSEMBLY_TYPE: "Assembly Type",
        NO_ASSEMBLY_DATA: "No assemblies available for site visit confirmation"
      },
      REVIEW_AND_FINISH: {
        DETAIL_TITLE: "Review and Finish",
        DETAIL_DESCRIPTION: "Well done! Review your program and tap finish to complete Program setup.",
        PROGRAM: "Program",
        PROJECT: "Project",
        PROGRAM_TYPE: "Program Type",
        STATUS: "Status",
        SITE: "Site",
        PROGRAM_NAME_ASSEMBLIES: "{programName} Assemblies",
        PROGRAM_ASSEMBLIES: "Program Assemblies",
        TABLE_COLUMNS: {
          ASSEMBLY: "Assembly",
          STAGE_OF_CONSTRUCTION: "Stage of Construction",
          SATURATION_RATE: "Sample Rate",
          INSPECTION_ORGANIZATION: "Inspection Organization",
          NUMBER_OF_ACTIVITIES: "# of Activities",
          ACTIVITY_COUNT_LABEL: "{count} Activities",
          ASSEMBLY_TYPE: "Assembly Type",
          RISK_LEVEL: "Risk Level",
          TEMPLATE: 'template',
          PHOTO_REQUIRED: "Photo required"
        }
      },
      REVIEW_CHECKLISTS: {
        DETAIL_TITLE: "Review Checklists",
        DETAIL_DESCRIPTION: "Review the inspection checklist created from your assembly and component selections",
        ASSEMBLY: "Assembly",
        ASSEMBLY_TYPE: "Assembly Type",
        NO_CHECKLISTS_DATA: "No checklists to review"
      }
    },
    PROGRAM_LIST: {
      LIST_TITLE: "All Programs",
      NO_RECORDS_TO_DISPLAY: "No programs to display. Create your first program to get overviews and reports.",
      LIST_DESCRIPTION: "Run programs to quickly create campaigns across your site for your chosen solution."
    },
    PROGRAM_CAMPAIGNS: {
      NO_RECORDS_TO_DISPLAY: "No Campaigns to display.",
      TABLE_COLUMNS: {
        CAMPAIGN: "Campaign",
        TYPE: "type",
        STATUS: "status",
        START_DATE: "Start Date",
        WORKFLOWS: "workflows",
        WORFLOW_COUNT_TEXT: "{workflowCount} Workflows"
      }
    },
    DATA_ACCESS: {
      NO_REPORTS: "You have no reports to view."
    },
    ENERGY_STAR_PROGRAM: {
      SELECT: "Select",
      CONTACT_NAME: "Contact Name",
      SELECTED: "Selected",
      NEED_HELP: "Need Help?",
      ESCALATION_CRITERIA: {
        ESCALATION_CONTACT: "Escalation Contact",
        SELECT_CONTACT: "Select Contact"
      },
      FILE_ATTACHMENTS_COUNT_SINGULER_TEXT: "{count} Attachment",
      FILE_ATTACHMENTS_COUNT_PLURAL_TEXT: "{count} Attachments",
      HEADER_TAB_LIST: {
        PARTNERSHIPS: "Partnerships",
        SPECIFICATIONS: "Specifications",
        INSPECTIONS: "Inspections",
        SCHEDULING: "Scheduling",
        REPORTING: "Reporting",
        SUMMARY: "summary"
      },
      ERRORS: {
        MISSING_ROLES_NOT_FETCHED: "Unable to fetch missing roles. Please refresh page and try again.",
        SELECT_ENERGY_CONFIG: "Please select one option to proceed.",
        SELECT_CHECKLIST: "Please select checklist to proceed.",
        SELECT_CONTACTS: "Please select atleast one contact to proceed.",
        UPLOAD_IMAGE: "Please upload a file"
      },
      ENERGY_STAR_SAGA: {
        PARTNERSHIP_STATUS: {
          ADDING_SUCCESS: "Qualifications and Partnership Status added successfully.",
          ERROR_IN_ADDING: "Error in adding Qualifications and Partnership Status.",
          UPDATED_SUCCESS: "Qualifications and Partnership Status updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Qualifications and Partnership Status."
        },
        GRADING: {
          ADDING_SUCCESS: "HVAC Design and Performance Grading configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding HVAC Design and Performance Grading configuration.",
          UPDATED_SUCCESS: "HVAC Design and Performance Grading configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating HVAC Design and Performance Grading configuration."
        },
        EQUIPEMENT: {
          ADDING_SUCCESS: "HVAC Heating Equipment configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding HVAC Heating Equipment configuration.",
          UPDATED_SUCCESS: "HVAC Heating Equipment configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating HVAC Heating Equipment configuration."
        },
        APPLIANCES: {
          ADDING_SUCCESS: "Fuel Fired Appliances configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Fuel Fired Appliances configuration.",
          UPDATED_SUCCESS: "Fuel Fired Appliances configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Fuel Fired Appliances configuration."
        },
        BALANCING: {
          ADDING_SUCCESS: "HVAC Supply and Return Airflow Balancing configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding HVAC Supply and Return Airflow Balancing configuration.",
          UPDATED_SUCCESS: "HVAC Supply and Return Airflow Balancing configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating HVAC Supply and Return Airflow Balancing configuration."
        },
        TESTING: {
          ADDING_SUCCESS: "HVAC Duct Leakage Testing configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding HVAC Duct Leakage Testing configuration.",
          UPDATED_SUCCESS: "HVAC Duct Leakage Testing configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating HVAC Duct Leakage Testing configuration."
        },
        APPLICATIONS: {
          ADDING_SUCCESS: "Insulation Applications configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Insulation Applications configuration.",
          UPDATED_SUCCESS: "Insulation Applications configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Insulation Applications configuration."
        },
        BRIDGING: {
          ADDING_SUCCESS: "Thermal Bridging configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Thermal Bridging configuration.",
          UPDATED_SUCCESS: "Thermal Bridging configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Thermal Bridging configuration."
        },
        SYSTEM_GUIDELINES: {
          ADDING_SUCCESS: "Water Management System Guidelines configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Water Management System Guidelines configuration.",
          UPDATED_SUCCESS: "Water Management System Guidelines configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Water Management System Guidelines configuration."
        },
        VERIFICATION_OPTION: {
          ADDING_SUCCESS: "Builder Verification Option configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Builder Verification Option configuration.",
          UPDATED_SUCCESS: "Builder Verification Option configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Builder Verification Option configuration."
        },
        SAURATION: {
          ADDING_SUCCESS: "Saturation configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Saturation configuration.",
          UPDATED_SUCCESS: "Saturation configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Saturation configuration."
        },
        DOCUMENTATION: {
          ADDING_SUCCESS: "Documentation retention configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Documentation retention configuration.",
          UPDATED_SUCCESS: "Documentation retention configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Documentation retention configuration."
        },
        VERIFICATION_OPTION_ACTIVITIES: {
          ADDING_SUCCESS: "Builder Verification Option checklist configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Builder Verification Option checklist configuration.",
          UPDATED_SUCCESS: "Builder Verification Option checklist configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Builder Verification Option checklist configuration."
        },
        ESCALATION: {
          ADDING_SUCCESS: "Escalation Criteria configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Escalation Criteria configuration.",
          UPDATED_SUCCESS: "Escalation Criteria configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Escalation Criteria configuration."
        },
        REPORTING_AND_CERTIFICATES: {
          ADDING_SUCCESS: "Reporting and Certificates configuration added successfully.",
          ERROR_IN_ADDING: "Error in adding Reporting and Certificates configuration.",
          UPDATED_SUCCESS: "Reporting and Certificates configuration updated successfully.",
          ERROR_IN_UPDAING: "Error in updating Reporting and Certificates configuration."
        }
      }
    },
    VIEW_PROGRAM: "View Program",
    EDIT_PROGRAM_HEADER: "Edit - {programName}",
    ADD_NEW: "Add New"
  },
  SETUP_TAB_COUNT_TEXT: "{count} Steps total:",
  OVERVIEW: "overview",
  RESOURCES: {
    HEADER_TITLE: "Resources",
    RESOURCE_CENTER: "Resource Center",
    RESOURCE: "Resource",
    TABLE: {
      DOWNLOAD: "Download",
      REPLACE: "Replace",
      EDIT: "Edit",
      REMOVE: "Remove",
      UPDATE: "Update",
      VIEW: "View"
    },
    TABLE_COLUMNS: {
      FILE: "File",
      FILE_LOCATION: "File Location",
      LOCATION_TYPE: "Location Type",
      UPLOADED_BY: "Uploaded By",
      UPLOADED_ON: "Uploaded On",
      FILE_CATEGORY: "File Category"
    },
    ADVANCED_SEARCH: {
      TITLE: "Browse Resources",
      FILE: "File",
      TYPE: "Type",
      UPLOADED_BY: "Uploaded By",
      UPLOADED_DATE_FROM: "Uploaded Date From",
      UPLOADED_DATE_TO: "Uploaded Date To"
    },
    TITLE: 'Title',
    FILES: 'Files',
    CATEGORY: 'Category',
    SELECT_FILE_CATEGORY: 'Select file category',
    IN_PROGRESS: "In Progress...",
    FILE: 'File',
    EDIT_DOCUMENT_DETAILS: "Edit document details",
    NOTES: "Notes",
    RESOURCE_CENTER_UPLOADER_DESCRIPTION: "Drag 'n' drop some files here, or click to select files",
    RESOURCE_CENTER_UPLOADER_SUB_DESCRIPTION: "Only files with size less than 100MB will be accepted",
    FILE_TITLE_REQUIRED: "File title is required",
    FILE_CATEGORY_REQUIRED: "File category is required",
    FILE_SIZE_INVALID: "Some files are rejected, as only files with size less than 100MB will be accepted",
    FILE_INVALID_FORMAT: "Some files are rejected due to invalid file formats choosen",
    NO_RESOURCES_TO_DISPLAY: "No resources to display",
    PROGRAM_FILES: "Program Files",
    CAMPAIGN_FILES: "Campaign Files",
    ORGANIZATION_FILES: "Organization Files",
    SITE_FILES: "Site Files",
    UNIT_FILES: "Unit Files",
    DOCUMENTS_ONLY: "Documents Only",
    VIEW_FILE_ERROR: "Unable to open file. Please try again.",
    SELECT_FILES_TO_PERFORM_ACTION: "Select files from list to perform some action.",
    SEND_VIA_EMAIL: "Send Via Email",
    DOWNLOAD: "Download"
  },
  PROGRAM_SAGA: {
    ADDED_SUCCESS: "Program added successfully.",
    UPDATE_SUCCESS: "Program updated successfully.",
    ERROR_IN_ADDING: "Error in adding program",
    ERROR_IN_UPDATING: "Error in updating program",
    DELETED_SUCCESS: "Program deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting program",
    ERROR_IN_GETTING_SAFETY_REQUIREMENTS: "Unable to load safety requirements.",
    ERROR_IN_ADDING_SAFETY_REQUIREMENTS: 'Error in adding program safety requirements.',
    ERROR_IN_UPDATING_SAFETY_REQUIREMENTS: 'Error in updating program safety requirements.',
    SAFETY_REQUIREMENTS_ADDED_SUCCESS: 'Osha safety requirements added successfully.',
    SAFETY_REQUIREMENTS_UPDATE_SUCCESS: 'Osha safety requirements updated successfully.',
    OBSERVATION_CAMPAIGN_ADDED_SUCCESS: 'Observation campaign added successfully.',
    OBSERVATION_CAMPAIGN_UPDATE_SUCCESS: 'Observation campaign updated successfully.',
    ERROR_IN_ADDING_OBSERVATION_CAMPAIGN: 'Error in adding observation campaign details.',
    ERROR_IN_UPDATING_OBSERVATION_CAMPAIGN: 'Error in updating observation campaign details.',
    PROGRAM_ASSEMBLY_LIST_UPDATED_SUCCESS: "Program Assembly list updated successfully",
    ERROR_IN_UPDATING_PROGRAM_ASSEMBLY_LIST: "Error in updating program Assembly list.",
    PROGRAM_ESCALATION_ADDED_SUCCESS: "Program escalation added successfully.",
    PROGRAM_ESCALATION_UPDATED_SUCCESS: "Program escalation updated successfully.",
    ERROR_IN_ADDING_PROGRAM_ESCALATION: "Error in adding program escalation.",
    PROGRAM_TASK_CLOSE_OUT_ADDED_SUCCESS: "Program Task Close Out added successfully",
    PROGRAM_TASK_CLOSE_OUT_UPDATED_SUCCESS: "Program Task Close Out updated successfully",
    ERROR_IN_UPDATING_TASK_CLOSE_OUT: "Error in updating Program Task Close Out",
    ERROR_IN_ADDIND_PROGRAM_TASK_CLOSE_OUT: "Error in adding Program Task Close Out",
    PROGRAM_ASSEMBLY_REINSPECTION_LIST_UPDATE_SUCCESS: "Assembly resinspection configuration updated successfully.",
    ERROR_IN_UPDATING_PROGRAM_ESCALATION: "Error in updating program escalation.",
    ERROR_IN_UPDATING_PROGRAM_ASSEMBLY_REINSPECTION_LIST: "Error in updating Assembly resinspection configuration.",
    ERROR_IN_UPDATING_PROGRAM_ASSEMBLY_INSPECTION_LIST: "Error in updating Assembly inspection configuration.",
    PROGRAM_ASSEMBLY_INSPECTION_LIST_UPDATE_SUCCESS: "Assembly inspection configuration updated successfully.",
    PROGRAM_CONFIRM_SITE_VISIT_ADDED_SUCCESS: "Program confirm site visit added successfully",
    ERROR_IN_ADDING_PROGRAM_CONFIRM_SITE_VISIT: "Error in adding Program confirm site visit",
    PROGRAM_CONFIRM_SITE_VISIT_UPDATED_SUCCESS: "Program confirm site visit updated successfully",
    ERROR_IN_UPDATING_PROGRAM_CONFIRM_SITE_VISIT: "Error in updating Program confirm site visit",
    UPDATED_SUCCESS_IN_FINISH_PROGRAM_WIZARD: "Program wizard completed successfully",
    ERROR_IN_UPDATING_FINISH_PROGRAM_WIZARD: "Error in completing program wizard",
    CLONE_SUCCESS: "Program clone created successfully",
    ERROR_IN_CLONING: "Error cloning a program"
  },
  DOCUMENTATION_CAMPAIGN_SAGA: {
    ADDING_SUCCESS: 'Program documentation campaign services added successfully.',
    ERROR_IN_ADDING: 'Error in adding program documentation campaign services.',
    UPDATED_SUCCESS: "Program documentation campaign services updated successfully",
    ERROR_IN_UPDAING: "Error in updaing program documentation campaign services"
  },
  CAMPAIGN_WORKFLOW_SAGA: {
    DELETED_SUCCESS: "Campaign workflow deleted successfully.",
    ERROR_IN_GETTING_CAMPAIGN_WORKFLOW_DETAILS: "Unable to fetch campaign workflow details.",
    STATUS_UPDATED_SUCCESS: "Campaign workflow status updated successfully",
    ERROR_IN_UPDATING_STATUS: "Error in updating campaign workflow status",
    RESET_BLOCK_SUCCESS: "Reset child campaign block successfully",
    ERROR_IN_RESET_BLOCK: "Error in resetting child campaign block"
  },
  CAMPAIGN_WORKFLOW_LIST_SAGA: {
    ERROR_IN_GETTING_CAMPAIGN_WORKFLOW_LIST: "Unable to fetch campaign workflow list."
  },
  DATA_RETENTION_SAGAS: {
    ADDED_SUCCESS: "Custom data retention policy added successfully.",
    UPDATE_SUCCESS: "Custom data retention policy updated successfully.",
    ERROR_IN_ADDING: "Error in adding custom data retention policy",
    ERROR_IN_UPDATING: "Error in updating custom data retention policy",
    DELETED_SUCCESS: "Custom data retention policy deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting custom data retention policy"
  },
  REPORTS: {
    REPORTS: "Reports",
    DETAILS: "Details",
    ACTIVITY: "Activity",
    ACTIVITY_STATUS: "Activity Status",
    ASSIGNEE: "Assignee",
    UNIT: "Unit",
    TASK: "Task",
    RESPONSIBLE_TRADE: "Responsible Trade",
    RESPONSIBLE_PARTY: "Responsible Party",
    RISK_LEVEL: "Risk Level",
    DAYS_OPEN: "Days Open",
    SORT_BY_SCHEDULE_DATE: "Sort By Schedule Date:",
    LESSON_NAME: "Lesson Name",
    STATUS: "Status",
    TRADE: "Trade",
    DUE_DATE: "Due Date",
    DAYS_OVERDUE: "Days Overdue",
    COMPLETION_DATE: "Completion Date",
    RESULT: "result",
    TRADE_COMPANY: "Trade Company",
    SCORE: "Score",
    FIRST_INSPECTION_DATE: "First Inspection Date",
    LAST_INSPECTION_DATE: "Last Inspection Date",
    VIEW_REPORT: "View Report",
    ACTION_ASSIGNED_TO: "Action Assigned To",
    UNANSWERED_ACTIVITIES: "Unanswered Activities",
    ANSWERED_ACTIVITIES: "Answered Activities",
    APPROVED_ACTIVITIES: "Approved Activities",
    UNAPPROVED_ACTIVITIES: "Unapproved Activities",
    CONCEALED_ACTIVITIES: "Concealed Activities",
    NA_ACTIVITIES: "Na Activities",
    OPEN_ACTIONS: "Open Actions",
    CLOSED_ACTIONS: "Closed Actions",
    CORRECTED: "Corrected",
    VERIFIED: "Verified",
    REINSPECTED: "ReInspected",
    PHOTO_COUNT: "Photo Count",
    OPEN_LE_5: "0-5 Days Open",
    OPEN6_10: "6-10 Days Open",
    OPEN11_15: "11-15 Days Open",
    OPEN16_30: "16-30 Days Open",
    OPENG30: "30+ Days Open",
    WINDWARD_REPORT_LOADING_MESSAGE: "Please wait while the document is being prepared.",
    DOCUMENT_READY_FOR_DOWNLOAD: "Document is Ready for download.",
    CLICK_TO_VIEW_REPORT: "Click To View Report",
    CLICK_TO_SEND_EMAIL: "Click to Send Email",
    FAILED_TO_GENERATE_REPORT: "Failed to Generate the report",
    EXPORT_ALERT_MESSAGE: "Export {fileName} is being prepared. Click to view the Report",
    EXPORT_EMAIL_SUCCESS: "Report Email sent successfully",
    EXPORT_EMAIL_FAILED: "Failed to send report email.",
    REPORT_EMAIL_SENT_MESSAGE: "Report will be sent to your email once generated.",
    CAMPAIGN: {
      SITE: "Site",
      UNIT: "Unit",
      CAMPAIGN: "Campaign",
      ASSIGNEE: "Assignee",
      RESPONSIBLE_TRADE: "Responsible Trade",
      ACTIVITY_STATUS: "Activity Status",
      RISK_LEVEL: "Risk Level",
      RESET: "Reset",
      TO: "to",
      FROM: "from",
      FILTER_BY_INSPECTION_COMPLETEION_DATE: "Filter by Inspection Completion Date:",
      UNACCEPTABLE_ACTIVITIES: "Unacceptable Activities",
      INSPECTION_COMPLETION_DATE: "Inspection Completion Date",
      TRADE_FIX_DATE: "Trade Fix Date",
      SUPERINTENDENT_VERIFICATION_DATE: "Superintendent Verification Date",
      CURRENT_ACTION_ITEM: "Current Action Item",
      CURRENT_ACTION_ITEM_DUE_DATE: "Current Action Item Due Date",
      CURRENT_ACTION_ITEM_ASSIGNED_TO: "Current Action Item Assigned To",
      LAST_ACTION_ITEM: "Last Action Item",
      LAST_ACTION_ITEM_NOTE: "Last Action Item Note",
      REINSPECTION_REQUIRED: "ReInspection Required?",
      HOLD_PRODUCTION: "Hold Production?",
      PHOTOS: "Photos",
      NO_DATA_MSG: "Unacceptable Activities Reports will appear as you add them.",
      LESSON_NAME: "Lesson Name",
      CHAPTER: "Chapter",
      COURSE: "Course",
      SUBJECT: "Subject",
      TRADE: "Trade",
      OVERDUE: "Overdue",
      PASS: "Pass",
      FAIL: "Fail",
      FILTER_BY_DUE_DATE: "Filter by Due Date:",
      FAILED_ACTIVITIES_TRAINING_REPORT: "Failed Activities Training Report",
      ACTIVITY_NAME: 'Activity Name',
      SCHEDULED_DATE: "Scheduled Date:",
      DATE_ASSIGNED: "Date Assigned",
      WORKFLOW_TASKS: "Workflow Tasks",
      TRAINING_CAMPAIGN: "Training Campaign",
      TRAINING_CAMPAIGN_NO_DATA_MSG: "Training Campaign Activities Reports will appear as you add them.",
      UNAPPROVED_ACTIVITIES: "Unapproved Activities",
      FAILED_ACTIVITIES: "Failed Activities"
    },
    PROGRAMS: {
      LESSON_NAME: "Lesson Name",
      CHAPTER: "Chapter",
      COURSE: "Course",
      SUBJECT: "Subject",
      TRADE: "Trade",
      ASSIGNEE: "Assignee",
      OVERDUE: "Overdue",
      PASS: "Pass",
      FAIL: "Fail",
      FILTER_BY_DUE_DATE: "Filter by Due Date:",
      SCHEDULED_DATE: "Scheduled Date:",
      STATUS: "Status",
      DUE_DATE: "Due Date",
      DAYS_OVERDUE: "Days Overdue",
      COMPLETION_DATE: "Completion Date",
      RESULT: "result",
      UNACCEPTABLE_ACTIVITIES_TRAINING_REPORT: "Unacceptable Activities Training Report"
    },
    PROJECT: {
      NO_OF_ACTIVITIES_COMPLETED: "# of activities completed",
      NO_OF_UNACCEPTABLE_ACTIVITIES: "# of unacceptable activities",
      DAILY_INSPECTION_REPORT: "Daily Inspection Report",
      ORGANIZATION: "Organization",
      PROJECT: "Project",
      PROGRAM: "Program",
      CAMPAIGN: "Campaign",
      SITE: "Site",
      RESPONSIBLE_SITE_ROLE: "Responsible Site Role",
      NO_DATA_MSG: "Inspection Reports will appear as you add them.",
      DATE_ASSIGNED: "Date Assigned",
      REPORTS_NO_DATA_MSG: "Traning Report details will appear here as you add them."
    },
    SITE: {
      UNIT: "Unit",
      SITE: "Site",
      ASSIGNEE: "Assignee",
      RESET: "Reset",
      TO: "to",
      FROM: "from",
      NO_DATA_MSG: "Unacceptable Activities Reports will appear as you add them.",
      LESSON_NAME: "Lesson Name",
      CHAPTER: "Chapter",
      COURSE: "Course",
      SUBJECT: "Subject",
      TRADE: "Trade",
      OVERDUE: "Overdue",
      PASS: "Pass",
      FAIL: "Fail",
      FILTER_BY_DUE_DATE: "Filter by Due Date:",
      UNACCEPTABLE_ACTIVITIES_TRAINING_REPORT: "Unacceptable Activities Training Report",
      ACTIVITY_NAME: 'Activity Name',
      SCHEDULED_DATE: "Scheduled Date:",
      DATE_ASSIGNED: "Date Assigned"
    },
    DATA_ACCESS: {
      SITE: "Site",
      UNIT: "Unit",
      ASSIGNEE: "Assignee",
      DATE: "Date",
      TOTAL_ACTIVITIES: "Total Activities",
      OPEN_ACTIONS: "Open Actions",
      UNIT_ADDRESS: "Unit Address",
      DETAILED_STATUS: "Detailed Status",
      TASK_ID: "Task Id",
      RESPONSIBLE_TRADE: "Responsible Trade",
      ACTIVITY_STATUS: "Activity Status",
      STATUS: "Status",
      RISK_LEVEL: "Risk Level",
      CAMPAIGN: "Campaign",
      PROGRAM: "Program",
      PROJECT: "Project",
      ORGANIZATION: "Organization",
      CONTACT: "Contact",
      FILTER_BY_INSPECTION_COMPLETEION_DATE: "Filter by Inspection Completion Date:",
      TO: "to",
      FROM: "from",
      RESET: "Reset",
      UNACCEPTABLE_ACTIVITIES: "Unacceptable Activities",
      NO_DATA_MSG: "Unacceptable Activities Reports will appear as you add them.",
      INSPECTION_COMPLETION_DATE: "Inspection Completion Date",
      TRADE_FIX_DATE: "Trade Fix Date",
      SUPERINTENDENT_VERIFICATION_DATE: "Superintendent Verification Date",
      CURRENT_ACTION_ITEM: "Current Action Item",
      CURRENT_ACTION: "Current Action",
      CURRENT_ACTION_ID: "Current Action Id",
      CURRENT_ACTION_ITEM_DUE_DATE: "Current Action Item Due Date",
      CURRENT_ACTION_ITEM_ASSIGNED_TO: "Current Action Item Assigned To",
      LAST_ACTION_ITEM: "Last Action Item",
      LAST_ACTION_ITEM_NOTE: "Last Action Item Note",
      REINSPECTION_REQUIRED: "ReInspection Required?",
      HOLD_PRODUCTION: "Hold Production?",
      PHOTOS: "Photos",
      ACTIVITY_NAME: 'Activity Name',
      DATE_ASSIGNED: "Date Assigned",
      LESSON_NAME: "Lesson Name",
      CHAPTER: "Chapter",
      COURSE: "Course",
      SUBJECT: "Subject",
      TRADE: "Trade",
      OVERDUE: "Overdue",
      PASS: "Pass",
      FAIL: "Fail",
      FILTER_BY_DUE_DATE: "Filter by Due Date:",
      RESPONSIBLE_SITE_ROLE: "Responsible Site Role",
      TASK_SUMMARY: "Task Summary",
      NO_DATA_MSG_DAILY_INSPECTION_REPORT: "Daily Inspection Reports will appear as you add them.",
      EXPORT_TITLE: "Select how you would like to export this table's data.",
      ALL_ROWS: "All Rows",
      CURRENT_PAGE: "Current Page",
      SELECT_A_REPORT: "Select a Report",
      TASK_NAME: "Task Name",
      WORKFLOW_NAME: "Workflow Name",
      EXPORT: "Export",
      CONFIRM_PDF_EXPORT: "Confirm exporting table data as PDF",
      CONFIRM_DOC_EXPORT: "Confirm exporting table data as Word",
      LESSON: "Lesson",
      UNIT_CLOSEOUT: "Unit Closeout",
      CAMPAIGN_STATUS: "Campaign Status",
      TASK_STATUS: "Task Status",
      ACTIVITIES_COLLECTED: "Activities Collected",
      CONDITIONAL_RESPONSE_COLLECTED: "Conditional Responses Collected",
      ACTIVITIES_IN_VERIFICATION: "Activities In Verification",
      INSPECTION_TRACKER: "New Home Inspection Tracker",
      TRADE_PERFORMANCE: "Trade Performance Report",
      TASK: "Task",
      WORKFLOW: "Workflow",
      ACTIVITY_NAME: "Activity",
      EXPORT_MESSAGE: "Export {fileName} is being prepared. The export file will be sent to your email when its ready",
      TAGS: "Tags",
      UNIT_STATUS: "Unit Status",
      NO_TASKS_AVAILABLE_TO_SAMPLE: "No Tasks Available to sample",
      FILTER_BY_DATE: "Filter by Date",
      TASK_SUMMARY_STATUS: "Task Summary Status"
    },
    LESSON: "Lesson",
    ORGANIZATION: "Organization"
  },
  SEE_LESS: 'see less',
  MORE_DETAILS: 'more details',
  RESOURCE_SAGA: {
    UPLOAD_SUCCESS: 'Document uploaded to resource center sucessfully.',
    ERROR_IN_UPLOAD: 'Unable to upload document to resource center. Try again.',
    REPLACE_SUCCESS: 'Uploaded document replaced to resource center sucessfully.',
    ERROR_IN_REPLACE: 'Unable to replace document to resource center. Try again.',
    RENAME_SUCCESS: 'Document updated sucessfully.',
    ERROR_IN_RENAME: 'Unable to rename document. Try again.',
    DELETED_SUCCESS: "File deleted successfully",
    ERROR_IN_DELETING: "Error in deleting file"
  },
  MANAGE_TASK: {
    TASK_ID: 'Task ID',
    TABLE_COLUMNS: {
      ACTIVITY_NAME: 'Activity Name',
      ASSIGNED_FOR: "assigned for",
      RESULTS: 'results'
    },
    INSPECTION_FORM: 'Inspection Form'
  },
  TABLE_TITLE: {
    ROLES: "{name}'s Roles",
    UNITS: "{name}'s Units",
    RESPONSES: "{name}'s Responses",
    TRAING_CONTENT: "{name}'s Training Content"
  },
  ENGLISH: 'english',
  SPANISH: 'spanish',
  WARNING: 'Warning',
  ERROR: 'Error',
  SUCCESS: 'Success',
  TASKS: {
    FILES: "Files",
    ACTIONS: "Actions",
    TARGETS: "Targets",
    TYPE: "Type",
    NAME: "Name",
    TASK_FILES: "Task Files",
    NO_DATA_FOR_FILTERS: `You have no files matched with filters you applied.`,
    NO_RECORDS_TO_DISPLAY: "No records to display",
    TASKS_OVERVIEW_DESCRIPTION: "Run programs to quickly create campaigns across your site for your choosen solution.",
    HEADER_TITLE: "Tasks",
    APPROVE: "approve",
    REJECT: "reject",
    ASSIGN_TO_ME: "Assign to Me",
    NO_RESPONSE_FOUND: "No response found",
    SUBMIT_FOR_REVIEW: "Submit for Review",
    CONFIRM_REJECT: "Yes, I Reject Task",
    CONFIRM_APPROVE: "Yes, I Approve Task",
    CONFIRM_SUBMIT_FOR_REVIEW: "Yes, Submit Task for Review",
    SUBMIT_AND_CLOSE: "Submit and Close",
    TASK_REVIEW: {
      CUSTOM_NOTE_REQUIRED: "Confirm Response Changes -  Note Required",
      CONFIRM_RESPONSE_CHANGES: "Confirm Response Changes",
      CUSTOM_NOTE_REQUIRED_DESCRIPTION: "Please confirm that you would like to update this Activity's responses. Note: Once you hit confirm the responses will be changed across the whole Foresite system.",
      CUSTOM_NOTE_LABEL: "Add Note",
      CUSTOM_NOTE_PLACEHOLDER: "Write Description...",
      ADD_PHOTO: "Add Photo",
      MOVE_TO_NEEDS_REVIEW: "Move to Needs Review",
      ACTIVITY_NOTES: "Activity Notes",
      ADDITIONAL_NOTE: "Additional Note",
      NEED_REVIEW_NO_ACTIVITIES: "You reviewed all Activities in the Task. Complete the action by Approving or Rejecting the Task.",
      TASK_REVIEW_CUSTOMIZATION_NEED_REVIEW_NO_ACTIVITIES: "You reviewed all activities in the task, Complete the action by approving the task.",
      NEED_REVIEW_IN_REVISION_NO_ACTIVITIES: "You have no activities to update. Complete the action by submitting the task for review and approval.",
      APPROVED_NO_ACTIVITIES: "No Approved Activities. Use tab “Activities - Needs Review” to review, approve, and modify each activity as needed.",
      APPROVED_IN_REVISION_NO_ACTIVITIES: "There are no approved activities.",
      EDITED_NO_ACTIVITIES: "No Edited Activities. Use tab “Activities - Needs Review” to review, approve, and modify each activity as needed.",
      TASK_REVIEW_CUSTOMIZATION_EDITED_NO_ACTIVITIES: "No Edited Activities. Use tab \"Needs Review\" to review and modify each activity as needed.",
      ADDITIONAL_NO_ACTIVITIES: "You don't have any additional activities. Please review any remaining activities under \"Needs Review\" tab.",
      TASK_REJECTION_NOTE_LABEL: "Add Task Rejection Note",
      REJECTION_NOTE_REQUIRED: "Please add note to submit task rejection.",
      TASK_REVIEW_NOTE: "Task Review Note",
      ATTACHED_NOTE: "Attached Note"
    },
    PARTIAL_SUBMIT: "Partial Submit",
    NO_ACTIVITIES: "No Activities",
    OVERDUE: 'overdue',
    LOCATION: "Location",
    TODAY: 'today',
    MY_VIEW: 'my view',
    LIST_VIEW: 'list view',
    DUE_DATE: 'due date',
    DUE_DATE_CAMEL_CASE: 'Due date',
    CONSTRUCTION_MANAGER: "Construction Manager",
    PROGRAM: "Program",
    THIS_WEEK: 'this week',
    THIS_MONTH: 'this month',
    SHOW_DETAILS: 'show details',
    LESS_DETAILS: 'less details',
    GET_DIRECTIONS: 'Get directions',
    ASSIGNEE: 'assignee',
    TASK_ID: "Task Id",
    ASSIGNEE_CAMEL_CASE: 'Assignee',
    COMPLETE: 'complete',
    SUBMIT: 'submit',
    COMPLETED: 'completed',
    SEE_MAP: "see map",
    SEARCH_TASKS: "Search Tasks",
    TASK: "Task",
    REASSIGN_TASK: "reassign task",
    SELECT_CONTACT_TO_REASSIGN: "select contact to reassign",
    SUBMIT_AND_CLOSE: "Submit and Close",
    START_TASK: "Start Task",
    VIEW_TASK: "View Task",
    HIDE_TASK: "Hide Task",
    NOT_READY_FOR_INSPECTION: "Not Ready For Inspection",
    SELECT: "Select:",
    UNIT_DETAILS: "Unit Details",
    MANAGER: "Manager",
    ADDRESS: "Address",
    ADVANCED: {
      TASK: "Task",
      TASK_NAME: "Task Name",
      TASK_TYPE: "Task Type",
      TASK_STATUS: "Task Status",
      PROJECT: "Project",
      SITE: "Site",
      UNIT: "Unit",
      PROJECT_NAME: "Project Name",
      SITE_NAME: "Site Name",
      UNIT_NAME: "Unit Name",
      PROGRAM: "Program",
      PROGRAM_TYPE: "Program Type",
      CAMPAIGN: "Campaign",
      ORGANIZATION: "Organization",
      CAMPAIGN_NAME: "Campaign Name",
      CAMPAIGN_TYPE: "Campaign Type",
      DATA_RANGE_STARTDATE: "Scheduled Date - Start",
      DATA_RANGE_FINISHDATE: "Scheduled Date - End",
      SELECT_TYPE: "Select Type",
      SELECT_RANGE_START: "Select Range Start",
      SELECT_RANGE_FINISH: "Select Range Finish",
      BLOCK: "Block",
      TYPE: "Type",
      STATUS: "Status"
    },
    TABLE_COLUMNS: {
      TASK: 'task',
      TASK_TYPE: 'task type',
      TASK_STATUS: 'task status',
      SITE: 'site',
      UNIT: 'unit',
      ASSIGNEE: "Assignee",
      DUE_DATE: "Due Date",
      COMPLETED_DATE: "completed date",
      TASK_ID: "Task Id",
      LINK_TYPE: "Link Type"
    },
    ACTIVITY_SUB_MENU: {
      ACTIVITY_DETAILS: "Activity Details",
      VIEW_REGULATIONS: "View Regulations",
      DELETE_ACTIVITY: "Delete Activity",
      REGULATIONS: "Regulations",
      VIEW_TRAINING: "View Training",
      ADD_NOTES: "Add Note / Media",
      EDIT_NOTES: "Edit Note / Media",
      DELETE_COPY_TITLE: "Delete Copied Activity",
      DELETE_COPY_LABEL: "Are you sure you want to delete this copied activity?",
      EDIT_COPY_LABEL: "Edit Copy Label",
      COPY_LABEL_NAME: "Copy Label Name",
      ACTIVITY_DETAIL_SECTION: {
        ASSEMBLY: "Assembly",
        MAIN_COMPONENT: "Main Component",
        ASSEMBLY_TYPE: "Assembly Type",
        SUB_COMPONENT: "Sub-Component",
        MANUFACTURER: "Manufacturer",
        BRAND: "Brand",
        RESPONSIBLE_SITE_ROLE: "Responsible Site Role",
        RISK_LEVEL: "Risk Level"
      },
      ADD_NOTE_SECTION: {
        NOTE_LABEL: "Optional Note",
        NOTE_PLACEHOLDER: "Write Description...",
        ADD_PHOTO: "Add Photo / Video"
      },
      VIEW_REGULATION_SECTION: {
        APPLICABLE_REGULATIONS: "Applicable Regulations",
        REFERENCE_STANDARDS: "Reference Standards",
        INDUSTRY_INTEPRETATIONS: "Industry Interpretations"
      },
      VIEW_TRAINING_SECTION: {
        LAST: "Last",
        NEXT: "Next",
        NO_TRAINING_AVAILABLE: "No trainings available currently."
      },
      STANDARD: "Standard",
      INTERPRETATIONS: "Interpretations",
      SUBMIT: 'Submit'
    },
    NOT_STARTED: "not started",
    IN_PROGRESS: 'in progress',
    NEED_REVIEW: "Need Review",
    ADDITIONAL: "Additional",
    APPROVED: "Approved",
    EDITED: "Edited",
    ACTIVITY_COPIED: "Activity Copied",
    ACTIVITY_COPIED_ALERT_TEXT: "{activityCopy} - View activity under \"Not Started\"",
    ACTIVITY_DELETED: "Activity Deleted",
    COPIED_ACTIVITY_DELETED_ALERT_TEXT: "{activityCopyDeleted} - Copied activity succesfully deleted.",
    NEED_REVIEW_TAB_DETAILS: {
      TITLE: "Approve or Edit Each Activity",
      DESCRIPTION: "If the activity's responses look good click approve, if not edit the relevant response and attach a note as to why it needed to be updated."
    },
    NEW_TAB_DETAILS: {
      TITLE: "New Activities",
      DESCRIPTION: "A list of all the Activities in this task that have been added during review. You can remove Activities as needed."
    },
    APPROVED_TAB_DETAILS: {
      TITLE: "Approved Activities",
      DESCRIPTION: "A list of all the Activities in this task that have been approved after review. You can edit or move activities back to review as needed."
    },
    EDITED_TAB_DETAILS: {
      TITLE: "Edited Activities",
      DESCRIPTION: "A list of all the Activities in this task that have been edited after review. You can further edit or move activities back to review as needed."
    },
    NEED_REVIEW_TAB_IN_NEEDS_REVISION: {
      TITLE: "Review  Activities",
      DESCRIPTION: "Review the activities, edit responses as needed, and submit for review."
    },
    ACTIVITIES: 'activities',
    RESPONSES_PLACEHOLDER_TEXT: {
      SELECT_RESPONSE: 'select response',
      SELECT_DATE: 'select date',
      WRITE_TEXT_MESSGAE: 'write text response',
      WRITE_JSON_MESSGAE: 'write JSON response',
      UPLOAD_PHOTO: 'upload photo',
      ENTER_WHOLE_NUMBER: 'Enter Number (ex. 10)',
      ENTER_PERCENTAGE: 'Enter Percentage',
      ENTER_DECIMAL_NUMBER: 'Enter Number (ex. 10.37)',
      TO: 'to',
      ADD_SIGNATURE: 'add signature',
      UPDATE_SIGNATURE: 'update signature',
      UPLOAD_FILE: 'upload file'
    },
    VALIDATIONS: {
      REQUIRED_RESPONSE: 'This response is required',
      REVIEWER_NOTE_REQUIRED: "Please add note to confirm response changes.",
      REQUIRED_TRADE: "Trade is required",
      REQUIRED_TRADE_CONTACT: "Trade Contact is required"
    },
    ACTIVITIES_COUNT_DEATIL: 'activities {currentActivityNumber} / {totalActivityCount}',
    LAST: 'last',
    NEXT: 'next',
    SUBMIT_AND_NEXT: 'Submit & Next',
    BACK_TO_LIST: 'Back to List',
    ADDITIONAL_RESPONSES: 'additional responses',
    START_LESSON: "start lesson",
    VIEW_ACTIVITY: "view activity",
    SUBMIT: "submit",
    LESSON_COMPLETED: "lesson completed",
    TASK_COMPLETION_CONFIRMATION: 'Are you sure to complete this task?',
    ADDITIONAL_ACTIVITIES_ALERT_TEXT_ON_APPROVE_TASK: 'Do you want to complete any additional activities before approving task?',
    ADDITIONAL_ACTIVITIES_ALERT_TEXT_ON_REJECT_TASK: 'Do you want to complete any additional activities before rejecting task?',
    PARTIAL_SUBMIT_WARNING: "Task submission is not possible until all required activities are completed. Note: Activities which has red asterisk ('*') next to the activity name are required to be completed to submit this task.",
    ADDITIONAL_RESPONSE_TOOLTIP: "Additional responses may be conditional upon your answer above.",
    ADDITIONAL_NOTE: "Additional Note",
    MARK_AS_NOT_READY: "Mark as Not Ready",
    EXCEPTION: "Exception",
    DELETE_FILE: "Delete",
    SAVE_NOTE: "Save Note",
    UNGROUPED_ACTIVITY: "Ungrouped",
    ADD_TAG: "+ Add Tag",
    TRADE_CORRECTION: {
      TRADE_CORRECTION_DETAILS: "Trade Correction Details",
      SELECT_TRADE: "Select Trade",
      SELECT_TRADE_CONTACT: "Select Trade Contact",
      ATTACH_NOTE_FOR_TRADE: "Attach Note for Trade",
      ADD_ANOTHER_TRADE: "Add Another Trade",
      SELECT_DUE_DATE: "Select Due Date",
      ADD_NEW_TRADE_BUTTON_LABEL: "+ Add New Trade",
      ADD_CONTACT: "Add New Contact",
      FISRT_NAME: "First Name",
      LAST_NAME: "Last Name",
      ENTER_FIRST_NAME: "Enter First Name",
      ENTER_LAST_NAME: "Enter Last Name",
      JOB_TITLE: "Job Title",
      EMAIL: "Email",
      PHONE_NUMBER: "Phone Number",
      ADD_NEW_TRADE_CONTACT_BUTTON_LABEL: "+ Add New Trade Contact"
    },
    UPLOAD_FILE_ATTACH: "Attach at least 1 relevant file (PDF, Doc, etc)",
    UPLOAD_FILE_COUNT: "Attached Files",
    PRETASK_CHECKLIST_ERRORS: {
      ASSIGN_ACTION_TO: "Please select assign action to",
      SITE_PARTY_ROLE_ID: "Please select site role",
      CONTACT_ID: "Please select individual value",
      NUMBER_OF_DAYS_DUE: "Please enter number of days due"
    },
    UPLOAD_FILE_COUNT: "Attached Files ({count})",
    RELATED_TASKS_WITH_COUNT: "Related Tasks ({taskCount})",
    RELATED_TASKS_MODAL_TITLE: "View Related Tasks",
    CORRECTED_BY: "Corrected By",
    VERIFIED_BY: "Verified By",
    VIEW_ACTION: "View Action",
    CALCULATORS: {
      CALCULATION_RESPONSE_MESSAGE: "Complete the calculation response in mobile app",
      GLOBAL_INPUTS: "Global Inputs",
      GLOBAL_OUTPUTS: "Global Outputs",
      TOTAL_CONDITION_SPACE: "Total Condition Space",
      PRESSURE_EXPONENT: "Pressure Exponent",
      MONOMETER_SETTINGS: "Momometer Settings",
      TEST_METHOD: "Test Method",
      TEMP_IN: "Temp In",
      TEMP_OUT: "Temp Out",
      ELEVATION: "Elevation",
      WALL_HEIGHT: "Wall Height",
      SYSTEM_INPUTS: "System #{index} Inputs",
      SYSTEM_OUTPUTS: "System #{index} Outputs",
      FAN_RING_TYPE: "Fan Ring Type",
      BUILDING_PRESSURE: "Building Pressure",
      FAN_PRESSURE: "Fan Pressure",
      FAN_FLOW: "Fan Flow",
      BLOWER_DOOR_FACTORS_C: "Blower Door Factors - c",
      BLOWER_DOOR_FACTORS_N: "Blower Door Factors - n",
      BLOWER_DOOR_FACTORS_R: "Blower Door Factors - r",
      CFM50: "CFM50",
      CORRECTED_CFM50: "Corrected CFM 50",
      ELA: "ELA",
      INFILTRATION_VOLUME: "Infiltration Volume",
      ACH50: "ACH50",
      CORRECTED_ACH50: "Corrected ACH50",
      CO: "CO",
      SLA: "SLA",
      ACH_EST_ELSEWHERE_20_FACTOR: "ACH (est) - elsewhere (20 Factor)",
      ACH_EST_FLORIDA_40_FACTOR: "ACH (est) - Florida (40 Factor)",
      TOTAL_CONDITION_SPACE_SQFT: "Total Condition Space (Sq.Feet)",
      SQFT_SERVED: "Sq.Ft Served",
      FAN_RING: "Fan Ring",
      CFM25: "CFM25",
      INDIVIDUAL_QN: "Individual QN",
      TOTAL_CFM25: "Total CFM25",
      TOTAL_QN: "Total Qn",
      TOTAL_DUCT_LEAKAGE_CFM25: "Total Duct Leakage (CFM25)",
      CONDITIONED_FLOOR_AREA_CFA: "Conditioned Floor Area (CFA)",
      DOES_THE_SYSTEM_HAVE_A_TOTAL_AMOUNT_OF_SUPPLY_DUCTWORK_OR_DISTRIBUTION_BUILDING_CAVITIES_THAT_IS_GREATER_10_TOTAL_LINEAR_FEET: "Does the system have a total amount of supply ductwork or distribution building cavities that is > 10 total linear feet?",
      IS_THE_SYSTEM_ENTIRELY_IN_CONDITIONED_SPACE_VOLUME: "Is the system entirely in Conditioned Space Volume?",
      WAS_TOTAL_DUCT_LEAKAGE_TEST_EXEMPTION_TAKEN: "Was total duct leakage test exemption taken?",
      ENTER_NUMBER_OF_RETURNS: "Enter Number of Returns",
      DID_TESTING_OCCUR_AT_ROUGHIN_OR_FINAL: "Did testing occur at roughin or final?",
      LEAKAGE_PER_100_SQ_FT: "Leakage per 100 sq. ft.",
      DUCT_LEAKAGE_GRADE: "Duct Leakage Grade",
      SHOWERHEAD_FLOW_RATE1_GPM: "Showerhead Flowrate 1 (gpm)",
      SHOWERHEAD_FLOW_RATE2_GPM: "Showerhead Flowrate 2 (gpm)",
      SHOWERHEAD_FLOW_RATE3_GPM: "Showerhead Flowrate 3 (gpm)",
      SHOWERHEAD_FLOW_RATE4_GPM: "Showerhead Flowrate 4 (gpm)",
      SHOWERHEAD_FLOW_RATE5_GPM: "Showerhead Flowrate 5 (gpm)",
      SHOWERHEAD_FLOW_RATE6_GPM: "Showerhead Flowrate 6 (gpm)",
      SHOWERHEAD_FLOW_RATE7_GPM: "Showerhead Flowrate 7 (gpm)",
      SHOWERHEAD_FLOW_RATE8_GPM: "Showerhead Flowrate 8 (gpm)",
      SHOWERHEAD_FLOW_RATE9_GPM: "Showerhead Flowrate 9 (gpm)",
      SHOWERHEAD_FLOW_RATE10_GPM: "Showerhead Flowrate 10 (gpm)",
      KITCHEN_SINK_FAUCET_RATE_GPM: "Kitchen Sink Faucet Flow Rate (gpm)",
      AVERAGE_FLOW_RATE_GPM: "Average Flow Rate (gpm)",
      KITCHEN_FAUCET_FLOW_RATE_GPM: "Kitchen Faucet Flow Rate (gpm)",
      DIAMETER_OF_DUCT: "Diameter of Duct",
      VELOCITY_READING1: "Velocity Reading 1",
      VELOCITY_READING2: "Velocity Reading 2",
      VELOCITY_READING3: "Velocity Reading 3",
      VELOCITY_READING4: "Velocity Reading 4",
      AIR_FLOW_CFM: "Air Flow (CFM)",
      DEPTH: "Depth",
      WIDTH: "Width",
      EQUIVALENT_DUCT_SIZE: "Equivalent Duct size",
      READINGS: "Readings",
      ASHRAE_STANDARD: "ASHRAE Standard",
      NUMBER_OF_BEDROOMS_NBR: "Number of Bedrooms (Nbr)",
      VENTILATION_STRATEGY: "Ventilation Strategy",
      EXHAUST_AIRFLOW: "Exhaust Airflow",
      SYSTEM_AIRFLOW: "System Airflow",
      DESIGNATED_RUN_TIME: "Designated Run Time",
      MECHANICAL_VENTILATION_AIRFLOW_RATE: "Mechanical Ventilation Airflow Rate",
      VENTILATION_RANGE_MIN: "Ventilation Range Min",
      VENTILATION_RANGE_MAX: "Ventilation Range Max",
      CALCULATED_ASHRAE_FLOW_RATE: "Calculated ASHRAE 62.2 Flow Rate",
      CALCULATED_FLOW_CFM: "Calculated Flow (CFM)",
      COMPLIANCE_STATUS_ASHRAE: "Compliance Status (ASHRAE)",
      COMPLIANCE_STATUS_ENERGY_START_HVAC: "Compliance Status (ENERGY STAR - HVAC Design)",
      DUCT_DIAMETER: "Duct Diameter",
      INPUT: "Input",
      AVERAGE: "Average",
      SELECT_THE_MODE_HVAC_SYSTEM_WAS_TESTED_IN: "Select the mode HVAC System was tested in",
      Q_DESIGN_HEATING_CFM: "QDesign Heating (CFM)",
      Q_DESIGN_COOLING_CFM: "QDesign Cooling (CFM)",
      SELECT_TEST_METHOD: "Select Test Method",
      TEST_METHOD_FLOW_HOOD: "Test Method Flow Hood",
      NUMBER_OF_RETURNS: "Number of Returns",
      CFM_MULTIPLE_FIELDS_BASED_ON_NUMBER_OF_RETURNS_VALUE: "CFM (Multiple fields based on number of returns value)",
      TEST_METHOD_FLOW_GRID: "Test Method Flow Grid",
      PSOP_PRESSURE_OF_THE_SUPPLY_SIDE_DURING_NORMAL_OPERATION: "Psop (Pressure of the supply side during normal operation)",
      WAS_THERE_TURBULENCE_FLUCTUATIONS_0_05_IWC_WHEN_MEASURING_PSOP: "Was there turbulence (fluctuations >0.05 IWC) when measuring Psop?",
      PTEST_PRESSURE_OF_THE_SUPPLY_SIDE_DURING_TEST: "Ptest (Pressure of the supply side during test)",
      QTEST_AIRFLOW_FOR_THE_FLOW_GRID_CFM: "Qtest  - Airflow for the flow grid (CFM)",
      FAN_WATT_DRAW: "Fan Watt Draw",
      MEASUREMENT_METHOD: "Measurement Method",
      KH_FACTOR: "Kh Factor",
      NREV: "Nrev",
      TREV_MUST_BE_GREATER_THAN_OR_EQUAL_TO_90: "Trev (Must be greater than or equal to 90)",
      BLOWER_FAN_WATT_DRAW_WFAN: "Blower Fan Watt Draw (Wfan)",
      BLOWER_FAN_AIRFLOW: "Blower Fan Airflow",
      BLOWER_FAN_AIRFLOW_AT_OPERATING_CONDITIONS_QOP: "Blower fan airflow at operating conditions (Qop)",
      FAF: "FAF",
      BLOWER_FAN_VOLUMETRIC_AIRFLOW_GRADE: "Blower fan volumetric airflow grade",
      BLOWER_FAN_WATT_DRAW: "Blower Fan Watt Draw",
      BLOWER_FAN_EFFICIENCY: "Blower fan efficiency",
      BLOWER_FAN_EFFICIENCY_GRADE: "Blower fan efficiency grade",
      DOES_THE_LOCATION_OF_THE_GEOTAGGED_PHOTO_PROVIDED_IN_SECTION_8_5_2_1_2_MATCH_THE_LOCATION_OF_THE_FORCED_AIR_HVAC_SYSTEM_UNDER_TEST: "Does the location of the geotagged photo provided in Section 8.5.2.1.2 match the location of the Forced-Air HVAC System under test?",
      THE_TOTAL_WEIGHT_OF_REFRIGERANT_ADDED_TO_OR_REMOVED_FROM_THE_SYSTEM: "The total weight of refrigerant added to or removed from the system",
      REFRIGERANT_CHARGE_ADDED_OR_REMOVED: "Refrigerant Charge added or removed",
      WAS_FACTORY_CHARGE_REMOVED_FIRST: "Was factory charge removed first?",
      WAS_TIME_STAMPED_GEOTAGGED_PHOTO_S_COLLECTED_SHOWING_SCALE_WITH_AMOUNT_OF_REFRIGERANT_ADDED_OR_REMOVED: "Was time-stamped geotagged photo(s) collected, showing scale with amount of refrigerant added or removed?",
      THE_TOTAL_LENGTH_OF_THE_LIQUID_LINE: "The total length of the liquid line",
      THE_OUTSIDE_DIAMETER_OF_THE_LIQUID_LINE: "The outside diameter of the liquid line",
      THE_LENGTH_OF_LIQUID_LINE_ACCOUNTED_FOR_IN_THE_FACTORY_SUPPLIED: "The length of liquid line accounted for in the factory-supplied",
      THE_WEIGHT_OF_THE_FACTORY_SUPPLIED_REFRIGERANT: "The weight of the factory-supplied refrigerant",
      THE_WEIGHT_OF_REFRIGERANT_ADDED_FOR_SPECIFIC_COMPONENTS_OTHER_THAN_LINE_LENGTH: "The weight of refrigerant added for specific components (other than line length)",
      ARE_YOU_ABLE_TO_VERIFY_THE_LENGTH_OF_THE_LIQUID_LINE: "Are you able to verify the length of the liquid line?",
      IS_THE_LENGTH_OF_THE_LIQUID_LINE_MEASUREMENT_CORRECT: "Is the length of the liquid line measurement correct?",
      ENTER_LENGTH_OF_THE_LIQUID_LINE: "Enter length of the liquid line",
      ENTER_DIAMETER_OF_THE_LIQUID_LINE: "Enter diameter of the liquid line",
      NON_INVASIVE_INPUTS: "Non-Invasive Inputs",
      HAVE_PREREQUISITES_GRADE_I_OR_GRADE_II_HAVE_BEEN_MET_TO_CONTINUE_REFRIGERANT_CHARGE: "Have prerequisites Grade I or Grade II have been met to continue Refrigerant Charge?",
      EQUIPMENT_RATED_SEER: "Equipment Rated SEER",
      ENTER_MAXIMUM_TOTAL_HEAT_GAIN: "Enter maximum total heat gain",
      ENTER_METERING_DEVICE: "Enter metering device",
      TARGET_SUPERHEAT: "Target Superheat",
      TARGET_SUBCOOLING: "Target Subcooling",
      AMBIENT_TEMPERATURE: "Ambient Temperature",
      ENTER_DESIGN_COOLING_BLOWER_FAN_VOLUMETRIC_AIRFLOW: "Enter design cooling blower fan volumetric airflow",
      ENTER_MEASURED_RETURN_AIR_DRY_BULB_TEMPERATURE: "Enter measured return air dry-bulb temperature",
      ENTER_MEASURED_RETURN_AIR_WET_BULB_TEMPERATURE: "Enter measured return air wet-bulb temperature",
      ENTER_MEASURED_OUTDOOR_AIR_DRY_BULB_TEMPERATURE: "Enter measured outdoor air dry-bulb temperature",
      ENTER_MEASURED_SUCTION_LINE_TEMPERATURE: "Enter measured suction line temperature",
      ENTER_MEASURED_LIQUID_LINE_TEMPERATURE: "Enter measured liquid line temperature",
      FOR_WEIGH_IN: "For Weigh-in",
      FOR_NON_INVASIVE: "For Non-Invasive",
      TXV_EEV_DISPLAY_ONLY_IF_TXV_EEV_IS_SELECTED_FOR_ENTER_METERING_DEVICE: "TXV/EEV (Display only if TXV/EEV is selected for Enter Metering Device)",
      DELTA_LIQUID_LINE_LENGTH_FT: "Delta Liquid Line Length (ft)",
      WEIGHT_OF_THE_REFRIGERANT_REQUIRED_FOR_THE_INCREMENTAL_LIQUID_LINE_LENGTH: "Weight of the refrigerant required for the incremental liquid line length",
      TOTAL_ANTICIPATED_REFRIGERANT_WEIGH: "Total Anticipated Refrigerant Weigh",
      TOTAL_REPORTED_REFRIGERANT_WEIGHT: "Total Reported Refrigerant Weight",
      DEVIATION_BETWEEN_TOTAL_ANTICIPATED_AND_TOTAL_REPORTED_REFRIGERANT_WEIGHT: "Deviation between total anticipated and total reported refrigerant weight",
      GRADE_DESIGNATION: "Grade Designation",
      MIN_LIQUID_LINE_TEMP: "Min liquid line temp",
      MAX_LIQUID_LINE_TEMP: "Max liquid line temp",
      LIQUID_LINE_WITHIN_TARGET: "Liquid line within target?",
      TARGET_SUCTION_LINE_TEMP: "Target suction line temp",
      DIFFERENCE_DTD: "Difference DTD",
      QNORM: "Qnorm",
      DTD: "DTD",
      CTOA: "CTOA",
      SUCTION_LINE_WITHIN_TARGET: "Suction line within target?",
      MIN_SUCTION_LINE_TEMP: "Min suction line temp",
      MAX_SUCTION_LINE_TEMP: "Max suction line temp",
      TARGET_LIQUID_LINE_TEMP: "Target liquid line temp",
      DIFFERENCE_CTOA: "Difference CTOA",
      CFM_COUNT: "CFM #{index}",
      FEET: "Feet",
      INCHES: "Inches"
    }
  },
  TASK_DATA_COLLECTION_SAGA: {
    ERROR_IN_UPDATING_TASK_REVIEW_ASSIGNMENT: "Error in updating task review assignment.",
    TASK_REVIEW_ASSIGNMENT_UPDATED_SUCCESS: "Task review assignment updated successfully.",
    ASSIGN_SELF_SCHEDULE_UPDATED_SUCCESS: "Task self schedule assignment updated successfully.",
    ERROR_IN_UPDATING_SELF_SCHEDULE_ASSIGNMENT: "Error in updating task self schedule assignment.",
    ERROR_IN_FETCHING_LIST: 'Error in fetching activity collection.',
    ERROR_IN_SAVING_TASKS: 'Error in saving completed tasks.',
    ERROR_IN_SAVING_APPROVE_TASKS: 'Error in approving task.',
    ERROR_IN_SAVING_REJECT_TASKS: 'Error in rejecting task.',
    ERROR_IN_SUBMITTING_TASK_FOR_REVIEW: 'Error in submitting the task for the review.',
    SUBMIT_AND_REVIEW_TASKS_SUCCESS: "Task has been submitted successfully for the review.",
    ERROR_IN_SAVING: "Error in saving activity data.",
    SAVE_SUCCESS: "Activity data saved successfully.",
    COMPLETED_TASKS_SUCCESS: "Completed tasks saved successfully.",
    APPROVE_TASKS_SUCCESS: "Task has been approved successfully.",
    REJECT_TASKS_SUCCESS: "Task has been rejected successfully.",
    ERROR_IN_FETCHING_LAUNCH_LINK: 'Unable to start lesson. Please try again.',
    ERROR_IN_FETCHING_TASK_LIST: 'Error in fetching task list.',
    ERROR_IN_STARTING_ACTIVITY: 'Error in Starting the activity',
    ERROR_IN_FETCHING_CONTACT_LIST: 'Error in Fetching Contact List',
    ERROR_IN_UPDATING_TASK_CONTACT: 'Error in updating task contact',
    REASSIGNMENT_SUCCESS: 'Task Reassigned Successfully',
    ERROR_IN_SAVING_NOTES_AND_IMAGES: "Error in saving notes and images.",
    NOTES_AND_IMAGES_SAVE_SUCCESS: "Notes and Images saved successfully.",
    ADDITIONAL_NOTES_AND_IMAGES_DELETE_SUCCESS: "Additional Note and Media deleted successfully",
    ERROR_IN_DELETING_ADDITIONAL_NOTE_AND_MEDIA: "Error in deleting additional note and media",
    ERROR_IN_APPROVING_ACTIVITY: "Error in approving activity.",
    ACTIVITY_APPROVAL_SUCCESS: "Activity approved successfully.",
    ERROR_IN_MOVING_ACTIVITY_TO_NEEDS_REVIEW: "Error in moving activity to Needs Review.",
    ACTIVITY_MOVED_TO_NEEDS_REVIEW_SUCCESS: "Activity moved successfully to Needs Review.",
    TRADE_CONTACT_ADD_SUCCESS: "Trade contact added successfully.",
    ERROR_IN_ADDING_TRADE_CONTACT: "Error in adding trade contact.",
    COPIED_ACTIVITY_DELETED_SUCCESS: "Copied activity deleted succesfully.",
    ERROR_IN_DELETING_COPIED_ACTIVITY: "Error in deleting copied activity.",
    COPIED_ACTIVITY_LABEL_UPDATED_SUCCESS: "Copied activity label updated succesfully.",
    ERROR_IN_UPDATING_COPIED_ACTIVITY_LABEL: "Error in updating copied activity label.",
    COPIED_ACTIVITY_ADDED_SUCCESS: "Activity copy added successfully.",
    ERROR_IN_ADDING_COPIED_ACTIVITY: "Error in adding activity copy.",
    UPDATE_PRE_TASK_CHECKLIST_SUCCESS: "Pre task check list updated successfully.",
    ERROR_IN_UPDATING_PRE_TASK_CHECKLIST: "Error in updating pre task check list."
  },
  PLEASE_WAIT_MESSAGE: 'Please Wait...',
  SEE_MORE: 'see more',
  SEE_MAP: "see map",
  ALL_TASKS: "All Tasks",
  MY_TASKS: "My Tasks",
  SELF_ASSIGN: "Self Assign",
  ALL_ACTIONS: "All Actions",
  MY_ACTIONS: "My Actions",
  SELF_ASSIGNMENT: "Self-Assignment",
  ASSIGNED_TASKS: "Assigned Tasks",
  BUILD_WORKFLOW_SAGA: {
    ADDING_SUCCESS: 'Campaign workflow details added successfully.',
    ERROR_IN_ADDING: 'Error in adding campaign workflow details.',
    UPDATED_SUCCESS: "Campaign workflow details updated successfully.",
    ERROR_IN_UPDAING: "Error in updaing campaign workflow details."
  },
  ACTIONS_PAGE: {
    CARD_VIEW: 'card view',
    LIST_VIEW: 'list view',
    HEADER_TITLE: "Actions",
    ACTIONS_OVERVIEW_DESCRIPTION: "Run programs to quickly create campaigns across your site for your choosen solution.",
    DUE_DATE: 'due date',
    CONFIRM: 'Confirm',
    READY: 'ready',
    VIEW_MORE: 'view more',
    VIEW_MORE_COMPLETED: 'view more - completed',
    LOCATION: "Location",
    TASK: "task",
    SCHEDULED_FOR: "Scheduled for",
    NOT_READY: 'Not Ready',
    EXCEPTION: "Exception",
    ASSIGNEE: "Assignee",
    STATUS: "Status",
    SELECT_CONTACT_REASSIGN: "Select Contact to Reassign Task",
    REASON_SIGN_OFF: "Reason for Sign Off",
    ADVANCED: {
      TASK: "Task",
      TASK_NAME: "Task Name",
      ACTION_TYPE: "Action Type",
      PROJECT: "Project",
      SITE: "Site",
      UNIT: "Unit",
      PROJECT_NAME: "Project Name",
      SITE_NAME: "Site Name",
      UNIT_NAME: "Unit Name",
      PROGRAM: "Program",
      PROGRAM_TYPE: "Program Type",
      CAMPAIGN: "Campaign",
      CAMPAIGN_NAME: "Campaign Name",
      CAMPAIGN_TYPE: "Campaign Type",
      ACTIVITY: "Activity",
      ACTIVITY_NAME: "Activity Name",
      DUE_DATE_START: "Start of Date Range",
      DUE_DATE_FINISH: "End of Date Range",
      SELECT_START: "Select Date",
      SELECT_FINISH: "Select Date",
      SELECT_TYPE: "Select Type",
      SELECT_ACTION: "Select Action",
      SELECT_RANGE_START: "Select Range Start",
      SELECT_RANGE_FINISH: "Select Range Finish",
      ACTION_ASSIGNED_TO: "Action Assigned To",
      SELECT_ACTION_ASSIGNED: "Select Action Assigned",
      ACTIVITY_STATUS: "Activity status",
      ACTION_STATUS: "Action Status",
      SELECT_ACTION_STATUS: "Select Action Status",
      ALL_UNIT: "All Unit"
    },
    ACTIVITY: 'activity',
    CORRECTED: "Corrected",
    REASSIGN: "Reassign",
    CORRECTION_REASSIGN: "correction reassign",
    TASK_SIGN_OFF_REASSIGN: "sign off reassign",
    BULK_REASSIGN: "Bulk Reassign",
    BULK_REASSIGN_TITLE: "Select the contact to reassign selected action items.",
    CAUTION: "CAUTION",
    CAUTION_NOTE: "Note that this reassignment function does not validate the selected user’s site roles or group assignments.",
    ACTIONS: "Actions",
    SELECTED_ACTIONS: "Selected Actions",
    SELECT_CONTACT: "Select Contact to Assign",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    APPROVE: "approve",
    REJECT: "reject",
    UNSELECT_ALL: "Unselect All",
    ADDITIONAL_NOTE: "Additional Note",
    NOT_READY: "Not Ready",
    VERIFY_CORRECTION: "Verify Correction",
    REJECT_CORRECTION: "Reject Correction",
    CONFIRM_READY: "Confirm Ready",
    WRITE_NOTE_HERE: "Write your note here....",
    MARK_INSPECTION_READY: "mark inspection ready",
    CONFIRM_SITE_VISIT: "confirm site visit",
    CORRECTION: "Correction",
    NO_CORRECTION_DETAILS: "You have no correction details to see.",
    REASSIGN_CORRECTION: "Reassign Correction",
    VERIFICATION: "Verification",
    BACK: 'Back',
    YES_ITS_READY: "Yes it's Ready",
    NO_ITS_NOT_READY: "No it's Not Ready",
    CREATED: 'created',
    ASSIGNEE: 'assignee',
    CORRECTED_ACTION: "Yes, it's Corrected",
    REASSIGN_ACTION: "Yes, Reassign Task",
    VIEW_ADD_NOTES: "View / Add Notes",
    VIEW_ADD_PHOTO: "View / Add Photo",
    APPROVE_ACTION: "Yes, Approve Correction",
    REJECT_ACTION: "Yes, I Reject Correction",
    ADD_NOTE: "Add Note",
    HIDE_NOTE: "Hide Note",
    ADD_PHOTO: "Add Photo",
    VIEW_PHOTOS: "View Photos",
    ATTACHED_PHOTOS_LABEL: "({count}) Photos Attached",
    ATTATCHED_PHOTOS: "Add Photo ({count})",
    SUBMIT_AND_CLOSE: "Submit and Close",
    SUBMIT: "Submit",
    AUDIT_TRAIL: "Audit Trail",
    SUBMITTER: "Submitter",
    REASON_FOR_NOT_READY: "Reason for not ready:",
    SENT_TO: "Sent to:",
    OBSERVATION_DETAILS: "Observation Details",
    ACTIVITY_DETAILS: "Activity Details",
    FAILED_INSPECTION_CORRECTION_DESCRIPTION: "Correct the failed activity at the location list below.",
    INSPECTION_DETAILS: "Inspection Details",
    FAILED_ACTIVITIES_NO_DATA: "You have no failed activities.",
    CONFIRM_SITE_VISIT_ACTIVITIES_NO_DATA: "Confirm site visit has no activities.",
    CORRECTION_DETAILS: "Correction Details",
    OVERDUE_ACTION: "Overdue Action",
    READY_DATE: "Ready Date",
    READY_TIME: "Ready Time",
    VERIFY_BY_SUPERINDENDENT_DESCRIPTION_1: "Ensure the relevant trade corrected the fail activity at the location list below.",
    VERIFY_BY_SUPERINDENDENT_DESCRIPTION_2: "Pending: Attach a photo of the corrected item to close action.",
    CONFIRM_SITE_VISIT_DESCRIPTION: "Confirm that this scheduled inspection is ready on your site",
    VISIT_DETAILS: "Visit Details",
    ACTIVITIES: "Activities",
    REASSIGN_FAILED_INSPECTION_CORRECTION_DESCRIPTION: "Select a contact to corrected the failed item(s) shown below.",
    REASSIGNMENT_DETAILS: "Reassignment Details",
    ADD_NOTE_PLACEHOLDER: "Write your note here...",
    PHOTO_ATTACHMENTS_COUNT_SINGULER_TEXT: "{count} Attachment",
    PHOTO_ATTACHMENTS_COUNT_PLURAL_TEXT: "{count} Attachments",
    ACTION_STAGES: "action stages",
    STAGE_DETAILS: "stage details",
    VIEW_ATTACHMENTS: "view attachments ({count})",
    VIEW_LESS: "view less",
    LAST: "last",
    NEXT: "next",
    DELETE: "Delete",
    ATTACH_PHOTO: "attach photo",
    SAVE_AND_ADD_NEW: "Save and Add New",
    SAVE_AND_CLOSE: "Save and Close",
    ADD_NEW_NOTE: "add new note",
    CONTAIN_MULTIPLE_ACTIVITIES: "You cannot confirm actions of different types.",
    MULTI_SELECT_ACTIONS: "Multi-Select Actions",
    INSPECTION_REPORT: "Inspection Report",
    ADDITIONAL_RESPONSES: 'additional responses',
    ADDITIONAL_RESPONSE_TOOLTIP: "Additional responses may be conditional upon your answer above.",
    SYSTEM_DEFINITION: "System DefinItion",
    ASSIGNED_ROLE: "Assigned Role",
    REQUIRE_PHOTOS: "Require Photos",
    SYSTEM_CATEGORY: "System Category",
    CORRECT_FAILED_ACTIVITY_VM_LABEL: "Select Contact to Correct Failed Activity",
    PRIMARY_NOTE: "Primary Note",
    ATTACHED_NOTE: "Attached Note",
    CONFIRM_VISIT: "confirm visit",
    VERIFY: "verify",
    SIGN_OFF: "Sign Off",
    SIGN_OFF_ACTION: "Yes, Sign Off",
    TASK_SIGN_OFF: "Task Sign Off",
    TASK_SIGN_OFF_DESCRIPTION: "Sign off to completely close out this task.",
    SELECT_ACTION_TYPE_TO_PERFORM_ACTION: "Select action type from list to perform some action.",
    OPTIONAL: "Optional",
    REQUIRED: "Required",
    ADD_PHOTO: "Add Photo / Video",
    FILE_FORMAT_NOT_SUPPORTED_ERROR: "Only files with the following extensions are allowed: png, jpeg.",
    DATE_READY: "Date Ready",
    TIME_READY: "Time Ready",
    SELECT_REASON: "Select Reason",
    REASSIGN_TO_CONTACT: "Reassign to Contact"
  },
  ACTIONS: {
    HEADER_TITLE: "Actions",
    TASK_ACTIONS_TITLE: "Task's Actions",
    NO_ACTIONS: "No Actions",
    TABLE_COLUMNS: {
      TYPE: 'Type',
      LOCATION: 'location',
      TASK_ACTIVITY: 'task/activity',
      ASSIGNEE: 'assignee',
      COLUMN_ACTIONS: 'actions',
      SITE: "Site",
      UNIT: "Unit"
    },
    TODAY: 'today',
    THIS_WEEK: 'this week',
    THIS_MONTH: 'this month',
    PAST_DUE: "past due",
    OVERDUE_DAYS: "{days} Days",
    OVERDUE_DAY: "{days} Day",
    OVERDUE_HOURS: "{hours} Hours",
    OVERDUE_HOUR: "{hours} Hour",
    NO_DATA: "No actions to display."
  },
  NOTIFICATIONS: {
    HEADER_TITLE: "Notifications",
    NO_NOTIFICATIONS: "No Notifications",
    CLEAR_ALL: "Clear All",
    CLEAR_SELECTED: 'Clear Selected',
    CLEAR: "Clear",
    SUMMARY: "summary",
    TYPE: "Type",
    CREATED_ON: "created on",
    STATUS: "status",
    HEADER_TITLE: "Notifications",
    SEEN: "Seen",
    NEW: "New",
    CLEAR_NOTIFICATION: "Do you want to clear all notifications",
    CLEAR_SELECTED_NOTIFICATION: "Do you want to clear selected notifications",
    NO_DATA: "No notifications to display.",
    CLEAR_ALL_NOTIFICATION_SUCCESS: "All notifications cleared successfully",
    CLEAR_ALL_NOTIFICATION_FAILURE: "Unable to clear all notifications",
    CLEAR_NOTIFICATION_SUCCESS: "Notifications cleared successfully",
    CLEAR_NOTIFICATION_FAILURE: "Unable to clear notification",
    TOTAL_RESULTS: "{totalResults} results",
    CLEAR_SELECTED_NOTIFICATION_SUCCESS: "Selected notifications cleared successfully",
    CLEAR_SELECTED_NOTIFICATION_FAILURE: "Unable to clear selected notifications",
    NOTIFICATION_CUSTOM_TEXT: "A site visit has been confirmed at site: {siteName} unit: {unitName} on {scheduleDate} Click here to see the task.",
    TABLE_COLUMNS: {
      COLUMN_ACTIONS: 'actions'
    },
    HEADER_TITLE: "Notifications",
    SETTINGS: "Settings",
    HEADER_DESCRIPTION: "Adjust when each type of notification is sent and to which channels.",
    ERRORS: {
      INVALID_NOTIFICATION_CONFIG: "Notification configuration is not updated properly"
    }
  },
  QUICK_LINK: {
    HEADER_TITLE: "Quick Links",
    NO_DATA: "No links to display."
  },
  ACTIONS_PAGE_SAGA: {
    ACCEPT_BY_TRADE_UPDATE_SUCCESS: "Correction acceptance confirmed successfully.",
    ERROR_IN_UPDATING_ACCEPT_BY_TRADE: "Error in correction acceptance confirmation.",
    VERIFY_BY_SUPERINTENDENT_UPDATE_SUCCESS: "Superintendent's verification status updated successfully.",
    ERROR_IN_UPDATING_VERIFY_BY_SUPERINTENDENT: "Error in updating superintendent's verification status.",
    CONFIRM_SITE_VISIT_SUCCESS: "Confirming site visit successful",
    ERROR_IN_CONFIRMING_SITE_VISIT: "Error in confirming site visit",
    UPDATE_CONTACT_TRADE_REASSIGN_SUCCESS: "Contact trade reassigned successfully.",
    UPDATE_REASSIGN_FAILED_INSPECTION_SUCCESS: "Reassign failed inspection updated successfully.",
    ERROR_IN_UPDATING_REASSIGN_FAILED_INSPECTION: "Error in updating re-assign failed inspection status.",
    UPDATE_BULK_ASSIGN_ACTION_ITEMS: "Bulk assign action items update successfully.",
    ERROR_BULK_ASSIGN_ACTION_ITEMS: "Error in bulk assign action item update.",
    UPDATE_SIGN_OFF_SUCCESS: "Action Sign off updated successfully.",
    UPDATE_REASSIGN_SIGNOFF_SUCCESS: "Reassign action sign off updated successfully.",
    ERROR_IN_SIGN_OFF_ACTION: "Error in action sign off item update.",
    ERROR_IN_REASSIGN_SIGNOFF: "Error in reassign action sign off item update."
  },
  SETTINGS: {
    HEADER_TITLE: "Settings",
    SUB_TITLE: "Manage team roles and their related permissions.",
    DATA_RETENTION: {
      HEADER_TITLE: "Data Retention",
      DETAIL_DESCRIPTION: "Specify data retention settings and how you want your data to be removed after the data retention period. It may take up to 60 days after the retention period for data to be removed from Foresite.",
      STANDARD_DATA_RETENTION_POLICIES: "Standard Data Retention Policies",
      CUSTOM_DATA_RETENTION_POLICIES: "Custom Data Retention Policies",
      RETENTION_POLICY: "Retention Policy",
      REMOVAL_METHOD: "Removal Method",
      REQUIRED_BY: "Required By",
      RETENTION_PERIOD: "Retention Period",
      RETENTION_PERIOD_MONTHS: "Retention Period (Months)",
      CUSTOM_POLICY: "Custom policy",
      ERRORS: {
        RETENTION_POLICY: "Please enter retention policy name",
        REMOVAL_METHOD: "Please select removal method",
        REQUIRED_BY: "Please select required by value",
        RETENTION_PERIOD_MONTHS: "Please enter retention period",
        RETENTION_PERIOD_MONTHS_INVALID: "Please enter retention period greater then 0",
        RETENTION_PERIOD_MONTHS_INVALID_NUMERIC: "Please enter numeric value for retention period"
      }
    }
  },
  NO_RECORDS_TO_DISPLAY: "No records to display",
  OVERVIEW_SECTIONS: {
    OVERVIEW: "overview",
    WORKFLOWS: "workflows",
    CONTRACTS: "contracts",
    UNITS: "Units",
    TASKS: "Tasks",
    PLAN_DETAILS: "Plan Details",
    DIRECTORY: "Directory",
    DATA_ACCESS: "Data Access",
    FILES: "Files",
    CAMPAIGNS: "Campaigns",
    STEPS: "Steps",
    SITES: "Sites",
    PLANS: "Plans",
    SCHEDULE: "Schedule",
    STANDARD_REPORTS: "Standard Reports",
    CUSTOM_REPORTS: "Custom Reports",
    SYSTEMS: "Systems",
    REGULATIONS: "Regulations",
    STANDARDS: "Standards",
    INTERPRETATIONS: "Interpretations",
    TRAINING: "Training",
    RESOURCES: "Resources",
    ASSIGNMENT: "Assignment",
    CONTACT_DETAILS: "Contact Details",
    PURCHASE_ORDERS: "Purchase Orders",
    NOTES_MEDIA: "Note/Media",
    OVERVIEW_TITLE: 'Scheduled Event Overview',
    SAVE_AND_UPDATE: "Save & Update",
    NOTES: "Notes",
    EXTERNAL_THREAD: 'External Thread',
    PLAN: "Plan"
  },
  OVERVIEW_PAGE_SECTIONS: {
    SAMPLING_SECTION: {
      WORKFLOW: "Workflow",
      TEMPLATE: "Template",
      SITE: "Site",
      SAMPLING_RATE: "Sampling Rate",
      WORKFLOW_STATUS: "Workflow Status",
      CAMPAIGN_TYPE: "Campaign Type",
      PROGRAM_TYPE: "Program Type",
      PROGRAM: "Program",
      CAMPAIGN: "Campaign",
      SITE_LOCATION: "Site Location"
    },
    UNITS_SECTION: {
      LIST_TITLE: "All Units",
      UNITS: "Units",
      UNIT: "Unit",
      TYPE: "Type",
      BLOCK: "Block",
      BUILDING: "Building",
      FLOOR: "Floor",
      TASKS: "Tasks",
      SUB_UNIT: "Sub Unit Quantity",
      NO_OF_TASKS: "# of Tasks",
      STATUS: "Status",
      ACTIONS: "Actions",
      NO_UNITS_TO_DISPLAY: "No units to display.",
      ACTIVE_TASK_COUNT_TEXT: "{activeTaskCount} Active Tasks",
      UNIT_OVERVIEW: "Unit Overview",
      SITE: "Site",
      ADDRESS: "Address",
      PRIMARY_CONTACT: "Primary Contact",
      UNABLE_TO_GET_UNIT_DETAIL: "Unable to get unit detail. Please try again.",
      CAMPAIGN: "Campaign",
      CAMPAIGN_TYPE: "Campaign Type",
      PLAN_NAME: "Plan Name",
      PHASE_START_DATE: "Phase Start Date",
      PERMIT_NUMBER: "Permit Number",
      PERMIT_DATE: "Permit Date",
      PERMIT: "Permit",
      PHASE: "Phase",
      LOCATION_DETAILS_SUB_SECTION: "Location Details sub-section",
      ADDITIONAL_LOCATION_DETAILS_SUB_SECTION: "Additional Location details sub-section",
      PLAN_DETAILS_SUB_SECTION: "Plan Details sub-section",
      ADD_UNIT: "Add Unit",
      UNABLE_TO_DELETE_TITLE: "Can't delete unit!",
      SITE_UNIT_DELETE_PREVENTION_MESSAGE: "This unit has active tasks and may not be deleted.",
      EXTERNAL_ID: 'External ID',
      UNIT_PLAN: "Unit Plan",
      PLAN_DETAILS: "Plan Details",
      BLUEPRINT_FILES: "Blueprint File(s)",
      UNIT_SCHEDULE_SECTION: {
        ADD_NEW_LINE_ITEM: "Add New Line Item",
        SAVE_SUCCESS: "Unit schedule item saved successfully",
        ERROR_IN_SAVE: "Error in saving unit schedule item",
        DELETE_SUCCESS: "Unit schedule item deleted successfully",
        ERROR_IN_DELETING: "Error in deleting unit schedule item",
        STATUS_STARTED_SUCCESS: "Unit schedule started successfully.",
        STATUS_STARTED_FAILURE: "Error in starting Unit schedule.",
        STATUS_COMPLETED_SUCCESS: "Unit schedule completed successfully.",
        STATUS_COMPLETED_FAILURE: "Error in completing Unit schedule.",
        START: "Start",
        COMPLETE: "Complete",
        COMPLETED: "Completed",
        ADD_SCHEDULE_FOR_UNIT: "Unit does not have a schedule. To create a schedule please add schedule line items.",
        TABLE_COLUMNS: {
          SCHEDULE_LINE_ITEM: 'Schedule Line Item',
          PREREQS: "Prereqs",
          LAG_TIME_DAYS: "Lag Time (Days)",
          AUTO_START: "Auto Start",
          USOW: "USOW",
          ACTUAL_START_DATE: "Actual Start Date",
          ACTUAL_END_DATE: "Actual End Date",
          PLANNED_START_DATE: "Planned Start Date",
          PLANNED_END_DATE: "Planned End Date",
          STATUS: "Status",
          ACTIONS: "Actions",
          ORGANIZATION: "Organization"
        },
        UNIT_SCHEDULE_LINE_REORDER_SUCCESS: "Unit schedule lines reordered successfully.",
        ERROR_IN_REORDERING_UNIT_SCHEDULE_LINE: "Error in reordering unit schedule lines.",
        WARNING_MESSAGE_FOR_DELETING_SCHEDULE_LINE: "This schedule line item was automatically created based on the master schedule for this plan type. Deleting it may impact automatically triggered workflows or other unintended consequences. Do you wish to proceed?"
      },
      CHECKLIST_RECEIVED_DATE: "Checklist Received Date"
    },
    TASKS_SECTION: {
      FILE_FORMAT_NOT_SUPPORTED_ERROR: "Only files with the following extensions are allowed: png, jpeg.",
      LIST_TITLE: "All Tasks",
      TASK: "Task",
      TASK_ID: "Task Id",
      NO_TASKS: "No Tasks",
      TASKS: "Tasks",
      TYPE: "Type",
      ASSIGNEE: "Assignee",
      ORGANIZATION: "Organization",
      LOCATION: "Location",
      READY_DATE: "Ready Date",
      DUE_DATE: "due date",
      COMPLETED_DATE: "completed date",
      STATUS: "Status",
      TASK_RESULTS: "Task Results",
      CLOSE: "Close",
      UNITS: "Units",
      UNIT_COST: "Unit Cost",
      SCHEDULED: "Scheduled",
      ACTIONS: "Actions",
      NO_RECORDS_TO_DISPLAY: "No tasks to display.",
      TASK_OVERVIEW: "Task Overview",
      ADD_NOTES_MEDIA: "Add Notes / Media",
      MANAGE_PO: "Manage PO's",
      MANAGE_PURCHASE_ORDERS: "Manage Purchase Orders",
      OPTIONAL_NOTE: "Optional Note",
      ADD_PHOTO: "Add Photo / Video",
      SELECT_REASON: "Select Reason",
      REASON_FOR_NOT_READY: "Reason for not ready",
      REASON_FOR_EXCEPTION: "Reason for Exception",
      SUBMIT: 'Submit',
      SITE: "Site",
      ADDRESS: "Address",
      PRIMARY_CONTACT: "Primary Contact",
      PRE_TASK_CHECKLIST: "Pre-Task Chesklist",
      SELECT_ASSIGNEE: "Select Assignee",
      ASSIGN_ACTION_TO: "Assign Action To:",
      SELECT_SITE_ROLE: "Select Site Role",
      NUM_OF_DAYS_DUE: "Num. of Days Due (After Task Completed)",
      SELECT_INDIVIDUAL: "Select Individual",
      REOPEN_TASK: "Reopen Task",
      CLOSED_DATE: "Closed Date",
      CLOSE_TASKS: "Close Tasks",
      PLEASE_SELECT_CLOSED_DATE: "Please select closed date",
      ADVANCED: {
        CAMPAIGN: "Campaign",
        CAMPAIGN_TYPE: "Campaign Type",
        UNIT: "Unit",
        TASK_TYPE: "Task Type",
        DUE_DATE_FROM: "Due Date (From)",
        DUE_DATE_TO: "Due Date (To)",
        SELECT_RANGE_START: "Select Range Start",
        SELECT_RANGE_FINISH: "Select Range Finish",
        START_DATE: "Start Date",
        END_DATE: "End Date"
      },
      UNABLE_TO_GET_TASK_DETAIL: "Unable to get task detail. Please try again.",
      PRE_TASK_CHECKLIST: "Pre-Task Checklist",
      EXCEPTION_ON_HOLD: "Exception/On Hold",
      SAMPLE_DATE: "Sample Date",
      PLAN_SQ_FOOTAGE: "Plan Sq. Footage",
      PRIMARY_WORK_ORDER: "Primary Work Order",
      SECONDARY_WORK_ORDER: "Secondary Work Order"
    },
    DIRECTORY_SECTION: {
      PROGRAM_LIST_TITLE: "Program Directory",
      CAMPAIGN_LIST_TITLE: "Campaign Directory",
      ORGANIZATION_DIRECTORY: {
        INTERNAL_DIRECTORY: "Internal Directory",
        EXTERNAL_DIRECTORY: "External Directory",
        DIRECTORY: "Directory",
        ORGANIZATION_ROLE: "Org Role",
        ADD_NEW_CONTACT: "Add New Contact",
        UPDATE_CONTACT: "Update Contact",
        ADD_EXTERNAL_CONTACT: "Add External Contact",
        UPDATE_EXTERNAL_CONTACT: "Update External Contact",
        SELECT_CONTACT: "Select Contact(s)",
        CREATE_NEW_CONTACT: "Create New Contact",
        ERRORS: {
          ORG_ROLE: "Please select org role.",
          SYSTEM_ACCESS: "Please select system access.",
          ORGANIZATION: "Please select organization.",
          CONTACT: "Please select contact."
        }
      },
      ORGANIZATION_LIST_TITLE: "Organization Directory",
      SITE_LIST_TITLE: "Site Directory",
      DELETE_SITE_DIRECTORY_ALERT: "You have deleted a site role that is being used by a workflow on this site. Please add a {siteRoleName} contact for the workflows to function as expected.",
      NO_RECORDS_TO_DISPLAY: "No directories to display.",
      ROLE: "Role",
      CONTACT: "Contact",
      PHONE: "Phone",
      EMAIL: "Email",
      PRIMARY: "Primary",
      PRIMARY_CONTACT: "Primary Contact",
      ORGANIZATION: "Organization",
      SYSTEM_ROLE: "System Role",
      TITLE: "Title",
      ORG_ROLE: "Org role"
    },
    OVERVIEW_DETAIL_SECTION: {
      IN_PROGRESS: "In Progress",
      SUCCESS: "Success",
      FAILS: "Fails",
      LAST_30_DAYS: "Last 30 Days",
      DETAIL_TITLE: "Details",
      FAILED_ACTIVITIES: "Failed Activities",
      FAILED_ACTIVITIES_LEGEND: "Failed Items (Last 30 days)",
      FAILED_ACTIVITIES_PERFORMANCE: "{count}% increase in performance",
      ACTIVITY_TYPES: "Activity Types",
      TRADE_PRODUCTIVITY: "Trade Productivity",
      TRADE_PRODUCTIVITY_DELTA: "Last {month} Months",
      TRAINING_ACTIVITIES: "Training Activities",
      ASSIGNED_LESSONS: "Assigned Lessons",
      COMPLETED_LESSONS: "Completed Lessons",
      COURSE_PARTICIPATION: "Course Participation",
      KNOWLEDGE_IMPROVEMENT: "Knowledge Improvement",
      RECENT_CAMPAIGN_ACTIVITY: "Recent Campaign Activity",
      RECENT_CAMPAIGN_ACTIVITY_DESCRIPTION: "Progress from campaigns shared with you.",
      TOTAL_PARTICIPANTS: "{count} Participants",
      DUCT_LEAKAGE_TRACKING: "Duct Leakage Tracking",
      HOUSE_INFILTRATION_TRACKING: "House Infiltration Tracking",
      DUCT_LEAKAGE_TRAINING_LEGEND: "Average Duct Leakage Results",
      HOUSE_INFILTRATION_TRACKING_LEGEND: "Avergage Air Changes per Hour (ACH)"
    },
    PROGRAM_OVERVIEW_TAB: {
      PROGRAM_OVERVIEW: "Program Overview",
      TYPE: "Type",
      SITE: "Site",
      STATUS: "Status",
      ADDRESS: "Address",
      PRIMARY_CONTACT: "Primary Contact"
    },
    REQUIRES_ATTENTION: "Requires Attention",
    TASKS_STATUS: {
      HEADER_TITLE: "Tasks - Status",
      TASK_IN_VIEW_COUNT: "Tasks in view: {count}",
      FILTER_RESULTS: "Filter Results",
      REPORT_WIDGET: {
        OPEN_UNACCEPTABLE_ACTIVITIES: "Open Unacceptable Activities",
        OPEN_TRAINING_ACTIVITIES: "Open Training Activities",
        OPEN_ACTIVITIES: "Open Activities",
        TRAINING_INCOMPLETE: "Training Activities Incomplete",
        TRAINING_FAILED: "Training Activities Failed",
        COMPLETED_UNITS: "Completed Units",
        WARRANTY_COST: "Warranty Costs Avoided via Inspection",
        WARRANTY_COST_SAVINGS: "Warranty Cost Savings",
        WARRANTY_CLAIM: "Warranty Claims Avoided via Inspection",
        CORRECTION_COSTS: "Correction Costs Saved",
        CYCLE_TIME_REDUCTION: "Saved Cycle Time (Days)",
        CONSTRUCTION_CALLBACKS: "Construction Call Backs",
        UNIT_CLOSEOUT: "Unit Closeout",
        UNITS_OPEN: "Units Open",
        UNITS_FAILED: "Units Failed",
        UNITS_CERTIFIED: "Units Certified",
        ESTIMATED_TAX_INCENTIVE: "Estimated Tax Incentive",
        QUALITY_SCORE: "Quality Score",
        AVG_ACTIVITY_CYCLE_TIME: "Avg Activity Cycle Time",
        AVG_FAILED_ACTIVITIES: "Avg Failed Activities",
        ACTIVITY_CYCLE_TIME: "Activity Cycle Time",
        FAILURE_EXPLORATION: "Failure Exploration",
        READY_TASKS: "Ready Tasks",
        ASSIGNED_TASKS: "Assigned Tasks",
        RESCHEDULE_NEEDED: "Reschedules Needed",
        IN_PROGRESS_TASKS: "In Progress Tasks",
        OVERDUE_TASKS: "Overdue Tasks",
        IN_PROGRESS_STAR: "In Progress*",
        CONFIRM_SITE_VISIT_OVERDUE: "Confirm Site Visit Overdue",
        CORRECTION_OVERDUE: "Correction Overdue",
        CORRECTION: "Correction",
        VERIFICATION_OVERDUE: "Verification Overdue",
        VERIFICATION: "Verification",
        REASSIGN_CORRECTION_OVERDUE: "Reassign Correction Overdue",
        REASSIGN_CORRECTION: "Reassign Correction",
        VIEW_MORE: 'view more',
        VIEW_LESS: 'view less',
        CONFIRM_SITE_VISIT: "Confirm Site Visit",
        READY_ACTION_COUNT: "Ready Action Count",
        READY_ACTION_OVERDUE_COUNT: "Ready Action Overdue Count"
      }
    },
    ORGANIZATION_OVERVIEW_TAB: {
      ORGANIZATION_OVERVIEW: "Organization Overview",
      TYPE: "Type",
      PARENT: "Parent",
      CATEGORY: "Category",
      ADDRESS: "Address"
    },
    CAMPAIGN_OVERVIEW_TAB: {
      CAMPAIGN_OVERVIEW: "Campaign Overview",
      MASTER_CAMPAIGN: "Master Campaign",
      SHARED_CAMPAIGN: "Shared Campaign",
      TYPE: "Type",
      SITE: "Site",
      STATUS: "Status",
      ADDRESS: "Address",
      PROGRAM: "Program",
      ORGANIZATION: "Organization",
      CAMPAIGN_DESCRIPTION: "Campaign Description",
      CAMPAIGN_OBJECTIVE: "Campaign Objective",
      TRAINING_LEVEL: "Training Level",
      CREATED_BY: "Created By",
      UPDATED_BY: "Updated By",
      SHARED_CAMPAIGNS: {
        SITE_LIST: {
          LAST_VISITED: "Last Visited",
          CAMPAIGN: "Campaign",
          SELECT_SITES: "Select Sites",
          VIEW_CAMPAIGN: "View Campaign",
          CUSTOMIZED: "Customized"
        },
        ADD_SITES: {
          TITLE: "Add Site to Shared Campaign",
          SHARED_CAMPAIGN: "Shared Campaign",
          ADD_TO_CAMPAIGN: "Add To Campaign",
          ORGANIZATION: "Organization",
          FILTER_BY_ORGANIZATION: "Filter by Organization",
          MANAGE_REQUIRED_ROLES: "Manage Required Roles",
          SITE: "Site",
          ASSIGN_REQUIRE_ROLES: "Assign Required Site Roles",
          CONTACT: "Contact",
          SELECT_CONTACT: "Select Contact",
          ERRORS: {
            PLEASE_SELECT_ORGANIZATION: "Please select organization",
            PLEASE_SELECT_CONTACT: "Please select contact"
          }
        },
        ADD_SHARED_CAMPAIGN_TO_SITE: {
          TITLE: "Add Shared Campaign to Site",
          SITE: "Site",
          SELECT_CAMPAIGNS: "Select Campaign(s)",
          ADD_CAMPAIGN_TO_SITE: "Add Campaign to Site"
        },
        APPLY_CAMPAIGN_TO_ALL_UNIT: "Apply Campaign to all unit on selected site(s)"
      }
    },
    WORKFLOWS_SECTION: {
      WORKFLOW_OVERVIEW: "Workflow Overview",
      TEMPLATE: "Template",
      SITE: "Site",
      STAGE: "Stage",
      TAG: "Tag",
      START_DATE: "Start Date",
      UNABLE_TO_GET_WORKFLOW_DETAIL: "Unable to get workflow detail. Please try again.",
      NO_WORKFLOWS_TO_DISPLAY: "No workflows to display.",
      CANADD_WORKFLOW_WARNING: "You can’t add new workflow for this site. If you want to add you have to delete the existing workflow."
    },
    SITES_SECTION: {
      HEADER_TITLE: "Sites",
      SITE: "Site",
      ADD_SITE: "Add Site",
      ADD_SITES: "Add Sites",
      SITE_LIST: {
        LIST_TITLE: "All Sites",
        NO_DATA: "No Sites to display",
        NO_RECORDS_TO_DISPLAY: "No Sites to display. Create your first sites today and begin your journey to more profitable, safe, and compliant projects.",
        LIST_DESCRIPTION: "Sites act as your primary locations, and group revelant units."
      },
      TABLE_COLUMNS: {
        SITES: "Sites",
        ADDRESS: "Address",
        STATUS: "Status",
        TYPE: "Type",
        ORGANIZATION: "Organization",
        PROJECT: "Project",
        WORKFLOWS: "Workflows",
        UNITS: "Units",
        SITE: "Site",
        NUMBER_OF_UNITS: "Number of units",
        NUMBER_OF_CAMPAIGNS: "Number of Campaigns (Active campaigns only)",
        TAG: "Tag",
        CAMPAIGN_NAME: "Campaign Name"
      },
      SITE_OVERVIEW_TAB: {
        SITE_OVERVIEW: "Site Overview",
        ORGANIZATION: "Organization",
        TYPE: "Type",
        STATUS: "Status",
        ADDRESS: "Address",
        PRIMARY_CONTACT: "Primary Contact",
        PROJECT: "Project",
        POINT_OF_CONTACT: "Point of Contact",
        EXTERNAL_ID: "External ID",
        CUSTOMER_ID: "Customer ID",
        TAG: "Tag",
        AHJ: "AHJ"
      },
      SITE_LOCATION: "Site Location",
      ACTIVE_SITES: "Active Sites"
    },
    SAMPLING_OVERVIEW_TAB: {
      SITE: "Site",
      START_DATE: "Start Date",
      SAMPLING_RATE: "Sampling Rate",
      CURRENT_RATE: "Current Rate",
      TOTAL_UNITS_SAMPLED: "Unit Sampled {sampled} of {total}",
      TARGET_SAMPLE_RATE: "Target Sample Rate",
      SITE_ADDRESS: "Site Address",
      ORGANIZATION: "Organization",
      PROGRAM: "Program",
      CAMPAIGN: "Campaign",
      WORKFLOW: "Workflow"
    },
    CONTRACTS: {
      CONTRACT_MODAL: {
        MANAGE_PRODUCT: "Manage Product",
        PRODUCT_ID: "Product ID",
        PRODUCT_NAME: "Product Name",
        UNIT_AMOUNT: "Unit Amount",
        ADDITIONAL_FEE: "Additional Fee",
        ENTER_VALUE: "Enter Value",
        ENTER_ID: "Enter ID",
        SELECT_FEE: "Select Fee",
        SAVE_AND_ADD_NEW: "Save & Add New",
        SAVE_AND_FINISH: "Save & Finish",
        CONTRACT_WORKFLOW_ORDER: "Contract workflow order",
        TOTAL_UNIT_AMOUNT: "Total Unit Amount",
        PRODUCT_TYPE_REQUIRED: "Please select additional fee product type.",
        PRODUCT_BILLING_ID_REQUIRED: "Valid product id required to be entered to save contract.",
        PRODUCT_UNIT_AMOUNT_REQUIRED: "Product unit amount is required to save contract."
      },
      CONTRACT_DETAILS: {
        NO_RECORDS_TO_DISPLAY: "No Data Found",
        NO_DATA_FOR_FILTERS: 'You have no contract details matched with filters you applied.',
        HEADER_TITLE: "Contract Line Items",
        PRODUCT_NOT_FOUND_ALERT_TEXT: "Product not found - the product ID entered does not match CRM.",
        PRODUCT_MISMATCH_ALERT_TEXT: "Product mismatch - the product ID entered is not associated with this project in CRM; please confirm before continuing.",
        CONTRACT_LIST: {},
        TABLE_COLUMNS: {
          WORKFLOW_NAME: "Workflow Name",
          PRODUCT_ID: "Product ID",
          PRODUCT_NAME: "Product Name",
          UNIT_AMOUNT: "Unit Amount",
          ADD_PRODUCT_ID: "Add Product ID"
        }
      }
    },
    ORGANIZATIONAL_SITES: {
      SITE: "Site",
      ORGANIZATION: "Organization",
      CITY: "City",
      STATE: "State",
      SUB_REGION: "Sub Region",
      SITE_STATUS: "Site Status",
      NUMBER_OF_UNITS: "Number of units",
      UNITS_COMPLETED: "Units Completed",
      UNITS_IN_PROGRESS: "Units InProgress",
      UNITS_NOT_STARTED: "Units Not Started",
      TOTAL_ACTIONS: "Total Actions",
      OPEN_ACTIONS: "Open Actions",
      CLOSED_ACTIONS: "Closed Actions",
      LAST_VISIT: "Last Visit",
      PROGRESS: "Progress",
      TITLE: "Organization Sites",
      PERCENTAGE_COMPLETED: "Percentage Completed",
      PERCENTAGE_INPROGRESS: "Percentage InProgress",
      PERCENTAGE_NOT_STARTED: "Percentage Not Started"
    }
  },
  SITES_SECTION: {
    SETUP: {
      HEADER_TITLE: "Sites",
      OVERVIEW: "overview",
      LOCATION: "Location",
      PLANS: "Plans",
      DIRECTORY: "Directory",
      UNITS: "Units",
      REVIEW: "Review",
      ADD_NEW: "Add New",
      EDIT_SITE_HEADER: "Edit - {siteName}"
    },
    SITE_DETAILS: {
      TITLE: "Site Details",
      SITE_NAME: "Site Name",
      BUILDING_TYPE: "Building Type",
      ORGANIZATION: "Organization",
      POINT_OF_CONTACT: "Point of Contact",
      STATUS: "Status",
      CONSTRUCTION_START_DATE: "Construction Start Date",
      PROJECT: "Project",
      TRACT: "Tract",
      BUILDING_ID: "Building ID",
      SITE_DESCRIPTION: "Site Description",
      TRACT: "Tract",
      BUILDING_ID: "Building ID",
      ADD_PROJECT: "Add Project",
      EXTERNAL_ID: 'External ID',
      CUSTOMER_ID: "Customer ID",
      AHJ: "AHJ",
      SYNCHRONIZED: 'Synchronized',
      VALIDATIONS: {
        SITE_NAME: "Please select Site Name",
        BUILDING_TYPE: "Please select Building Type",
        ORGANIZATION: "Please select Organization",
        POINT_OF_CONTACT: "Please select Point of contact",
        STATUS: "Please select Status",
        PROJECT: "Please select project"
      }
    },
    LOCATION_DETAILS: {
      ERRORS: {
        STREET_ADDRESS: "Please enter street address",
        COUNTRY: "Please select country",
        CITY: "Please enter city name",
        STATE: "Please select state",
        ZIP_CODE: "Please enter zipcode",
        PHONE: "Please enter valid phone number"
      }
    },
    SITE_DIRECTORY: {
      TITLE: 'Site Directory',
      ADD: "Add",
      UPDATE: "Update",
      DIRECTORY_TEXT: "Create and assign your site roles below. You may add as many roles as you'd like.",
      EDIT_DIRECTORY_TEXT: "Edit and assign your site roles below. You may add as many roles as you'd like.",
      LOWERCASE_SITE_ROLE: "Site Role",
      PRIMARY_CONTACT: "Primary Contact"
    },
    SITE_UNITS: {
      EDIT_UNIT: "Edit Unit",
      ADD_UNITS_TO_SITE: "Add Units to Site",
      EDIT_MODE_TITLE: "Construction sites are location on which multiple units can be built.",
      TITLE: "Units represent individual areas of construction on your site.",
      PRIMARY_CONTACT: "Primary Contact",
      SYNCHRONIZED_WARNING: "This site is synchronized with an external portal! Adding a unit will break this synchronization and will require working with the support team to reestablish. Please confirm you want to manually add a new unit."
    },
    REVIEW: {
      TITLE: "Review",
      ORGANIZATION: "Organization",
      POINT_OF_CONTACT: "Point of Contact",
      STATUS: "Status",
      PROJECT: "Project",
      ADDRESS: "Address",
      TYPE: "Type",
      PLANS: "Plans",
      UNITS: "Units",
      ROLES: "Roles"
    },
    SITE_PLAN_SCHEDULE: {
      TABLE_COLUMNS: {
        SCHEDULE_LINE_ITEM: "Schedule Line Item",
        PREREQS: "Prereqs",
        LAG_TIME_DAYS: "Lag Time (Days)",
        AUTO_START: "Auto Start",
        USOW: "USOW",
        ORGANIZATION: "Organization"
      },
      PLAN_NAME: "Plan Name",
      ADD_NEW_LINE_ITEM: "Add New Line Item",
      PLAN_SCHEDULE: "Plan Schedule",
      DELETE_SCHEDULE_MODAL_MESSAGE: "Deleting would affect the workflows that are using this line item as a trigger and affect all schedule line items that use this as a prereq. Do you want to continue?",
      LIST_MESSAGES: {
        NO_PLAN_SELECTED: "Please select a site plan to view its schedule.",
        ADD_SCHEDULE_FOR_PLAN: "Selected plan does not have a schedule. To create a schedule please add schedule line items.",
        NO_DATA_FOR_FILTERS: 'You have no schedules matched with filters you applied.',
        LOADING: 'Loading',
        SITE_HAS_NO_PLAN: "There are currently no available plans. To view a plan's schedule, you must first add plans and then select a plan."
      },
      VALIDATIONS: {
        SCHEDULE_LINE_ITEM: "Schedule Line Item is required."
      },
      SITE_PLAN_SCHEDULE_LINE_REORDER_SUCCESS: "Site plan schedule lines reordered successfully.",
      ERROR_IN_REORDERING_SITE_PLAN_SCHEDULE_LINE: "Error in reordering site plan schedule lines."
    }
  },
  IMAGE_VIEW_MODAL: {
    PHOTO_DETAILS: "Photo Details",
    LOCATION: "Location",
    TIME: "Time",
    COPY: "Copy",
    TEXT_COPIED_SUCCESSFULLY: "Text copied successfully."
  },
  VIEW_ALL: "View All",
  ANALYZE: "Analyze",
  ADD_WORKFLOW: "Add Workflow",
  EXPORT: "Export",
  EXPORT_AS: "Export As",
  CSV: "CSV",
  PDF: "PDF",
  DOCX: "DOCX",
  TASK_RESULT_EXPORT: "Task Result Export",
  FAILED_INSPECTION_EXPORT: "Failed Inspection Export",
  AGING_REPORT: "Aging Report",
  OPEN_ACTIONS_AGING_BY_UNIT: "Open Actions Aging By Unit",
  FIT_AND_FINISH_PERFORMANCE: "Fit And Finish Performance Report",
  FIT_AND_FINISH_CLOSEOUT: "Fit And Finish CloseOut Report",
  INSPECTION_REPORT: "Inspection Report",
  SITE_VISIT_REPORT: "Site Visit Report",
  REGIONAL_SCHEDULING_REPORT: "Regional Scheduling Report",
  VIEWING: "Viewing",
  VIEW: "View",
  DEFAULT: "Default",
  ENTER_NAME: "Enter Name",
  HOME_PAGE: {
    REPORTS: {
      HEADER_TITLE: "Analytics"
    },
    REPORTS_DESCRIPTION: "Run programs to quickly create campaigns across your site for your choosen solution.",
    HEADER_TITLE: "Home",
    HOME_PAGE_WELCOME: "Welcome back {userName}.",
    HOME_PAGE_DESCRIPTION: {
      ACTIONS_PLURAL_TEXT: "actions",
      ACTIONS_SINGULAR_TEXT: "action",
      NOTIFICATIONS_PLURAL_TEXT: "notifications",
      NOTIFICATIONS_SINGULAR_TEXT: "notification",
      TASKS_PLURAL_TEXT: "tasks",
      TASKS_SINGULAR_TEXT: "task",
      DESCRIPTION: "You have {notificationCount} new {notificationValue}, {actionCount} {actionValue} and {taskCount} {taskValue}"
    },
    SITES: {
      TITLE: "Sites",
      NO_SITES: "No Sites",
      COMPLETE_TASKS: "Complete Tasks",
      ACTIVE_CAMPAIGN: "Active Campaign",
      IN_PROGRESS_TASKS: "In-progress Tasks",
      ACTIVE_PROGRAMS: "Active Programs",
      IN_QUEUE: "In Queue",
      UNITS: "Units",
      NO_DATA_MSG: "No sites found."
    },
    TABLE_COLUMNS: {
      TASK: "Task",
      TASK_TYPE: "Task Type",
      LOCATION: "Location",
      ASSIGNEE: "Assignee",
      STATUS: "Status",
      SITE: "Site",
      UNIT: "Unit"
    },
    TASK_TABLE_TITLE: "Scheduled Tasks ({count})",
    ACTION_TABLE_TITLE: "Open Actions ({count})"
  },
  WORKFLOW_ADD_TRAINING_SAGA: {
    ADDED_SUCCESS: "Training added successfully.",
    ERROR_IN_ADDING: "Error in adding training",
    UPDATED_SUCCESS: "Training details updated successfully",
    ERROR_IN_UPDATING: "Error in updating training details",
    ERROR_IN_FETCHING_TRAINING_DETAIL: 'Error in fetching training details.',
    DELETED_SUCCESS: "Training deleted successfully.",
    ERROR_IN_DELETING: "Error in deleting training",
    ACTIVITIES_ADDED_SUCCESS: "Activities added to Training successfully.",
    ERROR_IN_ADDING_ACTIVITIES: "Error in adding activities to training"
  },
  SAMPLING: {
    HEADER_TITLE: "Sampling",
    OPERATIONS: "Operations",
    HEADER_DESCRIPTION: "Manage the inspection rate of your job sites.",
    SITE_LIST_TITLE: "Select a Site",
    SITE_LIST_DESCRIPTION: "All your active workflows are grouped below by site. Select a site to view its workflows.",
    WORKFLOW_LIST_HEADER_TITLE: "All Workflows",
    WORKFLOW_LIST_TITILE: "Select a Workflow to Sample",
    WORKFLOW_LIST_DESCRIPTION: "Select a workflow to continue to it's sampling group pages.",
    TABLE_COLUMNS: {
      WORKFLOW: "Workflow",
      TEMPLATE: "Template",
      STATUS: "Status",
      START_DATE: "Start Date",
      TASKS: "Tasks",
      UNIT: "Unit",
      PLAN: "Plan",
      SITE: "Site",
      SAMPLING_RATE: "Sampling Rate",
      CURRENT_RATE: "Current Rate",
      REMAINING_UNITS: "Remaining Units to Group",
      READY_FOR_GROUPING: "Ready for Grouping",
      SAMPLE_INSPECT: "Sample/Inspect",
      READINESS_DATE: "Readiness Date",
      DATE: "Date",
      CAMPAIGN: "Campaign",
      TASK_NAME: "Task Name:",
      TASK_STATUS: "Task Status:",
      READY_FOR_GROUP: "Count of units ready to be added to sample group – ",
      TOTAL_UNITS: "Total Units:",
      UNITS_ADDED: "Units added to sample group:",
      UNITS_REMAINING: "Units remaining:",
      ASSIGNMENT: "Needs Assignment",
      LAST_TASK_COMPLETED: "Last Task Completed",
      INCOMPLETED_TASKS: "Incompleted Tasks",
      OBSERVED_TASKS: "Observed Tasks",
      SAMPLED_TASKS: "Sampled Tasks"
    },
    SAMPLE_UNITS: "Sample Units",
    SAMPLE_UNITS_DESCRIPTION: "Use this space to assign or sample units in your task. Select units, add them to a group, then select sample or inspect. Note: You can drag units into a group.",
    AVAILABLE_UNITS_TO_SAMPLE: "Available units to sample",
    SAMPLE_GROUPS_ACTIVE: "Sample Groups - Active",
    SAMPLE_GROUPS_COMPLETED: "Sample Groups - Completed",
    UNIT: "Unit",
    UNIT_TYPE: "Unit Type",
    TASK_STATUS: "Task Status",
    LOCATION_DETAILS: "Location Details",
    ADDITIONAL_LOCATION_DETAILS: "Additional Location Details",
    BLOCK: "Block",
    BUILDING: "Building",
    FLOOR: "Floor",
    PLAN_DETAILS: "Plan Details",
    PLAN_NAME: "Plan Name",
    PERMIT: "Permit #",
    PHASE: "Phase",
    NONE: "None",
    NO_DATA_MSG: "No workflows to display.",
    SAMPLE_GROUPS: "Sample Groups",
    SAMPLE_GROUP: "Sample Group",
    ADD_GROUP: "Add Group",
    DROP_TO_SAMPLE: "Drop Here to Sample",
    DROP_TO_INSPECT: "Drop Here to Inspect",
    ADD_TO_NEW_GROUP: "Add to New Group",
    NO_GROUP_UNITS_MESSAGE: "Select Units to Add to Group.",
    INSPECT: "Inspect",
    SAMPLE: "Sample",
    DELETE: "Delete",
    REMOVE: "Remove",
    REMOVE_SAMPLE_GROUP: "Remove Sample Group",
    CLOSE_SAMPLE_GROUP: "Close Sample Group",
    REOPEN_SAMPLE_GROUP: "Reopen Sample Group",
    INSPECTED: "{count} Inspected",
    SAMPLED: "{count} Sampled",
    NOT_READY_UNITS: "Units with tasks that are in 'Not Ready' cannot be added to a sample group, only units that are ready can be added to a sample group.",
    CANCELED_UNITS: "Units with tasks that are 'Canceled' cannot be added to a sample group.",
    WORKFLOW_SAMPLE_SUMMARY_TITLE: "Workflow Sample Summary"
  },
  SAMPLING_GROUP_SAGAS: {
    ADDED_SUCCESS: "Sampling group added successfully.",
    ERROR_IN_ADDING: "Error in adding sampling group",
    DELETED_SUCCESS: 'Sampling group deleted successfully.',
    ERROR_IN_DELETING: 'Error in deleting sampling group',
    UPDATED_OPEN_GROUP_SUCCESS: "Sampling group open successfully",
    ERROR_IN_UPDATING_OPEN_GROUP: "Error in opening sampling group",
    UPDATED_CLOSE_GROUP_SUCCESS: "Sampling group close successfully",
    ERROR_IN_UPDATING_CLOSE_GROUP: "Error in closing sampling group"
  },
  SAMPLING_GROUP_UNITS_SAGAS: {
    REMOVED_SUCCESS: 'Sampling group units removed successfully.',
    ERROR_IN_REMOVING: 'Error in removing sampling group units',
    ADDED_SUCCESS: "Sampling group units added successfully.",
    ERROR_IN_ADDING: "Error in adding sampling group units"
  },
  SAMPLING_GROUP_UNITS_TYPE_SAGAS: {
    UPDATED_SUCCESS: "Sampling group unit type updated successfully.",
    ERROR_IN_UPDATING: "Error in updating sampling group unit type",
    ADDED_SUCCESS: "Sampling group unit type added successfully.",
    ERROR_IN_ADDING: "Error in adding sampling group unit type"
  },
  OPERATIONS: "Operations",
  SCHEDULE: "Schedule",
  DELETE: "Delete",
  ALL_ACTIVITIES: {
    HEADER_TITLE: "Activities",
    ACTIVITY: "Activity",
    ACTIVITY_LIST: {
      LIST_TITLE: "All Activities",
      NO_RECORDS_TO_DISPLAY: "No activities to display.  Create your first sites today and begin your journey to more profitable, safe, and compliant projects.",
      LIST_DESCRIPTION: "Activities describe a single task such an observation or training lesson. Assign actitivies to contacts through camaigns or programs."
    },
    ACTIVITY_DETAILS: {
      ACTIVITY_NAME: "Activity Name",
      ACTIVITY_ID: "Activity Id",
      ACTIVITY_VERSION: "Activity Version",
      ACTIVITY_TYPE: "Activity Type",
      STANDARD_ACTIVITY: "Standard Activity"
    },
    OBSERVATION_ACTIVITY_DETAILS: {
      RISK_LEVEL: "Risk Level",
      RESPONSIBLE_SITE_ROLE: "Responsible Site Role",
      ASSEMBLY: "Assembly",
      ASSEMBLY_TYPE: "Assembly Type",
      MAIN_COMPONENT: "Main Component",
      SUB_COMPONENT: "Sub Component",
      MANUFACTURER: "Manufacturer",
      BRAND: "Brand",
      REGULATION_NAME: "Regulation Name",
      STANDARD_NAME: "Standard Name"
    },
    TRAINING_ACTIVITY_DETAILS: {
      COURSE: "Course",
      SUBJECT: "Subject",
      CHAPTER: "Chapter",
      LESSON: "Lesson",
      SOURCE: "Source",
      AUTHOR: "Author"
    },
    ACTIVITY_DETAIL_CARD_TITLE: "Activity Overview",
    ACTIVITY_OVERVIEW_DETAIL_DESCRIPTION: "Build templates to create a list of activities. Use templates in campaigns and programs to assign and send your template into the field.",
    NO_ACTIVITIES_DATA_MESSAGE: "No activities to display.  Create your first sites today and begin your journey to more profitable, safe, and compliant projects.",
    ADD_NEW_ACTIVITY: "Add New Activity",
    ADD_NEW_ACTIVITY_DESCRIPTION: "Select which type of activity you’d like to create, then click “Next” to create your activity.",
    ACTIVITY_DETAILS_TEXT: "Activity Details",
    TABLE_COLUMNS: {
      ACTIVITY_NAME: "Activity Name",
      ACTIVITY_TYPE: "Activity Type",
      ACTIVITY_ID: "Activity Id",
      VERSION: "Version",
      STANDARD: "Standard",
      TEMPLATES_USED: "Templates Used"
    }
  },
  CHECKLIST_ACTIVITIES_GROUP_SAGA: {
    ADDING_GROUP_SUCCESS: "Checklist activities group added successfully.",
    ERROR_IN_ADDING_GROUP: "Error in adding checklist activities group.",
    REMOVED_SUCCESS: 'Checklist activities group removed successfully.',
    ERROR_IN_REMOVING: 'Error in removing checklist activities group.',
    ADDING_ACTIVITIES_TO_GROUP_SUCCESS: "Checklist activities added to group successfully.",
    ERROR_IN_ADDING_ACTIVITIES_TO_GROUP: "Error in adding checklist activities to group.",
    UPDATE_ACTIVITY_GROUP_SUCCESS: "Checklist activities group updated successfully.",
    ERROR_IN_UPDATING_GROUP: "Error in updating checklist activities group.",
    ACTIVITY_REMOVED_SUCCESS: "Checklist activity removed from group successfully.",
    ERROR_IN_REMOVING_ACTIVITY: "Error in removing checklist activity from group.",
    GROUP_ACTIVITIES_REORDER_SUCCESS: "Group activities reordered successfully.",
    ERROR_IN_REORDERING_GROUP_ACTIVITIES: "Error in reordering group activities.",
    ACTIVITY_GROUP_REORDER_SUCCESS: "Checklist activities group reordered successfully.",
    ERROR_IN_REORDERING_ACTIVITY_GROUP: "Error in reordering checklist activities group."
  },
  TAG: "Tag",
  SELECT_TAG: "Select Tag",
  SKILL: "Skill",
  SELECT_SKILL: "Select Skill",
  SERVICE_TAG: "Service",
  SELECT_SERVICE_TAG: "Select Service Tag",
  COLOR_PICKER_LABEL: "Select Color",
  CAMAPIGN_CONTRACT_SAGA: {
    ADDED_SUCCESS: "Campaign contract added successfully.",
    ERROR_IN_ADDING: "Error in adding campaign contract.",
    UPDATED_SUCCESS: "Campaign contract updated successfully.",
    ERROR_IN_UPDATING: "Error in updating campaign contract.",
    DELETED_SUCCESS: 'Campaign contract deleted successfully.',
    ERROR_IN_DELETING: 'Error in deleting campaign contract',
    PRODUCT_DETAIL_CLEARED_SUCCESS: 'Product details cleared successfully.',
    ERROR_IN_CLEARING_PRODUCT_DETAIL: 'Error in clearing product details.'
  },
  FILTER_TABLE_MODAL: {
    HEADER_TITLE: "Filter Table",
    APPLY_SELECTIONS: "Apply Selections",
    SAVED_FILTERS: "Saved Filters",
    TABLE_COLUMNS: "Table Columns",
    SAVE_AS_NEW_FILTER: "Save as New Filter",
    FILTER_NAME: "Filter Name",
    ROWS_PER_PAGE: "Rows per Page",
    SAVE_AS_GLOBAL_FILTER: "Save as Global Filter",
    SAVE_AS_DEFAULT_FILTER: "Save my Default Filter",
    ADD_AS_SAVED_FILTER: "Add as Saved Filter",
    EDIT_FILTER: "Edit Filter",
    UNDO_CHANGES: "Undo Changes",
    FILTER: "Filter",
    ERRORS: {
      FILTER_NAME_REQUIRED: "Filter name is required to save filter."
    },
    SEARCH_NAME: "Search Name",
    RESET_ALL: "Reset All",
    RESET: "Reset",
    NO_SAVED_FILTERS: "No Saved Filters."
  },
  CALENDAR: {
    SELECT_MARKET: "Select Market",
    REGIONS: "Regions",
    SELECT_SITES: "Select Sites",
    SELECT_WORKFLOWS: "Select Workflows",
    SELECT_CONTACTS: "Select Contacts",
    CONTACTS: "Contacts",
    WORKFLOWS: "Workflows",
    SITES: "Sites",
    ORGANIZATION: "Organization",
    SITE: "Site",
    CAMPAIGN: "Campaign",
    ASSIGNEE: "Assignee",
    LOCATION: "Location",
    TASKS: "Tasks"
  },
  SEARCH_NAME: "Search Name",
  MONTH: "Month",
  WEEK: "Week",
  DAY: "Day",
  BY_CONTACT: "By Contact",
  BY_CAMPAIGN: "By Campaign",
  GO_TO_DAY_VIEW: "Go To Day View",
  SIGNATURE_PAD: {
    SAVE: "Save Signature",
    CANCEL: "Cancel ",
    CLEAR: "Clear",
    SIGNATURE_TITLE: 'Please add your signature'
  },
  HOME_PAGE_FILTER: {
    FILTER_BY_ORGANIZATION: "Filter by organization",
    ORGANIZATION: "organization",
    FILTER_BY_SITE: "Filter by site",
    SITE: "site",
    FILTER_BY_UNIT: "Filter by unit",
    UNIT: "unit"
  },
  COMMON_TAG_COLLECTION_SAGA: {
    ADDED_SUCCESS: "Tag added/updated successfully.",
    ERROR_IN_ADDING: "Error in adding/updating tag.",
    EMAIL_SEND_SUCCESS: "Email sent successfully.",
    ERROR_IN_SENDING_EMAIL: "Error in sending email."
  },
  COMMON_NOTES_SAGA: {
    UPDATE_NOTE_SUCCESS: "Notes updated successfully.",
    ERROR_IN_UPDATING_NOTE: "Error in updating note."
  },
  COMMON_SAGA: {
    UPSERT_CC_CONTACTS_SUCCESS: "CC Contacts added/updated successfully.",
    ERROR_IN_UPSERTING_CC_CONTACTS: "Error in adding/updating CC Contacts."
  },
  COMMUNICATION_LOGS: {
    HEADER_TITLE: "Communication Log",
    HEADER_DESCRIPTION: "View and manage the tags within your organization.",
    VIEW_MESSAGE: "View Message",
    SENT_FROM: "Sent From",
    SUBJECT: "Subject",
    SENT_TO: "Sent To",
    EMAIL_MESSAGE: "Email Message",
    SMS_MESSAGE: "SMS Message",
    MESSAGE: "Message",
    RESEND_MESSAGE: "Resend Message",
    CLOSE: "Close",
    CHANNEL: 'Channel',
    PLEASE_CONFIRM_TO_RESEND_MESSAGE: "Please confirm to resend message",
    TABLE_COLUMNS: {
      TYPE: "Type",
      ORGANIZATION: "Organization",
      SITE: "Site",
      UNIT: "Unit",
      TASK_ID: "Task Id",
      DATE: "Date",
      SENT_TO: "Sent To",
      VIEW: "View"
    },
    COMMUNICATION_LOG_SAGA: {
      MESSAGE_SENT_SUCCESSFULLY: "Message Sent Successfully",
      ERROR_IN_SENDING_MESSAGE: "Failed to send Message"
    }
  },
  TEXT_JSON_VIEW: {
    TEXT: "Text",
    VIEW_JSON_OBJECT: "View JSON Object",
    INVALID_JSON_DATA: "Invalid JSON Data. Please update JSON Data.",
    JSON_DATA_VIEW_PLACEHOLDER: "No JSON Object to be shown here."
  },
  ADMINISTRATIVE_TASK: "Administrative Task",
  EMAIL_MODAL: {
    SEND_EMAIL: "Send Email",
    RECIPIENTS: "Recipients",
    ENTER_RECIPIENTS_DESC: "Enter email or select contacts, roles",
    SUBJECT: "Subject",
    ENTER_EMAIL_SUBJECT_LINE: "Enter email subject line...",
    MESSAGE: "Message",
    ENTER_EMAIL_MESSAGE: "Enter email message...",
    FILES_ATTACHED: "{numberOfFiles} Files Attached",
    SHOW_ALL_FILES: "Show All Files",
    HIDE_FILES: "Hide Files",
    SEND: "Send",
    VALIDATIONS: {
      RECIPIENT: "Please add recipient",
      SUBJECT: "Please enter email subject line",
      MESSAGE: "Please enter email message",
      INVALID_EMAIL: "Following email addresses are invalid - {invalidEmailAddresses}. Please make sure that all addresses are properly formed."
    },
    SITE_DIRECTORY: "Site Directory",
    CLOSE_SITE_DIRECTORY: "Close Site Directory",
    ORG_DIRECTORY: "Org Directory",
    CLOSE_ORG_DIRECTORY: "Close Org Directory",
    ORGANIZATION_DIRECTORY: "Orgnization Directory",
    INTERNAL_CONTACTS: "Internal Contacts",
    EXTERNAL_CONTACTS: "External Contacts",
    DIRECTORY_LIST: {
      NO_DATA: "You have no contacts to select, to use as recipient.",
      NO_DATA_FOR_FILTERS: "You have no contacts matched with filters you applied."
    },
    RECIPIENT_INFO: "Note: For manual email adresses, enter email address and press enter key."
  }
}

export const ENGLISH_TAB_LANGUAGE_CONSTANTS = {
  RESPONSE_NAME_ERROR: "Please define response name",
  CHOICE: "Please enter choice",
  PLEASE_ENTER_BUTTON_NAME: "Please enter button name",
  PLEASE_SELECT_BUTTON_COLOR: "Please select button color",
  DUPLICATE_OTHER_OPTION: "You can't enter duplicate other option",
  PRIMARY_RESPONSE: "Primary Response",
  PRIMARY_RESPONSE_ERROR: "Select response type to create your primary response",
  CONDITIONAL_RESPONSES: "Conditional Responses",
  UPDATE_ACTIVITY: "Update Activity",
  ADD_ACTIVITY: "Add Activity",
  RESPONSE_NAME: "Response Name",
  DEFINE_ACTIVITY: "Define Activity",
  ACTIVITY: "Activity",
  SELECT_DATE: "Select Date",
  INSERT_RESPONSE_HERE: "Insert Response Here",
  ENTER_NUMBER: "Enter Number",
  PHOTO_RESPONSE_NOTE: "Note: The observer will be prompted to take a photo when presented with this response type.",
  UPDATE_RESPONSE: "Update Response",
  ADD_RESPONSE: "Add Response",
  CANCEL: "Cancel",
  CONDITION: "Condition",
  TYPE: 'Type',
  CRITERIA: "Criteria",
  NUMERIC_TYPE: "Numeric Type",
  NUMERIC_UNIT: "Numeric Unit",
  MIN_RANGE: "Min Range",
  MAX_RANGE: "Max Range",
  CONDITIONAL_RESPONSE: "Conditional Response",
  MIN_IN_RANGE: "Minimum value should be in range",
  MAX_IN_RANGE: "Maximum value should be in range",
  NUMERIC_MIN: "Please enter minimum range value",
  NUMERIC_MAX: "Please enter maximum range value",
  VALIDATE_NUMERIC: "Minimum value should be less the Maximum Value",
  NUMERIC_RESPONSE_CONDITION: "Please enter number",
  PLEASE_SELECT_DATE: "Please select date",
  RESPONSE_CONDITION: "Please select response condition",
  NUMERIC_TYPE_ERROR: "Please select numeric type",
  VALIDATE_MIN_NEGATIVE: "Minimum value should not be negative",
  VALIDATE_MAX_NEGATIVE: "Maximum value should not be negative",
  UNIT_TYPE: "Please select unit type",
  ACTIVITY_ERROR: "Please define activity",
  VERSION_ID: "Version Id is required to update activity. Please select version id.",
  ACTIVITY_RESPONSE: "Please add atleast one response for activity",
  OPTION_ERROR: "Please enter option",
  SAVE_CHANGES: "Save Changes",
  NUMERIC_MIN_DECIMAL: "Minimum range should not be decimal",
  NUMERIC_MAX_DECIMAL: "Maximum range should not be decimal",
  DESCRIPTION: "Description",
  INSERT_RESPONSE_HERE: "Insert Response Here",
  PLEASE_SELECT_CRITERIA: "Please Select Criteria",
  PLEASE_ENTER_VALID_ACTIVITY_HTML: "Please Enter Valid Html",
  RESPONSE_VALUE: "Response value",
  RESPONSE_VALUE_ERROR: "Please define response value",
  ADD_ANOTHER_BUTTON: "Add another button",
  BUTTON: "Button",
  BUTTON_COLOR: "Button Color",
  TEXT_COLOR: "Text Color",
  SELECT_CUSTOM_COLOR: "Select Custom Color",
  FILE_RESPONSE_NOTE: "Note: The observer will be prompted to upload file when presented with this response type.",
  SIGNATURE_RESPONSE_NOTE: "Note: The observer will be prompted to add their signature when presented with this response type.",
  ADD_DESCRIPTION_HERE: "Add description here...",
  DEFAULT_VALUE: "Default Value",
  RESULT_VALUE: "Result Update",
  ALLOW_NA: "Allow N/A"
}

export const SPANISH_TAB_LANGUAGE_CONSTANTS = {
  CRITERIA: "Criteria",
  RESPONSE_NAME_ERROR: "Defina el nombre de la respuesta",
  CHOICE: "Por favor ingrese la opción",
  DUPLICATE_OTHER_OPTION: "No puede ingresar otra opción duplicada",
  PRIMARY_RESPONSE: "Respuesta primaria",
  PRIMARY_RESPONSE_ERROR: "Seleccione el tipo de respuesta para crear su respuesta principal",
  CONDITIONAL_RESPONSE: "Respuesta condicional",
  CONDITIONAL_RESPONSES: "Respuestas condicionales",
  UPDATE_ACTIVITY: "Actualizar actividad",
  ADD_ACTIVITY: "Agregar actividad",
  RESPONSE_NAME: "Nombre de respuesta",
  DEFINE_ACTIVITY: "Escribe el mensaje en español",
  ACTIVITY: "Indicador de Punto de Control",
  BOOLEAN_OPTION_PLACEHOLDER: "Opción de traducción",
  SELECT_DATE: "Seleccione Fecha",
  INSERT_RESPONSE_HERE: "Insertar respuesta aquí",
  ENTER_NUMBER: "Ingresar Número",
  PHOTO_RESPONSE_NOTE: "Nota: Se le pedirá al observador que tome una foto cuando se le presente este tipo de respuesta.",
  UPDATE_RESPONSE: "Actualizar respuesta",
  ADD_RESPONSE: "Añadir respuesta",
  CANCEL: "Cancelar",
  CONDITION: "Condición",
  TYPE: 'Tipo',
  NUMERIC_TYPE: "Tipo Numérico",
  NUMERIC_UNIT: "Unidad Numérica",
  MIN_RANGE: "Rango mínimo",
  MAX_RANGE: "Rango máximo",
  MIN_IN_RANGE: "El valor mínimo debe estar dentro del rango",
  MAX_IN_RANGE: "El valor máximo debe estar dentro del rango",
  NUMERIC_MIN: "Por favor, introduzca el valor de rango mínimo",
  NUMERIC_MAX: "Por favor, introduzca el valor de rango máximo",
  VALIDATE_NUMERIC: "El valor mínimo debe ser menor que el valor máximo",
  NUMERIC_RESPONSE_CONDITION: "Por favor ingrese el número",
  PLEASE_SELECT_DATE: "Por favor seleccione la fecha",
  RESPONSE_CONDITION: "Seleccione la condición de respuesta",
  NUMERIC_TYPE_ERROR: "Seleccione el tipo numérico",
  VALIDATE_MIN_NEGATIVE: "El valor mínimo no debe ser negativo",
  VALIDATE_MAX_NEGATIVE: "El valor máximo no debe ser negativo",
  UNIT_TYPE: "Seleccione el tipo de unidad",
  ACTIVITY_ERROR: "Por favor, defina la actividad",
  VERSION_ID: "Se requiere el ID de la versión para actualizar la actividad. Seleccione la identificación de la versión.",
  ACTIVITY_RESPONSE: "Agregue al menos una respuesta para la actividad",
  OPTION_ERROR: "Por favor ingrese la opción",
  SAVE_CHANGES: "Guardar cambios",
  NUMERIC_MIN_DECIMAL: "El rango mínimo no debe ser decimal",
  NUMERIC_MAX_DECIMAL: "El rango máximo no debe ser decimal",
  DESCRIPTION: "Descripción",
  INSERT_RESPONSE_HERE: "Insertar respuesta aquí",
  PLEASE_ENTER_VALID_ACTIVITY_HTML: "Please Enter Valid Html",
  RESPONSE_VALUE: "Response value",
  RESPONSE_VALUE_ERROR: "Please define response value",
  PLEASE_ENTER_BUTTON_NAME: "Please enter button name",
  PLEASE_SELECT_BUTTON_COLOR: "Please select button color",
  ADD_ANOTHER_BUTTON: "Add another button",
  BUTTON: "Button",
  BUTTON_COLOR: "Button Color",
  TEXT_COLOR: "Text Color",
  SELECT_CUSTOM_COLOR: "Select Custom Color",
  FILE_RESPONSE_NOTE: "Note: The observer will be prompted to upload file when presented with this response type.",
  SIGNATURE_RESPONSE_NOTE: "Note: The observer will be prompted to add their signature when presented with this response type.",
  ADD_DESCRIPTION_HERE: "Add description here...",
  ALLOW_NA: "Allow N/A"
}
